import {ID} from '../../../_metronic/helpers'
import {statusColor} from '../../../_metronic/helpers/custom/funcs/statusColor'
import {StatusBarSettings} from './StatusBarSettings'

interface Props {
  status: {trueOption: string; falseOption: string; currentBooleanValue: boolean}
  allowDelete?: boolean
  id: ID
  refetch?: () => void
  settings?: boolean
}

function StatusBar({status, id, allowDelete, refetch, settings = true}: Props) {
  return (
    <>
      <button
        type='button'
        className='btn p-0 me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom-end'
        data-kt-menu-id={`status-bar-${id}`}
      >
        <span
          className={`mt-1 fw-semobold fs-7 StatusBar__status ${statusColor(
            status.currentBooleanValue
          )}`}
        >
          {status.currentBooleanValue === true ? status.trueOption : status.falseOption}
        </span>
      </button>
      {settings && <StatusBarSettings allowDelete={allowDelete} id={id} refetch={refetch} />}
    </>
  )
}

export {StatusBar}
