import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UserGroupsListHeader} from './components/header/UserGroupsListHeader'
import {UserGroupsTable} from './table/UserGroupsTable'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
} from '../../../../_metronic/helpers'
import {useEffect} from 'react'

const UserGroupsList = () => {
  const {itemIdForUpdate, filterUsers} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.USER_GROUPS_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])
  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Групи користувачів</h3>
      </div>

      <KTCard>
        <UserGroupsListHeader />
        <UserGroupsTable />
      </KTCard>
    </>
  )
}

const UserGroupsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UserGroupsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UserGroupsListWrapper}
