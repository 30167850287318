import {FC, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {useListView} from '../../../../core/ListViewProvider'
import {IOrder, IOrderProps} from '../../../../core/_models/_orders-models'

const types = {buyout: 'Викуп', commerce: 'Комерція'}

const OrderTypeCell = ({order}: IOrderProps) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{types[order?.type]}</div>
    </div>
  )
}

export {OrderTypeCell}
