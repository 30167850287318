import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../websites/core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ICreateNewOrder, IOrder} from '../../../../orders/core/_models/_orders-models'
import {editOrder} from '../../../../orders/core/_requests'

interface Props {
  buyout: IOrder
  invalidateBuyout: () => void
}
const BuyoutOverview = ({buyout, invalidateBuyout}: Props) => {
  const queryClient = useQueryClient()
  const initialValues: Partial<ICreateNewOrder> = {
    number: buyout.number,
  }
  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ICreateNewOrder>) => {
    const {number} = values
    setLoading(true)

    try {
      const dataToSend: Partial<ICreateNewOrder> = {
        id: buyout.id,
        number,
      }

      await editOrder(dataToSend)

      invalidateBuyout()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Номер викупу</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='number' required={true} type='text' />
                </div>
              </div>{' '}
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BuyoutOverview
