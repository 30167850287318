import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {ICategory} from '../../../../_models'
import {CategoryActionsCell} from './cells/CategoryActionsCell'
import {CategoryTitleCell} from './cells/CategoryTitleCell'
import {CategoryWebsitesCell} from './cells/CategoryWebsitesCell'
import {CategoryContentCell} from './cells/CategoryContentCell'

const categoriesColumns: ReadonlyArray<Column<ICategory>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'BLOG_COLUMNS_TITLE'} className='min-w-125px' />
    ),
    id: 'title',
    Cell: ({...props}) => <CategoryTitleCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'BLOG_COLUMNS_WEBSITES'} className='min-w-150px' />
    ),
    id: 'websites',
    Cell: ({...props}) => <CategoryWebsitesCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'BLOG_COLUMNS_CONTENT'} className='min-w-50px' />
    ),
    id: 'content',
    Cell: ({...props}) => <CategoryContentCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'COLUMNS_ACTIONS'} className='min-w-120px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CategoryActionsCell category={props.data[props.row.index]} />,
  },
]

export {categoriesColumns}
