import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {TemplatesListToolbar} from './TemplatesListToolbar'

const TemplatesListHeader = () => {
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      {' '}
      <ListSearch updateState={updateState} />
      <div className='card-toolbar'>
        <TemplatesListToolbar />
      </div>
    </div>
  )
}

export {TemplatesListHeader}
