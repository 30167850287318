import {Form, Formik, FormikHelpers} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, QUERIES, selectDebounce} from '../../../../../../_metronic/helpers'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWebsite} from '../../../../websites/core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {IArticle, IEditArticle, IEditArticleForm} from '../../../core/_models'
import {editArticle, uploadImage} from '../../../core/_requests'
import setErrorsForFormFromErrorsObject from '../../../../../../_metronic/helpers/custom/funcs/setErrorsForFormFromErrorsObject'
import WebsitesSelect from "../../../../../modules/custom/form-elements/selects/WebsitesSelect";
import {ICategorySelectOption} from "../../../../lists/categories/core/_models";
import {getCategories} from "../../../../categories/stores/core/_requests";
import PartialPageLoader from "../../../../../../_metronic/layout/core/PartialPageLoader";
import CategoriesMultipleSelect from "../../../../../modules/custom/form-elements/selects/CategorySelect";

interface Props {
  article: IEditArticle
  invalidateArticle: () => void
}
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const ArticleOverview = ({article, invalidateArticle}: Props) => {
  const intl = useIntl()
  const {locale = 'uk'} = useParams()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => +item.id === article.website.id)
  const initTranslations =
    article?.translations && article?.translations[locale]
      ? {...article?.translations[locale]}
      : {
          title: '',
          shortDescription: '',
          description: '',
          metaDescription: '',
          metaKeywords: '',
          metaTitle: '',
          breadcrumb: '',
        }
  const initialValues: Partial<IEditArticleForm> = {
    ...article,
    website: {...website, value: website?.id, label: website?.name || ''},
    ...initTranslations,
    categories:
      article.categories?.map((i) => ({
        value: i.id,
        label: i.name || '',
        image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
      })) || [],
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const [imageObj, setImageObj] = useState<{image: string; imageUrl: string} | null>({
    image: article.image || '',
    imageUrl: article.imageUrl || '',
  })

  const handleSubmit = async (
    values: Partial<IEditArticleForm>,
    formikHelpers: FormikHelpers<Partial<IEditArticleForm>>
  ) => {
    console.log('values', values)
    const {title, shortDescription, slug, name, authorName, breadcrumb} = values
    setLoading(true)

    try {
      let template: any = {
        description: '',
        metaDescription: '',
        metaKeywords: '',
        metaTitle: '',
        shortDescription: '',
        title: '',
        breadcrumb: null,
      }

      if (article?.translations && article?.translations[locale]) {
        template = {...template, ...article?.translations[locale]}
      }
      let translations = {
        ...article?.translations,
        [locale]: {
          ...template,

          title,
          shortDescription,
          breadcrumb,
        },
      }

      const dataToSend: Partial<IEditArticle> = {
        slug,
        image: imageObj?.image || null,
        translations,
        authorName,
      }

      if (
        locale.toLowerCase().trim() === website?.mainLocale.toLowerCase().trim() &&
        name !== title
      ) {
        dataToSend.name = title || name
      }

      console.log(dataToSend)
      await editArticle(dataToSend, article.id)

      invalidateArticle()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      console.log(error?.response?.data?.errors)
      if (error.response?.data?.errors) {
        setErrorsForFormFromErrorsObject({error, values, formikHelpers, intl})
      }
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }
  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    try {
      setLoading(true)

      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        'image',
        'image',
        uploadImage,
        false,
        'imageUrl'
      )
      console.log('linkres', linkResponse)

      setImageObj(linkResponse)

      invalidateArticle()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('image', '')

    setImageObj(null)
  }

  const [resultsLoading, setResultsLoading] = useState(false)

  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>(
    []
  )

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)
      getCategories(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getCategories]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {categoriesResults && categoriesResults.length > 0 ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form' id='form-overview-blog'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <div className='row align-items-center'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Сайт</label>

                  <div className='col-lg-8'>
                    <WebsitesSelect withTitle={false}/>
                  </div>
                </div>
                <div className='row my-7 align-items-center'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Категорії</label>
                  {/* {JSON.stringify(values.categories)} */}
                  <div className='col-lg-8'>
                    <CategoriesMultipleSelect
                      categoriesAvailable={categoriesResults.map((i) => ({
                        image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
                        label: i.name,
                        value: i.id,
                      }))}
                      values={values}
                    />
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Частина URL</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName={'slug'} required={true} type='text'/>
                  </div>
                </div>
                <div>
                  <FileInput
                    title={intl.formatMessage({
                      id: 'GENERAL.UPLOAD_IMAGE_LABEL',
                    })}
                    inputName='image'
                    inputId={`${article.id}-article-file-input`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: imageObj?.imageUrl || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    loading={loading}
                  />
                </div>
                {' '}
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='title' required={true} type='text'/>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Автор</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='authorName' required={true} type='text'/>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Опис</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='shortDescription' type='textarea'/>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Хлібні крихти</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='breadcrumb' required={true} type='text'/>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false}/>
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader/>
      )}
    </div>
  )
}

export default ArticleOverview
