import {ID, Response} from '../../../../_metronic/helpers'
import {
  IBasicSelect,
  TZeroOnes,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
export type IWHidden = {addressHidden: boolean; secondaryAddressHidden: boolean}

export type TAddresses = {
  addressName: string
  addressData: string[]
  secondaryAddressName?: string
  secondaryAddressData?: string[]
}
export type TName = {
  name: string | null
}

export type TWarehouseTranslations = Record<string, TAddresses & TName>

export type TCountryConfig = {countryConfig: ICountryConfig}

export type TShipmentBooleanTypes = {
  shipmentLocal?: boolean
  shipmentInternational?: boolean
  shipmentToClient?: boolean
}

export interface IWarehouse
  extends TAddresses,
    TName,
    IWHidden,
    TCountryConfig,
    TShipmentBooleanTypes {
  id: number
  translations: TWarehouseTranslations
  locales: string[]
  acceptType?: 'none' | 'amount' | 'full'
}

export interface ICountryConfig {
  country: string
  locationAddressFields: string[]
  parcelIdentByOrderNumber: boolean
}

export interface ICountryConfigForm extends Partial<ICountryConfig>, IBasicSelect {}

export interface ICreateWarehouseForm extends Pick<IWarehouse, 'addressData' | 'acceptType'> {
  name: string
  countryConfig: ICountryConfigForm
  hidden: TZeroOnes
  shipmentType?: number
}
export interface ICreateWarehouse
  extends TName,
    IWHidden,
    Pick<IWarehouse, 'acceptType'>,
    TShipmentBooleanTypes {
  countryConfig: {id: ID}
  translations: Partial<TWarehouseTranslations>
}

export type WarehousesQueryResponse = Response<Array<IWarehouse>>

export const warehouseLocales = ['uk', 'ru']
