/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {IParcel} from '../../../../core/_models/_models'

type Props = {
  parcel: IParcel
}

const ParcelDateCell = ({parcel}: Props) => {
  return (
    <div className='p-1 fs-7 S fw-semobold d-flex flex-column'>
      {parcel.createdAt && (
        <>
          {' '}
          <span>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={parcel.createdAt} />
          </span>
          <span>
            {' '}
            <FormattedTime
              value={new Date(parcel.createdAt)}
              hour='numeric'
              minute='numeric'
              second='numeric'
            />
          </span>
        </>
      )}
    </div>
  )
}

export {ParcelDateCell}
