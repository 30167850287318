import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'

const answerSchema = Yup.object().shape({
  answer: Yup.string().when('allowOwnAnswer', {
    is: false,
    then: Yup.string().min(1, 'Вкажіть щонайменше 1 символ').required('Вкажіть варіант відповіді'),
    otherwise: Yup.string(),
  }),
})

const questionSchema = Yup.object().shape({
  question: Yup.string().min(3, 'Вкажіть щонайменше 3 символи').required('Заповніть питання'),
  allowOwnAnswer: Yup.boolean(),
  answers: Yup.array()
    .of(answerSchema)
    .when('allowOwnAnswer', {
      is: true,
      then: Yup.array(),
      otherwise: Yup.array()
        .min(2, 'Вкажіть, як мінімум, два варіанти відповіді')
        .required('Вкажіть відповіді'),
    }),
})

const createPollSchema = Yup.object({
  name: Yup.string().required('Вкажіть назву опитування'),
  website: object_shape_basic_select,
  type: Yup.string().required('Вкажіть тип опитування'),
  questions: Yup.array()
    .of(questionSchema)
    .min(1, 'Вкажіть щонайменше одне питання')
    .required('Вкажіть питання'),
})

const editPollOverview = (intl: any) =>
  Yup.object({
    name: Yup.string().required('Вкажіть назву опитування'),
    website: object_shape_basic_select,
    type: Yup.string().required('Вкажіть тип опитування'),
    enabled: Yup.number(),
  })

export {createPollSchema, editPollOverview}
