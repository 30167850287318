import {useEffect, useState} from 'react'
import {getLayout, ILayout, usePageData} from '../../../../../_metronic/layout/core'
import {EditItemForm} from './components/EditItemForm'
import {getFulfPrintMode, setFulfPrintMode} from './components/printing/helpers/printModeLS'
import {PostponedPrintQueue} from './components/printing/PostponedPrintQueue'
import {PrintModeSelect} from './components/printing/PrintModeSelect'
import {ReceivedItemsTable} from './components/ReceivedItemsTable'
import {ReceiveShipmentForm} from './components/ReceiveShipmentForm'
import {ContextProvider, useReceiveShipmentContext} from './core/ReceiveShipmentContext'

const ReceiveShipmentPage = () => {
  const {receivedItems, initiatePrint, printQueue, itemForUpdate, errorMessage} =
    useReceiveShipmentContext()
  const {addNotification} = usePageData()
  const [config, setConfig] = useState<ILayout>(getLayout())

  const receivedItemToEdit = receivedItems.find((item) => item.code === itemForUpdate)

  useEffect(() => {
    if (errorMessage) {
      addNotification({
        type: 'danger',
        body: '',
        sound: true,
        soundSrc: '/media/music/error.mp3',
      })
    }
    // if (errorMessage === '') {
    //   addNotification({
    //     type: 'success',
    //     body: '',
    //     sound: true,
    //     soundSrc: '/media/music/end-tone.wav',
    //   })
    // }
  }, [errorMessage])

  useEffect(() => {
    const lsmode = getFulfPrintMode()
    if (!lsmode) {
      setFulfPrintMode('2')
    }
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end'>
        <PrintModeSelect />
      </div>
      {getFulfPrintMode() === '2' && initiatePrint === true && (
        <PostponedPrintQueue printQueue={printQueue} />
      )}
      <ReceiveShipmentForm />
      {itemForUpdate && receivedItemToEdit && <EditItemForm item={receivedItemToEdit} />}
      {receivedItems.length > 0 && <ReceivedItemsTable />}{' '}
    </>
  )
}

export const ReceiveShipmentWrapper = () => {
  return (
    <ContextProvider>
      <ReceiveShipmentPage />
    </ContextProvider>
  )
}
