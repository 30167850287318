import React, {useEffect, useRef} from 'react'
import ReactToPrint from 'react-to-print'

type ActionButtonProps = {
  componentToPrint: React.MutableRefObject<null>
  id: string
}

export const ActionButton = (props: ActionButtonProps) => {
  const {componentToPrint, id} = props

  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    buttonRef.current?.click()
  }, [])

  return (
    <div className='visually-hidden'>
      <ReactToPrint
        trigger={() => (
          <button type='button' ref={buttonRef} id={`print-${id}`}>
            {' '}
            {'Print'}{' '}
          </button>
        )}
        content={() => componentToPrint.current}
        pageStyle='@page { size: 30mm 20mm }'
      />
    </div>
  )
}
