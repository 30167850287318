import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {ArticleMeta} from './tabs/meta/ArticleMeta'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {ArticleHeader} from './header/ArticleHeader'
import {ArticleNoResults} from './ArticleNoResults'
import {IEditArticle, TArticleLog} from '../core/_models'
import {getArticleById, getArticleLogsById} from '../core/_requests'
import ArticleOverview from './tabs/overview/ArticleOverview'
import {ArticleText} from './tabs/content/ArticleText'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {ArticleLogs} from './tabs/logs/ArticleLogs'
import {IOrderHistory} from '../../orders/core/_models/_orders-models'

interface Props {
  id: string | number
}

const ArticlePage = ({id}: Props) => {
  const {data: articleById} = useQuery(`blog-article-${id}`, () => getArticleById(id))

  const [currArticle, setCurrArticle] = useState<IEditArticle | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateArticle = () => {
    queryClient.invalidateQueries(`blog-article-${id}`)
  }

  useEffect(() => {
    if (articleById) {
      setCurrArticle(articleById)
    }
  }, [articleById])

  const {data: history} =
    useQuery<TArticleLog[] | undefined>(`article-history-by-id-${id}`, () =>
      getArticleLogsById(id)
    ) || []

  return (
    <>
      {currArticle && articleById ? (
        <Routes>
          <Route
            element={
              <>
                <ArticleHeader article={currArticle} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <ArticleOverview article={currArticle} invalidateArticle={invalidateArticle} />
                </>
              }
            />

            <Route
              path={`text/:locale/:id`}
              element={
                <>
                  <ArticleText article={currArticle} invalidateArticle={invalidateArticle} />
                </>
              }
            />
            <Route
              path={`meta/:locale/:id`}
              element={
                <>
                  <ArticleMeta article={currArticle} invalidateArticle={invalidateArticle} />
                </>
              }
            />
            {history && (
              <Route
                path={`logs/:locale/:id`}
                element={
                  <>
                    <ArticleLogs article={currArticle} logs={history} />
                  </>
                }
              />
            )}
            <Route path={`:id`} element={<ArticleNoResults />} />

            <Route index element={<Navigate to={`edit/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ArticlePage
