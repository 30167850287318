const secondsToHoursMinsSeconds = (count: number) => {
  const days = Math.floor(count / (3600 * 24))
  const remainingSeconds = count % (3600 * 24)
  const hours = Math.floor(remainingSeconds / 3600)
  const minutes = Math.floor((remainingSeconds % 3600) / 60)
  const remainingSecondsFinal = remainingSeconds % 60

  const obj = {
    minutes,
    remainingSecondsFinal,
    hours,
    days,
  }
  return obj
}

export default secondsToHoursMinsSeconds
