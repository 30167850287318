import {Column} from 'react-table'
import {LocationCustomHeader} from './LocationCustomHeader'
import {Location} from '../../../core/_models'
import {LocationCityCell} from './cells/LocationCityCell'
import {LocationRecCell} from './cells/LocationRecCell'
import {LocationActionsCell} from './cells/LocationActionsCell'
import {LocationRegionCell} from './cells/LocationRegionCell'
import {LocationSelectionHeader} from './cells/selection/LocationSelectionHeader'
import {LocationSelectionCell} from './cells/selection/LocationSelectionCell'
import {LocationDelRegionsCell} from './cells/LocationDelRegionsCell'

const locationsColumns: ReadonlyArray<Column<Location>> = [
  {
    Header: (props) => <LocationSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <LocationSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <LocationCustomHeader
        tableProps={props}
        title={'LOCATIONS_COLUMNS_NAME'}
        className='min-w-125px text-center'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <LocationCityCell location={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <LocationCustomHeader
        tableProps={props}
        title={'LOCATIONS_COLUMNS_REGION'}
        className='min-w-125px'
      />
    ),
    id: 'region',
    Cell: ({...props}) => <LocationRegionCell location={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <LocationCustomHeader
        tableProps={props}
        title={'LOCATIONS_COLUMNS_DEL_REGIONS'}
        className='min-w-80px text-center'
      />
    ),
    id: 'del-regions',
    Cell: ({...props}) => <LocationDelRegionsCell location={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <LocationCustomHeader
        tableProps={props}
        title={'LOCATIONS_COLUMNS_REC_NUMBER'}
        className='min-w-80px text-center'
      />
    ),
    id: 'rec-number',
    Cell: ({...props}) => <LocationRecCell location={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <LocationCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <LocationActionsCell location={props.data[props.row.index]} />,
  },
]

export {locationsColumns}
