import {
  ICurrenciesList,
  TCurrencyData,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Field} from 'formik'
import currenciesLocal from '../../../../data/general/currencies.json'
import {useEffect, useState} from 'react'

interface Props {
  name: string
  currencies: ICurrenciesList[]
}

const topCurrencies = ['USD', 'EUR', 'PLN', 'UAH', 'GBP']

function CurrencySelect({name, currencies}: Props) {
  const [currCurrencies, setCurrCurrencies] = useState(currencies)
  const pushFront = (arr: {code: string; name: string}[], ...strings: string[]) => {
    const result: {code: string; name: string}[] = []
    strings.forEach((el) => {
      const line = arr.find((item) => item.code === el)
      if (line) {
        result.push(line)
      }
    })
    setCurrCurrencies(result)
  }

  useEffect(() => {
    pushFront(currencies, ...topCurrencies)
  }, [])

  const currSymbols: TCurrencyData = currenciesLocal
  return (
    <Field
      as='select'
      name={name}
      className='form-select form-select-solid'
      placeholder='Оберіть валюту'
    >
      <option>Оберіть валюту</option>
      {currCurrencies.map(({code, name}, index) => {
        // console.log('list', currenciesLocal[code].symbol)

        return (
          <option key={index} value={code}>
            {name.charAt(0).toUpperCase() + name.slice(1)}
            {currenciesLocal.hasOwnProperty(code) && ` ${currSymbols[code].symbol}`}
          </option>
        )
      })}
    </Field>
  )
}

export {CurrencySelect}
