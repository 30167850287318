import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {TemplateFieldsTab} from './TemplateFieldsTab'
import {TemplateHeader} from './header/TemplateHeader'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getTemplateById} from '../templates-list/core/_requests'
import {EmailTemplate} from '../templates-list/core/_models'
import {TemplateNoResults} from './TemplateNoResults'
import TemplateOverviewTab from './TemplateOverviewTab'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const TemplatePage = ({id}: Props) => {
  const {data: templateById} = useQuery(`template-${id}`, () => getTemplateById(id))

  const [currTemplate, setCurrTemplate] = useState<EmailTemplate | undefined>(undefined)

  useEffect(() => {
    if (templateById) {
      setCurrTemplate(templateById)
    }
  }, [templateById])

  return (
    <>
      {currTemplate && templateById ? (
        <Routes>
          <Route
            element={
              <>
                <TemplateHeader template={currTemplate} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <TemplateOverviewTab template={currTemplate} setCurrTemplate={setCurrTemplate} />
                </>
              }
            />
            <Route
              path={`fields/:locale/:id`}
              element={
                <>
                  <TemplateFieldsTab template={currTemplate} setCurrTemplate={setCurrTemplate} />
                </>
              }
            />

            <Route path={`:id`} element={<TemplateNoResults />} />

            <Route index element={<Navigate to={`/edit/overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default TemplatePage
