import {useEffect, useState} from 'react'
import {getLayout, ILayout, usePageData} from '../../../../../_metronic/layout/core'
import {EditItemForm} from './components/EditItemForm'
import {getFulfPrintMode, setFulfPrintMode} from './components/printing/helpers/printModeLS'
import {PostponedPrintQueue} from './components/printing/PostponedPrintQueue'
import {PrintModeSelect} from './components/printing/PrintModeSelect'
import {UnpackedItemsTable} from './components/UnpackedItemsTable'
import {UnpackShipmentForm} from './components/UnpackShipmentForm'
import {ContextProvider, useUnpackShipmentContext} from './core/UnpackShipmentContext'

const UnpackShipmentPage = () => {
  const {unpackedItems, initiatePrint, printQueue, itemForUpdate, errorMessage} =
    useUnpackShipmentContext()
  const {addNotification} = usePageData()
  const [config, setConfig] = useState<ILayout>(getLayout())

  const unpackedItemToEdit = unpackedItems.find((item) => item.code === itemForUpdate)

  useEffect(() => {
    if (errorMessage) {
      addNotification({
        type: 'danger',
        body: '',
        sound: true,
        soundSrc: '/media/music/error.mp3',
      })
    }
    // if (errorMessage === '') {
    //   addNotification({
    //     type: 'success',
    //     body: '',
    //     sound: true,
    //     soundSrc: '/media/music/end-tone.wav',
    //   })
    // }
  }, [errorMessage])

  useEffect(() => {
    const lsmode = getFulfPrintMode()
    if (!lsmode) {
      setFulfPrintMode('2')
    }
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end'>
        <PrintModeSelect />
      </div>
      {getFulfPrintMode() === '2' && initiatePrint === true && (
        <PostponedPrintQueue printQueue={printQueue} />
      )}
      <UnpackShipmentForm />
      {itemForUpdate && unpackedItemToEdit && <EditItemForm item={unpackedItemToEdit} />}
      {unpackedItems.length > 0 && <UnpackedItemsTable />}{' '}
    </>
  )
}

export const UnpackShipmentWrapper = () => {
  return (
    <ContextProvider>
      <UnpackShipmentPage />
    </ContextProvider>
  )
}
