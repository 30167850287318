import {Dispatch, SetStateAction} from 'react'
import {errors} from '../../errors'
import {getImage} from '../../funcs/getDataFromServer'
import {getImageLink} from '../../funcs/getImageLink'
import {scanDimensions} from '../../funcs/scanDimensions'
import {zeroPad} from '../../funcs/zeroPad'
import {IUnpackedItem} from '../core/_models'

export async function scanFunction(
  e: KeyboardEvent,
  dimensionsRef: React.RefObject<HTMLInputElement>,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  itemToEdit: IUnpackedItem,
  setItemIdForUpdate: Dispatch<SetStateAction<string | null>>,
  setUnpackedItems: Dispatch<SetStateAction<IUnpackedItem[]>>
) {
  if (e.key.toLowerCase() === 'enter') {
    e.preventDefault()
    setErrorMessage('')

    let string = dimensionsRef.current?.value || ''

    if (!string.includes('|')) {
      console.log('wrong string for dimensions', string)

      setErrorMessage(errors.ODD_DATA_MESSAGE)
      return
    } else {
      setErrorMessage('')
    }
    const dimensions = scanDimensions(string, setErrorMessage)

    const editedItem = {...itemToEdit, ...dimensions}
    setUnpackedItems((prev) => {
      const filtered = prev.filter((item) => item.code !== itemToEdit.code)
      return [editedItem, ...filtered]
    })

    setItemIdForUpdate(itemToEdit.code)

    console.log('scanFunction', dimensions)
  }
}

export async function photoFunction(
  itemToEdit: IUnpackedItem,
  setItemIdForUpdate: Dispatch<SetStateAction<string | null>>,
  setUnpackedItems: Dispatch<SetStateAction<IUnpackedItem[]>>,
  codeClearAndFocus: () => void
) {
  //   setParcelImageLoading(true)
  await getImage()
    .then((blob) => {
      console.log('parcelBlobObj', blob)
      return getImageLink(blob, 1)
    })
    .then((res) => {
      console.log('res', res)

      const updatedPhotos = [res, ...itemToEdit.photos].filter(
        (item) => item !== '/media/icons/duotune/general/gen017.svg'
      )
      console.log('to edit', itemToEdit)
      const editedItem = {...itemToEdit, photos: updatedPhotos}
      console.log('editedItem', editedItem)

      setUnpackedItems((prev) => {
        const filtered = prev.filter((item) => item.code !== itemToEdit.code)

        return [editedItem, ...filtered]
      })

      setItemIdForUpdate(itemToEdit.code)
    })
    .finally(() => {
      codeClearAndFocus()
    })

  console.log('photoFunction')
}

export const processPhotoScanProd = async (
  e: KeyboardEvent,
  dimensionsRef: React.RefObject<HTMLInputElement>,
  closestParcel: IUnpackedItem,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setUnpackedItems: Dispatch<SetStateAction<IUnpackedItem[]>>,
  codeClearAndFocus: () => void,
  unpackedItems: IUnpackedItem[]
) => {
  if (e.key.toLowerCase() === 'enter') {
    e.preventDefault()
    setErrorMessage('')
    let photo: string[] = []
    await getImage()
      .then((blob) => {
        // console.log('parcelBlobObj', blob)
        return getImageLink(blob, 1)
      })
      .then((retrievedImage) => {
        // console.log('retrievedImage', retrievedImage)
        photo = [retrievedImage]
      })

    let string = dimensionsRef.current?.value || ''

    if (!string.includes('|')) {
      // console.log('wrong string for dimensions', string)

      setErrorMessage(errors.ODD_DATA_MESSAGE)
      return
    }
    const dimensions = scanDimensions(string, setErrorMessage)
    const idCode = closestParcel.code + `-${zeroPad(unpackedItems.length + 1, 2)}`
    const newProd: IUnpackedItem = {
      code: idCode,
      weight: dimensions.weight,
      width: dimensions.width,
      length: dimensions.length,
      height: dimensions.height,
      photos: photo,
      client: closestParcel.client,
      type: 2,
    }

    console.log('newProd', newProd)
    if (unpackedItems.some((item) => item.code === idCode)) {
      return
    } else {
      setUnpackedItems((prev) => {
        console.log('prev', prev)
        return [newProd, ...prev]
      })
    }

    codeClearAndFocus()
    console.log('scanner photoscanprod')
  }
}
