import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {IWebsite} from '../../../websites/core/_models'
import {IPoll} from '../../core/_models'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileExcel} from '@fortawesome/free-regular-svg-icons'
import {pollsApi} from '../../core/_requests'

interface Props {
  poll: IPoll
  website: IWebsite | undefined
}

const PollHeaderLangSwitcher = ({poll, website}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const downloadResults = async () => {
    await pollsApi.downloadExcelResultsById(poll.id, `${poll.name.replace(/\s+/g, '_')}_results`)
  }

  const availableLangs = website ? website.enabledLocales : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <div className='d-flex flex-column align-items-end'>
      <ul className='nav'>
        {website &&
          availableLangs.map((locale) => (
            <li className='nav-item' key={locale}>
              <button
                type='button'
                onClick={() => {
                  navigate(`/polls/edit/${currTab}/${locale}/${poll.id}`)

                  setActiveTab(locale)
                }}
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                  activeTab === locale ? 'active' : ''
                } fw-bold px-4`}
              >
                {locale}
              </button>
            </li>
          ))}
      </ul>
      <div className='mt-4'>
        <button
          type='button'
          className='btn btn-sm btn-light-primary d-flex align-items-center'
          onClick={downloadResults}
        >
          <FontAwesomeIcon icon={faFileExcel} fontSize={23} />
          <span className='ms-2 fs-6'>Завантажити</span>
        </button>
      </div>
    </div>
  )
}

export default PollHeaderLangSwitcher
