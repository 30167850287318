import {useQuery} from 'react-query'
import {TranslationEditModalForm} from './TranslationEditModalForm'
import {isNotEmpty} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {getTranslationKey} from '../../core/_requests'
import {TranslationEditModalHeader} from './TranslationEditModalHeader'
import {TTranslationPages} from '../../../../core/_models'

const TranslationEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: translation,
    error,
  } = useQuery(
    `websiteTranslation-translation-${itemIdForUpdate}`,
    () => {
      return getTranslationKey(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  // if (!itemIdForUpdate) {
  //   return <TranslationEditModalForm isUserLoading={isLoading} translation={{id: undefined}} />
  // }
  if (!isLoading && !error && translation) {
    return (
      <div className='modal-content'>
        <TranslationEditModalHeader
          pageName={
            (translation.websitePages &&
              translation.websitePages.length > 0 &&
              translation.websitePages?.map((item: TTranslationPages) => item.name)) ||
            undefined
          }
        />
        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          {' '}
          <TranslationEditModalForm isUserLoading={isLoading} translation={translation} />
        </div>
      </div>
    )
  }

  return null
}

export {TranslationEditModalFormWrapper}
