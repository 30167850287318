import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {AcceptedTrackOrOrderResponse} from '../../tracks-accept/core/_models/_models'
import {
  ICreateNewTrack,
  IEditTrack,
  Track,
  TracksQueryResponse,
} from '../core/_models/_tracks-models'

const API_URL = process.env.REACT_APP_API_PATH
const TRACKS_URL = `/tracking_numbers`
const BASE_URL = `${API_URL}${TRACKS_URL}`

const getTracks = (query: string): Promise<TracksQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<TracksQueryResponse>) => {
      return d.data
    })
}

const getTrackById = async (id: ID): Promise<Track | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const getRelatedInfoByTrackNumber = (
  number: string,
  location: ID
): Promise<AcceptedTrackOrOrderResponse | undefined> => {
  return axios
    .get(`${BASE_URL}/get_accept/${number}?location=${location}`)
    .then((response: AxiosResponse<AcceptedTrackOrOrderResponse>) => response.data)
}

const addTrack = (track: Partial<ICreateNewTrack>): Promise<Track | undefined> => {
  return axios
    .post(`${BASE_URL}/create-as-company`, track)
    .then((response: AxiosResponse<Response<Track>>) => response.data)
    .then((response: Response<Track>) => response.data)
}

const editTrack = (track: IEditTrack): Promise<Track | undefined> => {
  return axios
    .post(`${BASE_URL}/edit`, track)
    .then((response: AxiosResponse<Response<Track>>) => response.data)
    .then((response: Response<Track>) => response.data)
}

// invoices

const uploadInvoice = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const getTracksForNewShipment = (whId: ID): Promise<Track[] | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?location=${whId}&exists[shipment]=false`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Response<Track[]>>) => {
      return d.data
    })
    .then((d: Response<Track[]>) => {
      return d.data
    })
}

export {
  getTracks,
  uploadInvoice,
  addTrack,
  editTrack,
  getTrackById,
  getRelatedInfoByTrackNumber,
  getTracksForNewShipment,
}
