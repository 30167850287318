import {Form, Formik, FormikValues} from 'formik'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {ReceiverType} from '../../../../fulfillment/receivers/elements/ReceiverType'
import {
  countryOverviewReceiverSchema,
  countryOverviewSenderSchema,
} from '../../../countries-list/core/yup/country-page'
import {ICountry, IReceiverCountryForm} from '../../../countries-list/core/_models'
import {updateCountry} from '../../../countries-list/core/_requests'

interface Props {
  currCountry: ICountry
  setCurrCountry: Dispatch<SetStateAction<ICountry | undefined>>
}

const ReceiverLayout = ({setCurrCountry, currCountry}: Props) => {
  const intl = useIntl()
  const initialValues: IReceiverCountryForm = {
    receiverType: [
      currCountry?.receiverTypes?.international === true ? 1 : 0,
      currCountry?.receiverTypes?.final === true ? 2 : 0,
    ].filter(Boolean),
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<IReceiverCountryForm>, actions: FormikValues) => {
    const {receiverType} = values
    setLoading(true)
    const payload = {
      receiverTypes: {
        international: !!receiverType?.includes(1 || '1'),
        final: !!receiverType?.includes(2 || '2'),
      },
    }

    try {
      const response = await updateCountry(currCountry.id, payload)

      if (response) {
        setCurrCountry(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      validationSchema={countryOverviewReceiverSchema(intl)}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({values, setFieldValue, errors}) => (
        <Form noValidate className='form'>
          {/* {JSON.stringify(values)} */}
          {/* {JSON.stringify(errors)} */}
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Типи отримувачів:
              </label>
              <div className='col-lg-8'>
                <div className='row flex-nowrap'>
                  <div className='col-lg-6 fv-row me-4'>
                    <ReceiverType values={{receiverType: values.receiverType}} />
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
          <CardFooter loading={loading} success={footerNotification?.success || false} />
        </Form>
      )}
    </Formik>
  )
}

export default ReceiverLayout
