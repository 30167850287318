import {FC} from 'react'
import {EmailTemplate} from '../../../core/_models'

type Props = {
  template: EmailTemplate
}

const TemplateIdCell: FC<Props> = ({template}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>{template.id}</p>
    </div>
  )
}

export {TemplateIdCell}
