/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackParcelsCell: FC<Props> = ({track}: Props) => {
  return (
    <div className='d-flex flex-gutter flex-shrink-0'>
      {track.parcel ? (
        <Link
          to={`/parcels/edit/overview/${track.parcel.id}`}
          className='fw-semobold d-block fs-6 me-1 d-flex'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen017.svg'
            className={`svg-icon-3 svg-icon-gray-600 cursor-pointer me-1`}
          />

          <span>{track.parcel.code}</span>
        </Link>
      ) : (
        '-'
      )}
    </div>
  )
}

export {TrackParcelsCell}
