/* eslint-disable jsx-a11y/anchor-is-valid */
import {Formik, Form, FormikValues} from 'formik'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {useState} from 'react'
import {IShipment} from '../../../core/_models/_models'

interface Props {
  shipment: IShipment
  setCurrShipment: (obj: IShipment) => void
  invalidateShipment: () => void
}

export function ShipmentOverviewTab({shipment, setCurrShipment}: Props) {
  const initialValues = {}

  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleSubmit = async (values: Partial<IShipment>, actions: FormikValues) => {
    const {} = values
    setLoading(true)
    // const response = await updateShipment(
    //   {
    //     phoneNumber: '+38' + phoneNumber,
    //     email,
    //     id: shipment.id,
    //   },
    //   shipment.id
    // )

    // if (response) {
    //   setCurrShipment(response)
    //   setLoading(false)
    //   setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //   setTimeout(() => {
    //     setFooterNotification(null)
    //   }, 3000)
    // } else {
    //   // set error
    //   setLoading(false)
    // }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={() => {}}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              <div className='card-body  p-9'></div>
              <CardFooter
                loading={loading}
                disabled={disableSubmit}
                success={false}
                // notification={footerNotification}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
