import {Notification} from '../../Notification'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ModalNotification} from '../../modals/ModalNotification'
import {FormattedMessage} from 'react-intl'
import {ErrorMessage} from 'formik'
import {TUploadedFile} from '../../../../../_metronic/helpers/custom/tsHelpers/fileHelpers'
import ImgDocPreview from './ImgDocPreview'

interface Props {
  inputName: string
  title: string
  setFieldValue: ISetFieldValue
  inputId: string
  loading: boolean
  linkEndpoint?: string // e.g. '/passport'
  uploadedFile: TUploadedFile
  uploadFunction?: (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    field: string
  ) => void
  simpleDeleteFunction?: () => void
  deleteFunction?: (setFieldValue: ISetFieldValue, field: string) => void
  placeholder?: string
  notification?: {bool: boolean; text?: string; borderTop?: boolean}
  required?: boolean
  proportionsTip?: string
  noExtraMargins?: boolean
}

export function FileInput({
  title,
  inputName,
  inputId,
  uploadedFile,
  loading,
  linkEndpoint = '',
  setFieldValue,
  uploadFunction = () => {
    return
  },
  deleteFunction,
  simpleDeleteFunction,
  placeholder,
  notification = {bool: false},
  required = false,
  proportionsTip,
  noExtraMargins = false,
}: Props) {
  return (
    <div className={`row ${noExtraMargins ? '' : 'mb-2'}`}>
      {title && (
        <label className={`col-lg-4 ${required === true ? 'required' : ''} fw-bold fs-6`}>
          {title}{' '}
        </label>
      )}
      <div className='col-lg-8'>
        <div className='row '>
          <div
            className={`col-lg-7 fv-row d-flex align-items-center${noExtraMargins ? '' : 'mb-6'}`}
          >
            {' '}
            <input
              name={inputName}
              accept='.jpg, .png, .pdf, .jpeg'
              className='form-control form-control-lg form-control-solid FileInput'
              type='file'
              placeholder={placeholder ? placeholder : ''}
              id={inputId}
              style={{display: 'none'}}
              onChange={(e) => uploadFunction(e, setFieldValue, inputName)}
            />
            <div>
              <label className='btn btn-sm btn-secondary btn-active-primary' htmlFor={inputId}>
                {uploadedFile && uploadedFile.link !== '' ? (
                  <FormattedMessage id='GENERAL.BUTTONS.UPDATE_FILE' />
                ) : (
                  <FormattedMessage id='GENERAL.BUTTONS.CHOOSE_FILE' />
                )}
              </label>
            </div>
            <div className='text-danger'>
              <ErrorMessage name={inputName} />
            </div>
            {proportionsTip && (
              <p className={`py-1 px-3 fs-6 fw-normal StatusBar__status bg-light-warning mt-3`}>
                {proportionsTip}
              </p>
            )}
          </div>

          <div
            className={`col-lg-5 fv-row ${
              noExtraMargins ? '' : 'mb-6'
            } position-relative ProfileOverview__passport-image`}
          >
            <label htmlFor={!uploadedFile || uploadedFile.link === '' ? inputId : ''}>
              {uploadedFile && (
                <ImgDocPreview
                  linkEndpoint={linkEndpoint}
                  loading={loading}
                  title={title}
                  uploadedFile={uploadedFile}
                />
              )}

              {uploadedFile && uploadedFile.link !== '' && (
                <div>
                  <button
                    type='button'
                    className='btn btn-icon btn-bg-white btn-active-color-primary btn-sm ProfileOverview__delete-image'
                    onClick={() => {
                      if (simpleDeleteFunction) {
                        simpleDeleteFunction()
                      } else if (deleteFunction) {
                        deleteFunction(setFieldValue, inputName)
                      } else {
                        return
                      }
                    }}
                  >
                    <ModalNotification
                      svg='/media/icons/duotune/general/gen027.svg'
                      addSvgClass='svg-icon-3'
                      addClass='ModalNotification__button-position'
                      children={
                        <div className='fs-7 fw-normal text-black'>
                          <FormattedMessage id='GENERAL.BUTTONS.DELETE_IMAGE' />
                        </div>
                      }
                    />
                  </button>
                </div>
              )}
            </label>
          </div>
        </div>{' '}
      </div>{' '}
      {notification.bool === true && (
        <Notification noteText={notification.text || ''} borderTop={notification.borderTop} />
      )}
    </div>
  )
}
