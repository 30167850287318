/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackActionsCell: FC<Props> = ({track}: Props) => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/tracks/edit/overview/${track.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
    </div>
  )
}

export {TrackActionsCell}
