import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
} from '../../../../_metronic/helpers'
import {TemplatesTable} from './table/TemplatesTable'
import {TemplatesListHeader} from './components/header/TemplatesListHeader'
import {useEffect} from 'react'

const TemplatesList = () => {
  const {filterUsers} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.EMAIL_TEMPLATES_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-end align-items-center mb-8 position-relative'
      ></div>
      <KTCard>
        <TemplatesListHeader />
        <TemplatesTable />
      </KTCard>
    </>
  )
}

const TemplatesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TemplatesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TemplatesListWrapper}
