import {useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'
import {Track} from '../../../../../pages/tracks/core/_models/_tracks-models'

interface Props {
  item: Track | IOrder
}
export function HeaderInfo({item}: Props) {
  const {number, products} = item
  const intl = useIntl()

  const codeRef = useRef(null)
  const [show, setShow] = useState(false)
  const target = useRef(null)
  return (
    <div className='flex-grow-1 w-100'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-0'>
              <p
                className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'
                ref={codeRef}
              >
                {number}
              </p>
              <div className='d-md-none d-lg-none'>
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{number}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{number}</p>
                </OverlayTrigger>
              </div>
            </div>
            <div
              ref={target}
              onClick={() => {
                copyToClipboard(codeRef, setShow)
                setTimeout(() => {
                  setShow(false)
                }, 1000)
              }}
              className='me-2 mb-2 mb-md-0'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${
                  show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
                }`}
              />
            </div>{' '}
            <Overlay target={target.current} show={show} placement='top'>
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                </Tooltip>
              )}
            </Overlay>
          </div>

          <div className='d-flex flex-wrap fw-bold fs-7 mb-4 pe-2'>
            {products.length > 0 ? (
              <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                <span className='me-1'>$</span>
                <span>{products.reduce((acc, i) => acc + i.priceForAll, 0)}</span>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
