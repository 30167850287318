import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQueries, useQuery} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {deliveryTypesApi} from '../core/_requests'
import {IDeliveryType} from '../core/_models'
// import {DeliveryTypeOverview} from './tabs/overview/DeliveryTypeOverview'
// import {DeliveryTypeParams} from './tabs/params/DeliveryTypeParams'
// import {DeliveryTypeHeader} from './header/DeliveryTypeHeader'
import {generalApi} from '../../../../_metronic/helpers/custom/api/generalApi'
import {DeliveryTypeHeader} from './header/DeliveryTypeHeader'
import {DeliveryTypeOverview} from './tabs/overview/DeliveryTypeOverview'
import {DeliveryTypeCategories} from './tabs/categories/DeliveryTypeCategories'
import {DeliveryTypeConditions} from './tabs/delivery-conditions/DeliveryTypeConditions'
import {DeliveryTypeSettings} from './tabs/settings/DeliveryTypeSettings'

interface Props {
  id: string | number
}

const DeliveryTypePage = ({id}: Props) => {
  const intl = useIntl()

  const {data: delTypeById} = useQuery(QUERIES.DELIVERY_TYPE_BY_ID, () =>
    deliveryTypesApi.getDeliveryTypeById(id)
  )

  useQueries([
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
  ])

  const [currDeliveryType, setCurrentDeliveryType] = useState<IDeliveryType | undefined>(undefined)

  useEffect(() => {
    if (delTypeById) {
      setCurrentDeliveryType(delTypeById)
    }
  }, [delTypeById])

  return (
    <>
      {currDeliveryType && delTypeById ? (
        <Routes>
          <Route
            element={
              <>
                <DeliveryTypeHeader deliveryType={currDeliveryType} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <DeliveryTypeOverview
                    deliveryType={currDeliveryType}
                    setCurrDeliveryType={setCurrentDeliveryType}
                  />
                </>
              }
            />
            <Route
              path={`categories/:id`}
              element={
                <>
                  <DeliveryTypeCategories
                    deliveryType={currDeliveryType}
                    setCurrDeliveryType={setCurrentDeliveryType}
                  />
                </>
              }
            />
            <Route
              path={`cost/:id`}
              element={
                <>
                  <DeliveryTypeConditions
                    deliveryType={currDeliveryType}
                    setCurrDeliveryType={setCurrentDeliveryType}
                  />
                </>
              }
            />
            <Route
              path={`add-fees/:id`}
              element={
                <>
                  <DeliveryTypeSettings
                    deliveryType={currDeliveryType}
                    setCurrDeliveryType={setCurrentDeliveryType}
                  />
                </>
              }
            />
            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default DeliveryTypePage
