import {Accordion} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {useFormikContext} from 'formik'
import {IInvoice} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {MultipleFileInput} from '../../../form-elements/file-inputs/MultipleFileInput'

interface Props {
  addedInvoices: IInvoice[]
  handleInvoiceArray: (item: IInvoice, itemIsAdded: boolean, setFieldValue: ISetFieldValue) => void
  uploadedFiles?: any
  uploadFunction?: (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    val: string
  ) => void
}

export function ListOfRecentInvoices({uploadedFiles, uploadFunction}: Props) {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <div className='custom-recent-invoices-accordion-header'>
          <Accordion.Header>
            <FormattedMessage id='TRACKS.COMPONENTS.RECENT_INVOICES.CHOOSE_BUTTON' />
          </Accordion.Header>
        </div>

        <Accordion.Body>
          <div className='table-responsive'>
            {' '}
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className='d-flex justify-content-center'>
                      <input
                        name={'require_invoice'}
                        className='form-control form-control-lg form-control-solid MultipleFileInput visually-hidden'
                        type='text'
                      />
                      <MultipleFileInput
                        inputId='invoice-input'
                        inputName='invoice_file'
                        uploadedFiles={uploadedFiles}
                        setFieldValue={setFieldValue}
                        uploadFunction={uploadFunction}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
