/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, useFormikContext} from 'formik'
import {useMutation} from 'react-query'
import {Modal} from 'react-bootstrap'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {FormattedMessage, useIntl} from 'react-intl'
import {CategoriesWrapper} from '../../../../../../modules/custom/sections/categories/CategoriesWrapper'
import {useAuth} from '../../../../../../modules/auth'
import {
  ICreateNewOrder,
  IOrder,
  IOrderBuyoutProps,
  IOrderFormProd,
} from '../../../../../orders/core/_models/_orders-models'
import {reformTrackFormProductToApi} from '../../../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {сreateBuyoutContentCardSchema} from '../../../../core/yup/buyout-page'
import {getCategories} from '../../../../../lists/categories/core/_requests'
import {editOrder} from '../../../../../orders/core/_requests'
import {getStoreByProductUrl} from '../../../../../stores/core/_requests'
import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IStore} from '../../../../../stores/core/_models'

interface Props {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  dataForEditing?: IOrderFormProd
  invalidateBuyout: () => void
}

const EditBuyoutProductsForm = ({
  setIsOpen,
  isOpen,
  buyout,
  dataForEditing,
  invalidateBuyout,
}: Props & IOrderBuyoutProps) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const inits: IOrderFormProd & {store?: IStore | null} = dataForEditing
    ? {...dataForEditing, store: buyout.websiteShop}
    : {
        category: {value: '', label: ''},
        qty: 1,
        cost: '',
        total: '',
        url: '',
        color: '',
        sku: '',
        size: '',
        store: null,
      }

  const [urlError, setUrlError] = useState('')

  const findStore = async (url: string, setFieldValue: ISetFieldValue, values: any) => {
    try {
      const res = await getStoreByProductUrl(url)

      if (res?.id && buyout.websiteShop?.id) {
        if (+buyout.websiteShop?.id !== +res?.id) {
          setUrlError(
            intl.formatMessage({id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.COST.URL_DIFF_STORE'})
          )
        } else {
          setFieldValue('store', res)
          setUrlError('')
        }
      } else if (res?.id && !buyout.websiteShop) {
        setFieldValue('store', res)
        setUrlError('')
      } else {
        setUrlError('')
      }
    } catch (error) {
      setUrlError('')

      console.log(error)
    }
  }

  const editBuyoutContentMutation = useMutation(editOrder, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      invalidateBuyout()

      closeModal()
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitStep = (values: IOrderFormProd, actions: FormikValues) => {
    let dataToSend: Partial<ICreateNewOrder> = {}
    if (dataForEditing?.id) {
      const oldProducts = buyout.products.filter(
        (item) => dataForEditing?.id && item?.id && +item?.id !== +dataForEditing?.id
      )

      dataToSend = {
        id: buyout.id,
        products: [
          ...oldProducts,
          {id: +dataForEditing?.id, ...reformTrackFormProductToApi(values)},
        ],
      }
    } else {
      dataToSend = {
        id: buyout.id,
        products: [...buyout.products, {...reformTrackFormProductToApi(values)}],
      }
    }


    editBuyoutContentMutation.mutate(dataToSend)

    actions.resetForm()
  }

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px'
      id='kt_modal_create_buyout_prod'
      aria-hidden='true'
    >
      <div className='modal-content CreateWebsite__overflow-container'>
        <div className='modal-header'>
          <h2>
            {dataForEditing ? (
              <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.EDIT_GOODS' />
            ) : (
              <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.ADD_GOODS' />
            )}
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-5 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={сreateBuyoutContentCardSchema(intl)}
              initialValues={inits}
              onSubmit={submitStep}
              enableReinitialize={true}
            >
              {({values, errors, setFieldValue}) => (
                <Form className='form' noValidate id='kt_modal_create_buyout_prod_form'>
                  {/* {JSON.stringify(dataForEditing?.id)} */}
                  <div className='w-100'>
                    <div className='fv-row mb-10'>
                      <div className='d-flex flex-column fv-row w-100'>
                        <InputTemplate
                          inputName='url'
                          type='text'
                          required={true}
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_LINK'})}
                          value={values.url}
                          marginClass='mb-0'
                          containerMarginBottom={false}
                          handleChange={(e: string) => {

                            const cleaned = e.replace(/\s/g, '')
                            setFieldValue('url', cleaned)
                            findStore(cleaned, setFieldValue, values)
                          }}
                          prependedFieldImage={
                            values?.store && (
                              <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer position-absolute top-50 translate-middle-y ps-3'>
                                <img
                                  alt=''
                                  className='rounded-circle w-40px me-2 fit-content'
                                  src={
                                    `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${values?.store?.logoUrl}` ||
                                    '/media/icons/duotune/ecommerce/ecm002.svg'
                                  }
                                />
                              </div>
                            )
                          }
                        />
                        <div className='text-danger'>{urlError}</div>
                      </div>
                      <div className='d-flex flex-column fv-row w-100'>
                        <CategoriesWrapper
                          inputName={{
                            category: `category`,
                            user_name: `user_name`,
                            model: `model`,
                          }}
                          setFieldValue={setFieldValue}
                          selectValues={{
                            category: values.category || {value: '', label: ''},
                          }}
                          apiRequest={getCategories}
                          values={values}
                        />
                      </div>
                      <div className='d-flex flex-gutter w-100'>
                        <InputTemplate
                          inputName={`qty`}
                          fieldWidth='w-50'
                          type='number'
                          required={true}
                          title={intl.formatMessage({
                            id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.QTY',
                          })}
                          value={values.qty}
                          handleChange={(val: number) =>
                            setFieldValue(`total`, (val * +values.cost).toFixed())
                          }
                          numberWithControls={true}
                        />

                        <InputTemplate
                          inputName={`cost`}
                          type='number'
                          fieldWidth='w-50'
                          required={true}
                          title={intl.formatMessage({
                            id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.COST',
                          })}
                          addSymbol={'$'}
                          value={values.cost}
                          handleChange={(val: number) =>
                            setFieldValue(`total`, (val * +values.qty).toFixed())
                          }
                        />
                      </div>{' '}
                      <div className='d-flex flex-gutter w-100'>
                        <InputTemplate
                          inputName={`total`}
                          addSymbol={'$'}
                          type='number'
                          fieldWidth='w-50'
                          required={true}
                          title={intl.formatMessage({
                            id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.TOTAL',
                          })}
                          value={values.total}
                          handleChange={(val: number) => {
                            const newVal = (val / +values.qty).toFixed(1)
                            setFieldValue(`cost`, newVal.replace(/\.0+$/, ''))
                          }}
                        />
                        <InputTemplate
                          inputName={`sku`}
                          type='text'
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.SKU'})}
                          fieldWidth='w-50'
                        />
                      </div>{' '}
                      <div className='d-flex flex-gutter w-100'>
                        <InputTemplate
                          inputName={`color`}
                          type='text'
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.COLOR'})}
                          fieldWidth='w-50'
                        />
                        <InputTemplate
                          inputName={`size`}
                          type='text'
                          fieldWidth='w-50'
                          title={intl.formatMessage({id: 'BUYOUTS.FORM.SIZE'})}
                        />{' '}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div>
                      <button
                        type='submit'
                        disabled={isLoading}
                        className='btn btn-lg btn-primary me-3'
                      >
                        <span className='indicator-label'>
                          {dataForEditing ? (
                            <FormattedMessage id='GENERAL.BUTTONS.SAVE' />
                          ) : (
                            <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                          )}

                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {EditBuyoutProductsForm}
