import {Formik, Form} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {ITranslationObject} from '../../../../websites/core/_models'
import {editTranslation} from '../../core/_requests'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  translation: ITranslationObject
  invalidateTranslation: () => void
}

export function TranslationTab({translation, invalidateTranslation}: Props) {
  const {locale} = useParams()
  const currLocale = locale || 'uk'

  const initialValues = {
    translation: translation.translations.find((item) => item.locale === currLocale)?.value || '',
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {translation: string}) => {
    setLoading(true)

    try {
      const translationsToSend = {
        ...translation,
        translations: [
          ...translation.translations.filter((item) => item.locale !== currLocale),
          {locale: currLocale, value: values.translation},
        ],
      }
      await editTranslation(translationsToSend, translation.id)

      invalidateTranslation()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {translation?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-client-tr'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <p> {translation.keyName}</p>
                    <p className='fs-8 text-muted'>{initialValues.translation}</p>
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate inputName='translation' type='text' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
