import {useEffect} from 'react'
import {useQueries, useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCountries} from '../countries/countries-list/core/_requests'
// import RegionPage from './region-page/RegionPage'
import {RegionsListWrapper} from './regions-list/RegionsList'
import RegionPage from './region-page/RegionPage'
import { couriersApi } from '../couriers/core/_requests'

const RegionsPage = () => {
  const location = useLocation()
  useQueries([
    {queryKey: [QUERIES.COUNTRIES_LIST], queryFn: () => getCountries('')},
    {queryKey: [QUERIES.COURIERS_LIST], queryFn: () => couriersApi.fetchCouriers('')},
  ])

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <RegionsListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <RegionPage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/areas/list' />} />
    </Routes>
  )
}

export default RegionsPage
