import React from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {DirectionsQueryResponse} from '../../directions/core/_models'
import {IDeliveryTypeForm} from '../core/_models'
import currenciesLocal from '../../../../data/general/currencies.json'
import {TCurrencyData} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface IProps {
  values: Partial<IDeliveryTypeForm>
  withTitle?: boolean
}
const OversizeAddFee = ({values, withTitle = true}: IProps) => {
  const currSymbols: TCurrencyData = currenciesLocal
  const queryClient = useQueryClient()

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}
  return (
    <div className='fv-row mb-5'>
      {withTitle && (
        <h3 className='fs-5 fw-bold mb-4 me-10'>Надбавка за перевищення розміру палети</h3>
      )}
      <div>
        {' '}
        <h4 className='fs-6 fw-normal mb-4 me-2'>Габарити палети:</h4>
        <div className='d-flex flex-gutter'>
          <InputTemplate
            title='Довжина палети'
            required={false}
            inputName='palletLength'
            titleFontSize='fs-7 fw-normal'
            type='number'
            fieldWidth='w-150px'
            addSymbol={
              directions?.data?.find((item) => values.direction && +values.direction === item.id)
                ?.sizeUnit
            }
            formValues={values}
          />

          <InputTemplate
            title='Ширина палети'
            required={false}
            inputName='palletWidth'
            titleFontSize='fs-7 fw-normal'
            type='number'
            fieldWidth='w-150px'
            addSymbol={
              directions?.data?.find((item) => values.direction && +values.direction === item.id)
                ?.sizeUnit
            }
            formValues={values}
          />
        </div>
      </div>
      <div>
        <h4 className='fs-6 fw-normal mb-4 me-2'>Розмір надбавки:</h4>
        <div className='d-flex mb-4'>
          {' '}
          <InputTemplate
            required={false}
            inputName='palletOversizeFee'
            type='number'
            fieldWidth='w-150px'
            addSymbol={
              currSymbols[
                //  @ts-ignore
                directions?.data?.find((item) => +values.direction === +item.id)?.serviceCurrency
              ]?.symbol ||
              directions?.data?.find((item) => values.direction && +values.direction === item.id)
                ?.serviceCurrency
            }
            symbolMarginTop={false}
            formValues={values}
          />
        </div>
      </div>
    </div>
  )
}

export default OversizeAddFee
