import {FormattedMessage} from 'react-intl'
import {AsideMenuItem} from '../AsideMenuItem'

const FinancesMenu = () => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-gray-300 fw-bold text-uppercase fs-8 ls-1'>
            <FormattedMessage id='GENERAL.ASIDE_MENU.FINANCES_SECTION' />
          </span>
        </div>
      </div>

      {menuItems.map((item, index) => (
        <AsideMenuItem
          key={index}
          to={item.to}
          title={item.title}
          fontIcon={item.fontIcon}
          icon={item.icon}
        />
      ))}
    </>
  )
}

const menuItems = [
  {
    to: '/finances/payments',
    title: 'GENERAL.ASIDE_MENU.FINANCES_PAYMENTS_TITLE',
    fontIcon: 'bi-archive',
    icon: '/media/icons/duotune/finance/fin004.svg',
  },
  {
    to: '/finances/payment-providers/list',
    title: 'GENERAL.ASIDE_MENU.FINANCES_PAYMENT_PROVIDERS_TITLE',
    fontIcon: 'bi-archive',
    icon: '/media/icons/duotune/finance/fin008.svg',
  },
]

export default FinancesMenu
