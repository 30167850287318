import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {IParcel} from '../../../core/_models/_models'
import {ParcelNumberCell} from './cells/ParcelNumberCell'
import {ParcelDateCell} from './cells/ParcelDateCell'
import {ParcelActionsCell} from './cells/ParcelActionsCell'
import {ParcelTracksCell} from './cells/ParcelTracksCell'
import {ParcelUserCell} from './cells/ParcelUserCell'
import {ParcelOrdersCell} from './cells/ParcelOrdersCell'
import {ParcelItemsInfoCell} from './cells/ParcelItemsInfoCell'

const parcelsColumns: ReadonlyArray<Column<IParcel>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PARCELS_COLUMNS_NUMBER'} className='min-w-50px' />
    ),
    id: 'number',
    Cell: ({...props}) => <ParcelNumberCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'TRACKS_COLUMNS_USER'} className='min-w-75px' />
    ),
    id: 'user',
    Cell: ({...props}) => <ParcelUserCell parcel={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PARCELS_COLUMNS_TRACKS'} className='min-w-80px' />
    ),
    id: 'tracks',
    Cell: ({...props}) => <ParcelTracksCell parcel={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'PARCELS_COLUMNS_ORDERS'}
  //       className='min-w-80px text-center'
  //     />
  //   ),
  //   id: 'order',
  //   Cell: ({...props}) => <ParcelOrdersCell parcel={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_CONTENT'}
        className='min-w-175px text-center'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <ParcelItemsInfoCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PARCELS_COLUMNS_DATE'} className='' />
    ),
    id: 'date',
    Cell: ({...props}) => <ParcelDateCell parcel={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ParcelActionsCell parcel={props.data[props.row.index]} />,
  },
]

export {parcelsColumns}
