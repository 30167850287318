/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {CourierHeaderName} from './CourierHeaderName'
import {CourierHeaderInfo} from './CourierHeaderInfo'
import {CourierHeaderNav} from './CourierHeaderNav'
import {ICourier} from '../../core/_models'

interface Props {
  courier: ICourier
}

const CourierHeader = ({courier}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    courier.logoUrl
                      ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` +
                        courier.logoUrl
                      : toAbsoluteUrl('/media/icons/duotune/maps/map005.svg')
                  }
                  alt='Courier'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <CourierHeaderName courier={courier} />{' '}
            </div>
          </div>
          <CourierHeaderInfo courier={courier} />
        </div>

        <CourierHeaderNav courier={courier} />
      </div>
    </div>
  )
}

export {CourierHeader}
