/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Location} from '../../core/_models'
import {LocationHeaderName} from './LocationHeaderName'
import {LocationHeaderInfo} from './LocationHeaderInfo'
import {LocationHeaderNav} from './LocationHeaderNav'

interface Props {
  location: Location
}

const LocationHeader = ({location}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/icons/duotune/maps/map008.svg')} alt='Location' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <LocationHeaderName location={location} />{' '}
              <div className='d-none d-md-block'>
                <LocationHeaderInfo location={location} />
              </div>
            </div>
          </div>
          <div className='d-md-none'>
            <LocationHeaderInfo location={location} />
          </div>
        </div>

        <LocationHeaderNav location={location} />
      </div>
    </div>
  )
}

export {LocationHeader}
