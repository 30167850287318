import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../_metronic/helpers/custom/funcs/directions'
import {IBasicSelect} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {directionsApi} from '../../../../../directions/core/_requests'
import {FormattedMessage, useIntl} from "react-intl";

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const DirectionFilter = ({savedFilter, setSavedFilter}: TProps) => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterDirections = useCallback(
    (callback?: any) => {
      directionsApi
        .fetchDirectionsWithIds('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
    },
    [directionsApi.fetchDirectionsWithIds, countries]
  )

  useEffect(() => {
    if(Object.keys(countries).length > 0)
      filterDirections()
  }, [countries])

  const handleOnChange = (event: { target: { value: any } }) => {
    setSavedFilter((prev) => {
      return {...prev, direction: event.target.value||null}
    })
  }

  return (
    <div className={`fv-row d-flex mt-1 flex-column mb-4`}>
      <label
        className={`position-relative flex-column fw-bold mb-2 required`}
        htmlFor={'directions'}
      >
        Напрямок:
      </label>
      <select className="form-select" onChange={handleOnChange} value={savedFilter.direction}>
        <option value=""><FormattedMessage id="TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL"/></option>
        {results.map(item =>
          <option key={item.value} value={`${item.value}`}>{item.label}</option>
        )}
      </select>
    </div>
  )
}

export default DirectionFilter
