import {TDeliveryTwoItem} from '../tsHelpers/deliveryTypesHelpers'

const minimumValueToSend = (
  min_value: number,
  weight: string | number | undefined,
  price: string | number | undefined
) => {
  if (min_value === 1) {
    return {min_weight: weight}
  } else if (min_value === 0) {
    return {min_price: price}
  } else {
    return
  }
}

const minValueInit = (weight: any, price: any) => {
  if (price) {
    return 0
  } else if (weight) {
    return 1
  } else {
    console.log('another value', weight, price)

    return 2
  }
}

const getDataForDeliveryTypeToSend = (
  type: number | string,
  minWeight: string | number | undefined,
  minPrice: string | number | undefined,
  price: string | number | null | undefined,
  max_weight: string | number | null | undefined,
  deliveryTwoFields: TDeliveryTwoItem[],
  min_value: number
) => {
  if (type == 1) {
    return {
      price: price,
      max_weight: max_weight,
      ...minimumValueToSend(min_value, minWeight, minPrice),
    }
  } else {
    let arrayLikeObject = {}
    deliveryTwoFields?.map(
      (item, index) =>
        (arrayLikeObject = {
          ...arrayLikeObject,
          [`${index + 1}`]: item.specificWeightPrice,
        })
    )

    return arrayLikeObject
  }
}
export {minimumValueToSend, minValueInit, getDataForDeliveryTypeToSend}
