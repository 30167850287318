import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'

export const createStoreSchema = (intl: any) =>
  Yup.object().shape({
    website: object_shape_basic_select.required('Вкажіть, як мінімум, один сайт'),
    name: Yup.string().min(3).required(intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS),
    slug: Yup.string().min(3).required(intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS),
    categories: Yup.array()
      .min(1, 'Оберіть, як мінімум, одну категорію')
      .required('Оберіть, як мінімум, одну категорію'),
  })
