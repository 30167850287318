/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {Portal} from '../../../partials'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {useLocation} from 'react-router-dom'
import {CreateWebsite} from '../../../../app/pages/websites/_modals/CreateWebsite'

const pathnames = ['/tracks', '/websites', '/directions', '/delivery-types']

function Toolbar1() {
  const {classes} = useLayout()
  const location = useLocation()

  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = () => {
    setModalOpen(true)
    // if (location.pathname === '/directions') {
    //   document.querySelector('.CreateDirection')?.classList.add('modal-open')
    // } else if (location.pathname === '/websites') {
    //   document.querySelector('.CreateWebsite')?.classList.add('modal-open')
    // }
  }
  return (
    <>
      {/* {modalOpen && location.pathname === '/directions' && (
        <Portal
          children={<CreateDirection setIsOpen={setModalOpen} isOpen={modalOpen} />}
          className='modal-root'
        />
      )} */}
      {modalOpen && location.pathname === '/websites' && (
        <Portal
          children={<CreateWebsite setIsOpen={setModalOpen} isOpen={modalOpen} />}
          className='modal-root'
        />
      )}

      {/* {modalOpen && location.pathname === '/tracks' && (
        <Portal
          children={<CreateTrack setIsOpen={setModalOpen} isOpen={modalOpen} />}
          className='modal-root'
        />
      )} */}

      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack mb-n20')}
        >
          <DefaultTitle />

          {/* begin::Actions */}
          <div className='d-flex align-items-center py-5'>
            {/* begin::Wrapper */}
            {/* <div className='me-4'>
              <a
                href='#'
                className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen031.svg'
                  className='svg-icon-5 svg-icon-gray-500 me-1'
                />
                Filter
              </a>

            </div> */}
            {/* end::Wrapper */}

            {/* begin::Button */}

            {pathnames.includes(location.pathname) && (
              <a
                href='#'
                className='btn btn-sm btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={handleClick}
              >
                {location.pathname === '/tracks' || location.pathname === '/lists/categories'
                  ? 'Добавить'
                  : 'Создать'}{' '}
              </a>
            )}
            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>

        {/* end::Container */}
      </div>
    </>
  )
}

export {Toolbar1}
