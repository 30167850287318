import {Field, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {DirectionsQueryResponse} from '../../directions/core/_models'
import {IDeliveryTypeForm} from '../core/_models'

interface IProps {
  values: Partial<IDeliveryTypeForm>
  withTitle?: boolean
}
const VolWeight = ({values, withTitle = true}: IProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()
  const queryClient = useQueryClient()
  const volumetricWeightOptions: string[] =
    queryClient.getQueryData([QUERIES.VOL_WEIGHT_OPTIONS]) || []

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}
  return (
    <div className='fv-row mb-5 d-flex'>
      {withTitle && <h3 className='required fs-5 fw-bold mb-4 me-10'> Об'ємна вага</h3>}
      <div className='d-flex flex-column'>
        {' '}
        {volumetricWeightOptions.map((item, index) => (
          <InputTemplate
            inputName='volumetricWeightType'
            required={false}
            type='radio'
            value={index}
            title={item}
            key={index}
            checkboxProps={{
              checked:
                (!!values.volumetricWeightType || values.volumetricWeightType === 0) &&
                +values.volumetricWeightType === +index,
              disabled: false,
              setFieldValue: setFieldValue,
              value: index,
              inputName: 'volumetricWeightType',
              type: 'radio',
            }}
          />
        ))}
        {!!values.volumetricWeightType && +values.volumetricWeightType === 2 && (
          <InputTemplate
            required={false}
            inputName='volumetricWeightMinValue'
            type='number'
            fieldWidth='w-150px'
            addSymbol={intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}
            symbolMarginTop={false}
          />
        )}
        {!!values.volumetricWeightType &&
          (+values.volumetricWeightType === 1 || +values.volumetricWeightType === 2) && (
            <>
              {' '}
              <InputTemplate
                inputName='volumetricWeightToWeightRatio'
                title="У скільки разів об'ємна вага має перевищувати фактичну:"
                type='number'
                // fieldWidth='w-150px'

                symbolMarginTop={false}
              />
              <InputTemplate
                inputName='volumetricWeightDivisor'
                type='number'
                title="Формула розрахунку об'ємної ваги"
                required={true}
                setFieldValue={setFieldValue}
                value={values.volumetricWeightDivisor}
                custom={
                  <div className='d-flex align-items-center flex-nowrap'>
                    <span className='fs-6 fw-normal col-7'>Довжина * Ширину * Висоту /</span>{' '}
                    <Field
                      name={'volumetricWeightDivisor'}
                      type='number'
                      className='form-control form-control-lg form-control-solid text-dark'
                    />
                  </div>
                }
              />
            </>
          )}
      </div>
    </div>
  )
}

export default VolWeight
