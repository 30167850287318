import {Formik, Form} from 'formik'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import CountriesSelect from '../../../../../modules/custom/form-elements/selects/CountriesSelect'
import {ICourier, ICreateNewCourierForm} from '../../../core/_models'
import {couriersApi} from '../../../core/_requests'
import DeliveryMethods from '../../../modules/DeliveryMethods'
import {delMethods} from '../../../_modals/CreateCourierForm'

interface Props {
  courier: ICourier
  setCurrCourier: (obj: ICourier) => void
}

export function CourierOverview({courier, setCurrCourier}: Props) {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const initialValues: Partial<ICreateNewCourierForm> = {
    type: courier.type,
    name: courier.name,
    enabled: courier.enabled ? 1 : 0,
    deliveryMethods: courier.deliveryMethods.map((i) => i.type),
    countryConfig: {
      value: courier.countryConfig?.id,
      label: courier.countryConfig ? countries[courier?.countryConfig?.country] : '',
    },
  }

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const [logoObj, setLogoObj] = useState<{logo: string; logoUrl: string} | null>({
    logo: courier.logo || '',
    logoUrl: courier.logoUrl || '',
  })
  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    try {
      setLoading(true)

      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        'logo',
        'logo',
        couriersApi.uploadFile,
        false,
        'logoUrl'
      )

      setLogoObj(linkResponse)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('logo', '')
    setLogoObj(null)
  }

  const handleSubmit = async (values: Partial<ICreateNewCourierForm>) => {
    setLoading(true)

    const {name, enabled, deliveryMethods, countryConfig} = values
    const dataTosend = {
      logo: logoObj?.logo || null,
      name,
      enabled: enabled && +enabled === 1 ? true : false,
      countryConfig: {id: countryConfig?.value},
      // @ts-ignore
      deliveryMethods: delMethods.filter((i) => deliveryMethods?.includes(i.type)),
    }
    const response = await couriersApi.editCourier(courier.id, dataTosend)

    if (response) {
      queryClient.invalidateQueries([QUERIES.COURIER_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {countries ? (
        <Formik
          // validationSchema={trackPageOverviewSchema(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  {/* {JSON.stringify(values)} */}
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='COURIERS.FORM.NAME' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <div className='col-lg-6 fv-row me-4'>
                        <InputTemplate inputName='name' type='text' required={true} />
                      </div>{' '}
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='COURIERS.FORM.STATUS' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <div className='col-lg-6 fv-row me-4'>
                        <CheckboxSwitcher
                          inputName='enabled'
                          firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'})}
                          secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'})}
                          // @ts-ignore
                          value={+values.enabled}
                          setFieldValue={setFieldValue}
                          bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                        />
                      </div>{' '}
                    </div>
                  </div>
                </div>
                {/* {JSON.stringify(values)} */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <DeliveryMethods delMethods={delMethods} flexRow={true} />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Країна </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <CountriesSelect queryFilter='filter[receive]=true' withTitle={false} />
                    </div>
                  </div>
                </div>
                <div>
                  <FileInput
                    title={intl.formatMessage({
                      id: 'GENERAL.UPLOAD_IMAGE_LABEL',
                    })}
                    inputName='image'
                    inputId={`${courier.id}-courier-file-input`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: logoObj?.logoUrl || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    loading={loading}
                  />
                </div>{' '}
              </div>
              <CardFooter loading={loading} success={success} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
