import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import { Direction } from '../../core/_models'

interface Props {
  direction: Direction
}

export function DirectionHeaderNav({direction}: Props) {
  const { pathname } = useLocation()
  
  
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
       {tabs.map(({path,intl})=><li key={path} className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/directions/edit/${path}/${direction.id}` && 'active')
            }
            to={`/directions/edit/${path}/${direction.id}`}
          >
            {intl}
            {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
          </Link>
        </li>)} 
     
      </ul>
    </div>
  )
}


const tabs = [
  {
    path: `overview`,
    intl: 'Основне',
  },
  {
    path: `params`,
    intl: 'Параметри',
  },
  
]