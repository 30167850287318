/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {Location} from '../../../../core/_models'

type Props = {
  location: Location
}

const LocationRecCell = ({location}: Props) => {
  return (
    <div className='text-center'>
      <p className={`p-1 fs-7 fw-semobold StatusBar__status bg-light`}></p>
    </div>
  )
}

export {LocationRecCell}
