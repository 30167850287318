import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {EmployeesListHeader} from './components/header/EmployeesListHeader'
import {EmployeesTable} from './table/EmployeesTable'
import {KTCard, KTSVG} from '../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import CreateUserModal from '../../../modules/custom/users/create-client-or-employee/CreateUserModal'

const EmployeesList = () => {
  const listView = useListView()
  const queryResponse = useQueryResponse()

  const {setItemIdForUpdate, itemIdForUpdate} = listView
  const openAddModal = () => {
    setItemIdForUpdate(null)
  }
  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Список співробітників</h3>
        <button type='button' className='btn btn-sm btn-primary' onClick={openAddModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          <FormattedMessage id='EMPLOYEES.PAGE.LIST.ADD_BUTTON' />
        </button>
      </div>
      <KTCard>
        <EmployeesListHeader />
        <EmployeesTable />
        {itemIdForUpdate !== undefined && (
          <CreateUserModal listView={listView} queryResponse={queryResponse} />
        )}
      </KTCard>
    </>
  )
}

const EmployeesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmployeesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EmployeesListWrapper}
