import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'
import {Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'

interface Props {
  item: Track | IOrder
}

export function HeaderShipment({item}: Props) {
  return (
    <div className='d-flex flex-column overflow-auto'>
      <div className='d-flex flex-column align-items-center align-items-md-end fs-8 my-3 mb-md-5'>
        <div className='text-right fs-7'>
          {/* @ts-ignore */}
          {item.order && <p className='pe-2'>{item.order?.number}</p>}
          {/* @ts-ignore */}
          {item.trackingNumbers &&
            /* @ts-ignore */
            item.trackingNumbers.map((item) => (
              <p key={item} className='pe-2'>
                {item.number}
              </p>
            ))}
        </div>
      </div>
    </div>
  )
}
