import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {ICountry} from '../../../core/_models'

type Props = {
  country: ICountry
}

const CountryTypeCell: FC<Props> = ({country}) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      {country.receive && (
        <p className='fw-semobold text-black d-block fs-6 text-center'>
          <FormattedMessage id='COUNTRIES.LIST.RECEIVER' />
        </p>
      )}
      {country.send && (
        <p className='fw-semobold text-black d-block fs-6 text-center'>
          <FormattedMessage id='COUNTRIES.LIST.SENDER' />
        </p>
      )}
    </div>
  )
}

export {CountryTypeCell}
