import {websitesApi} from '../core/_requests'
import {ErrorMessage} from 'formik'
import {LogoThumb} from './LogoThumb'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  title: string
  inputName: string
  setFieldValue: ISetFieldValue
  editingLogo?: string | null
  editingLogoDark?: string | null
  editingFavicon?: string | null
}

function WebsiteLogoItem({
  title,
  inputName,
  setFieldValue,
  editingLogo,
  editingLogoDark,
  editingFavicon,
}: Props) {
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    const response = await websitesApi.uploadImages(inputName, fileObj)
    setFieldValue(inputName, response[Object.keys(response)[0]])
  }
  return (
    <li>
      {' '}
      <label className='d-flex flex-stack cursor-pointer mb-5'>
        <span className='d-flex align-items-center me-2'>
          <span className='d-flex flex-column'>
            <span className='fw-bolder fs-6'>{title}</span>
          </span>
        </span>

        <span className='form-check form-check-custom form-check-solid'>
          <input
            id={inputName}
            name={inputName}
            className='form-control form-control-lg form-control-solid'
            type='file'
            onChange={handleImageUpload}
          />
        </span>

        {inputName === 'logo' && editingLogo && (
          <LogoThumb link={editingLogo} alt='Светлый логотип' />
        )}
        {inputName === 'logoDark' && editingLogoDark && (
          <LogoThumb link={editingLogoDark} alt='Тёмный логотип' />
        )}
        {inputName === 'favicon' && editingFavicon && (
          <LogoThumb link={editingFavicon} alt='ICO-логотип' />
        )}
      </label>
      <div className='text-danger'>
        <ErrorMessage name={inputName} />
      </div>
    </li>
  )
}

export {WebsiteLogoItem}
