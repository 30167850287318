import {ErrorMessage, useFormikContext} from 'formik'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {CategoryTab} from '../../../modules/custom/form-elements/CategoryTab'
import CategoriesMultipleSelect from '../../../modules/custom/form-elements/selects/CategorySelect'
import {ICategory} from '../../lists/categories/core/_models'
import {IDeliveryTypeForm} from '../core/_models'

interface IProps {
  values: IDeliveryTypeForm
}

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const Categories = ({values}: IProps) => {
  const {setFieldValue} = useFormikContext()
  const queryClient = useQueryClient()
  const categoriesList: ICategory[] = queryClient.getQueryData([QUERIES.CATEGORIES_LIST]) || []
  return (
    <>
      {' '}
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span className='required'> Категорії</span>{' '}
        </label>{' '}
        {categoriesList && categoriesList.length > 0 && (
          <CategoriesMultipleSelect
            categoriesAvailable={categoriesList.map((i) => ({
              image: i.logoUrl ? `${BASE_URL}${i.logoUrl}` : '/media',
              label: i.name,
              value: i.id,
            }))}
            values={values}
          />
        )}
      </div>
      <div className='text-danger'>
        <ErrorMessage name='categories' />
      </div>
    </>
  )
}

export default Categories
