import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {
  KTSVG,
  ListViewContextProps,
  QueryResponseContextProps,
} from '../../../../../_metronic/helpers'
import {Employee} from '../../../../pages/employees/employees-list/core/_models'
import {User} from '../../../../pages/users/core/_models'

interface Props {
  isLoading: boolean
  isValid: boolean
  listView: ListViewContextProps
}

const CreateUserModalHeader = ({isLoading, isValid, listView}: Props) => {
  const {itemIdForUpdate, setItemIdForUpdate} = listView

  return (
    <div
      className='modal-header px-6 py-2 bg-white position-fixed top-0 w-100 w-md-500px d-flex justify-content-between'
      style={{zIndex: 1, overflowX: 'hidden'}}
    >
      <h2 className='fw-bold fs-3 p-3'>Додати користувача</h2>

      <div className='d-flex flex-gutter'>
        <button
          type='submit'
          disabled={!!itemIdForUpdate || isLoading === true ? true : false}
          className='btn btn-sm btn-primary me-3 minify-button fs-7 fs-md-5'
        >
          <span className='indicator-label'>
            {isLoading === true ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <FormattedMessage
                id={itemIdForUpdate ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.CREATE'}
              />
            )}
          </span>
        </button>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
          onClick={() => {
            setItemIdForUpdate(undefined)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
    </div>
  )
}

export {CreateUserModalHeader}
