import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import Statistics from '../statistics/Statistics'
import {ChatsListFilter} from './filter/ChatListFilter'

const ChatsListHeader = () => {
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6 flex-column'>
      <div className='d-flex justify-content-between align-items-start'>
        {' '}
        <ListSearch updateState={updateState} />
        <ChatsListFilter />
      </div>
      <Statistics />
    </div>
  )
}

export {ChatsListHeader}
