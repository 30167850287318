/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {ICollection} from '../../../../../_models'
import {useMutation, useQueryClient} from 'react-query'
import {IWebsite} from '../../../../../../websites/core/_models'
import {deleteCollection} from '../../../../core/_requests'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {Portal} from '../../../../../../../../_metronic/partials'

type Props = {
  collection: ICollection
}

const CollectionActionsCell: FC<Props> = ({collection}: Props) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteCollection(collection.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/website-collections/blog/edit/overview/${Object.values(collection.locales)[0]}/${
            collection.id
          }`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'WAREHOUSE.PAGE.LIST.ACTIONS.TRASH_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={collection.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}

      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
      >
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => setDeleteModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </OverlayTrigger>
    </div>
  )
}

export {CollectionActionsCell}
