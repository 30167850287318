import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {EmailTemplate} from '../../templates-list/core/_models'

interface Props {
  template: EmailTemplate
}

const TemplateHeaderLangSwitcher = ({template}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const availableLangs = template?.translations ? Object.keys(template?.translations) : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <ul className='nav'>
      {template.translations &&
        availableLangs.map((locale) => (
          <li className='nav-item' key={locale}>
            <button
              type='button'
              onClick={() => {
                navigate(`/email-templates/edit/${currTab}/${locale}/${template.id}`)

                setActiveTab(locale)
              }}
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                activeTab === locale ? 'active' : ''
              } fw-bold px-4`}
            >
              {locale}
            </button>
          </li>
        ))}
    </ul>
  )
}

export default TemplateHeaderLangSwitcher
