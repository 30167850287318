import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CategoriesListFilter} from './CategoriesListFilter'

const CategoriesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()

  const openAddCategoryModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <CategoriesListFilter /> */}

      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddCategoryModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Добавить{' '}
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {CategoriesListToolbar}
