import {ErrorMessage, Field, FieldArray, useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  currentLinks: string[]
  inputName: string
  withTitle?: boolean
  floating?: boolean
}
export const LinksArray = ({
  currentLinks,
  inputName,
  withTitle = true,
  floating = false,
}: Props) => {
  const {setFieldValue, errors, validateField, setFieldTouched} = useFormikContext<any>()

  return (
    <div className='d-flex flex-column text-primary w-100'>
      {' '}
      <div className='w-100'>
        <FieldArray
          name={inputName}
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter position-relative'>
              {currentLinks?.map((item, index) => (
                <div
                  className='w-100 d-flex justify-content-between align-items-center'
                  key={index}
                >
                  <div className={`${floating === true ? 'form-floating' : ''} w-100`}>
                    <Field
                      style={{
                        paddingTop: floating === true ? (index === 0 ? '1.85rem' : '0.625rem') : '',
                      }}
                      className={`form-control form-control-lg form-control-solid`}
                      id={`${inputName}.${index}`}
                      name={`${inputName}.${index}`}
                      value={item}
                      type='text'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(`${inputName}.${index}`, e.target.value.replace(/\s/g, ''))
                      }
                      onBlur={() => {
                        validateField(inputName)
                        setFieldTouched(`${inputName}.${index}`, true)
                      }}
                    />

                    {withTitle === true && index === 0 && (
                      <label htmlFor={`${inputName}.${index}`}>
                        <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' />
                      </label>
                    )}
                  </div>
                  {index === 0 && (
                    <button
                      disabled={item === '' || !!errors.currentLinks}
                      className='btn btn-icon btn-bg-light ms-3'
                      type='button'
                      onClick={() => {
                        arrayHelpers.unshift('')
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen041.svg'
                        className='svg-icon-1x svg-icon-success d-block my-2'
                      />
                    </button>
                  )}
                  {currentLinks?.length > 1 && (
                    <button
                      className='btn btn-icon btn-bg-light ms-3'
                      type='button'
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen040.svg'
                        className='svg-icon-1x svg-icon-danger d-block my-2'
                      />
                    </button>
                  )}
                </div>
              ))}
              <div className='text-danger'>
                <ErrorMessage name={inputName} />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  )
}
