import {Route, Routes, Outlet, Navigate, useLocation, useParams} from 'react-router-dom'
import {OrdersListWrapper} from './orders-list/OrdersList'
import {useQueries, useQuery} from 'react-query'
// import {getRecentInvoices} from './core/_requests'
import {getReceivers} from '../fulfillment/receivers/receivers-list/core/_requests'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {useEffect} from 'react'
import {directionsApi} from '../directions/core/_requests'
import {QUERIES} from '../../../_metronic/helpers'
import EditingPage from '../../modules/custom/track-or-order/editing-page/EditingPage'
import {getOrderById} from './core/_requests'
import {generalApi} from '../../../_metronic/helpers/custom/api/generalApi'

const OrdersPage = () => {
  const location = useLocation()
  useQueries([
    {queryKey: [QUERIES.RECEIVERS_LIST], queryFn: () => getReceivers('')},
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
  ])

  useQuery(QUERIES.COUNTRY_ABBR_LIST, directionsApi.fetchDirectionCountriesList)

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <OrdersListWrapper />
            </>
          }
        />

        <Route
          path='create'
          element={
            <Navigate
              to={`/orders/list`}
              state={{
                create: true,
              }}
            />
          }
        />

        <Route
          path={'edit/*'}
          element={
            <EditingPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
              getByIdFunc={getOrderById}
            />
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/orders/list`}
            // to={pathnameValue(pathname, intl).defaultLink}
            // state={{deliveryType: pathnameValue(pathname, intl).deliveryType}}
          />
        }
      />
    </Routes>
  )
}

export default OrdersPage
