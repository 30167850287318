/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Modal} from 'react-bootstrap'
import {
  IShipmentAddressForm,
  IShipmentDetails,
} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {createShipmentAddressSchema} from '../../../../../_metronic/helpers/custom/formik-schemas/addresses'
import {AddressShipment} from './AddressShipment'
import {PostShipment} from './PostShipment'
import {InputTemplate} from '../../form-elements/InputTemplate'
import {SearchQueryProps} from '../Filter'
import {useQueryClient, useMutation} from 'react-query'
import {locationsApi} from '../../../../../_metronic/helpers/custom/api/locationsApi'
import {useIntl} from 'react-intl'

interface Props extends SearchQueryProps {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  profileAddresses: IShipmentDetails[]
  tempType: number
  apiMutation: {
    apiRequest: (data: any, id?: string | number) => Promise<any>
    toInvalidate: string
    id?: string | number
  }
}

const CreateShipmentAddress = ({
  setIsOpen,
  isOpen,
  profileAddresses,
  tempType,
  apiMutation,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const queryClient = useQueryClient()
  const intl = useIntl()
  const [loading, setIsLoading] = useState(false)

  const inits: IShipmentAddressForm = {
    typeOfDelivery: tempType,
    mainAddress: profileAddresses.length === 0 ? true : false,
    city: '',
    premisesType: 'apartment',
    mainOfficeCity: '',
    mainOfficeNumber: '',
    reserveOfficeCity: '',
    reserveOfficeNumber: '',
  }

  const [initValues] = useState<IShipmentAddressForm>(inits)

  const mutation = useMutation(apiMutation.apiRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(apiMutation.toInvalidate)
      setIsOpen(false)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitStep = async (values: IShipmentAddressForm, actions: FormikValues) => {

    let dataToSend

    if (+values.typeOfDelivery === 1) {
      const {apartment, city, houseLetter, houseNumber, mainAddress, office, street, premisesType} =
        values

      // const cityObj = await locationsApi.getCityById(city)
      // const streetObj = await locationsApi.getStreetById(street || '', city)

      dataToSend = {
        post: false,
        mainAddress,
        // city: {name: cityObj.name, id: cityObj.id},
        // region: cityObj.region,
        address: {
          apartment: apartment && premisesType === 'apartment' ? apartment : null,
          houseLetter: houseLetter ? houseLetter : null,
          houseNumber,
          office: office && premisesType === 'office' ? office : null,
          // street: streetObj ? {id: streetObj.ref, name: streetObj.name} : {id: null, name: street},
        },
        mainOffice: null,
        reserveOffice: null,
      }
    } else if (+values.typeOfDelivery === 2) {
      const {
        mainAddress,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
      } = values

      const cityObj = await locationsApi.getCityById(mainOfficeCity || '')
      const resCityObj = await locationsApi.getCityById(reserveOfficeCity || '')

      dataToSend = {
        post: true,
        mainAddress,
        city: {name: cityObj.name, id: cityObj.id},
        region: cityObj.region,
        address: null,
        mainOffice: {
          city: {name: cityObj.name, id: cityObj.id},
          id: mainOfficeNumber,
        },
        reserveOffice: {
          city: {name: resCityObj.name, id: resCityObj.id},
          id: reserveOfficeNumber,
        },
      }
    } else {
      return
    }
    setIsLoading(true)
    if (apiMutation.toInvalidate === QUERIES.RECEIVER_ADDRESSES) {
      mutation.mutate({...dataToSend, receiver_id: apiMutation.id})
    } else {
      mutation.mutate(dataToSend)
    }
    stepper.current?.goNext()

    actions.resetForm()
  }

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      id='kt_modal_create_sh_address'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>
            {intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.HEADING'})}{' '}
            {tempType === 1
              ? intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.HOME_ADDRESS_LABEL'})
              : intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.NP_ADDRESS_LABEL'})}
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-0 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={createShipmentAddressSchema(intl)}
              initialValues={initValues}
              onSubmit={submitStep}
              enableReinitialize={true}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({values, setFieldValue, setFieldTouched, errors}) => (
                <Form className='form' noValidate>
                  {/* {JSON.stringify(errors)} */}

                  <div data-kt-stepper-element='content'>
                    <div className='w-100'>
                      {tempType ? (
                        <>
                          {+tempType === 1 && (
                            <AddressShipment
                              setFieldValue={setFieldValue}
                              values={values}
                              editing={false}
                            />
                          )}
                          {+tempType === 2 && <PostShipment values={values} editing={false} />}
                        </>
                      ) : (
                        <></>
                      )}
                      <InputTemplate
                        inputName='mainAddress'
                        type='checkbox'
                        checkboxProps={{
                          checked: values.mainAddress,
                          disabled: profileAddresses.length === 0 ? true : false,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'mainAddress',
                          type: 'checkbox',
                        }}
                        title={intl.formatMessage({
                          id: 'COMPONENTS.CreateShipmentAddress.MAIN_ADDRESS',
                        })}
                      />{' '}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={loading}
                      >
                        {loading === true ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Подождите...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>Сохранить</span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateShipmentAddress}
