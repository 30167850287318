import {useFormikContext} from 'formik'
import {RefObject} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {FormRequestsListFilter} from '../../website-forms/website-form-requests-page/components/header/FormRequestsListFilter'
import {useListView} from '../core/ListViewProvider'

interface Props {
  isLoading: boolean
  asTrack: boolean
  allowCreateParcel: boolean
  submitRemotely: () => void
  formRef: RefObject<HTMLFormElement>
}

const AcceptTrackModalHeader = ({
  isLoading,
  asTrack,
  allowCreateParcel,
  submitRemotely,
  formRef,
}: Props) => {
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const {setFieldValue, handleSubmit} = useFormikContext()
  return (
    <div
      className='modal-header px-6 py-2 bg-white position-fixed top-0 w-100 w-md-500px d-flex justify-content-between'
      style={{zIndex: 1, overflowX: 'hidden'}}
    >
      <h2 className='fw-bold fs-3 p-3'>
        Прийняти на склад {/* <p className='fw-normal fs-6 m-0 mt-1'> */}
        {asTrack === true ? 'трек' : 'замовлення'}
        {/* </p> */}
      </h2>

      <div className='d-flex flex-gutter'>
        {!itemIdForUpdate && (
          <div>
            <button
              type='submit'
              disabled={!!itemIdForUpdate || isLoading}
              className='btn btn-sm btn-primary me-1 fs-7 fs-md-6'
            >
              <span className='indicator-label'>
                {isLoading === true ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <FormattedMessage
                    id={itemIdForUpdate ? 'GENERAL.BUTTONS.SAVE' : 'GENERAL.BUTTONS.CREATE'}
                  />
                )}
              </span>
            </button>
          </div>
        )}

        {!itemIdForUpdate && allowCreateParcel && (
          <div>
            <button
              type='submit'
              onClick={async (e) => {
                e.preventDefault()
                setFieldValue('create_parcel', true)
                handleSubmit()
              }}
              disabled={!!itemIdForUpdate || isLoading}
              className='btn btn-sm btn-primary fs-7 fs-md-6'
            >
              <span className='indicator-label'>
                {isLoading === true ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Запакувати'
                )}
              </span>
            </button>
          </div>
        )}

        <div
          className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
          onClick={() => {
            setItemIdForUpdate(undefined)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>

      {/* end::Close */}
    </div>
  )
}

export {AcceptTrackModalHeader}
