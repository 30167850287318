import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQueries, useQuery, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {pollsApi} from '../core/_requests'
import {IPoll} from '../core/_models'
import {PollHeader} from './header/PollHeader'
import {PollOverview} from './tabs/overview/PollOverview'
import {PollQuestionsAnswers} from './tabs/questions-answers/PollQuestionsAnswers'
import {PollResults} from './tabs/results/PollResults'
import {PollLogs} from './tabs/logs/PollLogs'
import {IWebsite} from '../../websites/core/_models'
import {pollResultsApi} from '../core/_results-requests'

interface Props {
  id: string | number
}

const PollPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: pollById} = useQuery(QUERIES.POLL_BY_ID, () => pollsApi.getPollById(id))

  useQueries([
    {
      queryKey: [`${QUERIES.POLL_RESULTS_BY_ID}-${id}`],
      queryFn: () => pollResultsApi.getPollsResults(id),
    },
  ])

  const [currPoll, setCurrentPoll] = useState<IPoll | undefined>(undefined)

  useEffect(() => {
    if (pollById) {
      setCurrentPoll(pollById)
    }
  }, [pollById])

  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => item.id === currPoll?.website.id)

  return (
    <>
      {currPoll && pollById ? (
        <Routes>
          <Route
            element={
              <>
                <PollHeader poll={currPoll} website={website} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <PollOverview poll={currPoll} setCurrPoll={setCurrentPoll} website={website} />
                </>
              }
            />
            <Route
              path={`qa/:locale/:id`}
              element={
                <>
                  <PollQuestionsAnswers
                    poll={currPoll}
                    setCurrPoll={setCurrentPoll}
                    website={website}
                  />
                </>
              }
            />
            <Route
              path={`results/:locale/:id`}
              element={
                <>
                  <PollResults poll={currPoll} setCurrPoll={setCurrentPoll} />
                </>
              }
            />
            <Route
              path={`logs/:id`}
              element={
                <>
                  <PollLogs poll={currPoll} setCurrPoll={setCurrentPoll} />
                </>
              }
            />
            <Route index element={<Navigate to={`overview/${website?.mainLocale}/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default PollPage
