import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {useEffect, useState} from 'react'
import {KTCard} from '../../../../../../_metronic/helpers'
import {TranslationsTable} from './table/TranslationsTable'
import {TranslationsListHeader} from './header/TranslationsListHeader'
import {TranslationEditModal} from './translation-edit-modal/TranslationEditModal'
import {useParams} from 'react-router-dom'
import {fetchTranslationsButton, getTranslationByWebsiteId} from '../core/_requests'

const TranslationList = () => {
  const {itemIdForUpdate} = useListView()
  const {id: websiteId} = useParams()
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    getTranslationByWebsiteId(websiteId, setTotalItems)
  }, [])

  const {updateState} = useQueryRequest()

  useEffect(() => {
    updateState({
      filter: {website: websiteId},
    })
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary mb-5'
          onClick={() => fetchTranslationsButton(websiteId)}
        >
          Синхронизировать ключи
        </button>
      </div>
      <KTCard>
        <TranslationsListHeader />
        <TranslationsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TranslationEditModal />}
    </>
  )
}

const TranslationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TranslationList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TranslationListWrapper}
