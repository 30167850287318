import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCompanyDetails} from '../settings/core/_requests'
import ArticlePage from './article-page/ArticlePage'
import {ArticlesListWrapper} from './blog-list/ArticlesList'
import {PageViewProvider} from './core/PageViewProvider'

const ArticlesPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useQuery(QUERIES.COMPANY_DETAILS, () => getCompanyDetails())
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <ArticlesListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <ArticlePage
                id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/blog/list`} />} />
    </Routes>
  )
}

export default ArticlesPage
