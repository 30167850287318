import {FieldArray, Form, Formik, FormikValues} from 'formik'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ICountry} from '../../../countries-list/core/_models'
import {updateCountry} from '../../../countries-list/core/_requests'

type TProps = {
  country: ICountry
  setCurrCountry: Dispatch<SetStateAction<ICountry | undefined>>
}
const AddressForm = ({country, setCurrCountry}: TProps) => {
  const initialValues: Partial<ICountry> = {
    locationAddressFields:
      country.locationAddressFields.length > 0 ? country.locationAddressFields : [''],
  }
  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ICountry>, actions: FormikValues) => {

    setLoading(true)
    const {locationAddressFields} = values
    const payload = {
      locationAddressFields,
    }

    try {
      const response = await updateCountry(country.id, payload)


      if (response) {
        setCurrCountry(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Formik
      // validationSchema={() => {}}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({values, setFieldValue, errors}) => (
        <Form noValidate className='form'>
          {/* {JSON.stringify(values)} */}
          {/* {JSON.stringify(errors)} */}
          <div className='card-body border-top p-9'>
            <FieldArray
              name='locationAddressFields'
              render={(arrayHelpers) => (
                <div className='d-flex flex-column flex-col-gutter'>
                  {values?.locationAddressFields?.map((field, index) => (
                    <div className='row mb-6' key={index}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Поле #{index + 1}{' '}
                      </label>
                      <div className='col-lg-8'>
                        <div className='row flex-nowrap'>
                          <div className='col-lg-8 d-flex align-items-center me-4'>
                            <InputTemplate
                              inputName={`locationAddressFields.${index}`}
                              type='text'
                              titleFontSize='text-black'
                              containerMarginBottom={false}
                            />
                            <button
                              className='btn ProfileContactItem__remove-button'
                              type='button'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen040.svg'
                                className='svg-icon-1x svg-icon-primary d-block my-2 CreateDeliveryType__button-icon'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                    <div className='col-lg-8 d-flex justify-content-center'>
                      <button
                        className='btn btn-sm btn-secondary d-flex align-items-center'
                        type='button'
                        onClick={() => arrayHelpers.push('')}
                      >
                        <span className='me-2'>
                          <FormattedMessage id='GENERAL.BUTTONS.ADD' values={{item: null}} />
                        </span>
                        <KTSVG
                          path='/media/icons/duotune/general/gen041.svg'
                          className='svg-icon-1x svg-icon-primary d-block my-2'
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          <CardFooter loading={loading} success={footerNotification?.success || false} />
        </Form>
      )}
    </Formik>
  )
}

export default AddressForm
