import {Dispatch, SetStateAction} from 'react'
import {errors} from '../errors'

export const scanDimensions = (
  string: string,
  setErrorMessage: Dispatch<SetStateAction<string>>
) => {
  let [gap, weight, width, length, height] = string.split('|')

  let dimensionsArray = [weight, width, length, height]

  for (let dimension in dimensionsArray) {
    if (dimensionsArray.hasOwnProperty(dimension)) {
      if (dimensionsArray[dimension].indexOf('*') !== -1) {
        dimensionsArray[dimension] = dimensionsArray[dimension].replace('*', '')
      }
      dimensionsArray[dimension] = dimensionsArray[dimension].toLowerCase()
    }
  }

  let dimensions: Record<string, string | number> = {
    weight: weight,
    width: width,
    length: length,
    height: height,
  }

  if (dimensions.width !== 'error0' && dimensions.length !== 'error0') {
    dimensions.weight = dimensions.weight || 0
    dimensions.width = dimensions.width || 0
    dimensions.height = dimensions.height || 0
    dimensions.length = dimensions.length || 0
    if (
      !(
        dimensions.weight > 0 &&
        dimensions.width > 0 &&
        dimensions.height > 0.5 &&
        dimensions.length > 0
      )
    ) {
      setErrorMessage(errors.SIZES_ERROR)
      if (dimensions.height <= 0.5) {
        setErrorMessage(errors.HEIGHT_ERROR)
      }
    }
  } else {
    dimensions.weight = dimensions.weight || 0
    dimensions.width = dimensions.width === 'error0' ? 0 : dimensions.width
    dimensions.height = dimensions.height || 0
    dimensions.length = dimensions.length === 'error0' ? 0 : dimensions.length

    setErrorMessage(errors.OVERSIZE_ERROR)
  }

  return dimensions
}
