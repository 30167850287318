/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../_metronic/helpers'

import {TStep} from '../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

function ItemStep({step, title, description, iconPath, stat, elementText, link}: TStep) {
  const numberWithSeparator = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  const statWithSymbol = (id: number, stat: number): string => {
    switch (id) {
      case 2:
        return `$${numberWithSeparator(stat)}`
      case 4:
        return `${numberWithSeparator(stat)}%`
      default:
        return `${numberWithSeparator(stat)}`
    }
  }
  return (
    <div key={step} className='card-body pt-3 ItemStep'>
      <div className='d-flex align-items-sm-center mb-7'>
        <div className='symbol symbol-60px symbol-2by3 me-4'>
          <KTSVG path={iconPath} className='svg-icon-2x svg-icon-warning' />
        </div>

        <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
          <div className='flex-grow-1 my-lg-0 my-2 me-2'>
            <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
              {title}
            </a>
            {link && (
              <a
                href={`https://${link}`}
                target='_blank'
                rel='noopener noreferrer'
                className='fw-bold text-gray-400 text-hover-primary d-block'
              >
                {link}
              </a>
            )}
            {description && (
              <span className='text-muted fw-semobold d-block pt-1'>{description}</span>
            )}
          </div>

          <div className='d-flex align-items-center'>
            {stat && (
              <div className='me-6'>
                <span className='badge badge-light fw-bold my-2'>{statWithSymbol(step, stat)}</span>
              </div>
            )}
            {!elementText && (
              <a href='#' className='btn btn-icon btn-light btn-sm border-0 ItemStep__arrow'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-2 svg-icon-primary'
                />
              </a>
            )}

            {elementText && (
              <a href='#' className='btn btn-light-primary me-3'>
                {elementText}
              </a>
            )}
          </div>
        </div>
      </div>{' '}
    </div>
  )
}

export {ItemStep}
