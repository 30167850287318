import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ReceiverOverview} from './tabs/overview/ReceiverOverview'
import {ReceiverContacts} from './tabs/contacts/ReceiverContacts'
import {ReceiverShipment} from './tabs/shipment/ReceiverShipment'
import {ReceiverHeader} from './header/ReceiverHeader'
import {useQuery} from 'react-query'
import {getReceiverById} from '../receivers-list/core/_requests'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ReceiverLogsWrapper} from './tabs/logs/ReceiverLogsWrapper'
import {QUERIES} from '../../../../../_metronic/helpers'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'

const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Profile',
  //   path: 'overview',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface Props {
  id: string | number
}

const ReceiverPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: receiverById} = useQuery(QUERIES.RECEIVER_BY_ID, () => getReceiverById(id))

  const [currReceiver, setCurrReceiver] = useState({})

  useEffect(() => {
    if (receiverById) {
      setCurrReceiver(receiverById)
    }
  }, [receiverById])

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <>
      {currReceiver && currReceiver !== {} && receiverById ? (
        <Routes>
          <Route
            element={
              <>
                {/* @ts-ignore */}
                <ReceiverHeader receiver={currReceiver} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.PROFILE'})}
                  </PageTitle>
                  {/* @ts-ignore */}
                  <ReceiverOverview receiver={currReceiver} setCurrReceiver={setCurrReceiver} />
                </>
              }
            />
            <Route
              path={`contacts/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.CONTACTS'})}
                  </PageTitle>
                  {/* @ts-ignore */}
                  <ReceiverContacts receiver={currReceiver} setCurrReceiver={setCurrReceiver} />
                </>
              }
            />
            <Route
              path={`shipment/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.ADDRESS'})}
                  </PageTitle>
                  <ReceiverShipment
                    receiverId={id}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </>
              }
            />
            <Route
              path={`logs/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS'})}
                  </PageTitle>
                  <ReceiverLogsWrapper id={id} />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ReceiverPage
