import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {receiversColumns} from './columns/_columns'
import {Receiver} from '../core/_models'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import {ListLoading} from '../../../../../modules/custom/lists/ListLoading'
import {ListPagination} from '../../../../../modules/custom/lists/ListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'

const ReceiversTable = () => {
  const receivers = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const queryResponse = useQueryResponse()
  const {response: itemsTotal} = queryResponse
  const queryRequest = useQueryRequest()

  const data = useMemo(() => receivers, [receivers])
  const columns = useMemo(() => receiversColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='d-none d-md-block'>
        {itemsTotal?.totalItems !== undefined && itemsTotal?.itemsPerPage !== undefined && (
          <ListPagination
            queryObj={{
              queryResponse,
              queryResponseLoading: isLoading,
              queryRequest,
            }}
            addClass='mt-4'
          />
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Receiver>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Receiver>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='RECEIVERS.PAGE.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {itemsTotal?.totalItems !== undefined && itemsTotal?.itemsPerPage !== undefined && (
        <ListPagination
          queryObj={{
            queryResponse,
            queryResponseLoading: isLoading,
            queryRequest,
          }}
          addClass='mt-4'
        />
      )}
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {ReceiversTable}
