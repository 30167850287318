import {FieldArrayRenderProps} from 'formik'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IPollForm} from '../core/_models'
import RemoveQuestionButton from './RemoveQuestionButton'

const QuestionItem = ({
  questionArrayHelpers,
  index,
  values,
}: {
  questionArrayHelpers: FieldArrayRenderProps
  index: number
  values: Partial<IPollForm>
}) => {
  return (
    <>
      {values.questions && values.questions?.length > 1 && (
        <div>
          <RemoveQuestionButton
            questionArrayHelpers={questionArrayHelpers}
            index={index}
            disabled={!values.questions[index]?.allowDelete}
          />
        </div>
      )}
      <InputTemplate
        inputName={`questions[${index}].question`}
        type='text'
        titleFontSize='fs-5 text-dark'
        containerMarginBottom={false}
      />
    </>
  )
}

export default QuestionItem
