import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackItemsInfoCell = ({track}: Props) => {
  return (
    <div>
      {track.products.map((item, index) =>
        item.title ? (
          <p key={index}>{`${item.title} ${item.quantity}x${item.priceForOne}`}</p>
        ) : (
          <p key={index}>-</p>
        )
      )}
    </div>
  )
}

export {TrackItemsInfoCell}
