import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {useListView} from '../core/ListViewProvider'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import {CreateArticleModalHeader} from './CreateArticleModalHeader'
import {createArticleSchema} from '../core/yup/blog-article'
import {IArticle, ICreateArticleForm} from '../core/_models'
import {addArticle} from '../core/_requests'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IWebsite} from '../../websites/core/_models'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import ScrollToFieldError from './ScrollToFieldError'
import {QUERIES} from '../../../../_metronic/helpers'
import WebsitesSelect from '../../../modules/custom/form-elements/selects/WebsitesSelect'
import {ICompanyDetails} from '../../settings/core/_models'

interface Props {
  article?: IArticle
}

const CreateArticleForm = ({article}: Props) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const companyDetails: ICompanyDetails | undefined = queryClient.getQueryData(
    QUERIES.COMPANY_DETAILS
  )
  const {setItemIdForUpdate} = useListView()
  const {selectedLang} = getConfig()

  const {refetch} = useQueryResponse()

  const inits: ICreateArticleForm = {
    name: '',
    slug: '',
    website:
      websitesData && websitesData[0]
        ? {
            value: websitesData[0]?.id,
            label: websitesData[0].name,
            name: websitesData[0].name,
            faviconUrl: websitesData[0].faviconUrl,
            host: websitesData[0].host,
          }
        : {value: '', label: ''},
    categories: [],
    // @ts-ignore
    categoriesList: [],
    translations: {
      [selectedLang]: {
        title: '',
        shortDescription: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
      },
    },
  }

  const [initValues] = useState<ICreateArticleForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const createArticleMutation = useMutation(addArticle, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)

      ScrollTopComponent.goTop()
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (
    values: ICreateArticleForm,
    formikHelpers: FormikHelpers<ICreateArticleForm>
  ) => {
    const website = websitesData?.find((item) => item.id === values.website.value)
    let translations = {
      [selectedLang]: {
        title: values.name,
        shortDescription: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        breadcrumb: '',
      },
    }
    if (website) {
      translations = {}

      website?.enabledLocales.forEach((locale) => {
        translations[locale] = {
          title: values.name,
          shortDescription: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
          metaKeywords: '',
          breadcrumb: values.name,
        }
      })
    }
    createArticleMutation.mutate({
      ...values,
      authorName: companyDetails?.name || null,
      website: {id: values.website.value || initValues.website.value},
      translations,
    })
    formikHelpers.resetForm()
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={createArticleSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_article_form'>
            <ScrollToFieldError />

            <CreateArticleModalHeader isLoading={isLoading} isValid={isValid} />
            <div
              className='scroll-y bg-white modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/* {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    <WebsitesSelect />
                  </div>
                  <div className='fv-row mb-5'>
                    {' '}
                    <InputTemplate
                      inputName='name'
                      type='text'
                      title={intl.formatMessage(
                        {id: 'BLOG.ADD_MODAL.FORM.TITLE_LABEL'},
                        {
                          locale: websitesData?.find((item) => item.id === values.website.value)
                            ? `(${
                                websitesData?.find((item) => item.id === values.website.value)
                                  ?.mainLocale
                              })`
                            : '',
                        }
                      )}
                      titleFontSize='fs-5 text-dark'
                    />
                  </div>
                  <div className='fv-row'>
                    {' '}
                    <InputTemplate
                      inputName='slug'
                      type='text'
                      title={intl.formatMessage({id: 'BLOG.ADD_MODAL.FORM.SLUG_LABEL'})}
                      titleFontSize='fs-5 text-dark'
                      handleChange={(string: string) => {
                        setFieldValue('slug', string.replaceAll(' ', ''))
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateArticleForm}
