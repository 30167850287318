import {FormikHelpers} from 'formik'

type TProps = {
  error: {response: {data: {errors: Record<string, string[]>}}}
  values: any
  formikHelpers: FormikHelpers<any>
  intl: any
}
const setErrorsForFormFromErrorsObject = ({error, values, formikHelpers, intl}: TProps) => {
  let msg = ''
  let ecount = 0
  const errs = error?.response?.data?.errors

  if (errs) {
    for (const [key, value] of Object.entries(errs)) {
      if (values && key in values) {
        formikHelpers.setFieldError(
          key,
          intl.formatMessage({id: value[0], defaultMessage: value[0]})
        )
        ecount++
      } else {
        if (value && ecount === 0) msg = intl.formatMessage({id: value, defaultMessage: value})
      }
    }
  }
  if (ecount === 0) formikHelpers.setStatus(msg)
}

export default setErrorsForFormFromErrorsObject
