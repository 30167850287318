/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
} from '../../../../_metronic/helpers'
import {LocationsTable} from './table/LocationsTable'
import {LocationsListHeader} from './components/header/LocationsListHeader'
import {useEffect} from 'react'
import {LocationsListFilter} from './components/header/LocationsListFilter'
import {PageTitle} from '../../../../_metronic/layout/core'
import CreateRegionModal from '../../regions/_modals/CreateRegionModal'

const LocationsList = () => {
  const {itemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.LOCATIONS_LIST)

    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Населені пункти</h3>
      </div>
      <KTCard>
        <LocationsListHeader />
        <LocationsTable />

        {itemIdForUpdate !== undefined && <CreateRegionModal />}
      </KTCard>
    </>
  )
}

const LocationsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LocationsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {LocationsListWrapper}
