import {Field, Form, Formik, FormikValues} from 'formik'
import {nanoid} from 'nanoid'
import React, {useEffect, useRef, useState} from 'react'
import {
  IBasicSelect,
  ISetFieldValue,
  TBasicImageResponse,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {getUsers} from '../../../../users/core/_requests'

import {useUnpackShipmentContext} from '../core/UnpackShipmentContext'
import {unpackShipmentSchema} from '../core/yup'
import {initialContext} from '../core/_contextHelpers'
import {
  FTUpdatePrintQueueParams,
  IGeneralReceiveForm,
  IUnpackedItem,
  ISaveResponse,
} from '../core/_models'
import {fulfillmentApi} from '../../_requests'

import {photoFunction, processPhotoScanProd, scanFunction} from '../funcs/scanner'
import {ClientSelect} from '../../modules/ClientSelect'
import {errors} from '../../errors'
import {initiateFullScanFromComputer} from '../../funcs/initiateFullScanFromComputer'
import {HiddenFields} from '../../modules/HiddenFields'
import {Errors} from '../../modules/Errors'
import {handleUser} from '../../funcs/handleUser'
import {refClearAndFocus} from '../funcs/refClearAndFocus'
import Select from 'react-select/dist/declarations/src/Select'
import {GroupBase} from 'react-select'

export function UnpackShipmentForm() {
  const inits = {
    client: '',
    code: '',
  }
  const {
    unpackedItems,
    setUnpackedItems,
    setInitiatePrint,
    setPrintQueue,
    printQueue,
    setCurrClient,
    currClient,
    setErrorMessage,
    errorMessage,
    setItemIdForUpdate,
    itemForUpdate,
    customUser,
    setCustomUser,
  } = useUnpackShipmentContext()
  const parcelNumberRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    refClearAndFocus(parcelNumberRef)
  }, [])

  const updateCurrClient = (id: string | number, creds: {firstName: string; lastName: string}) => {
    const clientObj = {
      externalId: id.toString(),
      firstName: creds.firstName,
      lastName: creds.lastName,
    }

    setCurrClient(clientObj)
  }

  const clientSelectRef = useRef<Select<IBasicSelect, false, GroupBase<IBasicSelect>>>(null)
  const hiddenDimensionsRef = useRef<HTMLInputElement>(null)
  const hiddenCellRef = useRef<HTMLInputElement>(null)
  const hiddenPhotoScanProdRef = useRef<HTMLInputElement>(null)

  const [showClientDropdown, setShowClientDropdown] = useState(false)

  // helpers
  const updatePrintQueue: FTUpdatePrintQueueParams = (itemsToSend) => {
    const newQueue = itemsToSend
      .filter((item) => item.type === 2)
      .map((item) => {
        return {clientId: item.client.externalId.toString(), goodsNumber: item.code}
      })

    return newQueue
  }

  const findClosestParcel = () => {
    const closestCellIndex = unpackedItems.findIndex((item) => item.type === 3)

    let parcelToFind: IUnpackedItem[]

    if (closestCellIndex === -1) {
      parcelToFind = unpackedItems
    } else {
      parcelToFind = unpackedItems.slice(0, closestCellIndex)
    }
    const parcel = parcelToFind.find((item) => item.type === 1)

    return parcel
  }
  const codeClearAndFocus = () => {
    refClearAndFocus(parcelNumberRef)
  }

  // code scanning
  const handleCode = async (values: IGeneralReceiveForm, setFieldValue: ISetFieldValue) => {
    // setInitiatePrint(false)

    setErrorMessage('')
    const trimmedCode = values.code.trim()

    if (!trimmedCode.includes('-') && unpackedItems.some((item) => item.type === 1)) {
      if (unpackedItems.some((item) => item.code.trim() === trimmedCode && !item.addedToCluster)) {
        // open editor
        setItemIdForUpdate(trimmedCode)
      } else if (
        unpackedItems.some((item) => item.code.trim() !== trimmedCode && !item.addedToCluster)
      ) {
        setErrorMessage(errors.ONLY_ONE_PARCEL_ALLOWED_ERROR)
        refClearAndFocus(parcelNumberRef)
        return
      }
    } else if (trimmedCode.includes('-') && (!unpackedItems[0] || unpackedItems[0].type === 3)) {
      setErrorMessage(errors.NO_PARCELS_FOR_PROD)
      return
    }

    if (unpackedItems[0] && unpackedItems[0].type === 3) {
      setPrintQueue(initialContext.printQueue)
    }
    if (
      unpackedItems.some(
        (item) =>
          item.code.trim() === trimmedCode && !item.addedToCluster && itemForUpdate !== trimmedCode
      )
    ) {
      // open editor
      setItemIdForUpdate(trimmedCode)
    } else {
      // handle the added code

      setErrorMessage('')
      // setShowClientDropdown(false)

      const res = await fulfillmentApi.identifyFulfItem(trimmedCode)

      // setFieldValue('client', credsString)

      if (res.data) {
        setErrorMessage(errors.EXISTING_PROD_ERROR)
        refClearAndFocus(parcelNumberRef)
        return
      } else {
        setErrorMessage('')
        const propsForUser = {
          code: trimmedCode,
          currClient,
          items: unpackedItems,
          customUser,
          setErrorMessage,
          setCurrClient,
          setFieldValue,
          initialContext,
          updateCurrClient,
          clientSelectRef,
        }
        const userHandled = await handleUser(propsForUser)
        if (userHandled) {
          const newItem = await initiateFullScanFromComputer(trimmedCode, currClient)
          setUnpackedItems((prev) => [newItem, ...prev])
          refClearAndFocus(parcelNumberRef)
          setFieldValue('code', '')
        }
      }
    }
  }

  const [usersLoading, setUsersLoading] = useState(false)
  const [usersResults, setUsersResults] = useState<IBasicSelect[]>([])

  const filterUsers = async (query: string) => {
    setUsersLoading(true)
    await getUsers(`search=${query}`)
      .then((res) => {
        const transformed = res?.data?.map((item) => {
          return {
            ...item,
            value: item.externalId || item.id || '',
            label:
              (item.externalId ? item.externalId + ' ' : '') +
              (item.lastName ? item.lastName + ' ' : '') +
              (item.firstName ? item.firstName : ''),
          }
        })
        setUsersResults(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  const handleParcel = async (values: IGeneralReceiveForm, actions: FormikValues) => {
    console.log('v', values)
    handleCode(values, actions.setFieldValue)
  }

  useEffect(() => {
    filterUsers('')
  }, [])

  useEffect(() => {
    if (printQueue.length > 0) {
      setInitiatePrint(true)
    }
  }, [printQueue])

  useEffect(() => {
    const saveItemsToCell = async (itemsToSave: IUnpackedItem[]) => {
      const sorted = itemsToSave.sort((a, b) => {
        if (a.type === 1 && b.type !== 1) {
          return -1
        } else if (a.type !== 1 && b.type === 1) {
          return 1
        } else {
          return 0
        }
      })
      const responses = []
      for (const item of sorted) {
        const {code, height, length, photos, weight, width, cell, type} = item
        const itemToSend: any = {
          weight: +weight,
          width: +width,
          length: +length,
          height: +height,
          cell: cell?.replace('#', '') || '',
          images: photos
            .filter((item) => item !== null)
            .map((item) => {
              // if there is a null, it means that getImageLink received a type-3-cell item
              return {image: item.replace('/uploads/parcel_image/', '')}
            }),
        }

        let res

        switch (type) {
          case 1:
            itemToSend.code = code
            itemToSend.user = currClient
            res = await fulfillmentApi.addParcel(itemToSend)
            break
          case 2:
            itemToSend.parcel = {
              code: itemsToSave.find((item) => item.type === 1)?.code,
            }

            if (code.split('-')[1].length === 3) {
              itemToSend.code = code
            }
            res = await fulfillmentApi.addParcelProduct(itemToSend)
            break
        }

        if (!res) {
          // responses.push('error')
          console.log('unsuc goods res', res)
          //push the data from request into the toResend array
          //  break
        } else {
          responses.push(res)
          // console.log('suc goods res', res)
          // print sticker
        }
      }
      // console.log('responses', responses)

      return responses
    }

    const submitAndClear = async function (e: KeyboardEvent) {
      if (e.key.toLowerCase() === 'enter') {
        e.preventDefault()
        setItemIdForUpdate(null)
        const firstItemIsACellNumber = unpackedItems.length > 0 && unpackedItems[0].type === 3
        const closestCellIndex = unpackedItems.findIndex((item) => item.type === 3)
        const someParcels = unpackedItems.slice(0, closestCellIndex).some((item) => item.type === 1)
        const noParcelsBeforeCell = closestCellIndex !== -1 && !someParcels
        console.log(
          'firstItemIsACellNumber',
          firstItemIsACellNumber,
          'someParcels',
          someParcels,
          'noParcelsBeforeCell',
          noParcelsBeforeCell
        )

        if (firstItemIsACellNumber || noParcelsBeforeCell) {
          setErrorMessage(errors.ADD_PARCEL)
          // console.log('error unpackedItems', unpackedItems)
        } else {
          // console.log('no error unpackedItems', unpackedItems)

          setErrorMessage('')
          const savedCellId = nanoid()
          const savedCell = {
            cell: hiddenCellRef.current?.value,
            code: savedCellId,
            client: initialContext.currClient,
            weight: '',
            length: '',
            height: '',
            width: '',
            photos: [],
            type: 3,
            addedToCluster: true,
          }

          // console.log('ri in cell', unpackedItems)

          const previousCellIndex = unpackedItems.findIndex((item) => item.type === 3)

          const sliceItemsToSend =
            previousCellIndex !== -1 ? unpackedItems.slice(0, previousCellIndex) : unpackedItems
          // console.log('slicedtosend', sliceItemsToSend)

          const itemsToSend = sliceItemsToSend.map((item) => {
            return {...item, cell: hiddenCellRef.current?.value}
          })
          console.log('to send', itemsToSend)

          const savingFinished: ISaveResponse[] = await saveItemsToCell(itemsToSend)
          // if (savingFinished.length === itemsToSend.length) {
          // console.log('saved', savingFinished)
          let updatedSavingFinished: IUnpackedItem[] = []
          setUnpackedItems((prev) => {
            const oldClusters = prev.filter((item) => item.addedToCluster === true)
            updatedSavingFinished = savingFinished.map((item) => {
              return {
                ...item,
                addedToCluster: true,
                type: item.parcel ? 2 : 1,
                photos: item.images.map((item) => item.imageUrl),
                client: {...currClient},
              }
            })
            console.log('updatedSavingFinished', updatedSavingFinished)

            const newUnpackedItems = [savedCell, ...updatedSavingFinished, ...oldClusters]
            return newUnpackedItems
          })

          // currently cell gets duplicated, but not prods or parcels, it was about identical keys/ids

          // console.log('received items after saving', unpackedItems)

          if (itemsToSend.some((item) => item.type === 2)) {
            const prods = updatePrintQueue(updatedSavingFinished)
            setPrintQueue([...prods])
            console.log('prods', prods)
          }
          // }
        }

        // refClearAndFocus(hiddenCellRef)
        setCustomUser(false)
        setCurrClient(initialContext.currClient)
        setInitiatePrint(false)
        refClearAndFocus(parcelNumberRef)

        console.log('submitandclearfinal')
      } else {
        return
      }
    }

    const scannerListener = async function (event: KeyboardEvent) {
      // console.log('ri scanner', unpackedItems)

      const scanComb = event.ctrlKey && event.altKey && event.keyCode === 220
      const photoComb = event.ctrlKey && event.altKey && event.key === '/'
      const photoScanComb = event.ctrlKey && event.altKey && event.key === '|'
      const photoScanProdComb = event.ctrlKey && event.altKey && event.key === '*'
      const cellScanComb = event.key === '#'

      if (scanComb || photoComb || photoScanComb) {
        if (unpackedItems.length > 0) {
          if (itemForUpdate === null && unpackedItems[0].type !== 3) {
            setItemIdForUpdate(unpackedItems[0].code)
          }
          // scan
          if (scanComb) {
            refClearAndFocus(hiddenDimensionsRef)
            hiddenDimensionsRef.current?.addEventListener('keydown', (e) =>
              scanFunction(
                e,
                hiddenDimensionsRef,
                setErrorMessage,
                unpackedItems[0],
                setItemIdForUpdate,
                setUnpackedItems
              )
            )

            return () =>
              hiddenDimensionsRef.current?.removeEventListener('keydown', (e) =>
                scanFunction(
                  e,
                  hiddenDimensionsRef,
                  setErrorMessage,
                  unpackedItems[0],
                  setItemIdForUpdate,
                  setUnpackedItems
                )
              )
          }
          // photo
          else if (photoComb) {
            photoFunction(unpackedItems[0], setItemIdForUpdate, setUnpackedItems, codeClearAndFocus)
          }
          // photoscan
          else if (photoScanComb) {
            photoFunction(unpackedItems[0], setItemIdForUpdate, setUnpackedItems, codeClearAndFocus)
            refClearAndFocus(hiddenDimensionsRef)
            hiddenDimensionsRef.current?.addEventListener('keydown', (e) =>
              scanFunction(
                e,
                hiddenDimensionsRef,
                setErrorMessage,
                unpackedItems[0],
                setItemIdForUpdate,
                setUnpackedItems
              )
            )

            return () =>
              hiddenDimensionsRef.current?.removeEventListener('keydown', (e) =>
                scanFunction(
                  e,
                  hiddenDimensionsRef,
                  setErrorMessage,
                  unpackedItems[0],
                  setItemIdForUpdate,
                  setUnpackedItems
                )
              )
          } else {
            return
          }
        } else {
          setErrorMessage(errors.ADD_PARCEL)
        }
      } // photoscanprod
      else if (photoScanProdComb) {
        setItemIdForUpdate(null)
        if (unpackedItems.length > 0) {
          if (unpackedItems[0].type === 3) {
            setErrorMessage(errors.ADD_PARCEL)
            // return
          } else {
            const closestParcel = findClosestParcel()

            if (!closestParcel) {
              setErrorMessage(errors.NO_PARCELS_FOR_PROD)
              refClearAndFocus(parcelNumberRef)
              console.log('no closest')
            } else {
              setErrorMessage('')
              refClearAndFocus(hiddenPhotoScanProdRef)

              hiddenPhotoScanProdRef.current?.addEventListener('keydown', (e) =>
                processPhotoScanProd(
                  e,
                  hiddenPhotoScanProdRef,
                  closestParcel,
                  setErrorMessage,
                  setUnpackedItems,
                  codeClearAndFocus,
                  unpackedItems
                )
              )
              console.log('psp', unpackedItems)

              return () =>
                hiddenPhotoScanProdRef.current?.removeEventListener('keydown', (e) =>
                  processPhotoScanProd(
                    e,
                    hiddenPhotoScanProdRef,
                    closestParcel,
                    setErrorMessage,
                    setUnpackedItems,
                    codeClearAndFocus,
                    unpackedItems
                  )
                )
            }
          }
        } else {
          setErrorMessage(errors.ADD_PARCEL)
        }
        refClearAndFocus(parcelNumberRef)
        console.log('photoscanprod')
      }
      // scan cell
      else if (cellScanComb) {
        refClearAndFocus(hiddenCellRef)
        hiddenCellRef.current?.addEventListener('keydown', submitAndClear)

        return () => hiddenCellRef.current?.removeEventListener('keydown', submitAndClear)
      } else {
        return
      }
    }

    document.addEventListener('keydown', scannerListener)
    return () => {
      document.removeEventListener('keydown', scannerListener)
    }
  }, [unpackedItems, unpackedItems.length])

  return (
    <>
      <HiddenFields
        hiddenDimensionsRef={hiddenDimensionsRef}
        hiddenPhotoScanProdRef={hiddenPhotoScanProdRef}
        hiddenCellRef={hiddenCellRef}
      />
      <div className={`card mb-5 mb-xl-10 `} id='fulfillment-accept-form'>
        <Errors errorMessage={errorMessage} />
        <Formik
          validationSchema={unpackShipmentSchema}
          initialValues={inits}
          onSubmit={handleParcel}
          enableReinitialize={true}
        >
          {({values, errors, touched, setFieldValue}) => (
            <Form noValidate id='accept-parcel-form'>
              {/* {JSON.stringify(errors)} */}
              <div className='card-body pt-5 pb-8'>
                <div className='d-flex justify-content-between  flex-gutter align-items-center fs-7'>
                  <div className={`fv-row d-flex w-75 flex-column`}>
                    <label
                      className={`d-flex position-relative fs-6 row-reverse justify-content-left fw-bold`}
                    >
                      <Field
                        type='text'
                        autoComplete='off'
                        className={`form-control form-control-lg form-control-solid fs-7`}
                        name='code'
                        placeholder='Номер'
                        innerRef={parcelNumberRef}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                          (e.target.placeholder = '')
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.placeholder = 'Номер'
                        }}
                      />
                    </label>
                  </div>

                  {/* {showClientDropdown === true ? ( */}
                  <ClientSelect
                    selectRef={clientSelectRef}
                    items={unpackedItems}
                    setUsersLoading={setUsersLoading}
                    setUsersResults={setUsersResults}
                    options={usersResults}
                    isLoading={usersLoading}
                    updateCurrClient={updateCurrClient}
                    currentValue={{
                      ...currClient,
                      value: currClient.externalId || '',
                      label:
                        (currClient.externalId ? currClient.externalId + ' ' : '') +
                        (currClient.lastName ? currClient.lastName + ' ' : '') +
                        (currClient.firstName ? currClient.firstName : ''),
                    }}
                  />
                  {/* ) : (
                    <InputTemplate
                      
                      required={true}
                      type='text'
                      placeholder='Клієнт'
                      inputName='client'
                      formValues={values}
                      fieldWidth='w-75'
                      inputFontSize='fs-7'
                      labelMargin='mb-0'
                      containerMarginBottom={false}
                      backgroundClass={errors.client && touched.client ? 'error-border' : ''}
                      errorMessage={false}
                    />
                  )} */}
                  {/* </div> */}

                  <div className=''>
                    <button type='submit' className='btn btn-sm btn-primary me-3'>
                      Прийняти
                    </button>
                  </div>
                </div>
              </div>{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
