import {FC} from 'react'
import {IUserGroup} from '../../core/_models'

type Props = {
  group: IUserGroup
}

const GroupUsersNumberCell: FC<Props> = ({group}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{group.usersCount}</span>
      </div>
    </div>
  )
}

export {GroupUsersNumberCell}
