import {QUERIES} from '../../../../../../_metronic/helpers'
import {ListFilter} from '../../../../../modules/custom/lists/users-employees/ListFilter'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const EmployeesListToolbar = () => {
  const request = useQueryRequest()
  const response = useQueryResponse()
  return (
    <div className='d-flex justify-content-end' data-kt-employee-table-toolbar='base'>
      <ListFilter
        useQueryRequest={request}
        useQueryResponse={response}
        queriesKey={QUERIES.EMPLOYEES_LIST}
      />
    </div>
  )
}

export {EmployeesListToolbar}
