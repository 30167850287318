/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../../../websites/core/_models'
import {IChat} from '../../../../core/_models'

type Props = {
  chat: IChat
}

const ChatActionsCell: FC<Props> = ({chat}: Props) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  // const website = websitesData?.find((item) => item.id === category.website.id)

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      {/* {modalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'TRACKS.PAGE.LIST.ACTIONS.TRASH_CONF'})}
                  setModalOpen={setModalOpen}
                  handleAction={() => archiveMutation.mutate()}
                  id={track.id}
                  isOpen={modalOpen}
                />
              }
              className='modal-root'
            />
          )} */}
      {/* <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/website-categories/stores/edit/overview/${Object.values(category.locales)[0]}/${
            category.id
          }`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger> */}

      <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
        <KTSVG path='/media/icons/duotune/communication/com007.svg' className='svg-icon-3' />
      </button>
    </div>
  )
}

export {ChatActionsCell}
