import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQueries, useQuery} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {directionsApi} from '../core/_requests'
import {Direction} from '../core/_models'
import {DirectionOverview} from './tabs/overview/DirectionOverview'
import {DirectionParams} from './tabs/params/DirectionParams'
import {DirectionHeader} from './header/DirectionHeader'
import {generalApi} from '../../../../_metronic/helpers/custom/api/generalApi'

interface Props {
  id: string | number
}

const DirectionPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: directionById} = useQuery(QUERIES.DIRECTION_BY_ID, () =>
    directionsApi.getDirectionById(id)
  )

  useQueries([
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
  ])

  const [currDirection, setCurrentDirection] = useState<Direction | undefined>(undefined)

  useEffect(() => {
    if (directionById) {
      setCurrentDirection(directionById)
    }
  }, [directionById])

  return (
    <>
      {currDirection && directionById ? (
        <Routes>
          <Route
            element={
              <>
                <DirectionHeader direction={currDirection} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <DirectionOverview
                    direction={currDirection}
                    setCurrDirection={setCurrentDirection}
                  />
                </>
              }
            />
            <Route
              path={`params/:id`}
              element={
                <>
                  <DirectionParams
                    direction={currDirection}
                    setCurrDirection={setCurrentDirection}
                  />
                </>
              }
            />
            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default DirectionPage
