import {FieldArray, FieldArrayRenderProps} from 'formik'
import {IPollForm} from '../core/_models'
import AddAnswerButton from './AddAnswerButton'
import AddQuestionButton from './AddQuestionButton'
import AnswerItem from './AnswerItem'
import OwnAnswer from './OwnAnswer'
import QuestionItem from './QuestionItem'

const EditingItemBodyVersion = ({
  values,
  index,
  questionArrayHelpers,
}: {
  values: Partial<IPollForm>
  index: number
  questionArrayHelpers: FieldArrayRenderProps
}) => {
  return (
    <>
      <div className='row mb-2'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'>Питання {index + 1}</label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-8 fv-row d-flex'>
              <QuestionItem
                values={values}
                index={index}
                questionArrayHelpers={questionArrayHelpers}
              />
            </div>
          </div>
        </div>
      </div>

      <FieldArray
        name={`questions[${index}].answers`}
        render={(arrayHelpers) =>
          values.questions &&
          values.questions[index].answers?.map((_, answerIndex) => (
            <div className='row mb-3 align-items-center'>
              <label className='col-lg-3 col-form-label fw-bold fs-6 d-flex align-items-center py-0'>
                {answerIndex === 0 && (
                  <>
                    <span>Варіанти відповідей </span>{' '}
                    <AddAnswerButton arrayHelpers={arrayHelpers} />
                  </>
                )}
              </label>

              <div className='col-lg-9 d-flex flex-column flex-md-row align-items-center'>
                <AnswerItem
                  key={answerIndex}
                  answerIndex={answerIndex}
                  values={values}
                  questionIndex={index}
                  arrayHelpers={arrayHelpers}
                  editingFormat={true}
                />
              </div>
            </div>
          ))
        }
      />

      <div className='row mb-3'>
        <label className='col-lg-3 col-form-label fw-bold fs-6'></label>

        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-8 fv-row'>
              <OwnAnswer index={index} values={values} />
            </div>
            {values.questions && index === values.questions.length - 1 && (
              <div className='col-lg-4'>
                <AddQuestionButton arrayHelpers={questionArrayHelpers} />
              </div>
            )}
          </div>
        </div>
      </div>

      {values.questions && index !== values.questions.length - 1 && (
        <div className='separator bg-grey-400 h-1px mb-3'></div>
      )}
    </>
  )
}

export default EditingItemBodyVersion
