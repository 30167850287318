import {ErrorMessage, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {FULF_RECEIVER_VERIFICATION_STATUS} from '../../../../../../../_metronic/helpers'
import {customStyles} from '../../../../../../modules/custom/form-elements/ReactSelectTemplate'
import {NewReceiverFullForm} from '../../../receivers-list/core/_models'

const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

type Props = {
  values: Partial<NewReceiverFullForm>
}

export const Verification = ({values}: Props) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  const options = [
    {value: VERIFIED, label: intl.formatMessage({id: 'GENERAL.STATUSES.VERIFIED'})},
    {value: CHECKED, label: intl.formatMessage({id: 'GENERAL.STATUSES.CHECKED'})},
    {value: WAITING, label: intl.formatMessage({id: 'GENERAL.STATUSES.VERIF_WAITING'})},
  ]

  return (
    <div className={`fv-row d-flex flex-column mb-5`}>
      <Select
        options={options}
        styles={customStyles}
        value={values.verifStatus}
        className='form-control form-control-lg form-control-solid p-0 fw-normal'
        form='verifStatus'
        onChange={(e) => {
          setFieldValue('verifStatus', e)
        }}
      />
      <div className='text-danger'>
        <ErrorMessage name='verifStatus.value' />
      </div>
    </div>
  )
}
