import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'
import {trackPartials} from './partials'

const {
  trackCode,
  receiverNeeded,
  receiver,
  deliveryType,
  description,
  prods,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  orderInspection,
  inspectionDescription,
} = trackPartials

export const createTrackOnePageSchema = (intl: any) =>
  Yup.object().shape(
    {
      direction: object_shape_basic_select.required('Оберіть напрямок'),
      user: object_shape_basic_select.required('Вкажіть клієнта'),
      sendAsTrack: Yup.boolean(),
      sendAsBuyout: Yup.boolean(),
      track_code: Yup.string().when('sendAsBuyout', {is: false, then: trackCode(intl)}),
      receiverNeeded,
      receiver: Yup.object().when('receiverNeeded', {
        is: true,
        then: receiver(intl),
      }),
      delivery_type: deliveryType,
      description,
      prods: prods(intl),
      package_type: packageType,
      insurance,
      orderInspection,
      inspectionDescription: inspectionDescription(intl),
      require_invoice: requireInvoice.when('sendAsBuyout', {
        is: true,
        then: requireInvoice.notRequired(),
      }),
      invoice_file: invoiceFile(intl).when('sendAsBuyout', {
        is: true,
        then: invoiceFile(intl).notRequired(),
      }),
      invoiceLinks: invoiceLinks(intl).when('sendAsBuyout', {
        is: true,
        then: invoiceLinks(intl).notRequired(),
      }),
      store: Yup.object(),
    },
    [['invoice_file', 'invoiceLinks']]
  )
