/* eslint-disable jsx-a11y/anchor-is-valid */
import {CalculationTypeTwoItem} from './CalculationTypeTwoItem'
import {KTSVG} from '../../../../../_metronic/helpers'
import {TDeliveryTwoItem} from '../../../../../_metronic/helpers/custom/tsHelpers/deliveryTypesHelpers'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  removeField: (index: number) => void
  setFieldValue: ISetFieldValue
  addNewField: (setFieldValue: (name: string, array: TDeliveryTwoItem[]) => void) => void
  handleChange: (index: number, name: string, value: number) => void
  deliveryTwoFields: TDeliveryTwoItem[]
}
function CalculationTypeTwo({
  addNewField,
  removeField,
  handleChange,
  setFieldValue,
  deliveryTwoFields,
}: Props) {
  return (
    <div>
      {' '}
      <div className='d-flex'>
        <div className=''>
          {deliveryTwoFields.map((item, index) => (
            <CalculationTypeTwoItem
              key={index}
              index={index}
              item={item}
              deliveryTwoFields={deliveryTwoFields}
              handleChange={handleChange}
              removeField={removeField}
              setFieldValue={setFieldValue}
            />
          ))}
        </div>
      </div>
      <div className='CreateDeliveryType__button-container'>
        <button className='btn' onClick={() => addNewField(setFieldValue)}>
          <KTSVG
            path='/media/icons/duotune/general/gen041.svg'
            className='svg-icon-2x svg-icon-primary d-block my-2 '
          />
        </button>
      </div>
    </div>
  )
}

export {CalculationTypeTwo}
