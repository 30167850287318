/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../../../../../_metronic/helpers'

type Props = {
  number?: number
  iconAlt: string
  icon?: string
}

const EmployeeStatNumber: FC<Props> = ({number, iconAlt, icon}) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <OverlayTrigger placement='right' overlay={<Tooltip>{iconAlt}</Tooltip>}>
        <span className='text-center'>{number || 0}</span>
      </OverlayTrigger>
    </div>
  )
}

export {EmployeeStatNumber}
