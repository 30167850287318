import {FC, useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {FormattedDate, FormattedTime, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useListView} from '../../../../core/ListViewProvider'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackNumberCell: FC<Props> = ({track}) => {
  const {number} = track
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  const handleClick = async () => {
    if (itemIdForUpdate === track.id) {
      setItemIdForUpdate(undefined)
    } else {
      await setItemIdForUpdate(undefined)
      setItemIdForUpdate(track.id)
    }
  }

  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  const codeValueRef = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column me-2 cursor-pointer' onClick={handleClick}>
            <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
              {number}
            </span>{' '}
          </div>
          <div
            ref={target}
            onClick={() => {
              copyToClipboard(codeValueRef, setShow)
              setTimeout(() => {
                setShow(false)
              }, 1000)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
              }`}
            />
          </div>{' '}
          <Overlay target={target.current} show={show} placement='right'>
            {(props) => (
              <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
            )}
          </Overlay>
        </div>

        {track.order && (
          <div>
            {' '}
            <span className={`text-muted fw-semobold text-muted d-block fs-7`}>
              {track.order.number}
            </span>{' '}
          </div>
        )}

        <div className='d-flex justify-content-between align-items-center'>
          {track.direction?.id && (
            <FlagsDirectionBar chosenDirections={[{id: track?.direction?.id}]} minified={true} />
          )}{' '}
          {track?.cell && (
            <span className={`${!track.direction ? 'mt-2' : ''}`}>{track.cell.number}</span>
          )}
        </div>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={track.createdAt} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime
              value={track.createdAt}
              hour='numeric'
              minute='numeric'
              second='numeric'
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export {TrackNumberCell}
