import {FormattedMessage} from 'react-intl'
import {AsideMenuItem} from '../AsideMenuItem'

const TracksMenu = () => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-gray-300 fw-bold text-uppercase fs-8 ls-1'>
            <FormattedMessage id='GENERAL.ASIDE_MENU.ORDERS_SECTION' />{' '}
          </span>
        </div>
      </div>

      {menuItems.map((item, index) => (
        <AsideMenuItem
          key={index}
          to={item.to}
          title={item.title}
          fontIcon={item.fontIcon}
          icon={item.icon}
        />
      ))}
    </>
  )
}

export default TracksMenu

const menuItems = [
  {
    to: '/tracks/list',
    title: 'GENERAL.ASIDE_MENU.ORDERS_TRACKS_TITLE',
    fontIcon: 'bi-archive',
    icon: '/media/icons/duotune/ecommerce/ecm010.svg',
  },
  {
    to: '/orders/list',
    title: 'GENERAL.ASIDE_MENU.ORDERS_TITLE',
    fontIcon: 'bi-archive',
    icon: '/media/icons/duotune/coding/cod006.svg',
  },
  {
    to: '/tracks/add',
    title: 'GENERAL.ASIDE_MENU.ORDERS_ADD_TITLE',
    icon: '/media/icons/duotune/general/gen041.svg',
    fontIcon: 'bi-person',
  },
  {
    to: '/accept-tracks/list',
    title: 'GENERAL.ASIDE_MENU.ORDERS_ACCEPT_TITLE',
    fontIcon: 'bi-sticky',
    icon: '/media/icons/duotune/coding/cod004.svg',
  },
  {
    to: '/shipments/list',
    title: 'GENERAL.ASIDE_MENU.ORDERS_SHIPMENTS_TITLE',
    fontIcon: 'bi-sticky',
    icon: '/media/icons/duotune/maps/map003.svg',
  },
  {
    to: '/parcels/list',
    title: 'GENERAL.ASIDE_MENU.ORDERS_PARCELS_TITLE',
    fontIcon: 'bi-sticky',
    icon: '/media/icons/duotune/general/gen017.svg',
  },
]
