import {Formik, Form} from 'formik'
import {useState} from 'react'
// import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
// import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
// import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'

import {IPoll, IPollForm} from '../../../core/_models'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'

interface Props {
  poll: IPoll
  setCurrPoll: (obj: IPoll) => void
}

export function PollLogs({poll, setCurrPoll}: Props) {

  const initialValues: Partial<IPollForm> = {
  }

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values)} */}
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Назва</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'></div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Аватар </label>

                <div className='col-lg-8'>
                  <div className='row'></div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Напрямок</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'></div>
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
