import {ErrorMessage, Field, useFormikContext} from 'formik'
import {useQueryClient} from 'react-query'
import {ID, QUERIES} from '../../../../../_metronic/helpers'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {DirectionsQueryResponse} from '../../../directions/core/_models'
import {getWeightUnit} from '../../core/funcs/getMeasurementUnit'
import {IDeliveryTypeForm} from '../../core/_models'

const CalculationType = ({
  values,
  withTitle,
}: {
  values: Partial<IDeliveryTypeForm>
  withTitle: boolean
}) => {
  const queryClient = useQueryClient()

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}
  const direction = directions?.data?.find(
    ({id}) => values.direction && id && +values.direction === +id
  )

  const {setFieldValue} = useFormikContext()
  return (
    <>
      {direction && (
        <>
          {' '}
          {withTitle && (
            <p className='required fs-5 fw-bold mb-7'>Оберіть тип розрахунку доставки:</p>
          )}
          <div className='d-flex mw-500px '>
            <InputTemplate
              inputName='type'
              type='radio'
              title={`За ${getWeightUnit(direction, true)}`}
              checkboxProps={{
                checked: values.type && +values.type === 1 ? true : false,
                disabled: false,
                setFieldValue: setFieldValue,
                value: 1,
                inputName: 'type',
                type: 'radio',
              }}
              containerMarginBottom={false}
            />{' '}
            <InputTemplate
              inputName='type'
              type='radio'
              title={'За конкретну вагу'}
              checkboxProps={{
                checked: values.type && +values.type === 2 ? true : false,
                disabled: false,
                setFieldValue: setFieldValue,
                value: 2,
                inputName: 'type',
                type: 'radio',
              }}
              containerMarginBottom={false}
            />
          </div>
        </>
      )}
    </>
  )
}

export default CalculationType
