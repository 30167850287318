import * as Yup from 'yup'
import {receiverTypeSchema} from '../../../../fulfillment/receivers/receivers-list/core/yup/partials'

const countryOverviewSenderSchema = (intl: any) =>
  Yup.object({parcelIdentByOrderNumber: Yup.number().required()})

const countryOverviewReceiverSchema = (intl: any) =>
  Yup.object().shape({receiverType: receiverTypeSchema(intl).required()})

export {countryOverviewSenderSchema, countryOverviewReceiverSchema}
