import {CreateCategoryForm, TProps} from './CreateCategoryForm'

export const CreateCategoryFormWrapper = ({
  listView,
  queryResponse,
  addCategoryRequest,
}: TProps) => {
  const {itemIdForUpdate, setItemIdForUpdate} = listView

  if (itemIdForUpdate === null) {
    return (
      <CreateCategoryForm
        listView={listView}
        queryResponse={queryResponse}
        addCategoryRequest={addCategoryRequest}
      />
    )
  }

  return null
}
