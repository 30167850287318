import {ICategory} from '../../../_models'
import CategoryHeaderLangSwitcher from './CategoryHeaderLangSwitcher'
import {CategoryHeaderName} from './CategoryHeaderName'
import {CategoryHeaderNav} from './CategoryHeaderNav'

interface Props {
  category: ICategory
}

const CategoryHeader = ({category}: Props) => {
 
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={'/media/icons/duotune/art/art008.svg'}
                  alt='Template'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <CategoryHeaderName category={category} />
              
            </div>
          </div>
          <CategoryHeaderLangSwitcher category={category} />
        </div>

        <CategoryHeaderNav />
      </div>
    </div>
  )
}

export {CategoryHeader}
