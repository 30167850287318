/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackShipmentCell: FC<Props> = ({track}: Props) => {
  return (
    <div className='d-flex flex-gutter flex-shrink-0'>
      {track.shipment ? (
        <Link to={`/shipments/edit/overview/${track.shipment.id}`}>{track.shipment.id}</Link>
      ) : (
        <Link
          to='/shipments/list'
          state={{showChoseButton: true, trackId: track.id}}
          className='btn btn-sm text-dark btn-light'
        >
          + Додати
        </Link>
      )}
    </div>
  )
}

export {TrackShipmentCell}
