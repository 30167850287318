import {useQuery} from 'react-query'
import {AddReceiverModalForm} from './AddReceiverModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getReceiverById} from '../core/_requests'

const AddReceiverModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  // const {
  //   isLoading,
  //   data: receiver,
  //   error,
  // } = useQuery(
  //   `${QUERIES.FULF_RECEIVERS_LIST}-receiver`,
  //   () => {
  //     return getReceiverById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  if (itemIdForUpdate === null) {
    return <AddReceiverModalForm />
  }

  return null
}

export {AddReceiverModalFormWrapper}
