/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {IShipmentDetails} from '../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {Portal} from '../../../../_metronic/partials'

import {KTSVG, QUERIES} from '../../../../_metronic/helpers'
import {EditShipmentAddress} from './_modals/EditShipmentAddress'
import {SearchQueryProps} from './Filter'
import {ModalNotification} from '../modals/ModalNotification'
import {useMutation, useQueryClient} from 'react-query'
import {FormattedMessage, useIntl} from 'react-intl'

let nbsp = '\u00A0'

interface Props extends SearchQueryProps {
  item: IShipmentDetails
  apiMutation: {
    apiRequest: (data: any, id?: string | number) => Promise<any>
    toInvalidate: string
    id?: string | number
  }
}

const ShipmentCard = ({item, searchQuery, setSearchQuery, apiMutation}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const address = item.address
    ? [
        `${intl.formatMessage({id: 'COMPONENTS.ShipmentCard.HOUSE_CONTR'})}${nbsp}${
          item.address.houseNumber
        }${item.address.houseLetter ? `-${item.address.houseLetter.toUpperCase()}` : ''}`,
        `${
          item.address.apartment
            ? `${intl.formatMessage({id: 'COMPONENTS.ShipmentCard.APT_CONTR'})} ${
                item.address.apartment
              }`
            : ''
        }`,
        `${
          item.address.office
            ? `${intl.formatMessage({id: 'COMPONENTS.ShipmentCard.OF_CONTR'})} ${
                item.address.office
              }`
            : ''
        }`,
      ].filter((s) => s)
    : ''

  const changeMainAddressMutation = useMutation<any, Error>(
    async () => {
      setIsLoading(true)
      if (apiMutation.toInvalidate === QUERIES.RECEIVER_ADDRESSES) {
        return await apiMutation.apiRequest({
          ...item,
          mainAddress: true,
          receiver_id: apiMutation.id,
        })
      } else {
        return await apiMutation.apiRequest({...item, mainAddress: true})
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiMutation.toInvalidate)
      },
      onError: (error) => {
        console.log(error)
      },
      onSettled: () => setIsLoading(false),
    }
  )
  return (
    <>
      {modalOpen && (
        <Portal
          children={
            <EditShipmentAddress
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              id={item.id}
              dataForEditing={item}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              tempType={item.post}
              apiMutation={apiMutation}
            />
          }
          className='modal-root'
        />
      )}
      <div className='col-sm-6 col-xl-4'>
        <div className='card h-100'>
          <div className='card-header flex-nowrap border-0 pt-9'>
            <div className='card-title m-0'>
              <div className='symbol bg-light me-5 p-1'>
                {item.post === true ? (
                  <KTSVG
                    path='/media/icons/duotune/ecommerce/ecm004.svg'
                    className='svg-icon-3 svg-icon-primary'
                  />
                ) : (
                  <KTSVG
                    path='/media/icons/duotune/general/gen001.svg'
                    className='svg-icon-3 svg-icon-primary'
                  />
                )}
              </div>

              {/* <div>
                <p className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
                  {item.city.name}
                </p>
                <p className='fs-6 text-gray-400 fw-bold ms-1'>{item.region}</p>
              </div> */}
            </div>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => setModalOpen(true)}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>

          <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
            {' '}
            {item.mainAddress === true ? (
              <div className='d-flex flex-gutter'>
                {' '}
                <div className={`badge badge-light-success fw-bolder  px-2 py-2 mb-6`}>
                  <FormattedMessage id='COMPONENTS.ShipmentCard.MAIN_ADDRESS' />
                </div>
                <ModalNotification
                  svg='/media/icons/duotune/general/gen046.svg'
                  addClass='ModalNotification__position'
                  children={
                    <div className='fs-7 fw-normal'>
                      <FormattedMessage id='COMPONENTS.ShipmentCard.MAIN_ADDRESS.EXPLANATION' />
                    </div>
                  }
                />
              </div>
            ) : (
              <div className=''>
                <button
                  className='btn btn-bg-secondary px-2 py-1 btn-color-primary btn-sm mb-6 fs-8 fw-bolder'
                  onClick={() => changeMainAddressMutation.mutate()}
                >
                  <FormattedMessage id='COMPONENTS.ShipmentCard.MAKE_MAIN_BUTTON' />
                </button>
              </div>
            )}
            <div className='fs-4 fw-bolder text-dark'>
              {item.post ? (
                <FormattedMessage id='COMPONENTS.ShipmentCard.WH_DELIVERY' />
              ) : (
                <FormattedMessage id='COMPONENTS.ShipmentCard.ADDRESS_DELIVERY' />
              )}
            </div>
            {/* <div className='text-gray-400 fw-bold fs-6 mt-1 mb-7'>
              {' '}
              {address &&
                ((
                  <span className='fs-5 mb-3'>
                    {address.length > 0
                      ? item?.address?.street.name + ', ' + address.join(', ')
                      : ''}
                  </span>
                ) || (
                  <>
                    <p className='fs-5 mb-1'>
                      {item?.address?.street.name ? item.address.street.name : ''}
                    </p>{' '}
                    <p className='fs-5 m-0'>{address.length > 0 ? address.join(', ') : ''}</p>
                  </>
                ))}
              {item.mainOffice && (
                <>
                  <div className='fs-8 fw-bold mt-2 text-dark'>
                    <FormattedMessage id='COMPONENTS.ShipmentCard.MAIN_WH' />
                  </div>
                  <span className='fs-4 mb-3'>{`${intl.formatMessage({
                    id: 'COMPONENTS.ShipmentCard.NP_CONTR',
                  })} #${item.mainOffice.number}, ${item.mainOffice.city.name}`}</span>
                </>
              )}
            </div> */}
            {item.post === true && item.reserveOffice?.number && (
              <>
                <div className='fs-8 mt-2 fw-bold text-dark'>
                  <FormattedMessage id='COMPONENTS.ShipmentCard.RESERVE_WH' />
                </div>
                <div className='d-flex align-items-center flex-wrap mb-5 mt-auto fs-6'>
                  {/* <div className='fw-bold text-gray-400'>{`${intl.formatMessage({
                    id: 'COMPONENTS.ShipmentCard.NP_CONTR',
                  })} #${item.reserveOffice.number}, ${item.reserveOffice.city.name}`}</div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {ShipmentCard}
