import {StickerBarcode} from '../../../../../../modules/custom/images/StickerBarcode'
import '../../../../../../../_metronic/assets/sass/custom/printGoodsSticker.scss'

interface Props {
  goodsNumber: string
  client: string | number
  componentToPrint: React.MutableRefObject<HTMLDivElement | null> | null
}

export function GoodsSticker({goodsNumber, client, componentToPrint}: Props) {
  console.log(componentToPrint)

  return (
    <div
      ref={componentToPrint}
      style={{maxWidth: '1.9in', maxHeight: '1.2in', pageBreakAfter: 'always'}}
    >
      {' '}
      <table style={{width: '1.9in', height: '1.2in'}}>
        <tbody className='text-center'>
          <tr>
            <td className='fs-7'>{client}</td>
          </tr>

          <tr>
            <td>
              <StickerBarcode
                link={`https://www.vector-usa.biz/admin/barcode.php?text=${goodsNumber}&st=1`}
                sizes={{height: '55px'}}
              />
            </td>
          </tr>
          <tr>
            <td className='fs-7'>{goodsNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
