/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {IStatus} from '../../core/_models'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

type Props = {
  status: IStatus
}

const NameCell: FC<Props> = ({status}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={toAbsoluteUrl('/media/icons/duotune/graphs/gra006.svg')}
          alt=''
          style={{objectFit: 'contain'}}
        />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <span className='text-dark fw-semobold text-muted d-block fs-6'>{status.name}</span>
      </div>
    </div>
  )
}

export {NameCell}
