/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../_metronic/partials'
import {FormattedMessage, useIntl} from 'react-intl'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {useMutation} from 'react-query'
import {
  ICreateNewOrder,
  IOrderBuyoutProps,
  IOrderFormProd,
  IOrderProd,
} from '../../../../orders/core/_models/_orders-models'
import {reformTrackApiProductToForm} from '../../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {EditBuyoutProductsForm} from './_modals/EditBuyoutProductsForm'
import {editOrder} from '../../../../orders/core/_requests'

type Props = {
  item: IOrderProd
  invalidateBuyout: () => void
}

export function BuyoutItemCard({item, buyout, invalidateBuyout}: Props & IOrderBuyoutProps) {
  const intl = useIntl()

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const {category, priceForAll, priceForOne, quantity, title, url, id, sku, size, color} = item

  const editBuyoutContentMutation = useMutation(editOrder, {
    onSuccess: (data) => {
      invalidateBuyout()
    },

    onError: (error) => {
      console.log(error)
    },
  })

  const handleDelete = () => {
    const dataToSend: Partial<ICreateNewOrder> = {
      id: buyout.id,
      products: [...buyout.products.filter((prod) => prod.id !== id)],
    }

    editBuyoutContentMutation.mutate(dataToSend)
  }

  const [goodsField, setGoodsField] = useState<IOrderFormProd | null>(null)

  const setGoodsFieldsFunc = async (prod: IOrderProd) => {
    const res = await reformTrackApiProductToForm(prod)
    setGoodsField(res)
  }

  useEffect(() => {
    if (item) {
      setGoodsFieldsFunc(item)
    }
  }, [])
  return (
    <>
      {editModalOpen && goodsField && (
        <Portal
          children={
            <EditBuyoutProductsForm
              setIsOpen={setEditModalOpen}
              isOpen={editModalOpen}
              dataForEditing={{...goodsField, id: item.id}}
              buyout={buyout}
              invalidateBuyout={invalidateBuyout}
            />
          }
          className='modal-root'
        />
      )}

      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'BUYOUTS.PROD_CARD.TRASH_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => handleDelete()}
              id={item.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <div className='col-md-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-header border-0 pt-9 px-8'>
            <div className='card-title m-0'>
              <div className='symbol symbol-50px w-50px bg-light me-3'>
                <img
                  src={
                    category?.logoUrl
                      ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${category?.logoUrl}`
                      : toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm001.svg')
                  }
                  alt='card2'
                />
              </div>
            </div>

            <div className='card-toolbar d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary me-2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => setEditModalOpen(true)}
              >
                <KTSVG
                  path={
                    // allowEdit === 0 || isArchive === true
                    //   ? '/media/icons/duotune/general/gen021.svg'
                    // :
                    '/media/icons/duotune/art/art005.svg'
                  }
                  className='svg-icon-3 svg-icon-gray-900'
                />
              </button>
              {/* {allowDelete === 1 && isArchive === false && buyout.prods.length > 1 && ( */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => setDeleteModalOpen(true)}
              >
                <KTSVG
                  path={'/media/icons/duotune/general/gen027.svg'}
                  className='svg-icon-3 svg-icon-gray-900'
                />
              </button>
              {/* )} */}
            </div>
          </div>

          <div className='card-body p-8 pt-5'>
            <div className='fs-3 fw-bolder text-dark'>{title}</div>
            {/* {buyout.mitnitsya == 1 && (
              <div>
                <div className={`badge badge-light-info fw-bolder px-2 py-2 mb-6`}>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.COM_PROD' />
                </div>
              </div>
            )} */}

            <a
              href={url}
              className='d-block text-gray-400 fw-bold fs-5 my-2'
              target='_blank'
              rel='noreferrer noopener'
            >
              {url?.split('/').slice(0, 3).join('/').replace('https://', '')}
            </a>

            <div className='d-flex flex-wrap my-5'>
              {sku && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.ART_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{sku}</span>
                </div>
              )}

              {size && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.SIZE_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{size}</span>
                </div>
              )}

              {color && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.COLOR_LABEL' />
                  </span>
                  <span className='fw-bold text-gray-400'>{color}</span>
                </div>
              )}

              {!!buyout.weight && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <span className='fs-6 text-gray-800 fw-bolder me-2'>
                    <FormattedMessage id='BUYOUTS.FORM.WEIGHT' />
                  </span>
                  <span className='fw-bold text-gray-400'>{buyout.weight} кг</span>
                </div>
              )}
            </div>
            <div className='h-4px w-100 bg-light mb-5' data-bs-toggle='tooltip'>
              <div
                className={`bg-black rounded h-4px`}
                role='progressbar'
                style={{width: `${0}%`}}
              ></div>
            </div>

            <div>
              <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.COST' values={{cost: priceForOne}} />
              </p>
              {/* <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.REAL_COST' values={{price}} />
              </p> */}
              <p>
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.QTY' values={{qty: quantity}} />
              </p>
              <p className='fw-bold'>
                <FormattedMessage
                  id='BUYOUT_PAGE.CONTENT_TAB.TOTAL_COST'
                  values={{total: priceForAll}}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
