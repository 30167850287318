import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {useListView} from '../core/ListViewProvider'
import {CreateDirectionForm} from './CreateDirectionForm'

export const CreateDirectionFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  // const {
  //   isLoading,
  //   data: track,
  //   error,
  // } = useQuery(
  //   'trackEdit',
  //   () => {
  //     return getTrackById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  //  if (isLoading) {
  //    return (
  //      <div>
  //        <PartialPageLoader />
  //      </div>
  //    )
  // }

  if (itemIdForUpdate === null) {
    return <CreateDirectionForm />
  }

  return null
}
