import { useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikHelpers, useFormik, Field} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {AcceptTrackModalHeader} from './AcceptTrackModalHeader'
import { QUERIES} from '../../../../_metronic/helpers'
import {
  IAcceptedTrackOrOrderInfo,
  IAcceptTrackOrOrder,
  IAcceptTrackOrOrderForm,
  IAcceptWarehouse,
} from '../core/_models/_models'
import {IWarehouse} from '../../warehouses/core/_models'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {acceptItemSchema} from '../core/yup/track'
import {useIntl} from 'react-intl'
import {acceptItem} from '../core/_requests'
import {useListView} from '../core/ListViewProvider'
import {ClientSelect} from '../../fulfillment/receivers/elements/ClientSelect'
import transformUserForSelect from '../../users/funcs/transformUsersForSelect'
import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {getRelatedInfoByTrackNumber} from '../../tracks/core/_requests'
import {getRelatedInfoByOrderNumber} from '../../orders/core/_requests'
import {TrackGoods} from '../../../modules/custom/track-or-order/modules/goods/TrackGoods'
import {
  reformTrackApiProductsToForm,
  reformTrackFormProductsToApi,
} from '../../../../_metronic/helpers/custom/funcs/reformProducts'
import {ProdsToSend} from '../../tracks/core/_models/_tracks-models'
import {Direction} from '../../directions/core/_models'
import {directionsFuncs} from '../../../../_metronic/helpers/custom/funcs/directions'
import WarehousesSelect from '../../../modules/custom/form-elements/selects/WarehousesSelect'
import DirectionsSelect from '../../../modules/custom/form-elements/selects/DirectionsSelect'

type TProps = {directions: (IBasicSelect & Partial<Direction>)[]}

const AcceptTrackForm = ({directions}: TProps) => {
  const intl = useIntl()
  // service
  const {refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const listView = useListView()
  const {usersLoading, setUsersLoading, usersResults, setUsersResults, filterUsers} = listView

  // form fields data

  const warehouses: IWarehouse[] = queryClient.getQueryData(QUERIES.WAREHOUSES_LIST) || []
  const countries: Record<string, string> = queryClient.getQueryData(QUERIES.COUNTRIES_LIST) || {}
  const defGoodFields = [
    {
      category: {label: '', value: ''},
      qty: 1,
      cost: '',
      total: '',
    },
  ]


  const inits = {
    number: '',
    altNumber: '',
    user: {value: '', label: ''},
    direction: directions[0] || null,
    location: warehouses[0] || null,
    prods: defGoodFields,
    cell: '',
    create_parcel: false,
  }

  // useStates
  // @ts-ignore
  const [initValues] = useState<IAcceptTrackOrOrderForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  const [trackCode, setTrackCode] = useState('')

  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})

  //mutation
  const acceptMutation = useMutation(acceptItem, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      // setItemIdForUpdate(undefined)
      setTrackCode('')
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (values: IAcceptTrackOrOrderForm, formikHelpers: FormikHelpers<any>) => {
    try {
      const data: Partial<IAcceptTrackOrOrder> = {
        user: values.user.value,
        direction: values.direction.value,
      }

      if (values.create_parcel === true) {
        data.create_parcel = true
      }

      if (values.location?.value) {
        data.location = +values.location?.value
      }

      if (values.location?.countryConfig?.parcelIdentByOrderNumber === true) {
        data.order_number = values.number
        data.tracking_number = values.altNumber || values.number
      } else {
        data.tracking_number = values.number
        if (values.altNumber) {
          data.order_number = values.altNumber
        }
      }
      if (values.cell) {
        data.cell = values.cell
      }
      if (
        values.location?.acceptType === 'full' &&
        values.prods &&
        values.create_parcel === false
      ) {
        const reformedProds = reformTrackFormProductsToApi(values.prods)
        data.products = reformedProds
      } else if (values.location?.acceptType === 'amount' && values.create_parcel === false) {
        const mappedProds = values.prods?.map((i) => {
          return {quantity: i.qty} as ProdsToSend
        })
        data.products = mappedProds
      }

      acceptMutation.mutate(data)
      const lastLoc = values.location
      formikHelpers.resetForm()
      formikHelpers.setFieldValue('location', lastLoc)
    } catch (error) {
      console.log('accept track err', error)
    }
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  useEffect(() => {
    if (filterUsers) filterUsers('')
  }, [])

  const assignRelatedInfo = async (
    res: IAcceptedTrackOrOrderInfo | undefined,
    setFieldValue: ISetFieldValue,
    location: IAcceptWarehouse | undefined
  ) => {
    if (res?.user) {
      setFieldValue('user', transformUserForSelect(res.user))
    }
    if (res?.direction) {
      setFieldValue('direction', {
        ...res?.direction,
        value: res.direction.id,
        label: directionsFuncs.formDirection(
          res.direction.countryFrom,
          res.direction.countryTo,
          countries
        ),
      })
    }
    if (res?.products) {
      if (location?.acceptType === 'amount') {
        const totalQty = res.products.reduce((acc, i) => acc + i.quantity, 0)
        setFieldValue('prods', [])
        setFieldValue('prods[0].qty', totalQty)
      } else {
        const reformed = await reformTrackApiProductsToForm(res.products)
        setFieldValue('prods', reformed)
      }
    }
  }

  const findRelatedInfo = async (
    isOrder: boolean,
    val: string,
    location: IAcceptWarehouse | undefined,
    setFieldValue: ISetFieldValue
  ) => {
    if (!location || !val) {
      return
    }
    let res
    try {
      res = isOrder
        ? await getRelatedInfoByOrderNumber(val, location.value)
        : await getRelatedInfoByTrackNumber(val, location.value)

      await assignRelatedInfo(res, setFieldValue, location)
    } catch (error) {

      if (isOrder && error) {
        res = await getRelatedInfoByTrackNumber(val, location.value)
      }
      if (res) {
       
        await assignRelatedInfo(res, setFieldValue, location)
      }
    }
  }
  const hiddenCellRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const refClearAndFocus = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = ''
      ref.current.focus()
    }
  }
  const formik = useFormik({
    initialValues: inits,
    validationSchema: acceptItemSchema(intl),
    // @ts-ignore
    onSubmit: async (values: IAcceptTrackOrOrderForm, formikHelpers: FormikHelpers<any>) => {
      try {
        handleSubmit(values, formikHelpers)
      } catch (error) {
        console.log(error)
      } finally {
      }
    },
  })
  useEffect(() => {
    const traceCell = (e: any) => {

      if (formRef && formRef?.current && e.key === '#') {
        refClearAndFocus(hiddenCellRef)
        hiddenCellRef.current?.addEventListener('keydown', function (e) {
          if (e.key === 'enter') {
            e.preventDefault()
            formik.handleSubmit()
          }
        })
        return () =>
          hiddenCellRef.current?.removeEventListener('keydown', function (e) {
            if (e.key === 'enter') {
              e.preventDefault()

              formik.handleSubmit()
            }
          })
      }
    }

    document.addEventListener('keydown', traceCell)
    return () => {
      document.removeEventListener('keydown', traceCell)
    }
  }, [])

  return (
    <Formik
      validationSchema={acceptItemSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_track_form' ref={formRef}>
            <Field
              type='text'
              name='cell'
              className='visually-hidden'
              id='hidden-cell-ref'
              innerRef={hiddenCellRef}
            />
            <AcceptTrackModalHeader
              asTrack={!values.location?.countryConfig?.parcelIdentByOrderNumber || false}
              isLoading={isLoading}
              allowCreateParcel={!!values?.location?.shipmentInternational}
              submitRemotely={formik.handleSubmit}
              formRef={formRef}
            />
            {/* {JSON.stringify(errors)}
            {JSON.stringify(values)} */}
            <div
              className='scroll-y bg-white modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3'>
                <div className='w-100'>
                  {/* {values.location?.id ? ( */}
                  <WarehousesSelect />
                  {/* ) : (
                    <p className='text-danger fs-6'>
                      Для прийому треку спочатку створіть, як мінімум, один склад.
                    </p>
                  )} */}
                  <InputTemplate
                    title={`Номер ${
                      values.location?.countryConfig?.parcelIdentByOrderNumber === true
                        ? 'замовлення'
                        : 'треку'
                    }`}
                    inputName={'number'}
                    required={values.location?.countryConfig?.parcelIdentByOrderNumber === false}
                    type='text'
                    titleFontSize='fs-5'
                    handleBlur={(val: string) => {
                      findRelatedInfo(
                        values.location?.countryConfig?.parcelIdentByOrderNumber === true
                          ? true
                          : false,
                        val,
                        values?.location,
                        setFieldValue
                      )
                    }}
                  />
                  <InputTemplate
                    title={`Номер ${
                      values.location?.countryConfig?.parcelIdentByOrderNumber === true
                        ? 'треку'
                        : 'замовлення'
                    }`}
                    inputName={'altNumber'}
                    required={false}
                    type='text'
                    titleFontSize='fs-5'
                    handleBlur={(val: string) => {
                      findRelatedInfo(
                        values.location?.countryConfig?.parcelIdentByOrderNumber === false
                          ? true
                          : false,
                        val,
                        values.location,
                        setFieldValue
                      )
                    }}
                    placeholder={
                      values.location?.countryConfig?.parcelIdentByOrderNumber === true
                        ? values.number
                        : ''
                    }
                  />
                  {setUsersLoading &&
                    setUsersResults &&
                    usersResults &&
                    (usersLoading === true || usersLoading === false) &&
                    filterUsers && (
                      <ClientSelect
                        setUsersLoading={setUsersLoading}
                        setUsersResults={setUsersResults}
                        options={usersResults}
                        isLoading={usersLoading}
                        currentValue={values.user}
                        onInputChange={filterUsers}
                        widthClassName='w-100 text-dark'
                        titleClass='fs-5'
                      />
                    )}
                  {directions && (
                    <div className='w-100'>
                      <div className='fv-row mb-5'>
                        <DirectionsSelect />
                      </div>
                    </div>
                  )}
                  {values?.prods &&
                    (values.location?.acceptType === 'full' ||
                      values.location?.acceptType === 'amount') && (
                      <TrackGoods
                        prods={values.prods}
                        setFieldValue={setFieldValue}
                        values={{prods: values.prods, location: values.location}}
                        listView={listView}
                      />
                    )}
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {AcceptTrackForm}
