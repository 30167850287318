import {Column} from 'react-table'
import {WebsitePageActionsCell} from './WebsitePageActionsCell'
import {CustomHeader} from './CustomHeader'
import {IWebsitePage} from '../../core/_models'
import {WebsitePageNameCell} from './WebsitePageNameCell'

const websitePagesColumns: ReadonlyArray<Column<IWebsitePage>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Назва' className='min-w-150px' />,
    id: 'name',
    Cell: ({...props}) => <WebsitePageNameCell page={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Редагувати' className='min-w-50px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <WebsitePageActionsCell page={props.data[props.row.index]} />,
  },
]

export {websitePagesColumns}
