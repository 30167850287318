import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import UserGroupPage from './user-group-page/UserGroupPage'
import {UserGroupsListWrapper} from './user-groups-list/UserGroupsList'

const UserGroupsPage = () => {
  const location = useLocation()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<UserGroupsListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <UserGroupPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to={`/user-groups/list`} />} />
    </Routes>
  )
}

export default UserGroupsPage
