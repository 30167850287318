/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Employee} from '../../../core/_models'

type Props = {
  employee: Employee
}

const EmployeeAddress: FC<Props> = ({employee}) => {
  const boolean = true
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className='text-dark fw-bold text-hover-primary fs-5 mb-1'>Київ</span>
        <span className='text-muted fw-semobold text-muted d-block fs-7'>Київська обл.</span>
        <span className='text-muted fw-semobold text-muted d-block fs-7'>
          {' '}
          {boolean === true ? 'Відділення' : 'Адреса'}
        </span>
        <span className='text-muted fw-semobold text-muted d-block fs-7'>НП №2</span>
      </div>
    </div>
  )
}

export {EmployeeAddress}
