import {useFormikContext} from 'formik'
import React from 'react'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IPollForm} from '../core/_models'

const OwnAnswer = ({values, index}: {values: Partial<IPollForm>; index: number}) => {
  const {setFieldValue} = useFormikContext()
  return (
    <InputTemplate
      inputName={`questions[${index}].allowOwnAnswer`}
      type='checkbox'
      fieldWidth='w-50'
      checkboxProps={{
        checked: values?.questions ? values?.questions[index]?.allowOwnAnswer : false,
        disabled: false,
        setFieldValue: setFieldValue,
        value: 1,
        inputName: `questions[${index}].allowOwnAnswer`,
        type: 'checkbox',
      }}
      title={'Свій варіант'}
      containerMarginBottom={false}
    />
  )
}

export default OwnAnswer
