/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FlagsDirectionBar} from '../../../../../modules/custom/FlagsDirectionBar'
import {ICategory} from '../../core/_models'

type Props = {
  category: ICategory
}

const CategoryDirectionsCell: FC<Props> = ({category}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {category.directions && (
          <FlagsDirectionBar
            chosenDirections={category.directions}
            additionalClass='DirectionsCell__column'
          />
        )}
      </div>
    </div>
  )
}

export {CategoryDirectionsCell}
