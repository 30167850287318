import {ITelegramTranslation} from '../../../../core/_models'

type Props = {
  translation: ITelegramTranslation
}

const TranslationKeyCell = ({translation}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold fs-6 m-0'>{translation.keyName}</p>
    </div>
  )
}

export {TranslationKeyCell}
