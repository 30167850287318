import {Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import {Header} from './header/Header'
import {useQuery, useQueryClient} from 'react-query'
import {OverviewTab} from './overview/OverviewTab'
import {ContentTab} from './content/ContentTab'
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {reformTrackApiProductsToForm} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {
  IOrder,
  IOrderFormProd,
  IOrderHistory,
} from '../../../../pages/orders/core/_models/_orders-models'
import {ITrackFormProd, Track} from '../../../../pages/tracks/core/_models/_tracks-models'
import {ID, QUERIES} from '../../../../../_metronic/helpers'
import {getOrderById, getOrderLogsById} from '../../../../pages/orders/core/_requests'
import {getTrackById} from '../../../../pages/tracks/core/_requests'
import {PageTitle} from '../../../../../_metronic/layout/core'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'
import {BuyoutContent} from '../../../../pages/buyouts/buyout-page/tabs/content/BuyoutContent'
import {BuyoutHeader} from '../../../../pages/buyouts/buyout-page/header/BuyoutHeader'
import {BuyoutLogs} from '../../../../pages/buyouts/buyout-page/tabs/logs/BuyoutLogs'
import BuyoutOverview from '../../../../pages/buyouts/buyout-page/tabs/overview/BuyoutOverview'

interface Props {
  id: string | number
  getByIdFunc: (id: ID) => any
}

export const typeOfOrder = (pathname: string) => {
  const options = {
    tracks: {
      query: 'track',
      apiRequest: getTrackById,
      apiHistory: getOrderLogsById,
      path: 'tracks',
      intl: 'TRACK',
      icon: '/media/icons/duotune/ecommerce/ecm010.svg',
    },
    orders: {
      query: 'order',
      apiRequest: getOrderById,
      apiHistory: getOrderLogsById,
      path: 'orders',
      intl: 'ORDER',
      icon: '/media/icons/duotune/coding/cod006.svg',
    },
  }

  return pathname.split('/')[1] === 'tracks' ? options.tracks : options.orders
}

const EditingPage = ({id}: Props) => {
  const {pathname} = useLocation()

  const {data: itemById} =
    useQuery<Track | IOrder | undefined>(`${typeOfOrder(pathname).query}-by-id-${id}`, () => {
      return typeOfOrder(pathname).apiRequest(id)
    }) || {}

  const {data: currItemHistory} =
    useQuery<IOrderHistory[] | undefined>(
      `${typeOfOrder(pathname).query}-history-by-id-${id}`,
      () => {
        if (typeOfOrder(pathname).apiHistory) {
          return typeOfOrder(pathname).apiHistory(id)
        }
      }
    ) || []

  const queryClient = useQueryClient()

  const invalidateOrder = () => {
    queryClient.invalidateQueries(`${typeOfOrder(pathname).query}-by-id-${id}`)
  }

  const intl = useIntl()

  const [currItem, setCurrItem] = useState<Track | IOrder | undefined>(undefined)

  const [goodsFields, setGoodsFields] = useState<(ITrackFormProd | IOrderFormProd)[]>([])

  const getGoodsFields = async (item: Track | IOrder) => {
    const res = await reformTrackApiProductsToForm(item.products)

    setGoodsFields(res)
  }

  useEffect(() => {
    if (itemById) {
      setCurrItem(itemById)
      getGoodsFields(itemById)
    }
  }, [itemById])

  return (
    <>
      {currItem !== null && currItem ? (
        <>
          {'type' in currItem && currItem.type === 'buyout' ? (
            <Routes>
              <Route
                element={
                  <>
                    <BuyoutHeader buyout={currItem} setCurrBuyout={setCurrItem} />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`settings/:id`}
                  element={
                    <>
                      <BuyoutOverview buyout={currItem} invalidateBuyout={invalidateOrder} />
                    </>
                  }
                />{' '}
                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <BuyoutContent buyout={currItem} invalidateBuyout={invalidateOrder} />
                    </>
                  }
                />
                {/* <Route
                  path={`comments/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.COMMENTS_TAB'})}</PageTitle>
                      <BuyoutComments buyout={currBuyout} />
                    </>
                  }
                /> */}
                {currItemHistory && (
                  <Route
                    path={`logs/:id`}
                    element={
                      // <>
                      //   <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.STATUSES_TAB'})}</PageTitle>
                      <BuyoutLogs logs={currItemHistory} />
                      // </>
                    }
                  />
                )}
                {/* <Route
                  path={`price/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB'})}</PageTitle>
                      <BuyoutPrices buyout={currBuyout} isArchive={isArchive} />{' '}
                    </>
                  }
                /> */}
                {/* <Route
                  path={`settings/:id`}
                  element={
                    <>
                      <PageTitle>{intl.formatMessage({id: 'BUYOUT_PAGE.SETTINGS_TAB'})}</PageTitle>
                      <BuyoutSettings
                        buyout={currBuyout}
                        setCurrBuyout={setCurrBuyout}
                        isArchive={isArchive}
                      />
                    </>
                  }
                /> */}
                <Route index element={<Navigate to={`content/${id}`} />} />
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route
                element={
                  <>
                    <Header item={currItem} />
                    <Outlet />
                  </>
                }
              >
                <Route
                  path={`overview/:id`}
                  element={
                    <>
                      <OverviewTab item={currItem} setCurrItem={setCurrItem} />
                    </>
                  }
                />

                <Route
                  path={`content/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage(
                          {id: `${typeOfOrder(pathname).intl}_PAGE.CONTENT_TAB`},
                          {qty: currItem.products.length}
                        )}
                      </PageTitle>
                      <ContentTab item={currItem} setCurrItem={setCurrItem} />
                    </>
                  }
                />
                {/*   <Route
                  path={`invoice/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage({id: `${typeOfOrder.intl}_PAGE.INVOICE_TAB`})}
                      </PageTitle>
                      <TrackInvoice item={currItem} setCurrItem={setCurrItem} />
                    </>
                  }
                /> */}

                {/* <Route
                  path={`receiver/:id`}
                  element={
                    <>
                      <PageTitle>
                        {intl.formatMessage({id: `${typeOfOrder.intl}_PAGE.RECEIVER_TAB`})}
                      </PageTitle>
                      <TrackReceiver track={currItem} setCurrItem={setCurrItem} />
                    </>
                  }
                /> */}
                <Route
                  index
                  element={<Navigate to={`/${typeOfOrder(pathname).path}/edit/overview/${id}`} />}
                />
              </Route>
            </Routes>
          )}
        </>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default EditingPage
