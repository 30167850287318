import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const ShipmentStatusFilter = ({savedFilter, setSavedFilter}: TProps) => {
  return (
    <div className='mb-10'>
      <label className='form-label fw-bold'>
        <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS' />
      </label>

      <div className='d-flex'>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='shipment'
            type='radio'
            value={undefined}
            checked={savedFilter?.closed === undefined}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, closed: undefined}
              })
            }}
          />
          <span className='form-check-label'>
            <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL' />
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='shipment'
            type='radio'
            value={1}
            checked={savedFilter?.closed === true}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, closed: true}
              })
            }}
          />
          <span className='form-check-label'>
            Закриті
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' /> */}
          </span>
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            name='shipment'
            type='radio'
            value={0}
            checked={savedFilter?.closed === false}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, closed: false}
              })
            }}
          />
          <span className='form-check-label'>
            Активні
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' /> */}
          </span>
        </label>
      </div>
    </div>
  )
}

export default ShipmentStatusFilter
