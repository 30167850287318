import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {transformDirectionsForForm} from '../../../modules/custom/track-or-order/_modals/OnePageFormWrapper'
import {DirectionsQueryResponse} from '../../directions/core/_models'
import {IWebsite} from '../../websites/core/_models'
import {useListView} from '../core/ListViewProvider'
import {CreatePollForm} from './CreatePollForm'

export const CreatePollFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  // const {
  //   isLoading,
  //   data: track,
  //   error,
  // } = useQuery(
  //   'trackEdit',
  //   () => {
  //     return getTrackById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  //  if (isLoading) {
  //    return (
  //      <div>
  //        <PartialPageLoader />
  //      </div>
  //    )
  // }
  const queryClient = useQueryClient()
  const websites: IWebsite[] = queryClient.getQueryData([QUERIES.WEBSITES_LIST]) || []

  const countries: Record<string, string> =
    queryClient.getQueryData(QUERIES.COUNTRY_ABBR_LIST) || {}
  if (itemIdForUpdate === null && websites) {
    return (
      <CreatePollForm
        websites={websites.map((i) => ({
          ...i,
          value: i?.id,
          label: i.name,
          name: i.name,
          faviconUrl: i.faviconUrl,
          host: i.host,
        }))}
      />
    )
  }

  return null
}
