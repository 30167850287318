import {Column} from 'react-table'
import {TrackCustomHeader} from './TrackCustomHeader'
import {Track} from '../../../core/_models/_tracks-models'
import {TrackItemsInfoCell} from './cells/TrackItemsInfoCell'
import {TrackSelectionCell} from './cells/selection/TrackSelectionCell'
import {TrackNumberCell} from './cells/TrackNumberCell'
import {TrackStatusCell} from './cells/TrackStatusCell'
import {TrackActionsCell} from './cells/TrackActionsCell'
import {TrackSelectionHeader} from './cells/selection/TrackSelectionHeader'
import {TrackUserCell} from './cells/TrackUserCell'
import {TrackShipmentCell} from './cells/TrackShipmentCell'
import {TrackParcelsCell} from './cells/TrackParcelsCell'

const tracksColumns: ReadonlyArray<Column<Track>> = [
  // {
  //   Header: (props) => <TrackSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <TrackSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_TRACK_NUMBER'}
        className='min-w-50px'
      />
    ),
    id: 'trackNumber',
    Cell: ({...props}) => <TrackNumberCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_CONTENT'}
        className='min-w-150px'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <TrackItemsInfoCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader tableProps={props} title={'TRACKS_COLUMNS_USER'} className='min-w-125px' />
    ),
    id: 'user',
    Cell: ({...props}) => <TrackUserCell track={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_SHIPMENT'}
        className='min-w-50px'
      />
    ),
    id: 'shipment',
    Cell: ({...props}) => <TrackShipmentCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'TRACKS_COLUMNS_PARCELS'}
        className='min-w-50px'
      />
    ),
    id: 'parcels',
    Cell: ({...props}) => <TrackParcelsCell track={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TrackCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TrackActionsCell track={props.data[props.row.index]} />,
  },
]

export {tracksColumns}
