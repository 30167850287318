import {Form, Formik} from 'formik'
import {useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ImageLightbox} from '../../../../../modules/custom/images/ImageLightbox'
import {useUnpackShipmentContext} from '../core/UnpackShipmentContext'
import {editUnpackedItemSchema} from '../core/yup'
import {IEditUnpackedItem, IUnpackedItem} from '../core/_models'

interface Props {
  item: IUnpackedItem
}

export function EditItemForm({item}: Props) {
  const {setItemIdForUpdate, setUnpackedItems} = useUnpackShipmentContext()

  const inits = {
    ...item,
    client: item.client.externalId + ' ' + item.client.firstName + ' ' + item.client.lastName,
  }

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleSubmit = async (values: IEditUnpackedItem) => {

    const editedItem = {...values, client: item.client}
    setItemIdForUpdate(null)
    setUnpackedItems((prev) => {
      const filtered = prev.filter((item) => item.code !== editedItem.code)

      return [editedItem, ...filtered]
    })
  }
  return (
    <div className={`card mb-5 mb-xl-10 `}>
      {' '}
      <Formik
        validationSchema={editUnpackedItemSchema}
        initialValues={inits}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue, errors, touched}) => (
          <Form noValidate>
            {/* {JSON.stringify(errors)} */}

            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  {/* {imageIsLoading === true ? (
                    <div className='d-flex justify-content-center align-items-center w-100px w-lg-160px mx-9 my-12'>
                      <span className='text-center h-60px w-60px spinner-border spinner-border-sm align-middle'></span>
                    </div>
                  ) : (
                    <> */}
                  <div
                    className={`symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ${
                      item.photos[0] === '/media/icons/duotune/general/gen017.svg'
                        ? ''
                        : 'cursor-zoom-in'
                    }`}
                    onClick={
                      item.photos[0] !== '/media/icons/duotune/general/gen017.svg'
                        ? openLightbox
                        : () => {}
                    }
                  >
                    <img
                      src={
                        item.photos[0] === '/media/icons/duotune/general/gen017.svg'
                          ? item.photos[0]
                          : `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` +
                            item.photos[0]
                      }
                      alt='itemPhoto'
                      style={{objectFit: 'contain'}}
                    />

                    {item.photos[0] !== '/media/icons/duotune/general/gen017.svg' && (
                      <button
                        type='button'
                        className='btn btn-sm position-absolute'
                        style={{top: '75%', right: '10px'}}
                      >
                        <KTSVG
                          path='/media/icons/duotune/files/fil012.svg'
                          className='svg-icon-1 svg-icon-gray-900 me-1 position-absolute'
                        />
                        <span className='position-absolute top-75 end-0 text-white fs-8'>
                          {item.photos.length}
                        </span>
                      </button>
                    )}
                  </div>{' '}
                  <ImageLightbox
                    viewerIsOpen={viewerIsOpen}
                    closeLightbox={closeLightbox}
                    currentImage={currentImage}
                    images={item.photos.map((photo, index) => {
                      return {
                        src: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + photo,
                        title: `${item.code}-${index + 1}`,
                      }
                    })}
                  />
                  {/* </>
                  )} */}
                </div>

                <div className='d-flex flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-start mb-2 flex-gutter fw-bolder fs-7'>
                        <InputTemplate
                          required={true}
                          type='text'
                          placeholder={item.type === 1 ? '№ посилки' : '№ товару'}
                          inputName='code'
                          formValues={values}
                          fieldWidth='w-100'
                          inputFontSize='fs-7'
                          backgroundClass={errors.client && touched.client ? 'error-border' : ''}
                          errorMessage={false}
                        />
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-7 mb-1 pe-2 flex-gutter'>
                        <div className='d-flex flex-gutter'>
                          {' '}
                          <InputTemplate
                            required={true}
                            type='number'
                            inputName='width'
                            formValues={values}
                            containerMarginBottom={false}
                            fieldWidth={'w-80px'}
                            addSymbol='см'
                            inputFontSize='fs-8'
                            symbolMarginTop={false}
                            backgroundClass={errors.width && touched.width ? 'error-border' : ''}
                            errorMessage={false}
                          />
                          <InputTemplate
                            required={true}
                            type='number'
                            inputName='height'
                            formValues={values}
                            containerMarginBottom={false}
                            fieldWidth={'w-80px'}
                            addSymbol='см'
                            inputFontSize='fs-8'
                            symbolMarginTop={false}
                            backgroundClass={errors.height && touched.height ? 'error-border' : ''}
                            errorMessage={false}
                          />
                          <InputTemplate
                            required={true}
                            type='number'
                            inputName='length'
                            formValues={values}
                            containerMarginBottom={false}
                            fieldWidth={'w-80px'}
                            addSymbol='см'
                            inputFontSize='fs-8'
                            symbolMarginTop={false}
                            backgroundClass={errors.length && touched.length ? 'error-border' : ''}
                            errorMessage={false}
                          />
                        </div>
                        <InputTemplate
                          required={true}
                          type='number'
                          inputName='weight'
                          formValues={values}
                          containerMarginBottom={false}
                          fieldWidth={'w-80px'}
                          addSymbol='кг'
                          inputFontSize='fs-8'
                          symbolMarginTop={false}
                          backgroundClass={errors.weight && touched.weight ? 'error-border' : ''}
                          errorMessage={false}
                        />{' '}
                        <div>
                          <button type='submit' className='btn btn-sm btn-primary'>
                            Зберегти
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
