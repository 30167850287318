import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {ICategory} from '../../../../../_models'

type Props = {
  category: ICategory
}

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const CategoryTitleCell = ({category}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={toAbsoluteUrl(
            category.imageUrl
              ? `${BASE_URL}${category.imageUrl}`
              : '/media/icons/duotune/art/art008.svg'
          )}
          alt=''
          style={{objectFit: 'contain'}}
        />
      </div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>{category.name}</p>
    </div>
  )
}

export {CategoryTitleCell}
