import {useListView} from '../../../core/ListViewProvider'
import {DeliveryTypesListToolbar} from './DeliveryTypesListToolbar'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../../core/QueryRequestProvider'

const DeliveryTypesListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <ListSearch updateState={updateState} /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <DeliveryTypesListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {DeliveryTypesListHeader}
