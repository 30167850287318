import {useFormikContext} from 'formik'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import {CurrencySelect} from '../../../../../modules/custom/form-elements/CurrencySelect'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import PaymentProvidersSelect from '../../../../../modules/custom/form-elements/selects/PaymentProvidersSelect'

const DeliveryAndEtcSection = () => {
  const {values, setFieldValue} = useFormikContext<any>()

  const queryClient = useQueryClient()

  const currenciesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.CURRENCIES_LIST]) || {}
  return (
    <>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
          <h3>Доставка та інші послуги</h3>
        </label>

        <div className='col-lg-8'></div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
          <FormattedMessage id='GENERAL.LABELS.CURRENCY' />
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              {' '}
              {currenciesList && (
                <InputTemplate
                  inputName='serviceCurrency'
                  type='select'
                  setFieldValue={setFieldValue}
                  value={values.serviceCurrency}
                  custom={
                    <CurrencySelect
                      name='serviceCurrency'
                      currencies={objectToArray(currenciesList)}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
          <FormattedMessage id='COMPONENTS.PaymentProvidersSelect.LABEL' />
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <PaymentProvidersSelect withTitle={false} inputName='deliveryPaymentProvider' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeliveryAndEtcSection
