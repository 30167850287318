import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useListView} from '../core/ListViewProvider'
import {CreateDirectionFormWrapper} from './CreateDirectionFormWrapper'

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateDirectionModal = () => {
  const {itemIdForUpdate} = useListView()

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog w-100 mw-md-auto mw-0 me-0 my-0'
      show={itemIdForUpdate !== undefined}
      backdrop={false}
      className='OnePageForm mw-md-auto mw-lg-auto mw-0 p-0 shadow-sm'
    >
      <div
        className='d-flex flex-column justify-content-between modal-content modal-content-container pt-20'
        style={{
          height: '100vh',
          borderRadius: 0,
          overflowY: 'auto',
        }}
      >
        <CreateDirectionFormWrapper />
      </div>
    </Modal>,
    modalsRoot
  )
}

export default CreateDirectionModal
