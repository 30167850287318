import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ICompanyDetails} from '../../core/_models'
import {SettingsHeaderInfo} from './SettingsHeaderInfo'
import SettingsHeaderLangSwitcher from './SettingsHeaderLangSwitcher'
import {SettingsHeaderName} from './SettingsHeaderName'
import {SettingsHeaderNav} from './SettingsHeaderNav'

interface Props {
  company: ICompanyDetails | undefined
}

const SettingsHeader = ({company}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='avatar_blank' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <SettingsHeaderName company={company} />{' '}
              <div className='d-none d-md-block'>
                <SettingsHeaderInfo company={company} />
              </div>
            </div>
          </div>
          {/* <SettingsHeaderLangSwitcher company={company} /> */}
        </div>

        <SettingsHeaderNav company={company} />
      </div>
    </div>
  )
}

export {SettingsHeader}
