import * as Yup from 'yup'

const createDirectionSchema = Yup.object({
  countryFrom: Yup.string().required('Обязательное поле').label('Страна отправителя'),
  countryTo: Yup.string().required('Обязательное поле').label('Страна получателя'),

  senderTypes: Yup.array()
    .min(1, 'Нужно активировать хотя бы один тип доставки')
    .required()
    .label('Типы'),

  weightUnit: Yup.string()
    .required('Укажите единицу измерения веса')
    .label('Единица измерения веса'),
  sizeUnit: Yup.string()
    .required('Укажите единицу измерения длины')
    .label('Единица измерения длины'),
  buyoutCurrency: Yup.string().label('Валюта выкупа'),
  serviceCurrency: Yup.string().required().label('Валюта оплаты услуги'),
  taxFreeLimit: Yup.number()
    .typeError('Допустимы только числа')
    .required('Укажите сумму')
    .label('Сумма беспошлинного ввоза'),
  taxFreeLimitCurrency: Yup.string().required('Укажите валюту').label('Валюта'),
  brokerFee: Yup.number()
    .typeError('Допустимы только числа')
    .required('Укажите процент')
    .label('Брокерский процент'),
})

export {createDirectionSchema}
