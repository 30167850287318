import {Direction, DirectionsQueryResponse} from '../../../directions/core/_models'
import {Field, ErrorMessage} from 'formik'
import {IDeliveryTypeForm} from '../../../../../_metronic/helpers/custom/tsHelpers/deliveryTypesHelpers'
import {ID, QUERIES} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'
import {getWeightUnit} from '../../core/funcs/getMeasurementUnit'
import {getCurrencySymbol} from '../../core/funcs/getCurrencySymbol'

import {TCurrencyData} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import currenciesLocal from '../../../../../data/general/currencies.json'

interface Props {
  values: IDeliveryTypeForm
}
function CalculationTypeOne({values}: Props) {
  const queryClient = useQueryClient()

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}

  const direction = directions?.data?.find(({id}) => values.direction && +values.direction === id)

  const currSymbols: TCurrencyData = currenciesLocal

  return (
    <>
      {' '}
      {directions?.data && direction && (
        <>
          {' '}
          <div className='d-flex'>
            <div className='fv-row mb-10 DeliveryTypeOne__price'>
              {' '}
              <label className='d-flex align-items-center fs-6 fw-normal mb-2'>
                <span className='required'>
                  Вартість за {getWeightUnit(direction)} (
                  {currSymbols[
                    //  @ts-ignore
                    directions?.data?.find((item) => +values.direction === +item.id)
                      ?.serviceCurrency
                  ]?.symbol ||
                    directions?.data?.find(
                      (item) => values.direction && +values.direction === item.id
                    )?.serviceCurrency}
                  )
                </span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Вкажіть вартість'
                ></i>
              </label>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='price'
                placeholder=''
              />
              <div className='text-danger'>
                <ErrorMessage name='price' />
              </div>
            </div>
            <div className='fv-row mb-10'>
              {' '}
              <label className='d-flex align-items-center fs-6 fw-normal mb-2'>
                <span className='required'>Максимальна вага ({getWeightUnit(direction)})</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Вкажіть максимальну вагу'
                ></i>
              </label>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='max_weight'
                placeholder=''
              />
              <div className='text-danger'>
                <ErrorMessage name='max_weight' />
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='fv-row mb-10 DeliveryTypeOne__price'>
              {' '}
              <div className='d-flex'>
                {' '}
                <label className='d-flex align-items-center fs-6 fw-normal mb-2 me-2'>
                  <span>Мінімальна вартість ({getCurrencySymbol(direction)})</span>
                </label>
                <Field className='form-check-input' type='radio' name='minimum_value' value='0' />
              </div>
              {+values.minimum_value === 0 && (
                <div>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='min_price'
                    placeholder=''
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='min_price' />
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              {' '}
              <div className='d-flex'>
                {' '}
                <label className='d-flex align-items-center fs-6 fw-normal mb-2 me-2'>
                  <span>Мінімальна вага ({getWeightUnit(direction)})</span>
                </label>
                <Field className='form-check-input' type='radio' name='minimum_value' value='1' />
              </div>
              {+values.minimum_value === 1 && (
                <div>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='min_weight'
                    placeholder=''
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='min_weight' />
                  </div>
                </div>
              )}
            </div>
          </div>{' '}
          <div className='text-danger'>
            <ErrorMessage name='minimum_value' />
          </div>
        </>
      )}
    </>
  )
}

export {CalculationTypeOne}
