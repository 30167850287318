import {IPaymentProvider} from '../../core/_models'

interface Props {
  provider: IPaymentProvider
}

export function ProviderHeaderName({provider}: Props) {
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
              {provider.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
