import {FormattedMessage} from 'react-intl'
import {Link, useLocation, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'
import {Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {typeOfOrder} from '../EditingPage'

interface Props {
  item: Track | IOrder
}

export const HeaderNav = ({item}: Props) => {
  const {pathname} = useLocation()
  const {products} = item
  const {id} = useParams()

  const pagePath = `/${typeOfOrder(pathname).path}`

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((tab) => (
          <li className='nav-item' onClick={scrollToTabsStart} key={tab.path}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (pathname === `${pagePath}/edit/${tab.path}/${id}` && 'active')
              }
              to={`${pagePath}/edit/${tab.path}/${id}`}
            >
              <span className='me-2'>
                <FormattedMessage
                  id={tab.intl}
                  values={{qty: tab.path === 'content' ? products.length : ''}}
                />
              </span>
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className={
                  `svg-icon-4 svg-icon-grey me-1 ` +
                  (pathname === `${pagePath}/edit/${tab.path}/${id}` && 'svg-icon-success')
                }
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const tabs = [
  {
    path: `overview`,
    intl: 'TRACK_PAGE.GEN_TAB',
  },
  {
    path: `content`,
    intl: 'TRACK_PAGE.CONTENT_TAB',
  },
  {
    path: `invoice`,
    intl: 'TRACK_PAGE.INVOICE_TAB',
  },
  {
    path: `receiver`,
    intl: 'TRACK_PAGE.RECEIVER_TAB',
  },
]
