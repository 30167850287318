import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IUserGroupEdit} from '../../user-groups-list/core/_models'

interface Props {
  group: IUserGroupEdit
}

export function UserGroupHeaderNav({group}: Props) {
  const {pathname} = useLocation()
  const {id} = useParams()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {group.translations &&
          Object.entries(group.translations).map(([key, value]) => (
            <li className='nav-item' onClick={scrollToTabsStart} key={key}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (pathname === `/user-groups/edit/${key}/${id}` && 'active')
                }
                to={`/user-groups/edit/${key}/${id}`}
              >
                {key}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  )
}
