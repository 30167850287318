import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IWebsitePage} from '../../website-pages-list/core/_models'

interface Props {
  page: IWebsitePage
}

export function PageHeaderNav({page}: Props) {
  const {pathname} = useLocation()
  const {locale, websiteId, id} = useParams()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((tab) => (
          <li className='nav-item' onClick={scrollToTabsStart} key={tab.path}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (pathname ===
                  `/websites/pages/list/${websiteId}/edit/${tab.path}/${locale}/${id}` && 'active')
              }
              to={`/websites/pages/list/${websiteId}/edit/${tab.path}/${locale}/${id}`}
            >
              {tab.intl}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const tabs = [
  {
    path: `overview`,
    intl: 'Основне',
  },
  {
    path: `seo`,
    intl: 'SEO',
  },
]
