import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {EmailTemplate, EmailTemplatesQueryResponse} from './_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const TEMPLATES_URL = `/email_templates`
const BASE_URL = `${APP_URL}${API_URL}${TEMPLATES_URL}`

const getEmailTemplates = (query: string): Promise<EmailTemplatesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<EmailTemplatesQueryResponse>) => d.data)
}

const getTemplateById = (id: ID): Promise<EmailTemplate | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<EmailTemplate>) => d.data)
}

const editEmailTemplate = (id: ID, data: Partial<EmailTemplate>) => {
  return axios.put(`${BASE_URL}/${id}`, data).then((response) => {
    return response.data
  })
}

export {getEmailTemplates, getTemplateById, editEmailTemplate}
