import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce} from '../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../_metronic/helpers/custom/funcs/directions'
import {IBasicSelect} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'
import AsyncSelect from 'react-select/async'
import {customStyles} from '../../../../../../modules/custom/form-elements/ReactSelectTemplate'
import {directionsApi} from '../../../../../directions/core/_requests'
import {components} from 'react-select'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const DirectionFilter = ({savedFilter, setSavedFilter}: TProps) => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterDirections = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      directionsApi
        .fetchDirectionsWithIds('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [directionsApi.fetchDirectionsWithIds]
  )

  useEffect(() => {
    filterDirections()
  }, [])

  const optionFormat = (v: IBasicSelect) => (
    <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
      <FlagsDirectionBar chosenDirections={[{id: v.value}]} minified={true} />

      <div className='d-flex flex-column fw-bold text-gray-800 '>{v.label}</div>
    </div>
  )
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Не знайдено</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(results)
      return
    }

    filterDirections('')
  }

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    setSavedFilter((prev) => {
      return {...prev, directions: selectedValue}
    })
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column
      
       mb-4`}
      // ${widthClassName}
    >
      {/* {title === true && ( */}
      <label
        className={`position-relative flex-column fw-bold mb-2 required`}
        // ${
        //   titleClass ? titleClass : 'fs-6'
        // }
        // `}
        htmlFor={'directions'}
      >
        Напрямок:
      </label>
      {/* )} */}
      <AsyncSelect
        styles={customStyles}
        formatOptionLabel={optionFormat}
        className='form-control form-control-lg form-control-solid p-0 fw-normal'
        loadOptions={loadOptions}
        defaultOptions={results}
        form={'directions'}
        // closeMenuOnSelect={false}
        isDisabled={false}
        components={{NoOptionsMessage}}
        loadingMessage={() => 'Зачекайте...'}
        placeholder={'Напрямок'}
        isClearable={true}
        isLoading={resultsLoading}
        value={savedFilter?.directions?.value ? savedFilter?.directions : null}
        onChange={handleOnChange}
        onInputChange={(e) => {
          filterDirections(e)
        }}
      />

      {/* {formik && (
        <div className='text-danger'>
          <ErrorMessage name={'directions.label'} />
        </div>
      )} */}
    </div>
  )
}

export default DirectionFilter
