import * as Yup from 'yup'
import {
  apartmentSchema,
  citySchema,
  houseLetterSchema,
  houseNumberSchema,
  mainOfficeCitySchema,
  mainOfficeNumberSchema,
  officeSchema,
  premisesTypeSchema,
  reserveOfficeCitySchema,
  reserveOfficeNumberSchema,
  streetSchema,
  typeOfDeliverySchema,
} from './partials/addressesPartials'

const createShipmentAddressSchema = (intl: any) =>
  Yup.object({
    houseNumber: houseNumberSchema(intl),
    houseLetter: houseLetterSchema(intl),
    street: streetSchema(intl),
    premisesType: premisesTypeSchema(intl),
    apartment: apartmentSchema(intl),
    office: officeSchema(intl),
    city: citySchema(intl),
    mainOfficeCity: mainOfficeCitySchema(intl),
    mainOfficeNumber: mainOfficeNumberSchema(intl),
    reserveOfficeCity: reserveOfficeCitySchema(intl),
    reserveOfficeNumber: reserveOfficeNumberSchema(intl),
    typeOfDelivery: typeOfDeliverySchema(intl),
    mainAddress: Yup.mixed().required().label(intl.messages.YUP_VALIDATION_SHIPMENT_MAIN_WH),
  })

const editShipmentAddressSchema = (intl: any) =>
  Yup.object({
    typeOfDelivery: typeOfDeliverySchema(intl),
    houseNumber: houseNumberSchema(intl),
    houseLetter: houseLetterSchema(intl),
    street: streetSchema(intl),
    premisesType: premisesTypeSchema(intl),
    apartment: apartmentSchema(intl),
    office: officeSchema(intl),
    city: citySchema(intl),
    mainOfficeCity: mainOfficeCitySchema(intl),
    mainOfficeNumber: mainOfficeNumberSchema(intl),
    reserveOfficeCity: reserveOfficeCitySchema(intl),
    reserveOfficeNumber: reserveOfficeNumberSchema(intl),
  })

export {createShipmentAddressSchema, editShipmentAddressSchema}
