import axios from 'axios'
import {ITariffs} from '../tsHelpers/generalHelpers'

const API_URL = process.env.REACT_APP_API_PATH
const PUBLIC_URL = `/public`
const BASE_URL = `${API_URL}${PUBLIC_URL}`

const getSpecificCountryByCode = async (code: string) => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/countries?code=${code}`)

  return data[Object.keys(data)[0]]
}
const getTariffs = async () => {
  const {data} = await axios.get<ITariffs | undefined>(`https://
www.vector-usa.biz/api/track/tariffs`)

  return data
}

const getSymbolByCode = async (code: string) => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/currencies?code=${code}&symbol=true`)
  return data[Object.keys(data)[0]]
}

const getListOfCurrencies = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/currencies`)

  return data
}

const getWeightUnits = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/weight-units`)

  return data
}
const getSizeUnits = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/directions/size-units`)

  return data
}

const getCategories = async () => {
  const {data} = await axios.get<any>(`${BASE_URL}/product_categories`)

  const transformed = [...data?.reverse()]
  return transformed
}

export const generalApi = {
  getSpecificCountryByCode,
  getSymbolByCode,
  getListOfCurrencies,
  getWeightUnits,
  getSizeUnits,
  getCategories,
  getTariffs,
}
