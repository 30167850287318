import {Formik, Form} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {IDeliveryType, IDeliveryTypeForm} from '../../../core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {deliveryTypesApi} from '../../../core/_requests'
import {
  getDataForDeliveryTypeToSend,
  minValueInit,
} from '../../../../../../_metronic/helpers/custom/funcs/delivery-types'
import {objectToArray2} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import DeliveryTime from '../../../elements/DeliveryTime'
import DelTypeCalculation from '../../../elements/DelTypeCalculation'
import OversizeAddFee from '../../../elements/OversizeAddFee'

interface Props {
  deliveryType: IDeliveryType
  setCurrDeliveryType: (obj: IDeliveryType) => void
}

export function DeliveryTypeConditions({deliveryType, setCurrDeliveryType}: Props) {
  const minValueInitValue = minValueInit(
    deliveryType.rate.data.min_weight,
    deliveryType.rate.data.min_price
  )

  const deliveryTwoItemsInits = deliveryType.rate.data.price
    ? [{weight: 1, specificWeightPrice: 0}]
    : objectToArray2(deliveryType.rate.data)
  const [deliveryTwoFields, setDeliveryTwoFields] = useState(deliveryTwoItemsInits)
  const initialValues: Partial<IDeliveryTypeForm> = {
    direction: deliveryType.direction?.id,
    type: +deliveryType.rate.type,
    price: deliveryType.rate.data.price,
    deliveryTwoItems: deliveryTwoItemsInits,
    max_weight: deliveryType.rate.data.max_weight,
    minimum_value: minValueInitValue.toString(),
    min_price: +minValueInitValue === 0 ? deliveryType.rate.data.min_price : '',
    min_weight: +minValueInitValue === 1 ? deliveryType.rate.data.min_weight : '',
  }

  const queryClient = useQueryClient()

  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const getCountries: Record<string, string> = countriesList

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IDeliveryTypeForm>) => {
    setLoading(true)

    const {
      max_weight,
      price,
      type,
      min_weight,
      min_price,
      minimum_value,
      palletLength,
      palletOversizeFee,
      palletWidth,
    } = values

    const deliveryTypeData = {
      id: deliveryType.id,
      rate: {
        ...deliveryType.rate,
        type: type ? +type : null,
        data:
          type && minimum_value
            ? getDataForDeliveryTypeToSend(
                +type,
                min_weight,
                min_price,
                price,
                max_weight,
                deliveryTwoFields,
                +minimum_value
              )
            : null,
      },
      specialRates: [],
    }
    const response = await deliveryTypesApi.editDeliveryType(deliveryTypeData)

    if (response) {
      queryClient.invalidateQueries([QUERIES.DELIVERY_TYPE_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values)} */}
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  Оберіть тип розрахунку доставки:
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <DelTypeCalculation
                      values={values}
                      setDeliveryTwoFields={setDeliveryTwoFields}
                      deliveryTwoFields={deliveryTwoFields}
                      withTitle={false}
                    />
                  </div>
                </div>
              </div>{' '}
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
