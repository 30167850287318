import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {WarehousesQueryResponse, IWarehouse, ICreateWarehouse} from './_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const WAREHOUSES_URL = `/locations`
const BASE_URL = `${APP_URL}${API_URL}${WAREHOUSES_URL}`

const getWarehouses = (query: string, getLastOne?: boolean): Promise<WarehousesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}${getLastOne ? '&order[orderAcceptLocation]=asc' : ''}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<WarehousesQueryResponse>) => d.data)
}

const getWarehouseById = (id: ID): Promise<IWarehouse | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IWarehouse>) => d.data)
}

const addWarehouse = (data: ICreateWarehouse): Promise<IWarehouse | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .post(`${BASE_URL}`, data, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IWarehouse>) => d.data)
}

const updateWarehouse = (
  id: ID,
  data: Partial<ICreateWarehouse>
): Promise<IWarehouse | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .put(`${BASE_URL}/${id}`, data, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IWarehouse>) => d.data)
}

const deleteWarehouse = async (id: number) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {getWarehouses, getWarehouseById, addWarehouse, updateWarehouse, deleteWarehouse}
