import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import {CurrencySelect} from '../../../../../modules/custom/form-elements/CurrencySelect'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {useListView} from '../../../core/ListViewProvider'

const TaxFreeSection = () => {
  const intl = useIntl()
  const {itemIdForUpdate} = useListView()
  const {values, setFieldValue} = useFormikContext<any>()

  const queryClient = useQueryClient()

  const currenciesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.CURRENCIES_LIST]) || {}
  return (
    <>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
          <h3>
            <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_HEADING' />
          </h3>
        </label>

        <div className='col-lg-8'></div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
          <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_LIMIT' />
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title={intl.formatMessage({
              id: 'DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_LIMIT.TOOLTIP',
            })}
          ></i>
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              {' '}
              <InputTemplate
                inputName={`taxFreeLimit`}
                disabled={!!itemIdForUpdate}
                type='number'
                required={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
          <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.TAX_FREE_CURRENCY' />
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              {currenciesList && (
                <InputTemplate
                  inputName='taxFreeLimitCurrency'
                  type='select'
                  required={true}
                  setFieldValue={setFieldValue}
                  value={values.taxFreeLimitCurrency}
                  custom={
                    <CurrencySelect
                      name='taxFreeLimitCurrency'
                      currencies={objectToArray(currenciesList)}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TaxFreeSection
