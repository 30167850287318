import {ErrorMessage, Field} from 'formik'

const DeliveryTime = ({withTitle = true}) => {
  return (
    <div className='fv-row mb-5'>
      {withTitle && (
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Термін доставки (к-сть днів)</span>
        </label>
      )}
      <Field
        type='number'
        className='form-control form-control-lg form-control-solid'
        name='deliveryTime'
        placeholder=''
      />
      <div className='text-danger'>
        <ErrorMessage name='deliveryTime' />
      </div>
    </div>
  )
}

export default DeliveryTime
