import {Column} from 'react-table'
import {ArticleCustomHeader} from './ArticleCustomHeader'
import {IArticle} from '../../../core/_models'
import {ArticleDateCell} from './cells/ArticleDateCell'
import {ArticleActionsCell} from './cells/ArticleActionsCell'
import {ArticleTitleCell} from './cells/ArticleTitleCell'
import {ArticleWebsitesCell} from './cells/ArticleWebsitesCell'

const articlesColumns: ReadonlyArray<Column<IArticle>> = [
  {
    Header: (props) => (
      <ArticleCustomHeader
        tableProps={props}
        title={'BLOG_COLUMNS_TITLE'}
        className='min-w-125px'
      />
    ),
    id: 'title',
    Cell: ({...props}) => <ArticleTitleCell article={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ArticleCustomHeader
        tableProps={props}
        title={'BLOG_COLUMNS_WEBSITES'}
        className='min-w-150px'
      />
    ),
    id: 'websites',
    Cell: ({...props}) => <ArticleWebsitesCell article={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ArticleCustomHeader
        tableProps={props}
        title={'BLOG_COLUMNS_CREATED'}
        className='min-w-50px'
      />
    ),
    id: 'date',
    Cell: ({...props}) => <ArticleDateCell article={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ArticleCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ArticleActionsCell article={props.data[props.row.index]} />,
  },
]

export {articlesColumns}
