import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQueries, useQuery} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {couriersApi} from '../core/_requests'
import {ICourier} from '../core/_models'
import {CourierOverview} from './tabs/overview/CourierOverview'
import {CourierHeader} from './header/CourierHeader'
import {generalApi} from '../../../../_metronic/helpers/custom/api/generalApi'

interface Props {
  id: string | number
}

const CourierPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: courierById} = useQuery(QUERIES.COURIER_BY_ID, () => couriersApi.getCourierById(id))

  useQueries([
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
  ])

  const [currCourier, setCurrentCourier] = useState<ICourier | undefined>(undefined)

  useEffect(() => {
    if (courierById) {
      setCurrentCourier(courierById)
    }
  }, [courierById])

  return (
    <>
      {currCourier && courierById ? (
        <Routes>
          <Route
            element={
              <>
                <CourierHeader courier={currCourier} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <CourierOverview courier={currCourier} setCurrCourier={setCurrentCourier} />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default CourierPage
