import {FieldArray, useFormikContext} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import AddAnswerButton from './AddAnswerButton'
import AddQuestionButton from './AddQuestionButton'
import AnswerItem from './AnswerItem'
import CreatingItemBodyVersion from './CreatingItemBodyVersion'
import EditingItemBodyVersion from './EditingItemBodyVersion'
import OwnAnswer from './OwnAnswer'
import {IQAItemProps} from './QuestionAnswerItem'
import QuestionItem from './QuestionItem'
import RemoveAnswerButton from './RemoveAnswerButton'
import RemoveQuestionButton from './RemoveQuestionButton'

const QuestionAnswerItemBody = ({
  index,
  values,
  questionArrayHelpers,
  editingFormat,
}: Omit<IQAItemProps, 'field'>) => {
  const {setFieldValue} = useFormikContext()
  return (
    <>
      {editingFormat ? (
        <EditingItemBodyVersion
          index={index}
          questionArrayHelpers={questionArrayHelpers}
          values={values}
        />
      ) : (
        <CreatingItemBodyVersion
          index={index}
          questionArrayHelpers={questionArrayHelpers}
          values={values}
        />
      )}
    </>
  )
}

export default QuestionAnswerItemBody
