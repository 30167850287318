/* eslint-disable jsx-a11y/anchor-is-valid */
import {IStatus} from '../../core/_models'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'

type Props = {
  status: IStatus
}

const EntityCell: FC<Props> = ({status}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {Object.entries(status?.entityTypes).map(
          ([k, v]) =>
            v && (
              <span>
                <FormattedMessage id={`STATUSES.ENTITIES.${k.toUpperCase()}`} />
              </span>
            )
        )}
      </div>
    </div>
  )
}

export {EntityCell}
