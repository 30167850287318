import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {ICountry} from '../../../countries-list/core/_models'
import ReceiverLayout from './ReceiverLayout'
import SenderLayout from './SenderLayout'

interface Props {
  country: ICountry
  setCurrCountry: Dispatch<SetStateAction<ICountry | undefined>>
}
const CountryOverview = ({country, setCurrCountry}: Props) => {
  //   const initialValues = {}

  //   const [loading, setLoading] = useState(false)

  //   const [footerNotification, setFooterNotification] = useState<{
  //     success: boolean
  //     color: string
  //     intl: string
  //     icon: string
  //   } | null>(null)

  //   const handleSubmit = async (values: Partial<NewLocationFullForm>, actions: FormikValues) => {
  //     const {} = values
  //     setLoading(true)

  // try {
  //   const response = await updateLocation(payload, id)


  //   if (response) {
  //     setCurrLocation(response)
  //     setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
  //     setTimeout(() => {
  //       setFooterNotification(null)
  //     }, 3000)
  //   }
  // } catch (error: any) {
  //   console.log(error)
  //   if (error?.response?.data?.message) {
  //     setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

  //     setTimeout(() => {
  //       setFooterNotification(null)
  //     }, 3000)
  //   }
  // } finally {
  //   setLoading(false)
  // }
  //   }

  return (
    <>
      {country ? (
        <div className='card mb-5 mb-xl-10 pt-10'>
          {country.receive === true && (
            <ReceiverLayout currCountry={country} setCurrCountry={setCurrCountry} />
          )}

          {country.send === true && (
            <SenderLayout currCountry={country} setCurrCountry={setCurrCountry} />
          )}
        </div>
      ) : (
        <PartialPageLoader />
      )}
    </>
  )
}

export default CountryOverview
