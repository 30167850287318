import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {
  initialQueryState,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../_metronic/helpers'

import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const CountriesListFilter = () => {
  const {updateState, state}: any = useQueryRequest()
  const {isLoading} = useQueryResponse()

  // filters

  // const [sender, setSender] = useState<boolean | undefined>(true)
  // const [receiver, setReceiver] = useState<boolean | undefined>(true)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.COUNTRIES_LIST), '')
  }

  const filterData = (send: boolean, receive: boolean) => {
    const filter = {send, receive}
    updateState({
      ...initialQueryState,
      filter,
    })
    const data = JSON.stringify(filter)
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.COUNTRIES_LIST), data)
  }

  return (
    <>
      {/* <button
        disabled={isLoading}
        type='button'
        className='btn btn-sm btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Фільтр
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Фільтр</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-3' data-kt-user-table-filter='form'>
          <div className='py-6 mb-5'></div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Статус верифікації:</label>

            <div></div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Тип отримувача:</label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  onChange={(e) => setWithinCountry(e.target.checked)}
                />
                <span className='form-check-label'>По країні</span>
              </label>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='2'
                  defaultChecked={true}
                  onChange={(e) => setInternational(e.target.checked)}
                />
                <span className='form-check-label'>Міжнародний</span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='3'
                  defaultChecked={true}
                  onChange={(e) => setFinal(e.target.checked === true ? 1 : 0)}
                />
                <span className='form-check-label'>Кінцевий</span>
              </label>
            </div>
          </div>

          <div className='mb-10'>
            {' '}
            <label className='form-label fw-bold'>Отримувачі:</label>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                name='enabledLocations'
                defaultChecked={true}
                onChange={(e) => setEnabled(e.target.checked)}
              />
              <label className='form-check-label'>
                {' '}
                {enabled === true ? 'Активні' : 'Заблоковані'}
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Отмена
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Применить
            </button>
          </div>
        </div>
      </div> */}
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm ${
          !state.filter?.send && !state.filter?.receive ? 'btn-primary' : ' btn-light-primary'
        } fw-bold me-3`}
        onClick={() => {
          resetData()
        }}
      >
        Всі
      </button>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm fw-bold me-3 ${
          state.filter?.send === true ? 'btn-primary' : ' btn-light-primary'
        }`}
        onClick={() => {
          filterData(true, false)
        }}
      >
        Відправника
      </button>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm ${
          state.filter?.receive === true ? 'btn-primary' : ' btn-light-primary'
        } fw-bold me-3 `}
        onClick={() => {
          filterData(false, true)
        }}
      >
        Отримувача
      </button>
    </>
  )
}

export {CountriesListFilter}
