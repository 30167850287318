import {IReceivingUser} from '../_models'
import {getFullUpdate, getImage} from './getDataFromServer'
import {getImageLink} from './getImageLink'

export async function initiateFullScanFromComputer(code: string, clientObj: IReceivingUser) {
  const upd = await getFullUpdate(code)
  let photo: string = ''

  await getImage()
    .then((blob) => {
      return getImageLink(blob, 1)
    })
    .then((retrievedImage) => {
      photo = retrievedImage
    })
  const newItem = {
    code: code,
    weight: upd.weight,
    height: upd.height,
    width: upd.width,
    length: upd.length,
    client: clientObj,
    photos: [photo],
    type: code.includes('-') ? 2 : 1,
  }

  return newItem
}
