import {ICreateNewCourier, ICourier, CouriersQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ID} from '../../../../_metronic/helpers'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API = process.env.REACT_APP_API_PATH

const ENDPOINT_BASE = `/delivery_services`

const BASE_URL = `${API}${ENDPOINT_BASE}`

const fetchCouriers = (query: string): Promise<CouriersQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<CouriersQueryResponse>) => {
      return d.data
    })
}

const fetchCourierTypes = (): Promise<string[]> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/types`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<string[]>) => {
      return d.data.filter((i) => i !== 'custom')
    })
}

const getCourierById = (id: ID): Promise<ICourier | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ICourier>) => d.data)
}

const addCourier = async (data: Partial<ICreateNewCourier>) => {
  const {data: response} = await axios.post(`${BASE_URL}`, data)

  return response
}
const uploadFile = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const deleteCourier = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

const editCourier = async (id: ID, data: any) => {
  const {data: response} = await axios.put<any>(`${BASE_URL}/${id}`, data)

  return response
}

const patchStatus = async (id: ID, newStatus: boolean) => {
  const response = await axios.put<any>(`${BASE_URL}/${id}`, {enabled: newStatus})
  return response
}

export const couriersApi = {
  fetchCouriers,
  fetchCourierTypes,
  getCourierById,
  addCourier,
  uploadFile,
  deleteCourier,
  editCourier,
  patchStatus,
}
