import {IPollFormQuestion} from '../core/_models'

interface Props {
  item: IPollFormQuestion
  bgColor?: string
}

export function QuestionAnswerItemMinifiedHeader({item, bgColor = ''}: Props) {
  const defImg = '/media/icons/duotune/ecommerce/ecm002.svg'
  return (
    <div className={`table-responsive p-0 ${bgColor}`}>
      <table className='table align-middle table-row-dashed fs-5 fw-bold gy-5 dataTable no-footer'>
        <tbody>
          <tr>
            <td className='py-0'>{item.question}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
