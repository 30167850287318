import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {CategoryHeader} from './header/CategoryHeader'
import {ICategory} from '../core/_models'
import {getCategoryById} from '../core/_requests'
import CategoryOverviewTab from './tabs/overview/CategoryOverviewTab'
import {CategoryNoResults} from './CategoryNoResults'
import CategorySynonymsTab from './tabs/synonyms/CategorySynonymsTab'
import CategorySettingsTab from './tabs/settings/CategorySettingsTab'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const CategoryPage = ({id}: Props) => {
  const {data: categoryById} = useQuery(`category-${id}`, () => getCategoryById(id))

  const [currCategory, setCurrCategory] = useState<ICategory | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateCategory = () => {
    queryClient.invalidateQueries(`category-${id}`)
  }

  useEffect(() => {
    if (categoryById) {
      setCurrCategory(categoryById)
    }
  }, [categoryById])

  return (
    <>
      {currCategory && categoryById ? (
        <Routes>
          <Route
            element={
              <>
                <CategoryHeader category={currCategory} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <CategoryOverviewTab
                    category={currCategory}
                    invalidateCategory={invalidateCategory}
                  />
                </>
              }
            />
            <Route
              path={`synonyms/:locale/:id`}
              element={
                <>
                  <CategorySynonymsTab
                    category={currCategory}
                    invalidateCategory={invalidateCategory}
                  />
                </>
              }
            />
            <Route
              path={`settings/:locale/:id`}
              element={
                <>
                  <CategorySettingsTab
                    category={currCategory}
                    invalidateCategory={invalidateCategory}
                  />
                </>
              }
            />
            <Route path={`:id`} element={<CategoryNoResults />} />

            <Route index element={<Navigate to={`/overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default CategoryPage
