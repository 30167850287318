import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {selectDebounce} from '../../../../../_metronic/helpers'
import {websitesApi} from '../../../../pages/websites/core/_requests'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../InputTemplate'
import {getCategoriesByWebsite} from '../../../../pages/categories/stores/core/_requests'
import {ICategory} from '../../../../pages/categories/_models'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const WebsitesSelect = ({withTitle = true, disabled = false}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<{
    website: IBasicSelect
    categoriesList?: ICategory[]
  }>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterWebsites = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      websitesApi
        .fetchWebsites()
        .then((res) => {
          const reformed = res.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
            }
          })
          setResults(reformed)
          if (callback) {
            callback(reformed)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [websitesApi.fetchWebsites]
  )
  useEffect(() => {
    filterWebsites()
  }, [])

  useEffect(() => {
    filterWebsites()
  }, [filterWebsites])

  const getCategories = async () => {
    const res = await getCategoriesByWebsite(values.website.value)

    setFieldValue('categoriesList', res)
  }

  useEffect(() => {
    if (values.categoriesList) getCategories()
  }, [values?.website?.value])

  return (
    <InputTemplate
      inputName='website'
      required={true}
      type='select'
      // value={values.website || {value: '', label: ''}}
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.WebsitesSelect.LABEL'}) : ''}
      titleFontSize='fs-5 text-dark'
      disabled={disabled}
      reactSelectprops={{
        currentValue: values.website || {value: '', label: ''},
        options: results,
        optionFormat: ({faviconUrl, name, host}) => (
          <div className='tagify__dropdown__item d-flex align-items-center'>
            <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
              <img
                alt=''
                className='rounded-circle w-25px me-2'
                src={
                  faviconUrl
                    ? `${BASE_URL}${faviconUrl}`
                    : '/media/icons/duotune/communication/com001.svg'
                }
              />
            </div>

            <div className='d-flex flex-column text-gray-600'>
              <strong>{name}</strong>
              {host && <span className='fs-6 fw-normal'>{host}</span>}
            </div>
          </div>
        ),
        selectName: 'website',
        isLoading: resultsLoading,
        onInputChange: filterWebsites,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'STORES.ADD_MODAL.FORM.WEBSITES_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'STORES.ADD_MODAL.FORM.NO_WEBSITES_MESSAGE',
        }),
      }}
    />
  )
}

export default WebsitesSelect
