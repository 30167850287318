import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IPoll} from '../../core/_models'

interface Props {
  poll: IPoll
}

export function PollHeaderNav({poll}: Props) {
  const {pathname} = useLocation()
  const {id, locale} = useParams()

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map(({path, intl}) => (
          <li key={path} className='nav-item' onClick={scrollToTabsStart}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (pathname === `/polls/edit/${path}/${locale}/${poll.id}` && 'active')
              }
              to={`/polls/edit/${path}/${locale}/${poll.id}`}
            >
              {intl}
              {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const tabs = [
  {
    path: `overview`,
    intl: 'Основне',
    // можливість Зупинити та Активувати опитування.
    // сайт до якого воно прив'язане;
    // відмічаємо чи буде опція Свій варіант;
    // вказуємо умову старту, зараз буде тільки 2 на вибір:
    // - 1-ша авторизація на сайті;
    // - будь-яка авторизація на сайті.
  },
  {
    path: `qa`,
    intl: 'Питання та відповіді',
  },
  {
    path: `results`,
    intl: 'Результати',
  },
  {
    path: `logs`,
    intl: 'Логи',
  },
]
