import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {DeliveryTypeActionsCell} from './cells/DeliveryTypeActionsCell'
import {DeliveryTypeNameCell} from './cells/DeliveryTypeNameCell'
import {DeliveryTypeStatusCell} from './cells/DeliveryTypeStatusCell'
import {IDeliveryType} from '../../../core/_models'
import {DeliveryTypeDirectionCell} from './cells/DeliveryTypeDirectionCell'

const deliveryTypesColumns: ReadonlyArray<Column<IDeliveryType>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <DeliveryTypeNameCell deliveryType={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'DELIVERY_TYPES_COLUMNS_DIRECTION'} />
    ),
    id: 'direction',
    Cell: ({...props}) => <DeliveryTypeDirectionCell deliveryType={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_STATUS'}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <DeliveryTypeStatusCell deliveryType={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <DeliveryTypeActionsCell deliveryType={props.data[props.row.index]} />,
  },
]

export {deliveryTypesColumns}
