import {ListViewProvider, useListView} from './core/ListViewProvider'
import {StatusesTable} from './table/StatusesTable'

import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StatusesListHeader} from './components/header/StatusesListHeader'
import {KTCard} from '../../../../_metronic/helpers'

const StatusesList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Статуси</h3>
      </div>
      <KTCard>
        {' '}
        <StatusesListHeader />
        <StatusesTable />
      </KTCard>
    </>
  )
}

const StatusesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StatusesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StatusesListWrapper}
