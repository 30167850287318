import {Route, Routes} from 'react-router-dom'
import ChatTranslationsPage from './chat/ChatTranslationsPage'
import ClientTranslationsPage from './client/ClientTranslationsPage'
import TelegramTranslationsPage from './telegram/TelegramTranslationsPage'

const TranslationsPage = () => {
  return (
    <Routes>
      <Route path='chat/*' element={<ChatTranslationsPage />} />
      <Route path='client/*' element={<ClientTranslationsPage />} />
      <Route path='telegram/*' element={<TelegramTranslationsPage />} />

      {/* <Route path='company' element={< />} /> */}
    </Routes>
  )
}

export default TranslationsPage
