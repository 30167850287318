import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {IRegion} from '../../../../core/_models'

type Props = {
  region: IRegion
}

const RegionCourierCell: FC<Props> = ({region}) => {
  return (
    <div>
      <p className='fw-semobold text-black d-block fs-6 mb-0'>{region.deliveryService.name}</p>
    </div>
  )
}

export {RegionCourierCell}
