import {Dispatch, SetStateAction, useEffect} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'

import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/l10n/uk.js'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {startDateForSelect} from '../../../../core/_funcs'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
  resetFilter: () => void
  filterData: () => void
  dateState: any
  setDateState: Dispatch<SetStateAction<any>>
}
const DatePicker = ({
  savedFilter,
  setSavedFilter,
  resetFilter,
  filterData,
  dateState,
  setDateState,
}: TProps) => {
  const {isLoading} = useQueryResponse()

  const today = new Date()
  const yesterday = new Date(today)
  const tomorrow = new Date(today)
  // yesterday.setDate(today.getDate() - 1)
  // tomorrow.setDate(today.getDate() + 1)

  const data: {
    value: 1 | 2 | 3 | 4
    label: string
    dateRange: {
      startDate?: Date
      endDate?: Date
    }
  }[] = [
    {
      value: 1,
      label: 'Поточний день',
      dateRange: {startDate: today, endDate: today},
    },
    {
      value: 2,
      label: 'Останні 7 днів',
      dateRange: {startDate: startDateForSelect(7), endDate: today},
    },
    {
      value: 3,
      label: 'Останні 30 днів',
      dateRange: {startDate: startDateForSelect(31), endDate: today},
    },
    {
      value: 4,
      label: 'Останні 365 днів',
      dateRange: {startDate: startDateForSelect(365), endDate: today},
    },
  ]

  useEffect(() => {
    const prevMonthButton = document.querySelector('.flatpickr-prev-month')

    if (prevMonthButton) {
      ;(prevMonthButton as HTMLElement).click()
      ;(prevMonthButton as HTMLElement).click()
    }
  }, [])

  return (
    <div>
      {' '}
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm shadow-sm btn-${
          savedFilter && Object.keys(savedFilter)?.length > 0
            ? 'primary'
            : 'light-primary btn-color-muted'
        } me-3`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-id='date-menu'
      >
        <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-2' />
        Обрати дату
      </button>{' '}
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
        id='date-menu'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'> Оберіть дату</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5'>
            {data.map(({value, label, dateRange}) => (
              <label
                className='form-check form-check-sm form-check-custom form-check-solid mb-5'
                key={value}
              >
                <input
                  data-kt-menu-dismiss='true'
                  className='form-check-input'
                  name='date'
                  type='radio'
                  value={value}
                  checked={savedFilter.activeDateSelect === value}
                  onChange={() => {
                    setDateState(dateRange)
                    setSavedFilter((prev) => {
                      return {
                        ...prev,
                        activeDateSelect: value,
                        createdAfter: dateRange.startDate,
                        createdBefore: dateRange.endDate,
                      }
                    })
                  }}
                />
                <span className='form-check-label'>{label}</span>
              </label>
            ))}
          </div>
          {/* <label className='form-check form-check-sm form-check-custom form-check-solid fw-bold mb-2'>
            Дата:{' '}
          </label>
          <Flatpickr
            data-kt-menu-dismiss='true'
            value={[new Date(dateState?.startDate)]}
            onChange={([date]) => {
              setDateState({startDate: date, endDate: date})

              setSavedFilter((prev) => {
                return {
                  ...prev,
                  activeDateSelect: undefined,
                  createdAfter: date,
                  createdBefore: date,
                }
              })
            }}
            options={{
              locale: 'uk',
              dateFormat: 'd-M-Y',
            }}
            className='form-control form-control-solid mb-3'
            placeholder={'Оберіть дату'}
          /> */}

          <Flatpickr
            data-kt-menu-dismiss='true'
            value={[new Date(dateState?.startDate), new Date(dateState?.endDate)]}
            onChange={([startDate, endDate]) => {
              setDateState({startDate, endDate})

              setSavedFilter((prev) => {
                return {
                  ...prev,
                  activeDateSelect: undefined,
                  createdAfter: startDate,
                  createdBefore: endDate,
                }
              })
            }}
            options={{
              mode: 'range',
              locale: 'uk',
              dateFormat: 'd-M-Y',
              showMonths: 3,
              maxDate: today,
              onOpen: () => {
                const prevMonthButton = document.querySelector('.flatpickr-prev-month')

                if (prevMonthButton) {
                  ;(prevMonthButton as HTMLElement).click()
                  ;(prevMonthButton as HTMLElement).click()
                }
              },
            }}
            className='form-control form-control-solid mb-3'
            placeholder={'Оберіть діапазон'}
          />

          {/* <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetFilter}
              className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.RESET' />
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.APPLY' />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default DatePicker
