/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  QUERIES,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {AcceptTracksTable} from './table/AcceptTracksTable'
import {useLocation} from 'react-router-dom'
import OnePageModal from '../../../modules/custom/track-or-order/_modals/OnePageModal'
import {AcceptTracksListHeader} from './components/header/AcceptTracksListHeader'
import AcceptTrackModal from '../_modals/AcceptTrackModal'

const AcceptTracksList = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const location = useLocation()
  const {state: locState}: {pathname: string; state: any} = location
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.TRACKS_ACCEPT_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.TRACKS_ACCEPT_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  useEffect(() => {
    setItemIdForUpdate(null)
  }, [])

  const {state} = useQueryRequest()

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Прийом на склад</h3>
        <div className='d-flex flex-gutter'></div>
      </div>
      <KTCard>
        {/* <AcceptTracksListHeader />
        <AcceptTracksTable /> */}

        {itemIdForUpdate !== undefined && <AcceptTrackModal />}
      </KTCard>
    </>
  )
}

const AcceptTracksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AcceptTracksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AcceptTracksListWrapper}
