import {useUnpackShipmentContext} from '../core/UnpackShipmentContext'
import {UnpackedItemsTableRow} from './UnpackedItemsTableRow'
interface Props {
  cellNumber?: string
}
export function UnpackedItemsTable({cellNumber}: Props) {
  const {unpackedItems, setUnpackedItems} = useUnpackShipmentContext()

  const deleteGoods = (id: number | string) => {
    setUnpackedItems((prev) => prev.filter((item) => item.code !== id))
  }
  return (
    <div className={`card`} id='fulfillment-cell-table'>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            style={{tableLayout: 'fixed'}}
          >
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-200px'>№</th>
                <th className='min-w-100px'>Клієнт</th>
                <th className='min-w-30px'>Комірка</th>

                <th className='min-w-250px'>Габарити та вага</th>
                <th className='w-100px'></th>
              </tr>
            </thead>

            <tbody>
              {unpackedItems.map((shipment) => (
                <UnpackedItemsTableRow
                  key={shipment.code}
                  shipment={shipment}
                  cellNumber={cellNumber || null}
                  deleteItem={deleteGoods}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
