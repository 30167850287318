import {Column} from 'react-table'
import {GroupCustomHeader} from './GroupCustomHeader'
import {IUserGroup} from '../../core/_models'
import {GroupNameCell} from './GroupNameCell'
import {GroupUsersNumberCell} from './GroupUsersNumberCell'
import {GroupActionsCell} from './GroupActionsCell'

const websiteFormsColumns: ReadonlyArray<Column<IUserGroup>> = [
  {
    Header: (props) => (
      <GroupCustomHeader tableProps={props} title='Назва' className='min-w-150px' />
    ),
    id: 'name',
    Cell: ({...props}) => <GroupNameCell group={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <GroupCustomHeader tableProps={props} title='Кількість' className='min-w-150px' />
    ),
    id: 'usersNumber',
    Cell: ({...props}) => <GroupUsersNumberCell group={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GroupCustomHeader tableProps={props} title='Редагувати' className='min-w-50px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <GroupActionsCell group={props.data[props.row.index]} />,
  },
]

export {websiteFormsColumns}
