import {FC} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {directionsApi} from '../../../../../../directions/core/_requests'
import {IPaymentProvider} from '../../../../core/_models'

type Props = {
  paymentProvider: IPaymentProvider
}

const PaymentProviderTypeCell: FC<Props> = ({paymentProvider}) => {
  const {data: countriesList} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    directionsApi.fetchDirectionCountriesList
  )
  const getCountries: Record<string, string> = countriesList

  const {refetch} = useQueryResponse()
  return (
    <div className='d-flex align-items-center mb-3'>
      {/* <div className='symbol symbol-25px me-5'>
        <img
          src={
            paymentProvider.logoUrl
              ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${paymentProvider.logoUrl}`
              : toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm006.svg')
          }
          alt={paymentProvider.title}
        />
      </div> */}
      <p className='text-dark m-0'>
        {paymentProvider.type.charAt(0).toUpperCase() + paymentProvider.type.slice(1)}
      </p>
    </div>
  )
}

export {PaymentProviderTypeCell}
