import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {PaymentsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const PAYMENTS_URL = `/payments`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${PAYMENTS_URL}`

const getPayments = (query: string): Promise<PaymentsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<PaymentsQueryResponse>) => d.data)
}

const getPaymentStatuses = (): Promise<PaymentsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/status_values`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<PaymentsQueryResponse>) => d.data)
}

export const paymentsApi = {getPayments, getPaymentStatuses}
