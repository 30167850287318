import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import {StatusesListWrapper} from './StatusesList'
import StatusPage from './status-page/StatusPage'
import {PageViewProvider} from './core/PageViewProvider'

const StatusesPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<StatusesListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <StatusPage
                id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/lists/statuses/list`} />} />
    </Routes>
  )
}

export default StatusesPage
