import {FieldArray, FieldArrayRenderProps} from 'formik'
import React from 'react'
import {IPollForm} from '../core/_models'
import AddAnswerButton from './AddAnswerButton'
import AddQuestionButton from './AddQuestionButton'
import AnswerItem from './AnswerItem'
import OwnAnswer from './OwnAnswer'
import QuestionItem from './QuestionItem'

const CreatingItemBodyVersion = ({
  values,
  index,
  questionArrayHelpers,
}: {
  values: Partial<IPollForm>
  index: number
  questionArrayHelpers: FieldArrayRenderProps
}) => {
  return (
    <div>
      <h4> Питання {index + 1}</h4>

      <div className='d-flex flex-gutter align-items-center'>
        <QuestionItem values={values} index={index} questionArrayHelpers={questionArrayHelpers} />
      </div>

      <FieldArray
        name={`questions[${index}].answers`}
        render={(arrayHelpers) => (
          <div className='d-flex flex-column flex-col-gutter'>
            <div className='d-flex justify-content-between align-items-center mb-0'>
              <h5> Варіанти відповідей</h5>
              <AddAnswerButton arrayHelpers={arrayHelpers} />
            </div>
            {values.questions &&
              values.questions[index].answers?.map((_, answerIndex) => (
                <div className='d-flex align-items-center flex-gutter' key={answerIndex}>
                  <AnswerItem
                    answerIndex={answerIndex}
                    values={values}
                    questionIndex={index}
                    arrayHelpers={arrayHelpers}
                    editingFormat={false}
                  />
                </div>
              ))}
          </div>
        )}
      />

      <div className='d-flex justify-content-between align-items-center mt-1'>
        <OwnAnswer index={index} values={values} />
        {values.questions && index === values.questions.length - 1 && (
          <AddQuestionButton arrayHelpers={questionArrayHelpers} />
        )}
      </div>
    </div>
  )
}

export default CreatingItemBodyVersion
