import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {
  FULF_RECEIVER_VERIFICATION_STATUS,
  initialQueryState,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../_metronic/helpers'

import {ClientSelect} from '../../../elements/ClientSelect'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

const ReceiversListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {usersLoading, setUsersLoading, usersResults, setUsersResults, filterUsers} = useListView()

  // filters
  const [verifStatus, setVerifStatus] = useState<string | undefined>()
  const [final, setFinal] = useState(1)
  const [international, setInternational] = useState<boolean | undefined>(true)
  const [withinCountry, setWithinCountry] = useState<boolean | undefined>(true)
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined)
  const [client, setClient] = useState<number | undefined>(undefined)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.FULF_RECEIVERS_LIST), '')
  }

  const filterData = () => {
    const filter = {verifStatus, final, international, withinCountry, enabled, client}
    updateState({
      filter,
      ...initialQueryState,
    })
    const data = JSON.stringify(filter)
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.FULF_RECEIVERS_LIST), data)
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-sm btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Фільтр
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Фільтр</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-3' data-kt-user-table-filter='form'>
          <div className='py-6 mb-5'>
            {setUsersLoading &&
              setUsersResults &&
              usersResults &&
              (usersLoading === true || usersLoading === false) &&
              filterUsers && (
                <ClientSelect
                  setUsersLoading={setUsersLoading}
                  setUsersResults={setUsersResults}
                  options={usersResults}
                  isLoading={usersLoading}
                  onInputChange={filterUsers}
                  onChange={(e: any) => setClient(e.value)}
                  widthClassName='w-100'
                />
              )}
            {/* <div>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setVerifStatus(e.target.value)}
                value={verifStatus}
              >
                <option></option>
                <option value={VERIFIED}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
                </option>
                <option value={CHECKED}>
                  <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
                </option>
                <option value={WAITING}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
                </option>
              </select>
            </div> */}
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Статус верифікації:</label>

            <div>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setVerifStatus(e.target.value)}
                value={verifStatus}
              >
                <option></option>
                <option value={VERIFIED}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
                </option>
                <option value={CHECKED}>
                  <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
                </option>
                <option value={WAITING}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
                </option>
              </select>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Тип отримувача:</label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  onChange={(e) => setWithinCountry(e.target.checked)}
                />
                <span className='form-check-label'>По країні</span>
              </label>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='2'
                  defaultChecked={true}
                  onChange={(e) => setInternational(e.target.checked)}
                />
                <span className='form-check-label'>Міжнародний</span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='3'
                  defaultChecked={true}
                  onChange={(e) => setFinal(e.target.checked === true ? 1 : 0)}
                />
                <span className='form-check-label'>Кінцевий</span>
              </label>
            </div>
          </div>

          <div className='mb-10'>
            {' '}
            <label className='form-label fw-bold'>Отримувачі:</label>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value='1'
                name='enabledReceivers'
                defaultChecked={true}
                onChange={(e) => setEnabled(e.target.checked)}
              />
              <label className='form-check-label'>
                {' '}
                {enabled === true ? 'Активні' : 'Заблоковані'}
              </label>
            </div>
          </div>

          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Блоки:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value=''></option>
              <option value='VERIFIED'>Общее</option>
              <option value='CHECKED'>Контакты</option>
              <option value='WAITING'>Адрес</option>
              <option value='activity'>Активность</option>
              <option value='finances'>Финансы</option>
              <option value='reports'>Отчеты</option>
            </select>
          </div> */}
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div> */}

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Отмена
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Применить
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReceiversListFilter}
