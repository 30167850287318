import {ICreateNewRegion, IRegion, RegionsQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ID} from '../../../../_metronic/helpers'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API = process.env.REACT_APP_API_PATH

const ENDPOINT_BASE = `/delivery_regions`

const BASE_URL = `${API}${ENDPOINT_BASE}`

const fetchRegions = (query: string): Promise<RegionsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<RegionsQueryResponse>) => {
      return d.data
    })
}

const getRegionById = (id: ID): Promise<IRegion | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IRegion>) => d.data)
}

const addRegion = async (data: ICreateNewRegion) => {
  const {data: response} = await axios.post(`${BASE_URL}`, data)

  return response
}

const deleteRegion = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

const editRegion = async (id: ID, data: any) => {
  const {data: response} = await axios.put<any>(`${BASE_URL}/${id}`, data)

  return response
}

export const regionsApi = {
  fetchRegions,
  getRegionById,
  addRegion,
  deleteRegion,
  editRegion,
}
