import {FC} from 'react'
import {useQuery} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../../payment-providers/core/QueryResponseProvider'
import {directionsApi} from '../../../../../../directions/core/_requests'
import {IPayment} from '../../../../core/_models'
import currenciesLocal from '../../../../../../../../data/general/currencies.json'
import {TCurrencyData} from '../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Link} from 'react-router-dom'

type Props = {
  payment: IPayment
}

const PaymentSumCell: FC<Props> = ({payment}) => {
  const currSymbols: TCurrencyData = currenciesLocal

  return (
    <div className='d-flex flex-column mb-3'>
      <p className='text-dark m-0'>
        {payment.amount} {currSymbols[payment.currency].symbol}
      </p>
    </div>
  )
}

export {PaymentSumCell}
