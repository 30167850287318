import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {IRegion} from '../../../../core/_models'

type Props = {
  region: IRegion
}

const RegionDeliveryMethodsCell: FC<Props> = ({region}) => {
  return (
    <div>
      {region.deliveryMethods.map((i) => (
        <p className='fw-semobold text-black d-block fs-6 mb-0'>{i.name}</p>
      ))}
    </div>
  )
}

export {RegionDeliveryMethodsCell}
