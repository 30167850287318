import axios, {AxiosResponse} from 'axios'
import {SetStateAction, Dispatch} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import { IUserGroupEdit, UserGroupsQueryResponse} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const PAGE_URL = '/user_groups'

const BASE_URL = `${API_URL}${PAGE_URL}`

const getUserGroups = (query: string): Promise<UserGroupsQueryResponse> => {
  return axios.get(`${BASE_URL}?${query}`).then((d: AxiosResponse<UserGroupsQueryResponse>) => {
    return d.data
  })
}

const getUserGroupById = (id: ID, setState?: Dispatch<SetStateAction<number>>) => {
  return axios
    .get(`${BASE_URL}/${id}`)
    .then((response) => {
      if (setState) {
        setState(response.data.totalItems)
      }
      return response.data
    })
    .catch((err) => console.log(err))
}

const editUserGroup = (id: ID, data: Partial<IUserGroupEdit>) => {
  return axios.put(`${BASE_URL}/${id}`, data).then((response) => {
    return response.data
  })
}

export {getUserGroupById, getUserGroups, editUserGroup}
