import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {TracksListWrapper} from './tracks-list/TracksList'
import {useQueries, useQuery, useQueryClient} from 'react-query'
// import {getRecentInvoices} from './core/_requests'
import {getReceivers} from '../fulfillment/receivers/receivers-list/core/_requests'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {useEffect} from 'react'
import {directionsApi} from '../directions/core/_requests'
import {QUERIES} from '../../../_metronic/helpers'
import {Direction} from '../directions/core/_models'
import TracksAdd from './TracksAdd'
import EditingPage from '../../modules/custom/track-or-order/editing-page/EditingPage'
import {getTrackById} from './core/_requests'

const TracksPage = () => {
  const {config} = useLayout()
  const location = useLocation()

  useQuery(QUERIES.COUNTRY_ABBR_LIST, directionsApi.fetchDirectionCountriesList)
  useQueries([{queryKey: [QUERIES.RECEIVERS_LIST], queryFn: () => getReceivers('')}])
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <TracksListWrapper />
            </>
          }
        />

        <Route path='add' element={<TracksAdd />} />

        <Route
          path={'edit/*'}
          element={
            <EditingPage
              getByIdFunc={getTrackById}
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to={`/tracks/list`} />} />
    </Routes>
  )
}

export default TracksPage
