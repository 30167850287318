import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {LocationOverview} from './tabs/overview/LocationOverview'
import {LocationArea} from './tabs/area/LocationArea'
import {LocationHeader} from './header/LocationHeader'

import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getLocationById} from '../core/_requests'
import {Location} from '../core/_models'
import {LocationStatus} from './tabs/logs/LocationStatus'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const LocationPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: locationById} = useQuery(QUERIES.LOCATION_BY_ID, () => getLocationById(id))

  const [currLocation, setCurrLocation] = useState<Location | undefined>(undefined)

  useEffect(() => {
    if (locationById) {
      setCurrLocation(locationById)
    }
  }, [locationById])

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <>
      {currLocation && locationById ? (
        <Routes>
          <Route
            element={
              <>
                <LocationHeader location={currLocation} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <LocationOverview location={currLocation} setCurrLocation={setCurrLocation} />
                </>
              }
            />
            <Route
              path={`area/:id`}
              element={
                <>
                  <LocationArea location={currLocation} setCurrLocation={setCurrLocation} />
                </>
              }
            />

            <Route
              path={`status/:id`}
              element={
                <>
                  <LocationStatus location={currLocation} />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default LocationPage
