import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {PageViewProvider} from './core/PageViewProvider'
import WebsiteTranslationPage from './translation-page/WebsiteTranslationPage'
import TranslationBoard from './TranslationBoard'

const TranslationBoardPage = () => {
  const {id} = useParams()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<TranslationBoard />} />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <WebsiteTranslationPage id={id || ''} />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/websites/translation/${id}/list`} />} />
    </Routes>
  )
}

export default TranslationBoardPage
