import {ICategory} from '../../../../app/pages/lists/categories/core/_models'
import {getCategoryById} from '../../../../app/pages/lists/categories/core/_requests'
import {
  ITrackFormProd,
  ITrackProd,
  ProdsToSend,
} from '../../../../app/pages/tracks/core/_models/_tracks-models'

export const reformTrackFormProductsToApi = (prods: ITrackFormProd[]): ProdsToSend[] => {
  const reformed = prods.map((prod) => {
    return reformTrackFormProductToApi(prod)
  })

  return reformed
}

export const reformTrackFormProductToApi = (prod: ITrackFormProd): ProdsToSend => {
  const {category, qty, cost, total} = prod

  const reformed: ProdsToSend = {
    title: category.label || '',
    category: {id: category.value},
    quantity: +qty,
    priceForOne: +cost,
    priceForAll: +total,
  }

  if (prod?.url) {
    reformed.url = prod.url
  }
  if (prod?.sku) {
    reformed.sku = prod.sku
  }
  if (prod?.color) {
    reformed.color = prod.color
  }
  if (prod?.size) {
    reformed.size = prod.size
  }
  return reformed
}

export const reformTrackApiProductsToForm = async (
  prods: ITrackProd[]
): Promise<ITrackFormProd[]> => {
  const reformed = await Promise.all(
    prods.map(async (prod) => {
      return await reformTrackApiProductToForm(prod)
    })
  )

  return reformed
}

export const reformTrackApiProductToForm = async (prod: ITrackProd): Promise<ITrackFormProd> => {
  const {quantity, priceForOne} = prod
  const categoryForFormNameField: ICategory | undefined = await getCategoryById(prod.category?.id)

  if (!categoryForFormNameField) {
    throw new Error('Category not found')
  }

  const reformed: ITrackFormProd = {
    category: {
      ...categoryForFormNameField,
      value: categoryForFormNameField.id,
      label: categoryForFormNameField.name,
    },
    qty: quantity,
    cost: priceForOne,
    total: quantity * priceForOne,
  }

  if (prod?.url) {
    reformed.url = prod.url
  }
  if (prod?.sku) {
    reformed.sku = prod.sku
  }
  if (prod?.color) {
    reformed.color = prod.color
  }
  if (prod?.size) {
    reformed.size = prod.size
  }
  return reformed
}
