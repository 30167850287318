import {FieldArray} from 'formik'
import {AddTrackForm, ITrackFormProd} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {TrackGoodsItem} from './TrackGoodsItem'
import {FormattedMessage} from 'react-intl'
import {useListView} from '../../../../../pages/tracks/core/ListViewProvider'
import _ from 'lodash'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWarehouse} from '../../../../../pages/warehouses/core/_models'
import {IAcceptWarehouse} from '../../../../../pages/tracks-accept/core/_models/_models'
import {ListViewContextProps} from '../../../../../../_metronic/helpers'

interface Props {
  prods: ITrackFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: ITrackFormProd[]; location?: IAcceptWarehouse}

  listView: ListViewContextProps
}

export function TrackGoods({prods, setFieldValue, values, listView}: Props) {
  const {itemIdForUpdate, currPage} = listView

  return (
    <div className='d-flex flex-column'>
      <div className='w-100'>
        <FieldArray
          name='prods'
          render={(arrayHelpers) => (
            <div className='d-flex flex-column flex-col-gutter'>
              {prods.map((item, index) => (
                <TrackGoodsItem
                  key={index}
                  index={index}
                  item={item}
                  arrayHelpers={arrayHelpers}
                  prods={prods}
                  setFieldValue={setFieldValue}
                  values={values}
                  listView={listView}
                />
              ))}

              {!itemIdForUpdate &&
                (values?.location?.acceptType === 'full' || !values?.location?.acceptType) && (
                  <>
                    <div className='separator mb-3'></div>

                    <div>
                      <button
                        className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                        type='button'
                        onClick={() => {
                          const newItem: ITrackFormProd = {
                            category: {label: '', value: ''},
                            qty: 1,
                            cost: '',
                            total: '',
                          }
                          if (currPage === 'buyouts') {
                            newItem.url = ''
                            newItem.sku = ''
                            newItem.color = ''
                            newItem.size = ''
                          }
                          arrayHelpers.push(newItem)
                        }}
                      >
                        <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' />
                      </button>
                    </div>
                  </>
                )}
            </div>
          )}
        />
      </div>
    </div>
  )
}
