import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {IOrderProps} from '../../../../core/_models/_orders-models'

const WebsiteShop = ({order}: IOrderProps) => {
  return (
    <>
      {order.websiteShop.link ? (
        <a
          href={order.websiteShop.link}
          className='d-block ms-2'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img
            alt=''
            className='w-25px me-2 fit-content'
            src={
              order.websiteShop?.logoUrl
                ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${order.websiteShop.logoUrl}`
                : toAbsoluteUrl('/favicon.ico')
            }
          />
        </a>
      ) : (
        <div>
          <img
            alt=''
            className='w-25px me-2 fit-content'
            src={
              order.websiteShop?.logoUrl || order.websiteShop?.imageUrl
                ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${
                    order.websiteShop.logoUrl || order.websiteShop?.imageUrl
                  }`
                : toAbsoluteUrl('/favicon.ico')
            }
          />
        </div>
      )}
    </>
  )
}

export default WebsiteShop
