import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  IReceiverShipmentAddressToSend,
  IShipmentDetails,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {getConfig} from '../../../../../../_metronic/i18n/Metronici18n'
import {IReceiverToSend, NewReceiverFullForm, Receiver, ReceiversQueryResponse} from './_models'
const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const RECEIVERS_URL = `/receivers`
const BASE_URL = `${APP_URL}${API_URL}${RECEIVERS_URL}`

const getReceivers = (query: string): Promise<ReceiversQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ReceiversQueryResponse>) => d.data)
}

const getReceiverAddresses = async (id: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post<IShipmentDetails[]>(`${BASE_URL}/addresses`, {
    receiver_id: id,
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return data
}

const addReceiverAddress = async (data: IReceiverShipmentAddressToSend | IShipmentDetails) => {
  const {data: response} = await axios.post<any>(`${BASE_URL}/address_update`, data)
  return response
}

const getReceiverById = (id: ID): Promise<Receiver | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Receiver>) => response.data)
}

const createReceiver = (
  receiver: NewReceiverFullForm | Partial<Receiver>
): Promise<Receiver | undefined | any> => {
  return axios
    .post(`${BASE_URL}`, receiver)
    .then((response: AxiosResponse<Response<Receiver>>) => response.data)
    .then((response: Response<Receiver>) => response.data)
}

const updateReceiver = async (receiver: Partial<IReceiverToSend>, id: ID) => {
  const {data} = await axios.put(`${BASE_URL}/${id}`, receiver)

  return data
}

const getReceiverHistory = async (id: ID) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get(`${BASE_URL}/${id}/history`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return data
}

const deleteReceiver = async (id: ID) => {
   await axios.get(`${BASE_URL}/remove?id=${id}`)
}

const uploadFile = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const editReceiverOverview = async (data: any) => {
  const {data: response} = await axios.post<any>(`${BASE_URL}/update`, data)
  return response
}

export {
  getReceivers,
  createReceiver,
  updateReceiver,
  deleteReceiver,
  getReceiverById,
  uploadFile,
  editReceiverOverview,
  getReceiverAddresses,
  addReceiverAddress,
  getReceiverHistory,
}
