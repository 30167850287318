import {FC} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../../_metronic/helpers/custom/funcs/statusColor'

import {useQueryRequest} from '../../../../../payment-providers/core/QueryRequestProvider'
import {useQueryResponse} from '../../../../../payment-providers/core/QueryResponseProvider'
import {IPayment} from '../../../../core/_models'

type Props = {
  payment: IPayment
}

const PaymentStatusCell: FC<Props> = ({payment}) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()
  // const enableDisable = async (boolean: boolean) => {
  //   await updateReceiver({enabled: boolean}, receiver.id)

  //   refetch()
  // }
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <span className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer`}>
        {payment.status?.message}
      </span>
    </div>
  )
}

export {PaymentStatusCell}
