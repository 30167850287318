/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {IParcel} from '../../../../core/_models/_models'

type Props = {
  parcel: IParcel
}

const ParcelItemsInfoCell = ({parcel}: Props) => {
  return (
    <div>
      {parcel.parcelProducts?.length > 0
        ? parcel.parcelProducts?.map((item, index) =>
            item.title ? (
              <p key={index}>{`${item.title} ${item.quantity}x${item.priceForOne}`}</p>
            ) : (
              <span key={index}></span>
            )
          )
        : '-'}
    </div>
  )
}

export {ParcelItemsInfoCell}
