/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useListView} from '../../../../core/ListViewProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
// import {archiveTrack} from '../../../../core/_requests'
import {useIntl} from 'react-intl'
import {IOrder, IOrderProps} from '../../../../core/_models/_orders-models'

const OrderActionsCell = ({order}: IOrderProps) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()
  const {isArchive} = useListView()

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/orders/edit/${order.type === 'buyout' ? 'content' : 'overview'}/${order.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
    </div>
  )
}

export {OrderActionsCell}
