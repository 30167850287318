import {Form, Formik} from 'formik'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../_metronic/helpers'
import {EmailTemplate} from '../templates-list/core/_models'
import {editEmailTemplate} from '../templates-list/core/_requests'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {CardFooter} from '../../../modules/custom/form-elements/CardFooter'

interface Props {
  template: EmailTemplate
  setCurrTemplate: (obj: EmailTemplate) => void
}
const TemplateOverviewTab = ({template, setCurrTemplate}: Props) => {
  const {locale = 'uk'} = useParams()

  const initialValues: {subject: string} = {
    subject: template?.translations && locale ? template.translations[locale].subject : '',
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {subject: string}) => {
    setLoading(true)

    try {
      const translations =
        template.translations && locale
          ? {
              translations: {
                ...template.translations,
                [locale]: {...template.translations[locale], subject: values.subject},
              },
            }
          : template

      const response = await editEmailTemplate(template.id, translations)

      if (response) {
        setCurrTemplate(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row align-items-center'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  {template.translations && (
                    <>
                      <p className='fs-8 text-muted'>Назва</p>
                      <p>{template.translations[locale].subject}</p>
                    </>
                  )}
                </label>

                <div className='col-lg-8'>
                  <InputTemplate inputName={'subject'} required={false} type='textarea' />
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TemplateOverviewTab
