import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getUserGroups} from '../user-groups/user-groups-list/core/_requests'
import {EmployeesListWrapper} from './employees-list/EmployeesList'

const EmployeesPage = () => {
  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  useQuery(QUERIES.USER_GROUPS_LIST, () => getUserGroups(''))
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<EmployeesListWrapper />} />
      </Route>
      <Route index element={<Navigate to='/employees/list' />} />
    </Routes>
  )
}

export default EmployeesPage
