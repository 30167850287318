import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IOrder, IOrderProps} from '../../../orders/core/_models/_orders-models'

const BuyoutHeaderNav = ({order}: IOrderProps) => {
  const {products} = order
  const {id} = useParams()
  const location = useLocation()
  const tabs = [
    {
      value: 'content',
      label: 'BUYOUT_PAGE.CONTENT_TAB',
    },
    {
      value: 'settings',
      label: 'Налаштування',
    },
    {value: 'logs', label: 'Логи'},
  ]
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((tab) => (
          <li className='nav-item' onClick={() => scrollToTabsStart()}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (location.pathname === `/orders/edit/${tab.value}/${id}` && 'active')
              }
              to={`/orders/edit/${tab.value}/${id}`}
            >
              <FormattedMessage
                id={tab.label}
                values={{items: tab.value === 'content' ? `(${products.length})` : ''}}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BuyoutHeaderNav
