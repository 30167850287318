import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {EmailTemplate} from '../../../core/_models'

type Props = {
  template: EmailTemplate
}

const TemplateActionsCell: FC<Props> = ({template}) => {
  const availableLangs = template?.translations ? Object.keys(template?.translations) : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }
  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      <Link
        to={`/email-templates/edit/overview${
          template.locales && availableLangs.length > 0 ? `/${availableLangs[0]}` : '/uk'
        }/${template.id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>
    </div>
  )
}

export {TemplateActionsCell}
