import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {ParcelsListWrapper} from './parcels-list/ParcelsList'
import ParcelPage from './parcels-page/ParcelPage'
import {QUERIES} from '../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getWarehouses} from '../warehouses/core/_requests'
import {useEffect} from 'react'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const ParcelsPage = () => {
  const location = useLocation()
  useQuery(QUERIES.WAREHOUSES_LIST, () => getWarehouses(''))

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <ParcelsListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <ParcelPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/parcels/list' />} />
    </Routes>
  )
}

export default ParcelsPage
