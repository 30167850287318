/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {ITranslationObject} from '../../../../../core/_models'

type Props = {
  translation: ITranslationObject
}

const TranslationsKeyCell: FC<Props> = ({translation}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <p className='fw-semobold text-dark d-block fs-6 mb-2'>
          {' '}
          {translation.websitePages &&
            translation.websitePages.length > 0 &&
            translation.websitePages?.map((item) => item.name)}
        </p>

        <p className='text-gray fw-bold fs-7'>{translation.keyName} </p>
      </div>
    </div>
  )
}

export {TranslationsKeyCell}
