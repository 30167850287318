/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DeliveryTypeHeaderName} from './DeliveryTypeHeaderName'
import {DeliveryTypeHeaderInfo} from './DeliveryTypeHeaderInfo'
import {DeliveryTypeHeaderNav} from './DeliveryTypeHeaderNav'
import {IDeliveryType} from '../../core/_models'

interface Props {
  deliveryType: IDeliveryType
}

const DeliveryTypeHeader = ({deliveryType}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm006.svg')}
                  alt='Delivery type'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <DeliveryTypeHeaderName deliveryType={deliveryType} />{' '}
            </div>
          </div>
          {/* <DeliveryTypeHeaderInfo deliveryType={deliveryType} /> */}
        </div>

        <DeliveryTypeHeaderNav deliveryType={deliveryType} />
      </div>
    </div>
  )
}

export {DeliveryTypeHeader}
