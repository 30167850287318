import {ListViewContextProps, QueryResponseContextProps} from '../../../../../_metronic/helpers'
import {Employee} from '../../../../pages/employees/employees-list/core/_models'
import {User} from '../../../../pages/users/core/_models'
import CreateUserForm from './CreateUserForm'

type TProps = {
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<User | Employee>
}

export const CreateUserFormWrapper = ({listView, queryResponse}: TProps) => {
  const {itemIdForUpdate} = listView
  if (itemIdForUpdate === null) {
    return <CreateUserForm listView={listView} queryResponse={queryResponse} />
  }

  return null
}
