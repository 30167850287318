import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {ShipmentsListWrapper} from './shipments-list/ShipmentsList'
import ShipmentPage from './shipment-page/ShipmentPage'
import {QUERIES} from '../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getWarehouses} from '../warehouses/core/_requests'
import {getTracksForNewShipment} from '../tracks/core/_requests'
import {useEffect} from 'react'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const ShipmentsPage = () => {
  const location = useLocation()
  useQuery(QUERIES.WAREHOUSES_LIST, () => getWarehouses(''))

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <ShipmentsListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <ShipmentPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/shipments/list' />} />
    </Routes>
  )
}

export default ShipmentsPage
