/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {IUserGroup} from '../../core/_models'

type Props = {
  group: IUserGroup
}

const GroupNameCell: FC<Props> = ({group}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6 mb-1'>
          ID: {group.id}
        </a>
        <span className='text-muted fw-semobold text-muted d-block fs-7'>{group.title}</span>
      </div>
    </div>
  )
}

export {GroupNameCell}
