import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'

import {IParcel} from '../../../../core/_models/_models'

type Props = {
  parcel: IParcel
}

const ParcelTracksCell: FC<Props> = ({parcel}) => {
  return (
    <div className='d-flex align-items-center'>
      {parcel.trackingNumbers.map((item) => (
        <Link
          to={`/tracks/edit/overview/${item.id}`}
          className='fw-semobold d-block fs-6 me-1 d-flex'
          key={item.id}
        >
          <KTSVG
            path='/media/icons/duotune/ecommerce/ecm010.svg'
            className={`svg-icon-3 svg-icon-gray-600 cursor-pointer me-1`}
          />

          <span>{item.number}</span>
        </Link>
      ))}
    </div>
  )
}

export {ParcelTracksCell}
