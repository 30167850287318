import * as Yup from 'yup'
import {FULF_RECEIVER_VERIFICATION_STATUS} from '../../../../../../../_metronic/helpers'
import {
  apartmentSchema,
  citySchema,
  houseLetterSchema,
  houseNumberSchema,
  mainOfficeCitySchema,
  mainOfficeNumberSchema,
  officeSchema,
  premisesTypeSchema,
  reserveOfficeCitySchema,
  reserveOfficeNumberSchema,
  streetSchema,
} from '../../../../../../../_metronic/helpers/custom/formik-schemas/partials/addressesPartials'
import {
  basicNameSchema,
  patronymicOptionalSchema,
  phoneNumberSchema,
} from '../../../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'
import {
  emailSchema,
  passportSchema,
  receiverOwnerSchema,
  receiverTypeSchema,
  recTypeOfDeliverySchema,
} from './partials'

const {VERIFIED} = FULF_RECEIVER_VERIFICATION_STATUS

const receiverOverviewSchema = (intl: any) =>
  Yup.object().shape({
    active: Yup.number().required(intl.messages.YUP_VALIDATION_RECEIVER_PICK_STATUS),
    main: Yup.boolean().required(),
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    patronymic: patronymicOptionalSchema(intl),
    document: passportSchema(intl),
    receiverType: receiverTypeSchema(intl),
    verifStatus: Yup.object()
      .shape({
        label: Yup.string()
          .required(intl.messages.YUP_VALIDATION_VERIFSTATUS_NEEDED)
          .notOneOf([''], intl.messages.YUP_VALIDATION_VERIFSTATUS_NEEDED),
        value: Yup.string()
          .required(intl.messages.YUP_VALIDATION_VERIFSTATUS_NEEDED)
          .notOneOf([''], intl.messages.YUP_VALIDATION_VERIFSTATUS_NEEDED),
      })
      .required(intl.messages.YUP_VALIDATION_VERIFSTATUS_NEEDED),
    documentNumber: Yup.string().when('verifStatus.value', {
      is: VERIFIED,
      then: Yup.string().required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_DOC_NUMBER),
    }),
  })

const receiverContactsSchema = (intl: any) =>
  Yup.object().shape({
    phoneNumber: phoneNumberSchema(intl),
    email: emailSchema,
  })

const addReceiverSchema = (intl: any) =>
  Yup.object().shape({
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    patronymic: patronymicOptionalSchema(intl),
    document: passportSchema(intl),
    commonReceiver: Yup.boolean().required(),
    user: receiverOwnerSchema(intl),
    receiverType: receiverTypeSchema(intl),

    phoneNumber: phoneNumberSchema(intl),
    // email: emailSchema,
    // typeOfDelivery: recTypeOfDeliverySchema(intl),
    // houseNumber: houseNumberSchema(intl),
    // houseLetter: houseLetterSchema(intl),
    // street: streetSchema(intl),
    // premisesType: premisesTypeSchema(intl),
    // apartment: apartmentSchema(intl),
    // office: officeSchema(intl),
    // city: citySchema(intl),
    // mainOfficeCity: mainOfficeCitySchema(intl),
    // mainOfficeNumber: mainOfficeNumberSchema(intl),
    // reserveOfficeCity: reserveOfficeCitySchema(intl),
    // reserveOfficeNumber: reserveOfficeNumberSchema(intl),
  })

export {receiverOverviewSchema, receiverContactsSchema, addReceiverSchema}
