import {useQueries, useQuery, useQueryClient} from 'react-query'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {paymentProvidersApi} from './payment-providers/core/_requests'
import {PaymentProvidersListWrapper} from './payment-providers/list/PaymentProvidersList'
import PaymentProvidersPage from './payment-providers/PaymentProvidersPage'
import {paymentsApi} from './payments/core/_requests'
import {PaymentsListWrapper} from './payments/list/PaymentsList'

const FinancesPage = () => {
  useQueries([
    {
      queryKey: [QUERIES.PAYMENT_PROVIDER_TYPES_LIST],
      queryFn: paymentProvidersApi.getProviderTypes,
    },
    {
      queryKey: [QUERIES.PAYMENT_STATUSES],
      queryFn: paymentsApi.getPaymentStatuses,
    },
  ])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path={`payments`} element={<PaymentsListWrapper />} />
        <Route path={`payment-providers/*`} element={<PaymentProvidersPage />} />
      </Route>
      <Route index element={<Navigate to='/finances/payments' />} />
    </Routes>
  )
}

export default FinancesPage
