import * as Yup from 'yup'

export const createWebsiteSchema = [
  Yup.object({
    host: Yup.string().required('Укажите домен').label('Домен'),
    name: Yup.string().required('Укажите название сайта').label('Название сайта'),
    description: Yup.string().label('Описание сайта'),
  }),
  Yup.object({
    logo: Yup.mixed().label('Светлый логотип'),
    logoDark: Yup.mixed().label('Тёмный логотип'),
    favicon: Yup.mixed().label('ICO логотип'),
  }),
  Yup.object({
    mainColor: Yup.string().required('Выберите главный цвет сайта').label('Цвет'),
  }),
  Yup.object({
    links: Yup.array()
      .of(
        Yup.object().shape({
          linkName: Yup.string(),
          websiteUrl: Yup.string(),
        })
      )
      .label('Полезные ссылки'),
  }),
  Yup.object({
    directions: Yup.array()
      .min(1, 'Нужно указать хотя бы одно направление')
      .required('Выберите, как минимум, одно направление')
      .label('Направления сайта'),
  }),
  Yup.object({
    enabledLocales: Yup.array()
      .min(1, 'Оберіть, як мінімум, одну мову')
      .required('Оберіть, як мінімум, одну мову'),
    mainLocale: Yup.string(),
  }),
]
