import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {IPoll, ISavePoll, PollResultsQueryResponse, PollsQueryResponse} from './_models'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH

const ENDPOINT = `/poll_results`

const BASE_URL = `${API_URL}${ENDPOINT}`

const getPollsResults = (id: ID): Promise<PollResultsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?filter[poll]=${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<PollResultsQueryResponse>) => d.data)
}

export const pollResultsApi = {
  getPollsResults,
}
