import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {DeliveryTypesQueryResponse, ICreateNewDeliveryType, IDeliveryType} from './_models'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH

const ENDPOINT = `/delivery_types`

const BASE_URL = `${API_URL}${ENDPOINT}`

const fetchDeliveryTypes = (query: string): Promise<DeliveryTypesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<DeliveryTypesQueryResponse>) => d.data)
}

const getDeliveryTypeById = (id: ID): Promise<IDeliveryType | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IDeliveryType>) => d.data)
}

const addDeliveryType = async (data: ICreateNewDeliveryType) => {
  const {data: response} = await axios.post(`${BASE_URL}`, data)
  return response
}
const changeStatus = async (id: ID, newStatus: boolean) => {
  const response = await axios.post<any>(`${BASE_URL}`, {id, enabled: newStatus})
  return response
}

const deleteDeliveryType = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

const editDeliveryType = async (data: any) => {
  const {data: response} = await axios.post<any>(`${BASE_URL}`, data)
  return response
}

const uploadLogo = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const fetchVolumetricWeightOptions = async () => {
  const {data} = await axios.get<any>('/api/delivery_type/volumetric_weight_types')

  return data
}

const fetchVolumetricWeightPriceMethods = async () => {
  const {data} = await axios.get<any>('/api/delivery_type/volumetric_weight_price_methods')

  return data
}

export const deliveryTypesApi = {
  fetchDeliveryTypes,
  uploadLogo,
  addDeliveryType,
  changeStatus,
  deleteDeliveryType,
  editDeliveryType,
  fetchVolumetricWeightOptions,
  fetchVolumetricWeightPriceMethods,
  getDeliveryTypeById,
}
