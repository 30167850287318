import {FormattedDate, FormattedTime} from 'react-intl'
import {IArticle} from '../../../../core/_models'

type Props = {
  article: IArticle
}

const ArticleDateCell = ({article}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <span className='fw-semobold d-block fs-6 me-1'>
          {article.author
            ? `${article.author.firstName} ${article.author.lastName || ''}`
            : 'Неавторизований'}
        </span>
        <span className='fw-normal d-block fs-7'>
          <FormattedDate year='numeric' month='numeric' day='numeric' value={article.createdAt} />,{' '}
          <FormattedTime
            value={new Date(article.createdAt)}
            hour='numeric'
            minute='numeric'
            second='numeric'
          />
        </span>
      </div>
    </div>
  )
}

export {ArticleDateCell}
