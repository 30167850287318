import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {customStyles} from '../../../../modules/custom/form-elements/ReactSelectTemplate'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ErrorMessage, useFormikContext} from 'formik'

import {Dispatch, SetStateAction} from 'react'
import {getUsers} from '../../../users/core/_requests'
import {FormattedMessage} from 'react-intl'
import {IUserSelect} from '../../../users/core/_models'
import transformUserForSelect from '../../../users/funcs/transformUsersForSelect'

interface Props {
  options: IUserSelect[]
  isLoading: boolean
  currentValue?: IUserSelect | {value: null; label: null}
  noOptionsMessage?: string
  disabled?: boolean
  setUsersLoading: Dispatch<SetStateAction<boolean>>
  setUsersResults: Dispatch<SetStateAction<IUserSelect[]>>
  title?: boolean
  onInputChange: (query: string) => void
  onChange?: (arg: any) => void
  widthClassName?: string
  titleClass?: string
}

export const ClientSelect = ({
  options,
  isLoading,
  currentValue,
  noOptionsMessage,
  disabled = false,
  setUsersLoading,
  setUsersResults,
  title = true,
  onInputChange,
  onChange,
  widthClassName = 'w-75',
  titleClass,
}: Props) => {
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage ? noOptionsMessage : 'Не знайдено'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(options)
      return
    }

    setUsersLoading(true)
    getUsers(`search=${inputValue}`)
      .then((res) => {
        const transformed = res?.data?.map(transformUserForSelect)
        setUsersResults(transformed || [])
        callback(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  const formik = useFormikContext<any>()

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (onChange) {
      onChange(selectedValue)
      return
    } else if (formik && selectedValue !== null) {
      formik?.setFieldValue('user', selectedValue)
    } else {
      formik?.setFieldValue('user', '')
    }
  }

  const optionFormat = (item: IUserSelect) => {
    const {label, id, externalId} = item
    return (
      <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
        <div className='d-flex flex-column text-gray-800'>
          <span className='fw-bold'>{externalId || id}</span>

          <span className='fs-6 fw-normal'>
            <FormattedMessage id='PROFILE.USER_SELECT_LABEL' values={{label}} />
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column ${widthClassName} mb-4`}
    >
      {title === true && (
        <label
          className={`position-relative flex-column fw-bold mb-2 required ${
            titleClass ? titleClass : 'fs-6'
          }`}
          htmlFor={'user'}
        >
          Клієнт
        </label>
      )}
      <AsyncSelect
        styles={customStyles}
        formatOptionLabel={optionFormat}
        className='form-control form-control-lg form-control-solid p-0 fw-normal'
        loadOptions={loadOptions}
        defaultOptions={options}
        form={'user'}
        isDisabled={disabled}
        components={{NoOptionsMessage}}
        loadingMessage={() => 'Зачекайте...'}
        placeholder={'Клієнт'}
        isClearable={true}
        isLoading={isLoading}
        value={currentValue?.value ? currentValue : null}
        onChange={handleOnChange}
        onInputChange={(e) => {
          onInputChange(e)
        }}
      />

      {formik && (
        <div className='text-danger'>
          <ErrorMessage name={'user.label'} />
        </div>
      )}
    </div>
  )
}
