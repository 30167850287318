/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {PageHeaderName} from './PageHeaderName'
import {PageHeaderNav} from './PageHeaderNav'
import PageHeaderLangSwitcher from './PageHeaderLangSwitcher'
import {IWebsitePage} from '../../website-pages-list/core/_models'
import {IWebsite} from '../../../websites/core/_models'

interface Props {
  page: IWebsitePage
  website: IWebsite
}

const PageHeader = ({page, website}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/layouts/lay009.svg')}
                  alt='Location'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <PageHeaderName page={page} website={website} />{' '}
            </div>
          </div>
          <PageHeaderLangSwitcher page={page} />{' '}
        </div>

        <PageHeaderNav page={page} />
      </div>
    </div>
  )
}

export {PageHeader}
