import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {Location, LocationsQueryResponse} from './_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const RECEIVERS_URL = `/cities`
const BASE_URL = `${APP_URL}${API_URL}${RECEIVERS_URL}`

const getAddressCities = (query: string): Promise<LocationsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<LocationsQueryResponse>) => d.data)
}

const getLocationById = (id: ID): Promise<Location | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Location>) => d.data)
}

export {getAddressCities, getLocationById}
