import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {ParcelTracksTab} from './tabs/tracks/ParcelTracksTab'
import {ParcelOverviewTab} from './tabs/overview/ParcelOverviewTab'
import {ParcelHeader} from './header/ParcelHeader'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {getParcelById} from '../core/_requests'
import {IParcel} from '../core/_models/_models'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const ParcelPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: parcelById} = useQuery(`${QUERIES.PARCEL_BY_ID}-${id}`, () => getParcelById(id))

  const queryClient = useQueryClient()

  const invalidateParcel = () => {
    queryClient.invalidateQueries(`${QUERIES.PARCEL_BY_ID}-${id}`)
  }

  const [currParcel, setCurrParcel] = useState<IParcel | undefined>(undefined)

  useEffect(() => {
    if (parcelById) {
      setCurrParcel(parcelById)
    }
  }, [parcelById])

  return (
    <>
      {currParcel && parcelById ? (
        <Routes>
          <Route
            element={
              <>
                <ParcelHeader parcel={currParcel} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <ParcelOverviewTab
                    parcel={currParcel}
                    setCurrParcel={setCurrParcel}
                    invalidateParcel={invalidateParcel}
                  />
                </>
              }
            />
            <Route
              path={`tracks/:id`}
              element={
                <>
                  <ParcelTracksTab
                    parcel={currParcel}
                    setCurrParcel={setCurrParcel}
                    invalidateParcel={invalidateParcel}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ParcelPage
