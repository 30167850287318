export const receiverTypes = (intl: any) => [
  {
    val: 1,
    label: intl.formatMessage({
      id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER',
    }),
    noteText: intl.formatMessage({
      id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER.DESC',
    }),
  },
  {
    val: 2,
    label: intl.formatMessage({
      id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL',
    }),
    noteText: intl.formatMessage({
      id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL.DESC',
    }),
  },
  // {
  //   val: 3,
  //   label: intl.formatMessage({
  //     id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.LOCAL',
  //   }),
  //   noteText: intl.formatMessage({
  //     id: 'RECEIVERS.ADD_REC_MODAL.REC_TYPE.LOCAL.DESC',
  //   }),
  // },
]
