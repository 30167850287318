import * as Yup from 'yup'
import {latinAndNumbersOnly} from '../../../../../_metronic/helpers/custom/regexs'

const сreateBuyoutContentCardSchema = (intl: any) =>
  Yup.object({
    category: Yup.object()
      .shape({
        label: Yup.string()
          .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
          .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
        value: Yup.string()
          .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
          .notOneOf([''], intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY),
        brands: Yup.array(),
        require_weight: Yup.number(),
        require_name: Yup.object().shape({avia: Yup.number(), sea: Yup.number()}),
      })
      .required(intl.messages.YUP_VALIDATION_TRACK_CHOOSE_CATEGORY)
      .label('Категория товара'),

    url: Yup.string()
      .url(intl.messages.YUP_VALIDATION_URL)
      .required(intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS)
      .notOneOf([''], intl.messages.YUP_VALIDATION_ENTER_LINK_TO_GOODS),

    qty: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_QTY)
      .min(1, intl.messages.YUP_VALIDATION_TRACK_AT_LEAST_ONE_GOODS_ITEM)
      .label('Количество позиций'),
    cost: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_PRICE)
      .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
      .label('Стоимость одной позиции'),

    total: Yup.number()
      .required(intl.messages.YUP_VALIDATION_TRACK_ENTER_TOTAL)
      .min(0.1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
      .label('Суммарная стоимость'),
  })

const buyoutPageSettingsSchema = Yup.object({
  delivery_type: Yup.number().required().label('Тип доставки'),
  discountNumber: Yup.string().label('Купон на скидку'),
  dispatch: Yup.boolean().required().label('Тип отправки'),
})

export {сreateBuyoutContentCardSchema, buyoutPageSettingsSchema}
