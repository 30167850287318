import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {UserCell} from './cells/UserCell'
import {IPollResult} from '../../../../../../core/_models'
import {DateCell} from './cells/DateCell'
import {AnswerCell} from './cells/AnswerCell'

const storesColumns: ReadonlyArray<Column<IPollResult>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title={'SHIPMENTS_COLUMNS_DATE'} />,
    id: 'date',
    Cell: ({...props}) => <DateCell result={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title={'POLL_RESULTS_COLUMN_USER'} />,
    id: 'user',
    Cell: ({...props}) => <UserCell result={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'POLL_RESULTS_COLUMN_ANSWER'}
        className='min-w-125px'
      />
    ),
    id: 'answers',
    Cell: ({...props}) => <AnswerCell result={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'STORES_COLUMNS_WEBSITES'}
  //       className='min-w-150px'
  //     />
  //   ),
  //   id: 'websites',
  //   Cell: ({...props}) => <PollResultWebsitesCell store={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'STORES_COLUMNS_CREATED'}
  //       className='min-w-50px'
  //     />
  //   ),
  //   id: 'date',
  //   Cell: ({...props}) => <PollResultDateCell store={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'STORES_COLUMNS_BUYOUTS'}
  //       className='min-w-50px'
  //     />
  //   ),
  //   id: 'buyouts',
  //   Cell: ({...props}) => <PollResultBuyoutsCell store={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'COLUMNS_ACTIONS'}
  //       className='min-w-120px text-end'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <PollResultActionsCell store={props.data[props.row.index]} />,
  // },
]

export {storesColumns}
