import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {selectDebounce} from '../../../../../_metronic/helpers'

import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

import {InputTemplate} from '../InputTemplate'
import {couriersApi} from '../../../../pages/couriers/core/_requests'
import {ICourier} from '../../../../pages/couriers/core/_models'
import {paymentProvidersApi} from '../../../../pages/finances/payment-providers/core/_requests'

const PaymentProvidersSelect = ({withTitle = true, inputName = 'paymentProvider'}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<any>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<(IBasicSelect & Partial<ICourier>)[]>([])

  const filterProviders = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      paymentProvidersApi
        .getPaymentProviders('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.id,
              }
            })
            if (reformed) {
              setResults(reformed)
            }
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [couriersApi.fetchCouriers]
  )

  useEffect(() => {
    filterProviders()
  }, [filterProviders])

  return (
    <InputTemplate
      inputName={inputName}
      required={true}
      type='select'
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.PaymentProvidersSelect.LABEL'}) : ''}
      titleFontSize='text-black fs-6'
      objectErrorKey={`${inputName}.label`}
      reactSelectprops={{
        currentValue: values[inputName] || results[0],
        options: results,

        selectName: inputName,
        isLoading: resultsLoading,
        onInputChange: filterProviders,
        setFieldValue: setFieldValue,

        setObject: true,
        placeholder: intl.formatMessage({
          id: 'COMPONENTS.PaymentProvidersSelect.PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'COMPONENTS.PaymentProvidersSelect.NO_MESSAGE',
        }),
      }}
    />
  )
}

export default PaymentProvidersSelect
