import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {HeaderShipment} from './HeaderShipment'
import {HeaderInfo} from './HeaderInfo'
import {ImageLightbox} from '../../../images/ImageLightbox'
import {useState} from 'react'
import {HeaderNav} from './HeaderNav'
import {Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'
import {useLocation} from 'react-router-dom'
import {typeOfOrder} from '../EditingPage'

interface Props {
  item: Track | IOrder
}

const Header = ({item}: Props) => {
  const {pathname} = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-center justify-content-md-between overflow-hidden overflow-md-auto'>
          <div className='d-flex flex-row w-100'>
            <div className='me-7 mb-4 cursor-zoom-in'>
              <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl(typeOfOrder(pathname).icon)}
                  alt={typeOfOrder(pathname).query}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <HeaderInfo item={item} />
            </div>
          </div>

          <HeaderShipment item={item} />
        </div>
        <HeaderNav item={item} />
      </div>
    </div>
  )
}

export {Header}
