/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {IWebsitePage} from '../../core/_models'

type Props = {
  page: IWebsitePage
}

const WebsitePageActionsCell: FC<Props> = ({page}) => {
  const {websiteId} = useParams()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <Link
          to={`/websites/pages/list/${websiteId}/edit/overview/${page.website.enabledLocales[0]}/${page.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </div>
    </>
  )
}

export {WebsitePageActionsCell}
