import {useListView} from '../core/ListViewProvider'
import {CreateCategoryForm} from './CreateCategoryForm'

export const CreateCategoryFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  if (itemIdForUpdate === null) {
    return <CreateCategoryForm />
  }

  return null
}
