import {useFormikContext} from 'formik'
import {Dispatch, SetStateAction} from 'react'
import {IDeliveryTypeForm, TDeliveryTwoItem} from '../core/_models'
import CalculationType from '../modules/type-of-calculation/CalculationType'
import {CalculationTypeOne} from '../modules/type-of-calculation/CalculationTypeOne'
import {CalculationTypeTwo} from '../modules/type-of-calculation/CalculationTypeTwo'
import {TDelTwoFields} from '../_modals/CreateDeliveryTypeForm'

interface IProps {
  values: Partial<IDeliveryTypeForm>
  deliveryTwoFields: TDelTwoFields[]
  setDeliveryTwoFields: Dispatch<SetStateAction<TDelTwoFields[]>>
  withTitle?: boolean
}

const DelTypeCalculation = ({
  values,
  deliveryTwoFields,
  setDeliveryTwoFields,
  withTitle = true,
}: IProps) => {
  const {setFieldValue} = useFormikContext()
  const addNewField = (setFieldValue: (name: string, array: TDeliveryTwoItem[]) => void) => {
    setDeliveryTwoFields((prev) => [
      ...prev,
      {
        weight: deliveryTwoFields.length + 1,
        specificWeightPrice: 0,
      },
    ])
    setFieldValue('deliveryTwoItems', deliveryTwoFields)
  }

  const removeField = (index: number) => {
    let newDeliveryTwoFields: any[] = [...deliveryTwoFields]
    newDeliveryTwoFields.splice(index, 1)
    setDeliveryTwoFields(newDeliveryTwoFields)
  }

  const handleChange = (index: number, name: string, value: number) => {
    let newDeliveryTwoFields: any[] = [...deliveryTwoFields]

    newDeliveryTwoFields[index][name as keyof TDeliveryTwoItem] = value
    setDeliveryTwoFields(newDeliveryTwoFields)
  }

  return (
    <>
      <div className='fv-row mb-5'>
        {/* @ts-ignore */}
        <CalculationType values={values} withTitle={withTitle} />{' '}
      </div>

      {values.type && (
        <>
          {/* @ts-ignore */}
          {+values.type === 1 && <CalculationTypeOne values={values} />}
          {+values.type === 2 && (
            <CalculationTypeTwo
              removeField={removeField}
              setFieldValue={setFieldValue}
              addNewField={addNewField}
              handleChange={handleChange}
              deliveryTwoFields={deliveryTwoFields}
            />
          )}
        </>
      )}
    </>
  )
}

export default DelTypeCalculation
