import { useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {IWebsite, ITranslationObject} from '../../../../websites/core/_models'

interface Props {
  translation: ITranslationObject
  website: IWebsite | undefined
}

const TranslationHeaderLangSwitcher = ({translation, website}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  

  const availableLangs = translation ? translation.translations.map((i) => i.locale) : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <ul className='nav flex-nowrap'>
     
      {translation &&
        availableLangs.map((locale) => (
          <li className='nav-item' key={locale}>
            <Link
              to={`/translations/client/edit/${currTab}/${locale}/${translation.id}`}
              onClick={() => {
                setActiveTab(locale)
              }}
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                activeTab === locale ? 'active' : ''
              } fw-bold px-4`}
            >
              {locale}
            </Link>
          </li>
        ))}
    </ul>
  )
}

export default TranslationHeaderLangSwitcher
