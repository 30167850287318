import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {ShipmentTracksTab} from './tabs/tracks/ShipmentTracksTab'
import {ShipmentOverviewTab} from './tabs/overview/ShipmentOverviewTab'
import {ShipmentHeader} from './header/ShipmentHeader'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {getShipmentById} from '../core/_requests'
import {IShipment} from '../core/_models/_models'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const ShipmentPage = ({id}: Props) => {
  const intl = useIntl()

  const {data: shipmentById} = useQuery(`${QUERIES.SHIPMENT_BY_ID}-${id}`, () =>
    getShipmentById(id)
  )

  const queryClient = useQueryClient()

  const invalidateShipment = () => {
    queryClient.invalidateQueries(`${QUERIES.SHIPMENT_BY_ID}-${id}`)
  }

  const [currShipment, setCurrShipment] = useState<IShipment | undefined>(undefined)

  useEffect(() => {
    if (shipmentById) {
      setCurrShipment(shipmentById)
    }
  }, [shipmentById])

  return (
    <>
      {currShipment && shipmentById ? (
        <Routes>
          <Route
            element={
              <>
                <ShipmentHeader shipment={currShipment} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <ShipmentOverviewTab
                    shipment={currShipment}
                    setCurrShipment={setCurrShipment}
                    invalidateShipment={invalidateShipment}
                  />
                </>
              }
            />
            <Route
              path={`tracks/:id`}
              element={
                <>
                  <ShipmentTracksTab
                    shipment={currShipment}
                    setCurrShipment={setCurrShipment}
                    invalidateShipment={invalidateShipment}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ShipmentPage
