/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useState} from 'react'
import {Portal} from '../../../../../../_metronic/partials'
import {ProductCard} from './ProductCard'
import {FormattedMessage, useIntl} from 'react-intl'
import {CreateItemCard} from '../../../sections/CreateItemCard'
import {ITrackProd, Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'

interface Props {
  item: Track | IOrder
  setCurrItem: (obj: Track | IOrder) => void
}

export function ContentTab({item, setCurrItem}: Props) {
  const {products} = item
  const [modalOpen, setModalOpen] = useState(false)
  const intl = useIntl()

  return (
    <>
      {/* {modalOpen && (
        <Portal
          children={
            <CreateTrackContentCard
              item={item}
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              setCurrItem={setCurrItem}
            />
          }
          className='modal-root'
        />
      )} */}
      <div className='d-flex flex-wrap flex-stack mb-10 '>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.HEADING' />
        </h3>
      </div>
      <div className='row g-6 g-xl-9'>
        {products.map((product: ITrackProd, index: number) => (
          <ProductCard
            key={index}
            products={products}
            product={product}
            item={item}
            setCurrTrack={setCurrItem}
          />
        ))}{' '}
      </div>
    </>
  )
}
