import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ICreateShipment, IShipment, ShipmentsQueryResponse} from './_models/_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const SHIPMENTS_URL = `/shipments`
const BASE_URL = `${APP_URL}${API_URL}${SHIPMENTS_URL}`

const getShipments = (query: string): Promise<ShipmentsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ShipmentsQueryResponse>) => d.data)
}

const getShipmentById = (id: ID): Promise<IShipment | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IShipment>) => d.data)
}

const createShipment = (item: Partial<ICreateShipment>): Promise<IShipment | undefined> => {
  return axios
    .post(`${BASE_URL}`, item)
    .then((response: AxiosResponse<Response<IShipment>>) => response.data)
    .then((response: Response<IShipment>) => response.data)
}

const editShipment = (store: Partial<ICreateShipment>, id: ID): Promise<IShipment | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, store)
    .then((response: AxiosResponse<Response<IShipment>>) => response.data)
    .then((response: Response<IShipment>) => response.data)
}

export {getShipments, getShipmentById, createShipment, editShipment}
