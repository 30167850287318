import {Dispatch, SetStateAction} from 'react'
import {GroupBase} from 'react-select'
import Select from 'react-select/dist/declarations/src/Select'
import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ReceiveShipmentContextProps} from '../accept/core/_contextHelpers'
import {IReceivedItem} from '../accept/core/_models'
import {errors} from '../errors'
import {UnpackShipmentContextProps} from '../unpack/core/_contextHelpers'
import {IUnpackedItem} from '../unpack/core/_models'
import {IReceivingUser} from '../_models'
import {fulfillmentApi} from '../_requests'

interface Props {
  code: string
  currClient: IReceivingUser
  items: (IUnpackedItem | IReceivedItem)[]
  customUser: boolean
  setErrorMessage: Dispatch<SetStateAction<string>>
  setCurrClient: Dispatch<SetStateAction<IReceivingUser>>
  setFieldValue: ISetFieldValue
  initialContext: UnpackShipmentContextProps | ReceiveShipmentContextProps
  updateCurrClient: (id: string | number, creds: {firstName: string; lastName: string}) => void
  clientSelectRef: React.RefObject<Select<IBasicSelect, false, GroupBase<IBasicSelect>>>
}

export async function handleUser({
  code,
  currClient,
  items,
  customUser,
  setErrorMessage,
  setCurrClient,
  setFieldValue,
  initialContext,
  updateCurrClient,
  clientSelectRef,
}: Props) {
  let userId = code.slice(4, -3)
  if (userId.includes('-')) {
    userId = code.split('-')[0].slice(4, -3)
  }

  if (
    currClient.externalId !== '' &&
    currClient.externalId !== userId &&
    items.length > 0 &&
    items[0].type !== 3 &&
    customUser === false
  ) {
    // console.log(
    //   'currClient.externalId',
    //   currClient.externalId,
    //   'userId',
    //   userId,
    //   'items[0].type',
    //   items[0]
    // )

    setErrorMessage(errors.DIFFERENT_CLIENTS)
    return
  }

  const userCredentials = await fulfillmentApi
    .getUserByExternalId(userId)
    .catch((err) => console.log(err))
  if (!userCredentials && customUser === false) {
    setCurrClient(initialContext.currClient)
    setErrorMessage(errors.INDICATE_RECEIVER)
    clientSelectRef.current?.focus()
    return false
  } else if (customUser === true) {
    setErrorMessage('')

    let selectOption
    if (userCredentials) {
      selectOption = {
        value: userId,
        label: userCredentials.lastName + ' ' + userCredentials.firstName,
      }

      updateCurrClient(userId, userCredentials)
    } else {
      selectOption = {
        value: currClient.externalId,
        label:
          (currClient.lastName ? currClient.lastName + ' ' : '') +
          (currClient.firstName ? currClient.firstName : ''),
      }

      updateCurrClient(currClient.externalId, currClient)
    }

    setFieldValue('client', selectOption)
    return true
  }
}
