/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ICategory} from '../../core/_models'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

type Props = {
  category: ICategory
}

const CategoryNameCell: FC<Props> = ({category}) => {
  const getSynonyms = () => {
    if (category.synonyms.length > 3) {
      return `${category.synonyms.slice(0, 2).join(', ')}${'...'}`
    } else {
      return category.synonyms.join(', ')
    }
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={toAbsoluteUrl(
            category.logoUrl
              ? `${BASE_URL}${category.logoUrl}`
              : '/media/icons/duotune/ecommerce/ecm009.svg'
          )}
          alt=''
          style={{objectFit: 'contain'}}
        />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
          {category.deliveryFee ? `${category.name} (+${category.deliveryFee}%)` : category.name}
        </a>
        <span className='text-muted fw-semobold text-muted d-block fs-7'>{getSynonyms()}</span>
      </div>
    </div>
  )
}

export {CategoryNameCell}
