import {useEffect} from 'react'
import {useQueries, useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from './core/_requests'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCountries} from '../countries/countries-list/core/_requests'
import {DirectionsListWrapper} from './directions-list/DirectionsList'
import DirectionPage from './direction-page/DirectionPage'
import {generalApi} from '../../../_metronic/helpers/custom/api/generalApi'
import {paymentProvidersApi} from '../finances/payment-providers/core/_requests'

const DirectionsPage = () => {
  const location = useLocation()

  useQueries([
    {
      queryKey: [QUERIES.COUNTRIES_LIST],
      queryFn: () => getCountries(''),
    },
    {
      queryKey: [QUERIES.PAYMENT_PROVIDERS_LIST],
      queryFn: () => paymentProvidersApi.getPaymentProviders(''),
    },
    {
      queryKey: [QUERIES.COUNTRY_ABBR_LIST],
      queryFn: directionsApi.fetchDirectionCountriesList,
    },
    {
      queryKey: [QUERIES.CURRENCIES_LIST],
      queryFn: generalApi.getListOfCurrencies,
    },
    {queryKey: [QUERIES.WEIGHT_UNITS], queryFn: generalApi.getWeightUnits},
    {queryKey: [QUERIES.SIZE_UNITS], queryFn: generalApi.getSizeUnits},
  ])

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DirectionsListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <DirectionPage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/directions/list' />} />
    </Routes>
  )
}

export default DirectionsPage
