import {ID} from '../../../../../_metronic/helpers'
import {generalApi} from '../../../../../_metronic/helpers/custom/api/generalApi'
import {Direction} from '../../../directions/core/_models'

export function getCurrencySymbol(direction: Direction) {
  switch (direction?.serviceCurrency) {
    case 'UAH':
      return '₴'

    case 'USD':
      return '$'

    case 'EUR':
      return '€'

    case 'GBP':
      return '€'
    default:
      return '¤'
  }
}

export function getCurrencySymbolByCode(code: string) {
  return generalApi.getSymbolByCode(code)
}
