import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {ITranslationObject} from '../../../../core/_models'

interface Props {
  translation: ITranslationObject
}

export function TranslationHeaderNav({translation}: Props) {
  const {pathname} = useLocation()
  const {id, key, locale} = useParams()

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/websites/translation/${id}/edit/overview/${locale}/${key}` &&
                'active')
            }
            to={`/websites/translation/${id}/edit/overview/${locale}/${key}`}
          >
            Переклад
          </Link>
        </li>
      </ul>
    </div>
  )
}
