import {Formik, Form, FormikValues} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IEditStore, IEditStoreForm} from '../../../core/_models'
import {editStore} from '../../../core/_requests'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../../websites/core/_models'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  store: IEditStore
  invalidateStore: () => void
}

export function StoreMeta({store, invalidateStore}: Props) {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === store.website.id)

  const {locale = website?.mainLocale || 'uk'} = useParams()

  const initTranslations =
    store?.translations && store?.translations[locale]
      ? {...store?.translations[locale]}
      : {
          title: '',
          shortDescription: '',
          description: '',
          metaDescription: '',
          metaKeywords: '',
          metaTitle: '',
        }
  const initialValues: Partial<IEditStoreForm> = {
    ...initTranslations,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<IEditStoreForm>, actions: FormikValues) => {
    const {metaDescription, metaKeywords, metaTitle} = values
    setLoading(true)

    try {
      let translations = {
        ...store.translations,
        [locale]: {metaDescription, metaKeywords, metaTitle},
      }

      await editStore({translations}, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {store?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form' id='form-meta-store'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {['metaTitle', 'metaDescription', 'metaKeywords'].map((metaItem, index) => (
                  <div className='row align-items-center' key={index}>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>{metaItem} </label>

                    <div className='col-lg-8'>
                      <InputTemplate inputName={metaItem} required={false} type='textarea' />
                    </div>
                  </div>
                ))}{' '}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
