import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {PollActionsCell} from './cells/PollActionsCell'
import {PollNameCell} from './cells/PollNameCell'
import {PollStatusCell} from './cells/PollStatusCell'
import {IPoll} from '../../../core/_models'

const pollsColumns: ReadonlyArray<Column<IPoll>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <PollNameCell poll={props.data[props.row.index]} />,
  },
 
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_STATUS'}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <PollStatusCell poll={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <PollActionsCell poll={props.data[props.row.index]} />,
  },
]

export {pollsColumns}
