import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {NameCell} from './NameCell'
import {ActiveCell} from './ActiveCell'
import {DescriptionCell} from './DescriptionCell'
import {IStatus} from '../../core/_models'
import {EntityCell} from './EntityCell'

const statusesColumns: ReadonlyArray<Column<IStatus>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Назва' className='min-w-175px' />,
    id: 'name',
    Cell: ({...props}) => <NameCell status={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} className='min-w-50px' title='Відображення' />
    ),
    id: 'active',
    Cell: ({...props}) => <ActiveCell status={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} className='min-w-50px' title="Об'єкт" />,
    id: 'entity',
    Cell: ({...props}) => <EntityCell status={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Опис' className='min-w-175px' />,
    id: 'description',
    Cell: ({...props}) => <DescriptionCell status={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Действия' className='min-w-100px  text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell status={props.data[props.row.index]} />,
  },
]

export {statusesColumns}
