import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {IWebsitePage} from '../../../website-pages-list/core/_models'
import axios from 'axios'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {IWebsite} from '../../../../websites/core/_models'
import {QueryFunctionContext, useQuery, useQueryClient} from 'react-query'
import PageOverview from './PageOverview'

interface Props {
  page: IWebsitePage
  invalidatePage: () => void
  website: IWebsite
}

const fetchMetaKeys = async ({
  queryKey,
}: QueryFunctionContext<[string, string | undefined, string]>) => {
  const [_, websiteId, pageName] = queryKey
  const res = await axios.get(
    `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}/api/website_translation_keys?website=${websiteId}&websitePages.name=${pageName}&filter[keySearch]=breadcrumbs-key`
  )
  return res.data.data
}

const PageOverviewWrapper = ({page, invalidatePage, website}: Props) => {
  const {websiteId} = useParams()
  const queryClient = useQueryClient()
  const {data: metaKeys, isLoading} = useQuery(['metaKeys', websiteId, page.name], fetchMetaKeys)

  const invalidateMetaKeys = () => {
    queryClient.invalidateQueries(['metaKeys', websiteId, page.name])
  }

  useEffect(() => {
    invalidateMetaKeys()
  }, [websiteId, page.name])

  return (
    <>
      {metaKeys || !isLoading ? (
        <PageOverview
          page={page}
          invalidateData={() => {
            invalidatePage()
            invalidateMetaKeys()
          }}
          metaKeys={metaKeys}
          website={website}
        />
      ) : (
        <PartialPageLoader />
      )}
    </>
  )
}

export default PageOverviewWrapper
