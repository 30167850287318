import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {ChatsTable} from './table/ChatsTable'
import {ChatsListHeader} from './components/header/ChatsListHeader'
import {FormattedMessage} from 'react-intl'
import {ChatsListFilter} from './components/header/filter/ChatListFilter'

const ChatsList = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.CHATS_LIST)

    let state = {
      ...initialQueryState,
      filter: {
        ...savedFilter,
      },
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.CHATS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage id='CHATS.LIST.HEADING' />
        </h3>
        <div className='d-flex flex-gutter'>
          {/* <button type='button' className='btn btn-sm btn-primary' onClick={openAddModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            <FormattedMessage id='WEBSITE_CATEGORIES.LIST.HEADING.LIST.ADD_BUTTON' />
          </button> */}
        </div>
      </div>
      <KTCard>
        <ChatsListHeader />
        <ChatsTable />
      </KTCard>
    </>
  )
}

const ChatsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ChatsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ChatsListWrapper}
