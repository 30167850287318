import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {selectDebounce} from '../../../../../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import AsyncSelect from 'react-select/async'
import {customStyles} from '../../../../../../../../modules/custom/form-elements/ReactSelectTemplate'
import {components} from 'react-select'
import {paymentProvidersApi} from '../../../../../../payment-providers/core/_requests'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const ProvidersFilter = ({savedFilter, setSavedFilter}: TProps) => {
  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterProviders = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      paymentProvidersApi
        .getPaymentProviders('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [paymentProvidersApi.getPaymentProviders]
  )

  useEffect(() => {
    filterProviders()
  }, [])

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Не знайдено</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(results)
      return
    }

    filterProviders('')
  }

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    setSavedFilter((prev) => {
      return {...prev, paymentProvider: selectedValue}
    })
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column
      
       mb-4`}
    >
      <label
        className={`position-relative flex-column fw-bold mb-2 required`}
        htmlFor={'paymentProvider'}
      >
        Система оплати:
      </label>
      <AsyncSelect
        styles={customStyles}
        className='form-control form-control-lg form-control-solid p-0 fw-normal'
        loadOptions={loadOptions}
        defaultOptions={results}
        form={'paymentProvider'}
        // closeMenuOnSelect={false}
        isDisabled={false}
        components={{NoOptionsMessage}}
        loadingMessage={() => 'Зачекайте...'}
        placeholder={'Система оплати'}
        isClearable={true}
        isLoading={resultsLoading}
        value={savedFilter?.paymentProvider?.value ? savedFilter?.paymentProvider : null}
        onChange={handleOnChange}
        onInputChange={(e) => {
          filterProviders(e)
        }}
      />
    </div>
  )
}

export default ProvidersFilter
