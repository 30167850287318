import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {FlagsDirectionBar} from '../../../../modules/custom/FlagsDirectionBar'
import {Direction} from '../../core/_models'

interface Props {
  direction: Direction
}

export function DirectionHeaderName({direction}: Props) {
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <FlagsDirectionBar chosenDirections={[{id: direction.id}]} minified={true} />
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {directionsFuncs.formDirection(
                  direction.countryFrom,
                  direction.countryTo,
                  countries
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
