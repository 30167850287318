import {IEditArticle} from '../../core/_models'

interface Props {
  article: IEditArticle
}

export function ArticleHeaderName({article}: Props) {
  return (
    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
      <div className='d-flex flex-column'>
        <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
          <div className='d-flex align-items-center mb-2 mb-md-none'>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
              {article.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
