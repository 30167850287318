import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UserGroupsListFilter} from './UserGroupsListFilter'

const UserGroupsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddFormModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-form-table-toolbar='base'>
      <UserGroupsListFilter />

      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}

      {/* <button type='button' className='btn btn-primary' onClick={openAddFormModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Добавить клиента{' '}
      </button> */}
    </div>
  )
}

export {UserGroupsListToolbar}
