import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {StoresQueryResponse, IStore, ICreateStore, IEditStore} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const STORES_URL = `/website_shops`
const BASE_URL = `${API_URL}${STORES_URL}`

const getStores = (query: string): Promise<StoresQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<StoresQueryResponse>) => {
      return d.data
    })
}

const getStoreById = async (id: ID): Promise<IStore | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const getStoreByProductUrl = async (url: string): Promise<IStore | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/find_by_product_url?productUrl=${url}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const addStore = (store: ICreateStore): Promise<IStore | undefined> => {
  return axios
    .post(`${BASE_URL}`, store)
    .then((response: AxiosResponse<Response<IStore>>) => response.data)
    .then((response: Response<IStore>) => response.data)
}

const editStore = (store: Partial<IEditStore>, id: ID): Promise<IStore | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, store)
    .then((response: AxiosResponse<Response<IStore>>) => response.data)
    .then((response: Response<IStore>) => response.data)
}

const uploadImage = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const deleteStore = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {
  getStores,
  getStoreById,
  getStoreByProductUrl,
  uploadImage,
  addStore,
  editStore,
  deleteStore,
}
