import {ErrorMessage, Field} from 'formik'
import React from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../_metronic/helpers/custom/funcs/directions'
import {DirectionsQueryResponse} from '../../directions/core/_models'

const Direction = ({withTitle=true}) => {
  const queryClient = useQueryClient()

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}

  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  return (
    <>
      <div className='fv-row mb-10'>
       { withTitle&&<label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span className='required'> Напрямки</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Оберіть напрямок(ки)'
          ></i>
        </label>}
        <Field as='select' name='direction' className='form-select form-select-solid '>
          <option></option>

          {directions?.data?.map(
            ({id, countryTo, countryFrom}) =>
              id && (
                <option value={id} key={id}>
                  {directionsFuncs.formDirection(countryFrom, countryTo, countriesList)}
                </option>
              )
          )}
        </Field>
      </div>
      <div className='text-danger'>
        <ErrorMessage name='direction' />
      </div>
    </>
  )
}

export default Direction
