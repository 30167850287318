import {ICreateNewDeliveryType} from '../tsHelpers/deliveryTypesHelpers'
import axios from 'axios'
import {ID} from '../../crud-helper/models'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH

const fetchDeliveryTypes = async () => {
  const {data} = await axios.get<any[]>(`${API_URL}/delivery_types`)
  const reformedData = data.map((type) => {
    type.direction = +type.direction.id
    type.productCategories = type.productCategories.map(({id}: Record<string, number>) => id)
    return type
  })

  return reformedData
}

const addDeliveryType = async (data: ICreateNewDeliveryType) => {
  const {data: response} = await axios.post('/api/delivery_types', data)

  return response
}
const changeStatus = async (id: ID, newStatus: boolean) => {
  const response = await axios.post<any>('/api/delivery_types', {id, enabled: newStatus})
  return response
}

const deleteDeliveryType = async (id: ID) => {
  const response = await axios.delete(`/api/delivery_types/${id}`)
  return response
}

const editDeliveryType = async (data: any) => {
  const {data: response} = await axios.post<any>(`/api/delivery_types`, data)

  return response
}

const uploadLogo = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post('/api/delivery_types/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const fetchVolumetricWeightOptions = async () => {
  const {data} = await axios.get<any>('/api/delivery_type/volumetric_weight_types')

  return data
}

export const deliveryTypesApi = {
  fetchDeliveryTypes,
  uploadLogo,
  addDeliveryType,
  changeStatus,
  deleteDeliveryType,
  editDeliveryType,
  fetchVolumetricWeightOptions,
}
