import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useEffect} from 'react'
import {SettingsOverview} from './tabs/overview/SettingsOverview'
import {SettingsHeader} from './header/SettingsHeader'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import {getCompanyDetails} from '../core/_requests'
import {QUERIES} from '../../../../_metronic/helpers'
import {useQuery, useQueryClient} from 'react-query'

const EditingPage = () => {
  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  const {data: company} = useQuery(QUERIES.COMPANY_DETAILS, () => getCompanyDetails())

  const queryClient = useQueryClient()

  const invalidateCompany = () => {
    queryClient.invalidateQueries(QUERIES.COMPANY_DETAILS)
  }

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <SettingsHeader company={company} />
              <Outlet />
            </>
          }
        >
          <Route
            path={`overview/:locale`}
            element={<SettingsOverview company={company} invalidateCompany={invalidateCompany} />}
          />

          <Route index element={<Navigate to={`overview/uk`} />} />
        </Route>
      </Routes>
    </>
  )
}

export default EditingPage
