import {FormattedMessage} from 'react-intl'
import {ICourier} from '../../core/_models'

interface Props {
  courier: ICourier
}

export function CourierHeaderInfo({courier}: Props) {
  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex flex-column fw-bold fs-6 mb-4 pe-2'>
        <div className='btn btn-sm btn-light-primary fw-bold px-4'>
          <p className='fw-semobold text-black d-block fs-6 mb-0'>
            <FormattedMessage
              id={
                courier.type === 'custom'
                  ? 'COURIERS.ADD_MODAL.FORM.OWNERSHIP.COMPANY_L'
                  : `COURIERS.EXISTING_SERVICES.${courier.type.toUpperCase()}`
              }
            />{' '}
          </p>
        </div>
      </div>
      <div className='card-toolbar mb-2'></div>
    </div>
  )
}
