import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/l10n/uk.js'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css'
import {Dispatch, SetStateAction} from 'react'

type TProps = {
  dateState: any
  setDateState: Dispatch<SetStateAction<any>>
}

const AgreementDate = ({dateState, setDateState}: TProps) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-end'>
      <label className='fw-bold fs-6 mb-1'>Дата останнього оновлення угоди користувача:</label>

      <div className='w-50'>
        <Flatpickr
          value={dateState}
          onChange={([date]) => {
            setDateState(date)
          }}
          className='form-control'
          placeholder='Оберіть дату'
          options={{
            locale: 'uk',
          }}
        />
      </div>
    </div>
  )
}

export default AgreementDate
