import {useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ImageLightbox} from '../../../../../modules/custom/images/ImageLightbox'
import {useReceiveShipmentContext} from '../core/ReceiveShipmentContext'
import {IReceivedItem} from '../core/_models'
import {ActionButton} from './printing/ActionButton'
import {GoodsSticker} from './printing/GoodsSticker'
import {getFulfPrintMode} from './printing/helpers/printModeLS'
interface Props {
  shipment: IReceivedItem
  cellNumber: string | null
  deleteItem: (id: number | string) => void
}
export function ReceivedItemsTableRow({shipment, cellNumber, deleteItem}: Props) {
  const {setItemIdForUpdate} = useReceiveShipmentContext()
  const {code, client, weight, length, height, width, photos, cell, type, addedToCluster} = shipment

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const componentToPrint = useRef(null)

  return (
    <>
      {type === 3 ? (
        <tr>
          <th colSpan={4} className='fw-bold text-dark'>
            Комірка {cell}
          </th>
        </tr>
      ) : (
        <>
          <tr>
            <td>
              {' '}
              <div className='d-flex justify-content-start'>
                <div className='symbol symbol-45px me-5'>
                  {photos.length > 0 ? (
                    <div className='cursor-zoom-in'>
                      <div className='symbol symbol-fixed position-relative'>
                        <img
                          src={
                            photos[0] === '/media/icons/duotune/general/gen017.svg'
                              ? photos[0]
                              : `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + photos[0]
                          }
                          width='45'
                          height='45'
                          alt='track'
                          onClick={openLightbox}
                          style={{objectFit: 'contain'}}
                        />

                        <ImageLightbox
                          viewerIsOpen={viewerIsOpen}
                          closeLightbox={closeLightbox}
                          currentImage={currentImage}
                          images={photos.map((item, index) => {
                            return {
                              src: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + item,
                              title: `${code}-${index + 1}`,
                            }
                          })}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      src={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')}
                      alt='fulf-parcel'
                    />
                  )}
                </div>
                <div className='text-dark fw-bold fs-6'>{code}</div>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column'>
                <span className='text-dark fw-bold fs-6'>{client.externalId} </span>
                <span className='text-muted fw-normal fs-6'>
                  {client.firstName} {client.lastName}{' '}
                </span>
              </div>
            </td>
            <td>
              <div className='d-flex flex-column'>
                <span className='text-muted fw-normal fs-6'>{cell || ''} </span>
              </div>
            </td>

            <td colSpan={!addedToCluster ? 1 : 2}>
              {weight && length && height && width ? (
                <div className='d-flex flex-column'>
                  <span className='text-dark fw-bold fs-6'>{weight} кг</span>
                  <span className='text-muted fw-normal fs-6'>
                    {width + ' см' + ' x ' + height + ' см' + ' x ' + length + ' см'}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </td>
            {!addedToCluster && (
              <td>
                <div className='card-toolbar d-flex flex-gutter'>
                  <button
                    className='btn btn-sm btn-icon btn-active-color-primary '
                    type='button'
                    onClick={() => setItemIdForUpdate(code)}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1' />
                  </button>

                  <button
                    className='btn btn-sm btn-icon btn-active-color-primary '
                    type='button'
                    onClick={() => deleteItem(code)}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </button>
                </div>
              </td>
            )}
          </tr>
          {getFulfPrintMode() === '1' && type === 2 && (
            <>
              <div className='print-hidden'>
                <GoodsSticker
                  client={client.externalId}
                  goodsNumber={code}
                  componentToPrint={componentToPrint}
                />
              </div>
              <ActionButton componentToPrint={componentToPrint} id={code} />
            </>
          )}
        </>
      )}
    </>
  )
}
