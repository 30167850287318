import {FieldArrayRenderProps} from 'formik'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

const AddAnswerButton = ({arrayHelpers}: {arrayHelpers: FieldArrayRenderProps}) => {
  return (
    <button
      className='btn btn-icon d-flex align-items-center color-primary'
      type='button'
      onClick={() => {
        const newAnswerOption = {
          answer: '',
          allowDelete: true,
        }

        arrayHelpers.unshift(newAnswerOption)
      }}
    >
      <KTSVG
        path='/media/icons/duotune/general/gen041.svg'
        className='svg-icon-1 svg-icon-success d-block m-0 mx-2'
      />
    </button>
  )
}

export default AddAnswerButton
