/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../_metronic/helpers/custom/funcs/directions'
import {FlagsDirectionBar} from '../../../../../modules/custom/FlagsDirectionBar'
import {IOrderHistory} from '../../../../orders/core/_models/_orders-models'
import {getUserById} from '../../../../users/core/_requests'

interface Props {
  log: IOrderHistory
}

function BuyoutLogsItem({log}: Props) {
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const processValue = (key: string, value: any) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value
    } else if (typeof value === 'boolean' || value === null || value === undefined) {
      return value ? (
        <KTSVG
          path='/media/icons/duotune/general/gen043.svg'
          className='svg-icon-3 svg-icon-success'
        />
      ) : (
        <KTSVG
          path='/media/icons/duotune/general/gen040.svg'
          className='svg-icon-3 svg-icon-danger svg-bg-white'
        />
      )
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        return (
          <KTSVG
            path='/media/icons/duotune/general/gen040.svg'
            className='svg-icon-3 svg-icon-danger svg-bg-white'
          />
        )
      } else if (key === 'products') {
        return (
          <div className='d-flex flex-column'>
            {value.map((prod, index) => (
              <a
                className='text-dark text-hover-primary'
                href={prod.url}
                target='_blank'
                rel='noopener noreferrer'
                key={index}
              >
                <p className='m-0'>
                  <span className='fw-bold'>Категорія</span>: {prod.title}
                </p>
                <p className='m-0'>
                  <span className='fw-bold'>Артикул</span>: {prod.sku || '-'}
                </p>
                <p className='m-0'>
                  <span className='fw-bold'>Розмір</span>: {prod.size || '-'}
                </p>
                <p className='m-0'>
                  <span className='fw-bold'>Колір</span>: {prod.color || '-'}
                </p>
                <p className='m-0'>
                  <span className='fw-bold'>Вартість</span>: {prod.priceForOne}
                </p>
                <p className='m-0'>
                  <span className='fw-bold'>Кількість</span>: {prod.quantity}
                </p>
              </a>
            ))}
          </div>
        )
      }
    } else if (key === 'user') {
      return (
        <a className='text-dark text-hover-primary' href={`/users/edit/overview/${value.id}`}>
          {value.lastName + ' ' + value.firstName}
        </a>
      )
    } else if (key === 'websiteShop') {
      return (
        <a className='text-dark text-hover-primary' href={`/stores/edit/overview/uk/${value.id}`}>
          {value.name}
        </a>
      )
    } else if ((key = 'direction')) {
      return (
        <a className='text-dark text-hover-primary' href={`/directions/edit/overview/${value.id}`}>
          <div className='d-flex align-items-center'>
            {/* <FlagsDirectionBar chosenDirections={[{id: +value?.id}]} minified={true} /> */}
            <p className='text-gray-800 text-hover-primary fw-normal m-0'>
              {directionsFuncs.formDirection(value?.countryFrom, value?.countryTo, countries)}
            </p>
          </div>
        </a>
      )
    } else if (key === 'status') {
      return 'Статус'
    }
    return 'unknown'
  }
  return (
    <tr>
      <td className='mw-100px'>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={log.date} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime value={log.date} hour='numeric' minute='numeric' second='numeric' />
          </span>
        </div>
      </td>

      <td>
        {Object.entries(log.data)
          .filter(
            ([k, _]) =>
              k !== 'type' && k !== 'trackingNumberIds' && k !== 'weight' && k !== 'location'
          )
          .map(([k, v]) => (
            <p key={k} className='d-flex align-items-center'>
              {' '}
              <span className='col fw-bold me-2'>
                <FormattedMessage id={`BUYOUT_PAGE.LOGS_TAB.${k.toUpperCase()}`} />:
              </span>{' '}
              <span className='col-8 text-dark d-block fs-6'>{processValue(k, v)}</span>
            </p>
          ))}
      </td>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{log.user.name}</span>
      </td>
    </tr>
  )
}

export {BuyoutLogsItem}
