import {FC, useState, createContext, useContext, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../_metronic/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const {type} = useParams()

  // const getFilters = (param: string | undefined) => {
  //   switch (param) {
  //     case 'sea':
  //       return {archive: 0, type_delive: 1}
  //     case 'air':
  //       return {archive: 0, type_delive: 0}
  //     case 'archive':
  //       return {archive: 1}

  //     default:
  //       return {archive: 0, type_delive: 0}
  //   }
  // }

  // const filter = getFilters(type)
  const [state, setState] = useState<QueryState>({
    ...initialQueryRequest.state,
    // filter,
  })

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState

    setState(updatedState)
  }

  useEffect(() => {
    updateState({
      ...state,
      // , filter: getFilters(type)
    })
  }, [type])

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
