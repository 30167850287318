import {FC} from 'react'
import {IWarehouse} from '../../../../core/_models'

type Props = {
  warehouse: IWarehouse
}

const WarehouseAddressCell: FC<Props> = ({warehouse}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>{warehouse.addressData.join(', ')}</p>
    </div>
  )
}

export {WarehouseAddressCell}
