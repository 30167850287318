import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {PaymentProviderActionsCell} from './cells/PaymentProviderActionsCell'
import {PaymentProviderNameCell} from './cells/PaymentProviderNameCell'
import {IPaymentProvider} from '../../../core/_models'
import {PaymentProviderTypeCell} from './cells/PaymentProviderTypeCell'
import {PaymentProviderDirectionCell} from './cells/PaymentProviderDirectionCell'

const paymentProvidersColumns: ReadonlyArray<Column<IPaymentProvider>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <PaymentProviderNameCell paymentProvider={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PAYMENT_PROVIDERS_COLUMNS_DIRECTION'} />
    ),
    id: 'direction',
    Cell: ({...props}) => (
      <PaymentProviderDirectionCell paymentProvider={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title={'PAYMENT_PROVIDERS_COLUMNS_TYPE'} />,
    id: 'type',
    Cell: ({...props}) => <PaymentProviderTypeCell paymentProvider={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <PaymentProviderActionsCell paymentProvider={props.data[props.row.index]} />
    ),
  },
]

export {paymentProvidersColumns}
