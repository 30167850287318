import React, {useEffect} from 'react'
import {useQueries, useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../directions/core/_requests'

const TracksAdd = () => {
  const navigate = useNavigate()
  const [countryListAbbrs, countries] = useQueries([
    {
      queryKey: [QUERIES.COUNTRY_ABBR_LIST],
      queryFn: directionsApi.fetchDirectionCountriesList,
    },
    {queryKey: [QUERIES.COUNTRIES_LIST], queryFn: directionsApi.fetchDirectionCountriesList},
  ])
  useEffect(() => {
    if (countryListAbbrs?.data && countries?.data) {
      navigate('/tracks/list', {
        state: {
          create: true,
        },
      })
    }
  }, [countryListAbbrs?.data, countries?.data])

  return <></>
}

export default TracksAdd
