/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {categoriesLocales, ICategory} from '../../core/_models'
import {deleteCategory} from '../../core/_requests'

type Props = {
  category: ICategory
}

const CategoryActionsCell: FC<Props> = ({category}) => {
  const intl = useIntl()

  const {refetch} = useQueryResponse()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteCategory(category.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
        >
          <Link
            to={`/lists/categories/edit/overview/${categoriesLocales[0]}/${category.id}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </OverlayTrigger>
        {category.allowDelete === true && (
          <>
            {deleteModalOpen && (
              <Portal
                children={
                  <ActionConfirmation
                    actionName={intl.formatMessage({
                      id: 'WEBSITE_CATEGORIES.LIST.ACTIONS.TRASH_CONF',
                    })}
                    setModalOpen={setDeleteModalOpen}
                    handleAction={() => deleteMutation.mutate()}
                    id={category.id}
                    isOpen={deleteModalOpen}
                  />
                }
                className='modal-root'
              />
            )}
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
            >
              <a
                href='#'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => setDeleteModalOpen(true)}
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </a>
            </OverlayTrigger>
          </>
        )}
      </div>
    </>
  )
}

export {CategoryActionsCell}
