import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {IPaymentProvider, IPaymentProviderForm, PaymentProvidersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const ENDPOINT = `/payment_providers`
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${ENDPOINT}`

const getPaymentProviders = (query: string): Promise<PaymentProvidersQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<PaymentProvidersQueryResponse>) => d.data)
}

const getProviderById = (id: ID): Promise<IPaymentProvider | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IPaymentProvider>) => d.data)
}

const getProviderTypes = () => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/types`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<string[]>) => d.data)
}

const addPaymentProvider = async (data: IPaymentProviderForm) => {
  const {data: response} = await axios.post(`${BASE_URL}`, data)

  return response
}

const editPaymentProvider = async (id: ID, data: any) => {
  const {data: response} = await axios.put<any>(`${BASE_URL}/${id}`, data)

  return response
}

export const paymentProvidersApi = {
  addPaymentProvider,
  editPaymentProvider,
  getProviderTypes,
  getProviderById,
  getPaymentProviders,
}
