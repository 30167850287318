/* eslint-disable jsx-a11y/anchor-is-valid */
import stepsData from '../../../data/websites/websiteSteps.json'
import {useQueries} from 'react-query'
import {directionsApi} from '../directions/core/_requests'
import {websitesApi} from './core/_requests'
import {WebsitesItem} from './WebsitesItem'
import {useEffect} from 'react'
import {useListView} from './core/ListViewProvider'
import {QUERIES} from '../../../_metronic/helpers'
import PartialPageLoader from '../../../_metronic/layout/core/PartialPageLoader'

const Websites = () => {
  const {setWebsites} = useListView()

  const [{data: websitesData, error, isError, isLoading}, existingDirections] = useQueries([
    {queryKey: [QUERIES.WEBSITES_LIST], queryFn: websitesApi.fetchWebsites},

    {queryKey: [QUERIES.COUNTRY_ABBR_LIST], queryFn: directionsApi.fetchDirectionCountriesList},
    {queryKey: [QUERIES.LOCALES_LIST], queryFn: websitesApi.fetchWebsiteLanguagesList},
  ])

  useEffect(() => {
    setWebsites(websitesData) // eslint-disable-next-line
  }, [websitesData])

  if (isLoading) {
    return (
      <div>
        <PartialPageLoader fullHeight={true} />
      </div>
    )
  }
  if (isError) {
    console.log('reactQuery websites error', error)

    return <div>Error! </div>
  }

  return (
    <>
      {' '}
      <h3 className='mb-8'>Сайты</h3>
      {existingDirections?.data ? (
        <div className='row g-5 g-xl-8'>
          {websitesData?.map((item) => (
            <WebsitesItem
              key={item.id}
              dataForEditing={item}
              steps={stepsData}
              status={item.enabled}
            />
          ))}
        </div>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export {Websites}
