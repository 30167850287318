import {ErrorMessage, FieldArray} from 'formik'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {IPollForm} from '../core/_models'
import QuestionAnswerItem from './QuestionAnswerItem'

const QuestionsAnswers = ({
  values,
  editingFormat = false,
}: {
  values: Partial<IPollForm>
  editingFormat?: boolean
}) => {
  return (
    <FieldArray
      name='questions'
      render={(arrayHelpers) =>
        editingFormat ? (
          values.questions?.map((field, index) => (
            <QuestionAnswerItem
              index={index}
              key={index}
              field={field}
              values={values}
              questionArrayHelpers={arrayHelpers}
              editingFormat={editingFormat}
            />
          ))
        ) : (
          <div className='d-flex flex-column flex-col-gutter'>
            {values.questions?.map((field, index) => (
              <QuestionAnswerItem
                index={index}
                key={index}
                field={field}
                values={values}
                questionArrayHelpers={arrayHelpers}
                editingFormat={editingFormat}
              />
            ))}
          </div>
        )
      }
    />
  )
}

export default QuestionsAnswers
