import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CategoriesQueryResponse, ICategory, ICategoryForm} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const BASE_URL = `${API_URL}/product_categories`

const getCategories = (query: string): Promise<CategoriesQueryResponse> => {
  return axios.get(`${BASE_URL}?${query}`).then((d: AxiosResponse<CategoriesQueryResponse>) => {
    const transformed = {...d.data, data: d.data.data?.reverse()}
    return transformed
  })
}

const addLogo = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const getCategoryById = (id: ID): Promise<ICategory | undefined> => {
  return axios.get(`${BASE_URL}/${id}`).then((response: AxiosResponse<ICategory>) => response.data)
}

const createCategory = (category: Partial<ICategoryForm>): Promise<ICategory | undefined> => {
  return axios
    .post(BASE_URL, category)
    .then((response: AxiosResponse<Response<ICategory>>) => response.data)
    .then((response: Response<ICategory>) => response.data)
}

const updateCategory = (category: Partial<ICategory>): Promise<ICategory | undefined> => {
  return axios
    .put(`${BASE_URL}/${category.id}`, category)
    .then((response: AxiosResponse<Response<ICategory>>) => response.data)
    .then((response: Response<ICategory>) => response.data)
}

const deleteCategory = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {getCategories, addLogo, createCategory, getCategoryById, updateCategory, deleteCategory}
