import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {transformDirectionsForForm} from '../../../modules/custom/track-or-order/_modals/OnePageFormWrapper'
import {Direction, DirectionsQueryResponse} from '../../directions/core/_models'
import {useListView} from '../core/ListViewProvider'
import {AcceptTrackForm} from './AcceptTrackForm'

export const AcceptTrackFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const queryClient = useQueryClient()
  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}

  const countries: Record<string, string> =
    queryClient.getQueryData(QUERIES.COUNTRY_ABBR_LIST) || {}

  if (itemIdForUpdate === null && directions?.data && countries) {
    return <AcceptTrackForm directions={transformDirectionsForForm(directions?.data, countries)} />
  }

  return null
}
