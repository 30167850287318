import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ICompanyDetails} from '../../core/_models'

interface Props {
  company: ICompanyDetails | undefined
}

export function SettingsHeaderInfo({company}: Props) {
  const intl = useIntl()

  return (
    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
      {company?.telegramBotName && (
        <a
          className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'
          target={'_blank'}
          href={`https://t.me/${company?.telegramBotName}`}
          rel='noreferrer noopener'
        >
          <KTSVG path='/media/svg/brand-logos/telegram-2.svg' className='svg-icon-4 me-2' />

          {company?.telegramBotName}
        </a>
      )}
      {/* <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 me-5 mb-2'>
          <span className='me-1'>
            {' '}
            <span> $</span>
          </span>{' '}
          <span style={{color: balance ? (+balance >= 0 ? 'green' : 'red') : ''}}> {balance}</span>{' '}
        </p>
        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 mb-2'>
          <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-4 me-1' />
          Київ, Самойловича, 7б, 73
        </p> */}
    </div>
  )
}
