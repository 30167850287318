import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'

const createCourierSchema = Yup.object({
  // similar
  deliveryMethods: Yup.array().min(1, 'Оберіть, як мінімум, один тип доставки').required(),
  countryConfig: object_shape_basic_select,
  // own service
  name: Yup.string().when('ownership', {
    is: 0 || '0',
    then: Yup.string().required('Вкажіть назву служби'),
  }),
  // existing service
  type: Yup.string().when('ownership', {
    is: 1 || '1',
    then: Yup.string().required('Оберіть службу доставки'),
  }),
})

export {createCourierSchema}
