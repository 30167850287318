import {useEffect} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import {PaymentProvidersListWrapper} from './list/PaymentProvidersList'
import ProviderPage from './provider-page/ProviderPage'

const PaymentProvidersPage = () => {
  const location = useLocation()
  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<PaymentProvidersListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <ProviderPage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/finances/payment-providers/list' />} />
    </Routes>
  )
}

export default PaymentProvidersPage
