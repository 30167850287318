import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {ICourier} from '../../../../core/_models'

type Props = {
  courier: ICourier
}

const CourierDeliveryMethodsCell: FC<Props> = ({courier}) => {
  return (
    <div>
      {courier.deliveryMethods.map((i) => (
        <p className='fw-semobold text-black d-block fs-6 mb-0' key={i.id}>
          {i.name}
        </p>
      ))}
    </div>
  )
}

export {CourierDeliveryMethodsCell}
