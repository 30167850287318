/* eslint-disable jsx-a11y/anchor-is-valid */
import {QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {PollHeaderName} from './PollHeaderName'
import {PollHeaderInfo} from './PollHeaderInfo'
import {PollHeaderNav} from './PollHeaderNav'
import {IPoll} from '../../core/_models'
import PollHeaderLangSwitcher from './PollHeaderLangSwitcher'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../websites/core/_models'

interface Props {
  poll: IPoll
  website: IWebsite | undefined
}

const PollHeader = ({poll, website}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/files/fil008.svg')}
                  alt='Delivery type'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <PollHeaderName poll={poll} />{' '}
            </div>
          </div>
          <PollHeaderLangSwitcher poll={poll} website={website} />
        </div>

        <PollHeaderNav poll={poll} />
      </div>
    </div>
  )
}

export {PollHeader}
