import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LocationsListWrapper} from './locations-list/LocationsList'
import LocationPage from './location-page/LocationPage'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {useEffect} from 'react'

const LocationsPage = () => {
  const location = useLocation()

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<LocationsListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <LocationPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/locations/list' />} />
    </Routes>
  )
}

export default LocationsPage
