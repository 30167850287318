import {FC, useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {usePageData} from '../../../../../../../_metronic/layout/core'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useListView} from '../../../../core/ListViewProvider'
import {IOrder, IOrderProps} from '../../../../core/_models/_orders-models'
import WebsiteShop from '../modules/WebsiteShop'

const OrderNumberCell = ({order}: IOrderProps) => {
  const {number} = order
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleClick = async () => {
    if (itemIdForUpdate === order.id) {
      setItemIdForUpdate(undefined)
    } else {
      await setItemIdForUpdate(undefined)
      setItemIdForUpdate(order.id)
    }
  }

  const getFalseFields = (entries: Record<string, boolean>) => {
    return Object.entries(entries).filter(([key, value]) => value === false)
  }

  // const falseEntries = getFalseFields(track.field_state)

  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  // const missingTooltip = (
  //   <div className='fs-7 fw-normal' id='tooltip'>
  //     <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
  //     <ul className='m-0'>
  //       {falseEntries.map(([key, _]) => (
  //         <li key={key}>
  //           <FormattedMessage id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`} />
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // )

  const codeValueRef = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <div className='d-flex align-items-center'>
          <div className='d-flex m-2 mb-1 cursor-pointer h-25px' onClick={handleClick}>
            <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
              {number}
            </span>{' '}
          </div>
          <div
            ref={target}
            onClick={() => {
              copyToClipboard(codeValueRef, setShow)
              setTimeout(() => {
                setShow(false)
              }, 1000)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className={`svg-icon-3 ${
                show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
              }`}
            />
          </div>{' '}
          <Overlay target={target.current} show={show} placement='right'>
            {(props) => (
              <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
            )}
          </Overlay>
        </div>
        <div className='d-flex align-items-center'>
          {' '}
          {order.direction?.id && (
            <FlagsDirectionBar chosenDirections={[{id: order.direction.id}]} minified={true} />
          )}
          {order?.cell && (
            <span className={`${!order.direction ? 'mt-2' : ''}`}>{order.cell.number}</span>
          )}
          {order.websiteShop && order.type === 'buyout' && <WebsiteShop order={order} />}
        </div>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={order.createdAt} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime
              value={order.createdAt}
              hour='numeric'
              minute='numeric'
              second='numeric'
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export {OrderNumberCell}
