import {FormattedMessage} from 'react-intl'
import {AsideMenuItem} from '../AsideMenuItem'

const LogisticsMenu = () => {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-gray-300 fw-bold text-uppercase fs-8 ls-1'>
            <FormattedMessage id='GENERAL.ASIDE_MENU.LOGISTICS_SECTION' />
          </span>
        </div>
      </div>

      {menuItems.map((item, index) => (
        <AsideMenuItem
          key={index}
          to={item.to}
          icon={item.icon}
          title={item.title}
          fontIcon={item.fontIcon}
        />
      ))}
    </>
  )
}

export default LogisticsMenu

const menuItems = [
  {
    to: '/directions',
    icon: '/media/icons/duotune/arrows/arr030.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_DIRECTIONS_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/states',
    icon: '/media/icons/duotune/maps/map009.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_COUNTRIES_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/couriers',
    icon: '/media/icons/duotune/maps/map005.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_COURIERS_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/areas',
    icon: '/media/icons/duotune/maps/map002.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_REGIONS_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/locations',
    icon: '/media/icons/duotune/maps/map008.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_CITIES_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/delivery-types',
    icon: '/media/icons/duotune/ecommerce/ecm006.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_DELTYPES_TITLE',
    fontIcon: 'bi-layers',
  },
  {
    to: '/warehouses',
    icon: '/media/icons/duotune/technology/teh005.svg',
    title: 'GENERAL.ASIDE_MENU.LOGISTICS_WAREHOUSES_TITLE',
    fontIcon: 'bi-layers',
  },
]
