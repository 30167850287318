import {ErrorMessage, Field, Form, Formik, FormikHelpers} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import ReactInputMask from 'react-input-mask'
import {FormattedMessage, useIntl} from 'react-intl'
import {useMutation, useQueryClient} from 'react-query'
import {useLocation} from 'react-router-dom'
import {
  ID,
  KTSVG,
  ListViewContextProps,
  QUERIES,
  QueryResponseContextProps,
  selectDebounce,
} from '../../../../../_metronic/helpers'
import setErrorsForFormFromErrorsObject from '../../../../../_metronic/helpers/custom/funcs/setErrorsForFormFromErrorsObject'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWebsite} from '../../../../pages/websites/core/_models'
import {Employee} from '../../../../pages/employees/employees-list/core/_models'
import {IUserGroup} from '../../../../pages/user-groups/user-groups-list/core/_models'
import {getUserGroups} from '../../../../pages/user-groups/user-groups-list/core/_requests'
import {User} from '../../../../pages/users/core/_models'
import {createClient} from '../../../../pages/users/core/_requests'
import {GroupSelect} from '../../../../pages/users/profile/tabs/overview/GroupSelect'
import {InputTemplate} from '../../form-elements/InputTemplate'
import WebsitesSelect from '../../form-elements/selects/WebsitesSelect'
import {CreateUserModalHeader} from './CreateUserModalHeader'
import {ICreateUserForm} from './_models'
import {createUserValidationSchema} from './_yup'

type TProps = {
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<User | Employee>
}

const CreateUserForm = ({listView, queryResponse}: TProps) => {
  const {setItemIdForUpdate} = listView
  const {pathname} = useLocation()
  const page = pathname.split('/')[1]
  const intl = useIntl()

  const {refetch} = queryResponse

  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  // @ts-ignore
  const {data: userGroupsData}: IUserGroup[] | undefined = queryClient.getQueryData(
    QUERIES.USER_GROUPS_LIST
  )

  const [groupSelectResults, setGroupSelectResults] = useState<IBasicSelect[]>([])

  const [formik, setFormik] = useState<{
    values: ICreateUserForm
    formikHelpers: FormikHelpers<ICreateUserForm>
  } | null>(null)

  const inits: ICreateUserForm = {
    group:
      userGroupsData && userGroupsData[0]
        ? {value: userGroupsData[1]?.id, label: userGroupsData[1].title}
        : {label: '', value: ''},
    website:
      websitesData && websitesData[0]
        ? {
            value: websitesData[0]?.id,
            label: websitesData[0].name,
            name: websitesData[0].name,
            faviconUrl: websitesData[0].faviconUrl,
            host: websitesData[0].host,
          }
        : {value: '', label: ''},

    email: '',
    password: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    patronymic: '',
  }

  const [initValues] = useState<ICreateUserForm>(inits)

  const [isLoading, setIsLoading] = useState(false)

  const setGroups = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setIsLoading(true)
      getUserGroups(`search=${query}`)
        .then((res) => {

          const reformed = res?.data?.map((group) => {
            return {value: group.id, label: group.title}
          })

          setGroupSelectResults(reformed || [])
          if (callback) {
            callback(reformed)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    }, 500),
    []
  )

  useEffect(() => {
    setGroups('')
  }, [setGroups])

  //mutation
  const createClientMutation = useMutation(createClient, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)
    },
    onError: (error: any) => {
      //   setCreateResponse('error')
      if (formik && error.response?.data?.errors) {
        setErrorsForFormFromErrorsObject({
          error,
          values: formik?.values,
          formikHelpers: formik?.formikHelpers,
          intl,
        })
      }
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (values: ICreateUserForm, formikHelpers: FormikHelpers<ICreateUserForm>) => {
    try {
      const data: any = {
        ...Object.fromEntries(Object.entries(values).filter(([key, value]) => key !== 'group')),
        website: values.website?.value ? +values.website?.value : 1,
      }

      if (page === 'employees' && values.group?.value) {
        data.group = +values.group?.value
      }
      setFormik({values, formikHelpers})
      createClientMutation.mutate(data)
    } catch (error: any) {
      console.log(error)
    }
  }
  return (
    <Formik
      validationSchema={createUserValidationSchema(intl)}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_article_form'>
            <CreateUserModalHeader isLoading={isLoading} isValid={isValid} listView={listView} />
            <div
              className='scroll-y bg-white modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/* {JSON.stringify(errors)} */}
                {/* {JSON.stringify(values)} */}
                <div className='w-100'>
                  <div className={`fv-row mb-5 ${page === 'users' ? 'd-none' : ''}`}>
                    <GroupSelect
                      inputName={'group'}
                      setFieldValue={setFieldValue}
                      value={values.group || groupSelectResults[0]}
                      options={groupSelectResults.filter(({value}) => value !== 1)}
                      isLoading={isLoading}
                      onInputChange={setGroups}
                      title='Група'
                    />
                  </div>
                  <div className='fv-row mb-5'>
                    <WebsitesSelect />
                  </div>
                  <div className='fv-row mb-5 d-flex'>
                    <InputTemplate
                      inputName='email'
                      titleFontSize='fs-5 text-dark'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'PROFILE.PAGE.CONTACTS.EMAIL'})}
                      fieldWidth='w-50'
                      marginClass='me-2'
                    />
                    <InputTemplate
                      inputName='phoneNumber'
                      type='tel'
                      title='Номер телефону'
                      fieldWidth='w-50'
                      titleFontSize='fs-5 text-dark'
                      required={true}
                      setFieldValue={setFieldValue}
                      value={values.phoneNumber}
                      custom={
                        <Field name={'phoneNumber'}>
                          {() => (
                            <ReactInputMask
                              name={'phoneNumber'}
                              type='tel'
                              maskChar={null}
                              mask='+38 (099) 999-99-99'
                              className={`form-control form-control-lg form-control-solid text-dark`}
                              value={values.phoneNumber}
                              onChange={(e) => {
                                setFieldValue('phoneNumber', e.target.value.replace(/[^0-9+]/g, ''))
                              }}
                            />
                          )}
                        </Field>
                      }
                    />
                  </div>
                  <div className='fv-row mb-5 d-flex'>
                    <InputTemplate
                      inputName='password'
                      titleFontSize='fs-5 text-dark'
                      type='text'
                      required={true}
                      title={'Пароль'}
                    />
                  </div>
                  <div className='fv-row mb-5 d-flex'>
                    {' '}
                    <InputTemplate
                      inputName='lastName'
                      titleFontSize='fs-5 text-dark'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.LAST_NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                    <InputTemplate
                      inputName='firstName'
                      titleFontSize='fs-5 text-dark'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                  </div>{' '}
                  <div className='fv-row mb-5'>
                    <InputTemplate
                      inputName='patronymic'
                      titleFontSize='fs-5 text-dark'
                      type='text'
                      fieldWidth='w-50'
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PATRONYMIC'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateUserForm
