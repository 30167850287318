interface Props {
  hiddenDimensionsRef: React.RefObject<HTMLInputElement>
  hiddenPhotoScanProdRef: React.RefObject<HTMLInputElement>
  hiddenCellRef: React.RefObject<HTMLInputElement>
}
export const HiddenFields = ({
  hiddenDimensionsRef,
  hiddenPhotoScanProdRef,
  hiddenCellRef,
}: Props) => {
  return (
    <div className='d-flex flex-column-reverse flex-col-rev-gutter visually-hidden'>
      <input
        type='text'
        name='hiddenDimensionsRef'
        id='hidden-ref'
        defaultValue={''}
        ref={hiddenDimensionsRef}
      />
      <input
        type='text'
        name='hiddenPhotoScanProdRef'
        id='hidden-prod-ref'
        defaultValue={''}
        ref={hiddenPhotoScanProdRef}
      />

      <input
        type='text'
        name='hiddenCellRef'
        id='hidden-cell-ref'
        defaultValue={''}
        ref={hiddenCellRef}
      />
    </div>
  )
}
