type Props = {
  errorMessage: string
}

export const Errors = ({errorMessage}: Props) => {
  return (
    <div className='mt-3 fw-bold text-danger fs-6 text-center'>
      {errorMessage.split('/').map((item, index) => (
        <p className='mb-0' key={index}>
          {item}
        </p>
      ))}
    </div>
  )
}
