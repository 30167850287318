/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {IWebsite} from '../../../../../websites/core/_models'

import {IChat} from '../../../../core/_models'

type Props = {
  chat: IChat
}

const ChatResponseCell = ({chat}: Props) => {
  const queryClient = useQueryClient()
  const statuses: Record<string, string> =
    queryClient.getQueryData(QUERIES.CHATS_STATUSES_LIST) || {}
  return (
    <div className='d-flex justify-content-start flex-column'>
      {!!chat.firstReplyTime && (
        <div className='mb-2 mt-1'>
          <span className='fw-normal d-block fs-7'>
            {new Date(chat.firstReplyTime * 1000).toISOString().slice(11, 19)}{' '}
          </span>
        </div>
      )}
      {!!chat.firstReplyUser && (
        <Link
          to={`/users/edit/overview/${chat?.firstReplyUser?.id}`}
          className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
        >
          <span>{chat?.firstReplyUser?.externalId}</span>
          <span className='text-muted fs-6'>
            {[
              chat?.firstReplyUser?.lastName,
              chat.firstReplyUser?.firstName,
              chat.firstReplyUser?.patronymic,
            ].join(' ')}
          </span>
        </Link>
      )}
    </div>
  )
}

export {ChatResponseCell}
