import {Field, ErrorMessage} from 'formik'

interface Props {
  index: number
}

function WebsiteLink({index}: Props) {
  return (
    <div className='fv-row mb-10'>
      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
        <span className=''>Ссылка {index + 1}</span>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Укажите название и ссылку'
        ></i>
      </label>

      <Field
        type='text'
        className='form-control form-control-lg form-control-solid mb-2'
        name={`links.${index}.linkName`}
        placeholder='Название'
      />
      <div className='text-danger'>
        <ErrorMessage name={`links.${index}.linkName`} />
      </div>
      <Field
        type='text'
        className='form-control form-control-lg form-control-solid'
        name={`links.${index}.websiteUrl`}
        placeholder='URL'
      />
      <div className='text-danger'>
        <ErrorMessage name={`links.${index}.websiteUrl`} />
      </div>
    </div>
  )
}

export {WebsiteLink}
