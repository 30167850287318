import {Formik, Form, FormikHelpers} from 'formik'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery, useQueryClient} from 'react-query'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import setErrorsForFormFromErrorsObject from '../../../../../../_metronic/helpers/custom/funcs/setErrorsForFormFromErrorsObject'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import CouriersSelect from '../../../../../modules/custom/form-elements/selects/CouriersSelect'
import {couriersApi} from '../../../../couriers/core/_requests'
import DeliveryMethods from '../../../../couriers/modules/DeliveryMethods'
import {regionOverviewSchema} from '../../../core/yup/yup'
import {IRegion, ICreateNewRegion, ICreateNewRegionForm} from '../../../core/_models'
import {regionsApi} from '../../../core/_requests'

interface Props {
  region: IRegion
  setCurrRegion: (obj: IRegion) => void
}

export function RegionOverview({region, setCurrRegion}: Props) {
  const intl = useIntl()

  const queryClient = useQueryClient()
  const {data: courierById} = useQuery(QUERIES.COURIER_BY_ID, () =>
    couriersApi.getCourierById(region.deliveryService.id)
  )
  const initialValues: Partial<ICreateNewRegionForm> = {
    name: region.name,
    deliveryService: {
      ...region.deliveryService,
      label: region.deliveryService?.name || '',
      value: region.deliveryService?.id || '',
    },
    deliveryMethods: region.deliveryMethods.map((i) => i.id?.toString()),
  }
  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (
    values: Partial<ICreateNewRegionForm>,
    formikHelpers: FormikHelpers<Partial<ICreateNewRegionForm>>
  ) => {
    try {
      setLoading(true)

      const {name, deliveryService, deliveryMethods} = values
      const dataTosend: Partial<ICreateNewRegion> = {
        name,
        deliveryService: {id: deliveryService?.value},
        // @ts-ignore
        deliveryMethods: values.deliveryMethods.map((i) => {
          return {id: i}
        }),
      }
      await regionsApi.editRegion(region.id, dataTosend)

      queryClient.invalidateQueries([QUERIES.REGION_BY_ID])
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error.response?.data?.errors) {
        setErrorsForFormFromErrorsObject({error, values, formikHelpers, intl})
      }
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {courierById ? (
        <Formik
          validationSchema={regionOverviewSchema(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values.deliveryService)} */}
              {/*   {JSON.stringify(courierById?.deliveryMethods)} */}
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='REGIONS.ADD_MODAL.FORM.NAME' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <div className='col-lg-6 fv-row me-4'>
                        <InputTemplate inputName='name' type='text' required={true} />
                      </div>{' '}
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='COMPONENTS.CouriersSelect.LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <CouriersSelect withTitle={false} />
                    </div>
                  </div>
                </div>{' '}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      {courierById?.deliveryMethods && (
                        <DeliveryMethods delMethods={courierById?.deliveryMethods} flexRow={true} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
