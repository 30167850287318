import {FormattedMessage} from 'react-intl'
import {FlagsDirectionBar} from '../../../../../modules/custom/FlagsDirectionBar'
import {IPaymentProvider} from '../../core/_models'

interface Props {
  provider: IPaymentProvider
}

export function ProviderHeaderInfo({provider}: Props) {
  const {orderDirections, deliveryDirections} = provider
  const allDirections = [...orderDirections, ...deliveryDirections]

  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex flex-column fw-bold fs-6 mb-4 pe-2'>
        {/* <div className='btn btn-sm btn-light-primary fw-bold px-4'> */}
        <div className='d-flex align-items-center mb-2 mb-md-none'>
          <FlagsDirectionBar
            chosenDirections={allDirections.filter(
              (id, index) => allDirections.indexOf(id) === index
            )}
            minified={true}
            additionalClass='py-0'
          />
        </div>
        {/* </div> */}
      </div>
      <div className='card-toolbar mb-2'></div>
    </div>
  )
}
