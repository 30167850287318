import {Formik, Form} from 'formik'
import {useState} from 'react'
// import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
// import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
// import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'

import {IPoll, IPollForm, PollResultsQueryResponse} from '../../../core/_models'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {pollsApi} from '../../../core/_requests'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {useIntl} from 'react-intl'
import WebsitesSelect from '../../../../../modules/custom/form-elements/selects/WebsitesSelect'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {IWebsite} from '../../../../websites/core/_models'
import {editPollOverview} from '../../../core/yup'
import {useParams} from 'react-router-dom'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  poll: IPoll
  setCurrPoll: (obj: IPoll) => void
  website: IWebsite | undefined
}

export function PollOverview({poll, setCurrPoll, website}: Props) {
  const intl = useIntl()
  const {locale = 'uk'} = useParams()

  const queryClient = useQueryClient()
  const pollResultsById: PollResultsQueryResponse | undefined = queryClient.getQueryData(
    `${QUERIES.POLL_RESULTS_BY_ID}-${poll.id}`
  )

  const pollTypes: string[] | undefined = queryClient.getQueryData(QUERIES.POLL_TYPES_LIST)

  const initialValues: Partial<IPollForm> = {
    name: poll.translations[locale]?.name || '',
    enabled: poll.enabled ? 1 : 0,
    website: {...website, value: website?.id, label: website?.name || ''},
    type: poll.type,
  }

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IPollForm>) => {
    setLoading(true)

    const {name, enabled, website, type} = values

    const pollData = {
      translations: {...poll.translations, [locale]: {name}},
      enabled: enabled === 1 ? true : false,
      website: {id: website?.value},
      type,
    }
    const response = await pollsApi.editPoll(pollData, poll.id)

    if (response) {
      queryClient.invalidateQueries([QUERIES.POLL_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {pollResultsById?.totalItems || pollResultsById?.totalItems === 0 ? (
        <Formik
          validationSchema={editPollOverview(intl)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values)} */}
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Назва</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-8 fv-row'>
                        <InputTemplate
                          inputName='name'
                          type='text'
                          titleFontSize='fs-5 text-dark'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Статус </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <CheckboxSwitcher
                        inputName='enabled'
                        firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'})}
                        secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'})}
                        value={values.enabled ? +values.enabled : 0}
                        setFieldValue={setFieldValue}
                        bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Вебсайт</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <WebsitesSelect
                        withTitle={false}
                        disabled={
                          pollResultsById?.totalItems ? pollResultsById?.totalItems > 0 : false
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                    Умова старту
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      {pollTypes?.map((type) => (
                        <InputTemplate
                          key={type}
                          inputName='type'
                          type='radio'
                          value={type}
                          title={intl.formatMessage({
                            id: `POLLS.ADD_MODAL.FORM.${type.toUpperCase()}`,
                          })}
                          checkboxProps={{
                            checked: values.type === type ? true : false,
                            disabled: false,
                            setFieldValue: setFieldValue,
                            value: type,
                            inputName: 'type',
                            type: 'radio',
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={success} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
