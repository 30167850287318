import {FC} from 'react'

import {IPayment} from '../../../../core/_models'

import {Link} from 'react-router-dom'

type Props = {
  payment: IPayment
}

const PaymentNameCell: FC<Props> = ({payment}) => {
  return (
    <div className='d-flex flex-column mb-3'>
      {payment.paymentProvider && (
        <Link to={`/finances/payment-providers/edit/${payment.paymentProvider?.id}`}>
          <p className='text-dark m-0'>{payment.paymentProvider?.name}</p>
        </Link>
      )}
    </div>
  )
}

export {PaymentNameCell}
