import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../../_metronic/layout/core'
import CategoryPage from './category-page/CategoryPage'
import {CategoriesListWrapper} from './categories-list/CategoriesList'
import {PageViewProvider} from './core/PageViewProvider'

const BlogCategoriesPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <CategoriesListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <CategoryPage
                id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/website-categories/blog/list`} />} />
    </Routes>
  )
}

export default BlogCategoriesPage
