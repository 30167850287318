/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import {
  LS_KEYS,
  QueryRequestContextProps,
  QueryResponseContextProps,
} from '../../../../_metronic/helpers'
import useQueryResponsePagination from './useQueryResponsePagination'

const mappedLabel = (label: string): string => {
  if (label === '❮') {
    return '❮'
  }

  if (label === '❯') {
    return '❯'
  }

  return label
}

interface Props {
  queryObj: {
    queryResponse: QueryResponseContextProps<any>
    queryResponseLoading: boolean
    queryRequest: QueryRequestContextProps
  }
  addClass?: string
}

const ListPagination = ({queryObj, addClass = ''}: Props) => {
  const pagination = useQueryResponsePagination({queryResponse: queryObj.queryResponse})
  const isLoading = queryObj.queryResponseLoading
  const {updateState} = queryObj.queryRequest
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, itemsPerPage: pagination.itemsPerPage || 10})
  }

  const updateItemsPerPage = (itemsPerPage: number) => {
    if (isLoading || pagination.itemsPerPage === itemsPerPage) {
      return
    }

    updateState({itemsPerPage, page: 1})
    const data = JSON.stringify(itemsPerPage)
    window.localStorage.setItem(LS_KEYS.PAGINATION_KEY, data)
  }

  const res = queryObj?.queryResponse?.response

  const showPagination =
    res?.totalItems !== undefined &&
    res?.itemsPerPage !== undefined &&
    res?.totalItems > res?.itemsPerPage

  const showItemsPerPage = res?.totalItems !== undefined && res?.totalItems <= 10 ? false : true

  return (
    <div className={`row d-flex flex-row flex-nowrap justify-content-between ${addClass}`}>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-end justify-content-md-start w-auto'>
        {showItemsPerPage === true && (
          <DropdownButton
            id='dropdown-item-button'
            title={pagination.itemsPerPage + ' '}
            drop='up'
            className='PaginationDropdown'
          >
            <Dropdown.Item onClick={() => updateItemsPerPage(10)}>10</Dropdown.Item>
            <Dropdown.Item as='button' onClick={() => updateItemsPerPage(25)}>
              25
            </Dropdown.Item>
            <Dropdown.Item as='button' onClick={() => updateItemsPerPage(50)}>
              50
            </Dropdown.Item>
            <Dropdown.Item as='button' onClick={() => updateItemsPerPage(100)}>
              100
            </Dropdown.Item>
          </DropdownButton>
        )}
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        {showPagination === true && (
          <div id='kt_table_paginate'>
            <ul className='pagination'>
              {pagination.links
                ?.map((link) => {
                  return {...link, label: mappedLabel(link.label)}
                })
                .map((link) => (
                  <li
                    key={link.label}
                    className={clsx('page-item', {
                      active: pagination.page === link.page,
                      disabled: isLoading,
                      previous: link.label === 'Previous',
                      next: link.label === 'Next',
                    })}
                  >
                    <a
                      className={clsx('page-link', {
                        'page-text': link.label === 'Previous' || link.label === 'Next',
                        'me-5': link.label === 'Previous',
                      })}
                      onClick={() => updatePage(link.page)}
                      style={{cursor: 'pointer'}}
                    >
                      {mappedLabel(link.label)}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export {ListPagination}
