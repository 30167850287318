import {useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../../../../../../_metronic/helpers'
import {IPollResult} from '../../../../../../../core/_models'

type Props = {
  result: IPollResult
}

const UserCell = ({result}: Props) => {
  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <div className='d-flex align-items-center'>
      <Link to={`/users/edit/overview/${result.user.id}`}>
        <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
          {result.user.externalId}
        </p>
        <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
          {[result?.user?.lastName, result.user?.firstName, result.user?.patronymic].join(' ')}
        </p>
      </Link>
    </div>
  )
}

export {UserCell}
