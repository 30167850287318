/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {ID, QUERIES} from '../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {Formik, Form, ErrorMessage, FormikHelpers} from 'formik'
import {ICreateNewRegion, ICreateNewRegionForm} from '../core/_models'
import {regionsApi} from '../core/_requests'
import {useListView as locationsUseListView} from '../../locations/core/ListViewProvider'
import ScrollToFieldError from './ScrollToFieldError'
import {CreateRegionModalHeader} from './CreateRegionModalHeader'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {FormattedMessage, useIntl} from 'react-intl'
import {createRegionSchema} from '../core/yup/yup'
import CouriersSelect from '../../../modules/custom/form-elements/selects/CouriersSelect'
import {CouriersQueryResponse} from '../../couriers/core/_models'
import DeliveryMethods from '../../couriers/modules/DeliveryMethods'
import setErrorsForFormFromErrorsObject from '../../../../_metronic/helpers/custom/funcs/setErrorsForFormFromErrorsObject'
import {useQueryResponse} from '../core/QueryResponseProvider'

export const intoIdObjArr = (arr: ID[] | undefined) => {
  if (!arr) {
    return []
  }
  return arr.map((i) => {
    return {id: i}
  })
}

const CreateRegionForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {itemIdForUpdate, setItemIdForUpdate, selected} = locationsUseListView()
  const [isLoading, setIsLoading] = useState(false)
  const {refetch} = useQueryResponse()

  const [createResponse, setCreateResponse] = useState('')
  const couriers: CouriersQueryResponse = queryClient.getQueryData([QUERIES.COURIERS_LIST]) || {}

  const inits: ICreateNewRegionForm = {
    name: '',
    deliveryService: couriers?.data
      ? {...couriers?.data?.[0], label: couriers?.data?.[0].name, value: couriers?.data?.[0].id}
      : {label: '', value: ''},
    deliveryMethods: [couriers?.data?.[0].deliveryMethods[0].id?.toString()],
    minWeight: '',
    maxWeight: '',
    paidByClient: 0,
    priority: 0,
  }

  const [formik, setFormik] = useState<{
    values: ICreateNewRegionForm
    formikHelpers: FormikHelpers<ICreateNewRegionForm>
  } | null>(null)

  const mutation = useMutation(regionsApi.addRegion, {
    onSuccess: () => {
      setCreateResponse('success')
      formik?.formikHelpers?.resetForm()
      refetch()
    },
    onMutate: () => {
      setCreateResponse('loading')
    },
    onError: (error: any) => {
      setCreateResponse('error')
      if (formik && error.response?.data?.errors) {
        setErrorsForFormFromErrorsObject({
          error,
          values: formik?.values,
          formikHelpers: formik?.formikHelpers,
          intl,
        })
      }
      console.log(error)
    },
  })

  const handleSubmit = async (
    values: ICreateNewRegionForm,
    formikHelpers: FormikHelpers<ICreateNewRegionForm>
  ) => {
    try {
      const {deliveryMethods, deliveryService, name, maxWeight, minWeight, paidByClient, priority} =
        values
      const dataToSend: ICreateNewRegion = {
        name,
        cities: intoIdObjArr(selected),
        deliveryService: {id: deliveryService?.value},
        deliveryMethods: intoIdObjArr(deliveryMethods),
        maxWeight: +maxWeight || null,
        minWeight: +minWeight || null,
        paidByClient: paidByClient === 1 ? true : false,
        priority,
      }
      await setFormik({values, formikHelpers})

      mutation.mutate(dataToSend)
    } catch (error: any) {
      console.log(error)
    }
  }

  return (
    <>
      {couriers ? (
        <Formik
          validationSchema={createRegionSchema(intl)}
          initialValues={inits}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue, isValid}) => (
            <Form className='form' noValidate id='kt_modal_create_direction_form'>
              <ScrollToFieldError />
              <CreateRegionModalHeader isLoading={isLoading} isValid={isValid} />
              {/* {JSON.stringify(errors)} */}
              {/* {JSON.stringify(values.deliveryService?.deliveryMethods)} */}
              <div
                className='scroll-y bg-white modal-body p-0'
                style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
              >
                <div className='p-3'>
                  <div className='w-100'>
                    <InputTemplate
                      inputName='name'
                      type='text'
                      title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.NAME'})}
                      required={true}
                    />
                    <CouriersSelect />
                  </div>
                </div>
                {values.deliveryService?.deliveryMethods && (
                  <>
                    <div className='separator bg-primary h-3px mb-3'></div>{' '}
                    <div className='p-3'>
                      <div className='w-100'>
                        <p className='required fs-6 fw-bold mb-3'>
                          <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                        </p>

                        <DeliveryMethods delMethods={values.deliveryService?.deliveryMethods} />
                      </div>
                    </div>
                  </>
                )}{' '}
                <div className='separator bg-primary h-3px mb-3'></div>
                <div className='p-3'>
                  <div className='w-100'>
                    <InputTemplate
                      inputName={`priority`}
                      fieldWidth='w-50'
                      disabled={!!itemIdForUpdate}
                      type='number'
                      required={true}
                      title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.PRIORITY_L'})}
                      value={values.priority}
                      numberWithControls={true}
                    />
                    <div className='d-flex flex-gutter'>
                      <InputTemplate
                        inputName={`minWeight`}
                        fieldWidth='w-50'
                        disabled={!!itemIdForUpdate}
                        type='number'
                        title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.MIN_WEIGHT_L'})}
                        value={values.minWeight}
                        numberWithControls={true}
                      />
                      <InputTemplate
                        inputName={`maxWeight`}
                        fieldWidth='w-50'
                        disabled={!!itemIdForUpdate}
                        type='number'
                        title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.MAX_WEIGHT_L'})}
                        value={values.maxWeight}
                        numberWithControls={true}
                      />
                    </div>{' '}
                    <p className='required fs-6 fw-bold mb-7'>
                      <FormattedMessage id='REGIONS.ADD_MODAL.FORM.PAID_BY' />
                    </p>
                    <InputTemplate
                      inputName='paidByClient'
                      required={false}
                      type='radio'
                      value={0}
                      title={intl.formatMessage({
                        id: 'REGIONS.ADD_MODAL.FORM.PAID_BY.COMPANY',
                      })}
                      checkboxProps={{
                        checked: +values.paidByClient === 0,
                        disabled: false,
                        setFieldValue: setFieldValue,
                        value: 0,
                        inputName: 'paidByClient',
                        type: 'radio',
                      }}
                    />
                    <InputTemplate
                      inputName='paidByClient'
                      required={false}
                      type='radio'
                      value={1}
                      title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.PAID_BY.CLIENT'})}
                      checkboxProps={{
                        checked: +values.paidByClient === 1,
                        disabled: false,
                        setFieldValue: setFieldValue,
                        value: 1,
                        inputName: 'paidByClient',
                        type: 'radio',
                      }}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='paidByClient' />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  )
}

export {CreateRegionForm}
