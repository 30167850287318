import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {EmployeesListToolbar} from './EmployeeListToolbar'
import {EmployeesListGrouping} from './EmployeesListGrouping'

const EmployeesListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      {' '}
      <ListSearch updateState={updateState} />
      <div className='card-toolbar'>
        {selected.length > 0 ? <EmployeesListGrouping /> : <EmployeesListToolbar />}
      </div>
    </div>
  )
}

export {EmployeesListHeader}
