import {FC, useState, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {IWebsite} from './_models'

const initialListView = {
  websites: [],
  setWebsites: () => {},
}
export type ListViewContextProps = {
  websites: IWebsite[]
  setWebsites: Dispatch<SetStateAction<any>>
}
const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [websites, setWebsites] = useState(initialListView.websites)

  return (
    <ListViewContext.Provider
      value={{
        websites,
        setWebsites,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
