import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {CollectionsQueryResponse, ICollection, ICreateCollection, IEditCollection} from '../../_models'

const API_URL = process.env.REACT_APP_API_PATH
const COLLECTIONS_URL = `/website_article_collections`
const BASE_URL = `${API_URL}${COLLECTIONS_URL}`

const getCollections = (query: string): Promise<CollectionsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<CollectionsQueryResponse>) => {
      return d.data
    })
}

const getCollectionsByWebsite = (website: ID) => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?website=${website}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d) => {

      return d.data.data
    })
}

const getCollectionById = async (id: ID): Promise<ICollection | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const addCollection = (collection: ICreateCollection): Promise<ICollection | undefined> => {
  return axios
    .post(`${BASE_URL}`, collection)
    .then((response: AxiosResponse<Response<ICollection>>) => response.data)
    .then((response: Response<ICollection>) => response.data)
}

const editCollection = (collection: Partial<IEditCollection>, id: ID): Promise<ICollection | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, collection)
    .then((response: AxiosResponse<Response<ICollection>>) => response.data)
    .then((response: Response<ICollection>) => response.data)
}

const deleteCollection = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {
  getCollections,
  getCollectionsByWebsite,
  getCollectionById,
  addCollection,
  editCollection,
  deleteCollection,
}
