import {Column} from 'react-table'
import {CountryCustomHeader} from './CountryCustomHeader'
import {IWarehouse} from '../../../core/_models'
import {WarehouseAddressCell} from './cells/WarehouseAddressCell'
import {WarehouseActionsCell} from './cells/WarehouseActionsCell'
import {WarehouseNameCell} from './cells/WarehouseNameCell'
import {WarehouseCountryCell} from './cells/WarehouseCountryCell'

const countriesColumns: ReadonlyArray<Column<IWarehouse>> = [
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'WAREHOUSES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <WarehouseNameCell warehouse={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'WAREHOUSES_COLUMNS_ADDRESS'}
        className='min-w-125px text-center'
      />
    ),
    id: 'address',
    Cell: ({...props}) => <WarehouseAddressCell warehouse={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'WAREHOUSES_COLUMNS_COUNTRY'}
        className='min-w-125px'
      />
    ),
    id: 'country',
    Cell: ({...props}) => <WarehouseCountryCell warehouse={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CountryCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <WarehouseActionsCell warehouse={props.data[props.row.index]} />,
  },
]

export {countriesColumns}
