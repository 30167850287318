import {User} from '../core/_models'

const transformUserForSelect = (original: Partial<User>) => {
  return {
    ...original,
    value: original.id || original.externalId || '',
    label:
      (original.lastName ? original.lastName + ' ' : '') +
      (original.firstName ? original.firstName : ''),
  }
}

export default transformUserForSelect
