import {useQuery} from 'react-query'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {websitesApi} from '../websites/core/_requests'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {WebsitePagesListWrapper} from './website-pages-list/WebsitePagesList'
import {useEffect} from 'react'
import WebsitePagePage from './website-page-page/WebsitePagePage'

const WebsitePages = () => {
  const {config} = useLayout()
  useQuery(QUERIES.WEBSITES_LIST, websitesApi.fetchWebsites)
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  const loc = useLocation()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/:websiteId'
          element={
            <div>
              <WebsitePagesListWrapper />
            </div>
          }
        />

        <Route
          path={'list/:websiteId/edit/*'}
          element={
            <WebsitePagePage id={loc.pathname.split('/')[loc.pathname.split('/').length - 1]} />
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/websites/pages/list/${+loc.pathname.split('/')[
              loc.pathname.split('/').length - 1
            ]}`}
          />
        }
      />
    </Routes>
  )
}

export default WebsitePages
