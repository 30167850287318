import {useEffect, useRef, useState} from 'react'
import {ErrorMessage, Form, Formik, FormikValues} from 'formik'
import {IReceiverToSend, NewReceiverFullForm} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {addReceiverAddress, createReceiver, uploadFile} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {FileInput} from '../../elements/FileInput'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {addReceiverSchema} from '../core/yup/receiver-page'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddressShipment} from '../../../../../modules/custom/shipment/_modals/AddressShipment'
import {PostShipment} from '../../../../../modules/custom/shipment/_modals/PostShipment'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useMutation, useQueryClient} from 'react-query'
import {CreateError} from '../../../../../modules/custom/responseHandlers/CreateError'
import {locationsApi} from '../../../../../../_metronic/helpers/custom/api/locationsApi'
import {PatronymicCheckbox} from '../../elements/PatronymicCheckbox'
import {ReceiverContact} from '../../elements/ReceiverContact'
import {FormattedMessage, useIntl} from 'react-intl'
import {ClientSelect} from '../../elements/ClientSelect'
import {ReceiverType} from '../../elements/ReceiverType'
import {IReceiverShipmentAddressToSend} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {AddReceiverModalHeader} from './AddReceiverModalHeader'
import ScrollToFieldError from './ScrollToFieldError'
import CountriesSelect from '../../../../../modules/custom/form-elements/selects/CountriesSelect'
import {CountriesQueryResponse} from '../../../../countries/countries-list/core/_models'

const AddReceiverModalForm = () => {
  const intl = useIntl()
  const {
    setItemIdForUpdate,
    usersLoading,
    setUsersLoading,
    usersResults,
    setUsersResults,
    filterUsers,
  } = useListView()
  const {refetch, response} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)
  const [createResponse, setCreateResponse] = useState('')
  const [addressValues, setAddressValues] = useState<any>(null)
  const [newReceiverId, setNewReceiverId] = useState('')
  const [imageIsLoading, setImageIsLoading] = useState(false)
  const [currPassport, setCurrPassport] = useState({documentUrl: '', document: ''})

  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const countriesData: CountriesQueryResponse =
    queryClient.getQueryData(QUERIES.COUNTRIES_LIST) || {}

  const defaultDataForTheFirstReceiver =
    usersResults && usersResults.length > 0 && response?.data?.length === 0
      ? {
          user: usersResults[0],
          firstName: usersResults[0].firstName || '',
          lastName: usersResults[0].lastName || '',
          patronymic: usersResults[0].patronymic || '',
          phoneNumber: usersResults[0].phoneNumber || '',
          email: usersResults[0].email || '',
        }
      : {
          user:
            usersResults && usersResults?.length > 0
              ? usersResults[0]
              : {id: '', firstName: '', lastName: '', value: '', label: ''},
          firstName: '',
          lastName: '',
          patronymic: '',
          phoneNumber: '',
          email: '',
        }

  const inits: NewReceiverFullForm = {
    ...defaultDataForTheFirstReceiver,
    countryConfig: {
      ...countriesData?.data?.[0],
      value: countriesData?.data?.[0]?.id || '',
      label: countriesData?.data?.[0]?.country ? countries[countriesData?.data?.[0]?.country] : '',
    },

    premisesType: 'apartment',
    noPatronymic: false,
    document: currPassport.documentUrl,
    receiverType: [1],
    typeOfDelivery: 1,
    city: '',
    commonReceiver: false,
  }

  const filterValues = (accepted: string[], values: any) => {
    const result = {}
    //@ts-ignore
    for (const type in values) if (accepted.indexOf(type) > -1) result[type] = values[type]

    return result
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const createReceiverMutation = useMutation(createReceiver, {
    onMutate: () => {
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      setItemIdForUpdate(undefined)

      // setNewReceiverId(data.id || '')
    },

    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)

      cancel(true)
    },
  })

  const addReceiverAddressMutation = useMutation(addReceiverAddress, {
    onSuccess: () => {
      setCreateResponse('success')
      setItemIdForUpdate(undefined)
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      cancel(true)
      setIsLoading(false)
    },
  })

  const shapeAddressData = async (submittedValues: any) => {
    let dataToSend

    if (+submittedValues.typeOfDelivery === 1) {
      const {apartment, city, houseLetter, houseNumber, office, street, premisesType} =
        submittedValues

      const cityObj = await locationsApi.getCityById(city)
      const streetObj = await locationsApi.getStreetById(street || '', city)

      dataToSend = {
        post: false,
        mainAddress: true,
        city: {id: city},
        region: cityObj.region,
        address: {
          apartment: apartment && premisesType === 'apartment' ? apartment : null,
          houseLetter: houseLetter ? houseLetter : null,
          houseNumber,
          office: office && premisesType === 'office' ? office : null,
          street: {
            id: streetObj ? streetObj.ref : street,
            name: streetObj ? streetObj.name : street,
          },
        },
        mainOffice: null,
        reserveOffice: null,
      }
    } else if (+submittedValues.typeOfDelivery === 2) {
      const {mainOfficeCity, mainOfficeNumber, reserveOfficeCity, reserveOfficeNumber} =
        submittedValues

      const cityObj = await locationsApi.getCityById(mainOfficeCity)

      dataToSend = {
        post: true,
        mainAddress: true,
        city: {id: mainOfficeCity},
        region: cityObj.region,
        address: null,
        mainOffice: {
          city: {id: mainOfficeCity},
          id: mainOfficeNumber,
        },
        reserveOffice: reserveOfficeCity
          ? {
              city: {id: reserveOfficeCity},
              id: reserveOfficeNumber,
            }
          : null,
      }
    } else {
      return
    }

    setAddressValues(dataToSend)
  }

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setIsLoading(true)
    setImageIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'document',
      'document',
      uploadFile
    )

    if (linkResponse) {
      setCurrPassport(linkResponse)
    } else {
      // set error
      console.log('ошибка')
    }
    setImageIsLoading(false)
    setIsLoading(false)
  }

  const handleSubmit = (values: NewReceiverFullForm, actions: FormikValues) => {
    try {
      setIsLoading(true)

      const {
        apartment,
        office,
        premisesType,
        city,
        email,
        firstName,
        houseNumber,
        lastName,
        commonReceiver,
        user,
        document,
        patronymic,
        phoneNumber,
        receiverType,
        street,
        typeOfDelivery,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
        noPatronymic,
      } = values
      const toSend: Partial<IReceiverToSend> & Partial<IReceiverShipmentAddressToSend> = {
        // apartment,
        // office,
        // premisesType,
        // city,
        // email,
        firstName,
        houseNumber: houseNumber ? houseNumber.toString() : '',
        lastName,
        noPatronymic,
        document: currPassport.document,
        documentUrl: currPassport.documentUrl,
        // passport: currPassportScan.filename,
        patronymic,
        common: commonReceiver,
        phoneNumber: phoneNumber,
        // receiverType,
        // street,
        // typeOfDelivery,
        international: receiverType.includes(1) ? true : false,
        final: receiverType.includes(2) ? true : false,
        withinCountry: receiverType.includes(3) ? true : false,
        // mainOfficeCity,
        // mainOfficeNumber,
        // reserveOfficeCity,
        // reserveOfficeNumber,
      }

      if (commonReceiver === false && user) {
        toSend.user = {id: user.id}
      }

      // setSubmittedValues(toSend)
      // setSubmittedForm(true)
      createReceiverMutation.mutate(toSend)
      // transfer to mutation success
      actions.resetForm()
      setCurrPassport({documentUrl: '', document: ''})
    } catch (error) {}
  }

  // useEffect(() => {
  // shapeAddressData(submittedValues)

  //   if (submittedForm && addressValues) {
  //     addReceiverAddressMutation.mutate({...addressValues, receiver_id: newReceiverId})
  //   }
  // }, [addressValues, newReceiverId])

  return (
    <Formik
      validationSchema={addReceiverSchema(intl)}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({values, setFieldValue, errors, setValues, isValid}) => (
        <Form noValidate className='form'>
          <ScrollToFieldError />
          <AddReceiverModalHeader isLoading={isLoading} isValid={isValid} />

          {/* {JSON.stringify(errors)}*/}
          {/* {JSON.stringify(values.user)} */}
          <div
            className='scroll-y bg-white modal-body p-0'
            style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
          >
            <div className='p-3 pb-6'>
              {' '}
              <div className='w-100'>
                <div className='mb-5'>
                  {setUsersLoading &&
                    setUsersResults &&
                    usersResults &&
                    (usersLoading === true || usersLoading === false) &&
                    filterUsers && (
                      <ClientSelect
                        setUsersLoading={setUsersLoading}
                        setUsersResults={setUsersResults}
                        options={usersResults}
                        isLoading={usersLoading}
                        currentValue={values.user}
                        disabled={values.commonReceiver}
                        onInputChange={filterUsers}
                        widthClassName='w-100'
                        titleClass='fs-5'
                        onChange={(e) => {
                          if (response?.data?.length === 0) {
                            setValues({
                              ...values,
                              user: e,
                              firstName: e?.firstName || '',
                              lastName: e?.lastName || '',
                              patronymic: e?.patronymic || '',
                              phoneNumber: e?.phoneNumber || '',
                              email: e?.email || '',
                            })
                          } else {
                            setFieldValue('user', e)
                          }
                        }}
                      />
                    )}
                  <InputTemplate
                    inputName='commonReceiver'
                    type='checkbox'
                    checkboxProps={{
                      checked: values.commonReceiver,
                      disabled: false,
                      setFieldValue: setFieldValue,
                      value: 1,
                      inputName: 'commonReceiver',
                      type: 'checkbox',
                    }}
                    title={'Застосовувати для будь-якого клієнта'}
                    containerMarginBottom={false}
                  />
                </div>
                {countriesData?.data && countriesData?.data?.length > 1 && (
                  <div className='mb-n4'>
                    <CountriesSelect queryFilter='filter[receive]=true' />
                  </div>
                )}
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3 pb-6'>
              {' '}
              <div className='w-100'>
                <div className='d-flex flex-md-gutter flex-wrap flex-md-nowrap'>
                  <InputTemplate
                    inputName='lastName'
                    type='text'
                    required={true}
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.LAST_NAME'})}
                    addSymbol={
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-3'
                      />
                    }
                  />
                  <InputTemplate
                    inputName='firstName'
                    type='text'
                    required={true}
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NAME'})}
                    addSymbol={
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-3'
                      />
                    }
                  />
                </div>
                <div className='d-flex flex-gutter align-items-center'>
                  <InputTemplate
                    inputName='patronymic'
                    type='text'
                    fieldWidth='w-50'
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PATRONYMIC'})}
                    required={!values.noPatronymic}
                    addSymbol={
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-3'
                      />
                    }
                  />
                  <PatronymicCheckbox
                    setFieldValue={setFieldValue}
                    title={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                    })}
                    value={values.noPatronymic}
                    addContClass='w-50'
                  />
                </div>
                {/* <div className='mb-5'>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-2 col-form-label fw-bold fs-6 
                      required
                     '
                    >
                      <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                    </label>
                    <div className='col-lg-9'>
                      <ReceiverType values={values} />
                    </div>
                  </div>
                </div>{' '} */}
                <div className='d-flex flex-wrap'>
                  {' '}
                  <FileInput
                    inputId='add-receiver-form'
                    inputName='document'
                    // required={values.receiverType.includes(1) || values.receiverType.includes(2)}
                    title={
                      intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE'}) + ':'
                    }
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: currPassport.documentUrl,
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    simpleDeleteFunction={setCurrPassport}
                    placeholder={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                    })}
                    notification={{
                      bool: true,
                      text: intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                      }),
                      borderTop: false,
                    }}
                    loading={imageIsLoading}
                  />
                </div>
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>

            <div className='p-3'>
              <div className='w-100'>
                <div className='fv-row d-flex flex-gutter'>
                  <InputTemplate
                    inputName='phoneNumber'
                    required={true}
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PHONE_LABEL'})}
                    type='tel'
                    fieldWidth='w-50'
                    setFieldValue={setFieldValue}
                    value={values.phoneNumber}
                    custom={
                      <ReceiverContact
                        inputName='phoneNumber'
                        setFieldValue={setFieldValue}
                        value={values.phoneNumber || ''}
                        // setDisableSubmit={setDisableSubmit}
                      />
                    }
                  />

                  {/* {values.receiverType.includes(2) && ( */}
                  <InputTemplate
                    inputName='email'
                    type='mail'
                    title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.EMAIL_LABEL'})}
                    fieldWidth='w-50'
                  />
                  {/* )} */}
                </div>
                <div className='fv-row'>
                  {values.receiverType.includes(2) ? (
                    <>
                      <div className='fv-row mb-10'>
                        <p className='required fs-5 fw-bold mb-7'>
                          <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DEL_TYPE_LABEL' />
                        </p>
                        <InputTemplate
                          inputName='typeOfDelivery'
                          required={false}
                          type='radio'
                          value={1}
                          title={intl.formatMessage({
                            id: 'RECEIVERS.ADD_REC_MODAL.ADDRESS_DELIVERY',
                          })}
                          checkboxProps={{
                            checked: values.typeOfDelivery === 1,
                            disabled: false,
                            setFieldValue: setFieldValue,
                            value: 1,
                            inputName: 'typeOfDelivery',
                            type: 'radio',
                          }}
                        />
                        <InputTemplate
                          inputName='typeOfDelivery'
                          required={false}
                          type='radio'
                          value={2}
                          title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NP_DELIVERY'})}
                          checkboxProps={{
                            checked: values.typeOfDelivery === 2,
                            disabled: false,
                            setFieldValue: setFieldValue,
                            value: 2,
                            inputName: 'typeOfDelivery',
                            type: 'radio',
                          }}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='typeOfDelivery' />
                        </div>
                      </div>
                      <div>
                        {+values.typeOfDelivery === 1 && (
                          <AddressShipment
                            setFieldValue={setFieldValue}
                            // @ts-ignore
                            values={filterValues(
                              [
                                'apartment',
                                'city',
                                'houseLetter',
                                'houseNumber',
                                'mainAddress',
                                'office',
                                'premisesType',
                                'street',
                                'typeOfDelivery',
                              ],
                              values
                            )}
                            editing={false}
                          />
                        )}
                        {+values.typeOfDelivery === 2 && (
                          <PostShipment
                            // @ts-ignore

                            values={filterValues(
                              [
                                'city',
                                'mainAddress',
                                'mainOfficeCity',
                                'mainOfficeNumber',
                                'reserveOfficeCity',
                                'reserveOfficeNumber',
                                'typeOfDelivery',
                              ],
                              values
                            )}
                            editing={false}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <AddressShipment
                      // @ts-ignore

                      values={filterValues(
                        [
                          'apartment',
                          'city',
                          'houseLetter',
                          'houseNumber',
                          'mainAddress',
                          'office',
                          'premisesType',
                          'street',
                          'typeOfDelivery',
                        ],
                        values
                      )}
                      editing={false}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {AddReceiverModalForm}
