import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {establishOwnerFromDomain} from '../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {TelegramTranslationsQueryResponse, ITelegramTranslation} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const CHAT_TR_URL = `/telegram_bot_translations`
const BASE_URL = `${API_URL}${CHAT_TR_URL}`

const getTranslations = (query: string): Promise<TelegramTranslationsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?website=${establishOwnerFromDomain()}&${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<TelegramTranslationsQueryResponse>) => {
      return d.data
    })
}

const getTranslationByKey = async (key: string): Promise<ITelegramTranslation | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(
    `${BASE_URL}?website=${establishOwnerFromDomain()}&keyName=${key}`,
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  return response.data
}

const editTranslation = (
  translation: Partial<ITelegramTranslation>
): Promise<ITelegramTranslation | undefined> => {
  return axios
    .post(`${BASE_URL}`, translation)
    .then((response: AxiosResponse<Response<ITelegramTranslation>>) => response.data)
    .then((response: Response<ITelegramTranslation>) => response.data)
}

export {getTranslations, getTranslationByKey, editTranslation}
