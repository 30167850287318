/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {IShipment} from '../../../../core/_models/_models'

type Props = {
  shipment: IShipment
}

const ShipmentDateCell = ({shipment}: Props) => {
  return (
    <div className='p-1 fs-7 S fw-semobold d-flex flex-column'>
      <span>
        <FormattedDate year='numeric' month='numeric' day='numeric' value={shipment.createdAt} />
      </span>
      <span>
        {' '}
        <FormattedTime
          value={new Date(shipment.createdAt)}
          hour='numeric'
          minute='numeric'
          second='numeric'
        />
      </span>
    </div>
  )
}

export {ShipmentDateCell}
