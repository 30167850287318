import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
} from '../../../../_metronic/helpers'

import {useEffect} from 'react'
import {PollsTable} from './table/PollsTable'
import {PollsListHeader} from './components/header/PollsListHeader'
import CreatePollModal from '../_modals/CreatePollModal'

const PollsList = () => {
  const {filterUsers, setItemIdForUpdate, itemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.POLLS_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          {/* <FormattedMessage id='DELIVERY_TYPES.PAGE_TITLE' /> */}
          Опитування
        </h3>
        <button type='button' className='btn btn-sm btn-primary' onClick={openAddModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {/* <FormattedMessage id='DELIVERY_TYPES.ADD_TYPE' /> */}
          Створити опитування
        </button>
      </div>
      <KTCard>
        <PollsListHeader />
        <PollsTable />
        {itemIdForUpdate !== undefined && <CreatePollModal />}
      </KTCard>
    </>
  )
}

const PollsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PollsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PollsListWrapper}
