const dateToHoursMinsSeconds = (createdAt: string, endedAt: string | Date) => {
  const startDate = new Date(createdAt)
  const endDate = new Date(endedAt)

  const timeDifferenceInMS = (endDate.getTime() - startDate.getTime()) / 1000

  const totalHours = String(Math.floor(timeDifferenceInMS / 3600)).padStart(2, '0')
  const remainingSeconds = timeDifferenceInMS % 3600
  const minutes = String(Math.floor(remainingSeconds / 60)).padStart(2, '0')
  const seconds = String(Math.floor(remainingSeconds % 60)).padStart(2, '0')
  return `${totalHours}:${minutes}:${seconds}`
}

export default dateToHoursMinsSeconds
