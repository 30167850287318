// @ts-nocheck
import {Column} from 'react-table'
import {CategoryActionsCell} from './CategoryActionsCell'
import {CategorySelectionCell} from './selection/CategorySelectionCell'
import {CategoryCustomHeader} from './CategoryCustomHeader'
import {CategorySelectionHeader} from './selection/CategorySelectionHeader'
import {CategoryNameCell} from './CategoryNameCell'

import {CategoryStatusCell} from './CategoryStatusCell'
import {ICategoryForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/listsHelpers'
import {CategoryDirectionsCell} from './CategoryDirectionsCell'
import {CategoryFeeCell} from './CategoryFeeCell'

const categoriesColumns: ReadonlyArray<Column<ICategoryForm>> = [
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Название' className='min-w-175px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CategoryNameCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Направления' className='min-w-175px' />
    ),
    id: 'directions',
    Cell: ({...props}) => <CategoryDirectionsCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} className='min-w-50px' title='Статус' />
    ),
    id: 'status',
    Cell: ({...props}) => <CategoryStatusCell category={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CategoryCustomHeader tableProps={props} title='Действия' className='min-w-100px  text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CategoryActionsCell category={props.data[props.row.index]} />,
  },
]

export {categoriesColumns}
