import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWebsite} from '../../../websites/core/_models'
import {IPollAnswerTranslation, IPollFormQuestion, IPollQuestion} from '../_models'
import {transformFormQuestionsForCreateApi} from './transformQuestions'

export const integrateEditedTranslations = (
  originalQuestions: IPollQuestion[],
  editedQuestions: IPollFormQuestion[],
  locale: string,
  website: IBasicSelect & Partial<IWebsite>
): IPollQuestion[] => {
  const newQuestions = editedQuestions.filter((i) => !i.id)

  const transformedQuestions = originalQuestions
    .filter((i) => editedQuestions.find((edited) => i.id === edited.id))
    .map((originalQuestion, index) => {
      const editedQuestion = editedQuestions[index]

      originalQuestion.translations[locale] = {
        question: editedQuestion.question,
      }

      originalQuestion.allowOwnAnswer = editedQuestion.allowOwnAnswer

      const originalAnswersById = new Map(
        originalQuestion.answers.map((answer) => [answer.id, answer])
      )

      originalQuestion.answers = editedQuestion.answers.map((editedAnswer) => {
        const originalAnswer = originalAnswersById.get(editedAnswer.id) || {
          translations: {[locale]: {answer: ''}},
        }

        return {
          ...originalAnswer,
          answer: editedAnswer.answer,
          image: editedAnswer.image,
          translations: originalAnswer?.id
            ? {
                ...originalAnswer.translations,
                [locale]: {
                  answer: editedAnswer.answer,
                },
              }
            : website?.enabledLocales?.reduce<IPollAnswerTranslation>((acc, locale) => {
                acc[locale] = {answer: editedAnswer.answer}
                return acc
              }, {}) || {},
        }
      })

      delete originalQuestion.question

      return originalQuestion
    })

  return [...transformedQuestions, ...transformFormQuestionsForCreateApi(newQuestions, website)]
}
