import {FormattedMessage} from 'react-intl'
import {IReceiverLog} from '../../../receivers-list/core/_models'
import {ReceiverLogsItem} from './ReceiverLogsItem'

interface Props {
  logs: IReceiverLog[]
}

export function ReceiverLogs({logs}: Props) {
  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-190px'>
                  <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_DATE_COLUMN' />
                </th>

                <th className='min-w-100px mw-50'>
                  <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_COLUMN' />
                </th>
                <th className='min-w-100px'>
                  <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.AUTHOR_COLUMN' />
                </th>
              </tr>
            </thead>

            <tbody>
              {logs.map((item, index) => (
                <ReceiverLogsItem key={index} log={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
