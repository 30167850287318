import {useEffect} from 'react'
import {useQueries, useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCountries} from '../countries/countries-list/core/_requests'
// import CourierPage from './courier-page/CourierPage'
import {CouriersListWrapper} from './couriers-list/CouriersList'
import CourierPage from './courier-page/CourierPage'
import {couriersApi} from './core/_requests'
import {directionsApi} from '../directions/core/_requests'

const CouriersPage = () => {
  const location = useLocation()
  useQueries([
    {queryKey: [QUERIES.COUNTRIES_LIST], queryFn: () => getCountries('filter[receive]=true')},
    {queryKey: [QUERIES.COURIER_TYPES], queryFn: couriersApi.fetchCourierTypes},
    {queryKey: [QUERIES.COUNTRY_ABBR_LIST], queryFn: directionsApi.fetchDirectionCountriesList},
  ])

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <CouriersListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <CourierPage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/couriers/list' />} />
    </Routes>
  )
}

export default CouriersPage
