import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {ChatActionsCell} from './cells/ChatActionsCell'
import {ChatItemCell} from './cells/ChatItemCell'
import {ChatStatusCell} from './cells/ChatStatusCell'
import {IChat} from '../../../core/_models'
import {ChatUserCell} from './cells/ChatUserCell'
import {ChatResponseCell} from './cells/ChatResponseCell'

const categoriesColumns: ReadonlyArray<Column<IChat>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'CHATS_COLUMNS_CHAT'} className='min-w-125px' />
    ),
    id: 'chat',
    Cell: ({...props}) => <ChatItemCell chat={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'CHATS_COLUMNS_USER'} className='min-w-125px' />
    ),
    id: 'user',
    Cell: ({...props}) => <ChatUserCell chat={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'CHATS_COLUMNS_STATUS'} className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => <ChatStatusCell chat={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'CHATS_COLUMNS_RESPONSE'} className='min-w-150px' />
    ),
    id: 'response',
    Cell: ({...props}) => <ChatResponseCell chat={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'COLUMNS_ACTIONS'} className='min-w-120px text-end' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ChatActionsCell chat={props.data[props.row.index]} />,
  },
]

export {categoriesColumns}
