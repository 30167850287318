import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {selectDebounce} from '../../../../../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {getUsers} from '../../../../../../../users/core/_requests'
import {IUserSelect} from '../../../../../../../users/core/_models'
import {ClientSelect} from '../../../../../../../fulfillment/receivers/elements/ClientSelect'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const UsersFilter = ({savedFilter, setSavedFilter}: TProps) => {
  const intl = useIntl()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IUserSelect[]>([])

  const filterUsers = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      getUsers('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                value: item.externalId || item.id || '',
                label:
                  (item.externalId ? item.externalId + ' ' : '') +
                  (item.lastName ? item.lastName + ' ' : '') +
                  (item.firstName ? item.firstName : ''),
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getUsers]
  )

  useEffect(() => {
    filterUsers()
  }, [])


  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    setSavedFilter((prev) => {
      return {...prev, user: selectedValue}
    })
  }

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column
      
       mb-4`}
    >
      <ClientSelect
        setUsersLoading={setResultsLoading}
        setUsersResults={setResults}
        options={results}
        isLoading={resultsLoading}
        onInputChange={filterUsers}
        onChange={handleOnChange}
        widthClassName='w-100'
        currentValue={savedFilter.user}
      />
    </div>
  )
}

export default UsersFilter
