import axios from 'axios'
import {establishOwnerFromDomain} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {User} from '../../../pages/users/core/_models'
import {AuthModel} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

export const GET_USER = () => `${API_URL}/profile`
export const LOGIN_URL = () => `${API_URL}/login_check`
export const REGISTER_URL = () => `${API_URL}/register`
export const REQUEST_PASSWORD_URL = () => `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string, lang: string) {
  return axios.post<AuthModel>(
    LOGIN_URL(),
    {
      username,
      password,
    },
    {
      headers: {
        'Accept-Language': lang,

        owner: establishOwnerFromDomain(),
      },
    }
  )
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  phoneNumber: string
  // password_confirmation: string
) {
  return axios.post(REGISTER_URL(), {
    email,
    firstname,
    lastname,
    password,
    phoneNumber,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL(), {
    email,
  })
}

export function getUser() {
  return axios.get<User>(GET_USER())
}
