import {useListView} from '../core/ListViewProvider'
import {CreateShipmentForm} from './CreateShipmentForm'

export const CreateShipmentFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  if (itemIdForUpdate === null) {
    return <CreateShipmentForm />
  }

  return null
}
