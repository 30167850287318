import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'
import {trackPartials} from '../../../tracks/core/yup/partials'

export const acceptItemSchema = (intl: any) =>
  Yup.object().shape({
    number: Yup.string().when('location?.countryConfig?.parcelIdentByOrderNumber', {
      is: false,
      then: Yup.string().required('Введіть номер'),
    }),
    altNumber: Yup.string(),
    location: object_shape_basic_select.required('Оберіть склад'),
    user: object_shape_basic_select.required('Вкажіть клієнта'),
    prods: trackPartials.prods(intl),
  })
