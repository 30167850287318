import {ListViewProvider, useListView} from './core/ListViewProvider'
import {CategoriesTable} from './table/CategoriesTable'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CategoriesListHeader} from './components/header/CategoriesListHeader'
import {KTCard} from '../../../../_metronic/helpers'
import CreateCategoryModal from './_modals/CreateCategoryModal'

const CategoriesList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Категорії</h3>
      </div>
      <KTCard>
        {' '}
        <CategoriesListHeader />
        <CategoriesTable />
        {itemIdForUpdate !== undefined && <CreateCategoryModal />}
      </KTCard>
    </>
  )
}

const CategoriesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CategoriesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CategoriesListWrapper}
