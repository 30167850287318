import {ErrorMessage, Field} from 'formik'

const Name = ({withTitle = true}) => {
  return (
    <div className='fv-row mb-10'>
      {' '}
      {withTitle && (
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Назва</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Вкажіть назву'
          ></i>
        </label>
      )}
      <Field
        type='text'
        className='form-control form-control-lg form-control-solid'
        name='title'
        placeholder=''
      />
      <div className='text-danger'>
        <ErrorMessage name='title' />
      </div>
    </div>
  )
}

export default Name
