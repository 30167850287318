import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ICompanyDetails} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const PAGE_URL = `/company`
const BASE_URL = `${API_URL}${PAGE_URL}`

const getCompanyDetails = (): Promise<ICompanyDetails> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ICompanyDetails>) => {
      return d.data
    })
}

const updateCompany = (company: Partial<ICompanyDetails>): Promise<ICompanyDetails | undefined> => {
  return axios.put(BASE_URL, company).then((response) => response.data)
}

export {getCompanyDetails, updateCompany}
