import {Link} from 'react-router-dom'
import {IParcel} from '../../../../core/_models/_models'

type Props = {
  parcel: IParcel
}

const ParcelUserCell = ({parcel}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {parcel?.user?.id ? (
          <Link
            to={`/users/edit/overview/${parcel?.user?.id}`}
            className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
          >
            <span>{parcel?.user?.externalId}</span>
            <span className='text-muted fs-6'>
              {[parcel?.user?.lastName, parcel.user?.firstName, parcel.user?.patronymic].join(' ')}
            </span>
          </Link>
        ) : (
          '-'
        )}
      </p>
    </div>
  )
}

export {ParcelUserCell}
