import {FC} from 'react'
import {ICountry} from '../../../core/_models'

type Props = {
  country: ICountry
}

const CountryIdCell: FC<Props> = ({country}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>
        {!country.parcelIdentByOrderNumber ? 'За номером треку' : 'За номером замовлення'}
      </p>
    </div>
  )
}

export {CountryIdCell}
