/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ProviderHeaderName} from './ProviderHeaderName'
import {ProviderHeaderInfo} from './ProviderHeaderInfo'
import {ProviderHeaderNav} from './ProviderHeaderNav'
import {IPaymentProvider} from '../../core/_models'

interface Props {
  provider: IPaymentProvider
}

const ProviderHeader = ({provider}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/finance/fin008.svg')}
                  alt='Location'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ProviderHeaderName provider={provider} />{' '}
            </div>
          </div>
          <ProviderHeaderInfo provider={provider} />
        </div>

        <ProviderHeaderNav provider={provider} />
      </div>
    </div>
  )
}

export {ProviderHeader}
