import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import {PollResultsTable} from './table/PollResultsTable'

const PollResultsList = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.POLL_RESULTS_BY_ID)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.POLL_RESULTS_BY_ID),
      JSON.stringify(savedFilter)
    )
  }, [])

  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <KTCard>
      <PollResultsTable />
    </KTCard>
  )
}

const PollResultsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PollResultsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PollResultsListWrapper}
