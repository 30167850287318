import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {directionsFuncs} from '../../../_metronic/helpers/custom/funcs/directions'

type TProps = {
  to: string
  from: string
  minified?: boolean
}

const FlagsDirectionItem = ({to, from, minified = false}: TProps) => {
  const flags = directionsFuncs.findFlags(from, to)

  return (
    <li className='FlagsDirectionBar__flags'>
      <img
        src={toAbsoluteUrl(flags.from)}
        width={minified === true ? '18px' : '20px'}
        alt=''
        className={`svg-icon-3 FlagsDirectionBar__flag ${
          minified === true ? 'm-0 rounded-circle' : 'ms-2'
        }`}
      />
      {minified === false && (
        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-4 me-1' />
      )}

      <img
        src={toAbsoluteUrl(flags.to)}
        width={minified === true ? '18px' : '20px'}
        alt=''
        className={`svg-icon-3 FlagsDirectionBar__flag ${
          minified === true ? 'm-0 rounded-circle' : 'ms-2'
        }`}
      />
    </li>
  )
}

export default FlagsDirectionItem
