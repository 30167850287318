import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {UserGroupTab} from './UserGroupTab'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {IUserGroupEdit} from '../user-groups-list/core/_models'
import {UserGroupHeader} from './header/UserGroupHeader'
import {getUserGroupById} from '../user-groups-list/core/_requests'
import {UserGroupNoResults} from './UserGroupNoResults'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const UserGroupPage = ({id}: Props) => {
  const {data: userGroupById} = useQuery(`user-group-${id}`, () => getUserGroupById(id))

  const [currGroup, setCurrGroup] = useState<IUserGroupEdit | undefined>(undefined)

  useEffect(() => {
    if (userGroupById) {
      setCurrGroup(userGroupById)
    }
  }, [userGroupById])

  return (
    <>
      {currGroup && userGroupById ? (
        <Routes>
          <Route
            element={
              <>
                <UserGroupHeader group={currGroup} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`:locale/:id`}
              element={
                <>
                  <UserGroupTab group={currGroup} setCurrGroup={setCurrGroup} />
                </>
              }
            />

            <Route path={`:id`} element={<UserGroupNoResults />} />

            <Route index element={<Navigate to={`edit/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default UserGroupPage
