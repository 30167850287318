/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'
import {Employee} from '../../../core/_models'

type Props = {
  employee: Employee
}

const EmployeeInfoCell: FC<Props> = ({employee}) => {
  const fio = [employee.firstName, employee.lastName, employee.patronymic].filter((s) => s)
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <Link
          to={`/users/edit/overview/${employee.id}`}
          className='text-dark fw-bold text-hover-primary fs-6 mb-1'
          // target='_blank'
          // rel='noopener noreferrer'
        >
          {employee.externalId}
        </Link>{' '}
        <Link
          to={`/users/edit/overview/${employee.id}`}
          className='text-dark fw-bold text-hover-primary fs-6 mb-1'
          // target='_blank'
          // rel='noopener noreferrer'
        >
          <span
            className={`text-muted fw-semobold text-muted d-block fs-7 ${
              employee.enabled === false ? `StatusBar__status ${statusColor(false)}` : ''
            }`}
          >
            {fio.length > 0 ? fio.join(' ') : employee.email}
          </span>
        </Link>
        {employee.directions && (
          <FlagsDirectionBar
            chosenDirections={employee.directions}
            minified={true}
          />
        )}
      </div>
    </div>
  )
}

export {EmployeeInfoCell}
