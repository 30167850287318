import axios from 'axios'
import {Query} from 'react-query'
import {getConfig} from '../../../i18n/Metronici18n'
import {ICityStreet, INovaPoshtaOffice} from '../tsHelpers/locationsHelpers'
import {IAddressCities} from '../tsHelpers/locationsHelpers'

// API URL ELEMENTS
// axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_VEC_URL}`

const API = process.env.REACT_APP_API_PATH

const ADDRESS_ENDPOINT = '/address'

// cities
const getAddressCities = async (city: string, warehouse?: number, big?: number) => {
  const {selectedLang} = getConfig()

  const res = await axios.get(
    `${API}/cities?translations.name=${city}&itemsPerPage=50`,

    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  const reformedData = res.data.data.map((item: IAddressCities) => {
    return {
      value: item.id,
      label: `${item.name} (${item.region.name} ${item.region.type})`,
    }
  })
  return reformedData
}

const getCityById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/city`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// streets
const getCityStreets = async (query: string, cityId: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/streets`,
    {
      search: query,
      city: cityId,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )
  const reformedData = data.map((item: ICityStreet) => {
    return {
      value: item.ref,
      label: item.name,
    }
  })
  return reformedData
}

const getStreetById = async (id: string, city_id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/street`,
    {
      id,
      city_id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// NP offices
const getNovaPoshtaOffices = async (query: string, cityId: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/warehouses`,
    {
      search: query,
      city: cityId,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  const reformedData = data.map((item: INovaPoshtaOffice) => {
    return {value: item.id, label: item.name, big: item.big}
  })

  return reformedData
}

const getWarehouseById = async (id: string) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(
    `${API}${ADDRESS_ENDPOINT}/warehouse`,
    {
      id: id,
    },
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data
}

// countries

const fetchDirectionCountriesList = async () => {
  const {data} = await axios.get(`${API}/public/directions/countries`)
  return data
}

export const locationsApi = {
  getAddressCities,
  getCityStreets,
  getNovaPoshtaOffices,
  fetchDirectionCountriesList,
  getCityById,
  getWarehouseById,
  getStreetById,
}
