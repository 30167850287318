/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IShipment} from '../../core/_models/_models'
import {ShipmentHeaderName} from './ShipmentHeaderName'
import {ShipmentHeaderNav} from './ShipmentHeaderNav'

interface Props {
  shipment: IShipment
}

const ShipmentHeader = ({shipment}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/icons/duotune/maps/map003.svg')} alt='Shipment' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ShipmentHeaderName shipment={shipment} />{' '}
              <div className='d-none d-md-block'>
                {/* <ShipmentHeaderInfo shipment={shipment} /> */}
              </div>
            </div>
          </div>
          <div className='d-md-none'>{/* <ShipmentHeaderInfo shipment={shipment} /> */}</div>
        </div>

        <ShipmentHeaderNav shipment={shipment} />
      </div>
    </div>
  )
}

export {ShipmentHeader}
