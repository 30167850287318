import {Field, Form, Formik, FormikValues} from 'formik'
import {nanoid} from 'nanoid'
import React, {useEffect, useRef, useState} from 'react'
import {
  IBasicSelect,
  ISetFieldValue,
  TBasicImageResponse,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {getUsers} from '../../../../users/core/_requests'
import {errors} from '../../errors'
import {useReceiveShipmentContext} from '../core/ReceiveShipmentContext'
import {receiveShipmentSchema} from '../core/yup'
import {initialContext} from '../core/_contextHelpers'
import {
  FTUpdatePrintQueueParams,
  IGeneralReceiveForm,
  IReceivedItem,
  ISaveResponse,
} from '../core/_models'
import {fulfillmentApi} from '../../_requests'
import {initiateFullScanFromComputer} from '../../funcs/initiateFullScanFromComputer'
import {photoFunction, processPhotoScanProd, scanFunction} from '../../unpack/funcs/scanner'
import {ClientSelect} from '../../modules/ClientSelect'
import Select from 'react-select/dist/declarations/src/Select'
import {GroupBase} from 'react-select'

export function ReceiveShipmentForm() {
  const inits = {
    client: '',
    code: '',
  }
  const {
    receivedItems,
    setReceivedItems,
    setInitiatePrint,
    setPrintQueue,
    printQueue,
    setCurrClient,
    currClient,
    setErrorMessage,
    errorMessage,
    setItemIdForUpdate,
    itemForUpdate,
    customUser,
    setCustomUser,
  } = useReceiveShipmentContext()
  const parcelNumberRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    refClearAndFocus(parcelNumberRef)
  }, [])

  const updateCurrClient = (id: string | number, creds: {firstName: string; lastName: string}) => {
    const clientObj = {
      externalId: id.toString(),
      firstName: creds.firstName,
      lastName: creds.lastName,
    }

    setCurrClient(clientObj)
  }
  const clientSelectRef = useRef<Select<IBasicSelect, false, GroupBase<IBasicSelect>>>(null)

  const hiddenDimensionsRef = useRef<HTMLInputElement>(null)
  const hiddenCellRef = useRef<HTMLInputElement>(null)
  const hiddenPhotoScanProdRef = useRef<HTMLInputElement>(null)

  const [showClientDropdown, setShowClientDropdown] = useState(false)

  // helpers
  const updatePrintQueue: FTUpdatePrintQueueParams = (itemsToSend) => {
    return itemsToSend
      .filter((item) => item.type === 2)
      .map((item) => {
        return {clientId: item.client.externalId.toString(), goodsNumber: item.code}
      })
  }

  const refClearAndFocus = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = ''
      ref.current.focus()
    }
  }

  const findClosestParcel = () => {
    const closestCellIndex = receivedItems.findIndex((item) => item.type === 3)

    let parcelToFind: IReceivedItem[]

    if (closestCellIndex === -1) {
      parcelToFind = receivedItems
    } else {
      parcelToFind = receivedItems.slice(0, closestCellIndex)
    }
    const parcel = parcelToFind.find((item) => item.type === 1)

    return parcel
  }
  const codeClearAndFocus = () => {
    refClearAndFocus(parcelNumberRef)
  }

  // code scanning
  const handleCode = async (values: IGeneralReceiveForm, setFieldValue: ISetFieldValue) => {
    // setInitiatePrint(false)
    refClearAndFocus(parcelNumberRef)

    setErrorMessage('')
    const trimmedCode = values.code.trim()

    if (receivedItems[0] && receivedItems[0].type === 3) {
      setPrintQueue(initialContext.printQueue)
    }
    if (receivedItems.some((item) => item.code.trim() === trimmedCode && !item.addedToCluster)) {
      // open editor
      // refClearAndFocus(parcelNumberRef)
      setItemIdForUpdate(trimmedCode)
    } else {
      // handle user
      let userId = trimmedCode.slice(4, -3)
      if (userId.includes('-')) {
        userId = trimmedCode.split('-')[0].slice(4, -3)
      }

      if (
        currClient.externalId !== '' &&
        currClient.externalId !== userId &&
        receivedItems.length > 0 &&
        receivedItems[0].type !== 3 &&
        customUser === false
      ) {
        setErrorMessage(errors.DIFFERENT_CLIENTS)
        return
      }

      const userCredentials = await fulfillmentApi
        .getUserByExternalId(userId)
        .catch((err) => console.log(err))
      if (!userCredentials && customUser === false) {
        setCurrClient(initialContext.currClient)
        setShowClientDropdown(true)
        setErrorMessage(errors.INDICATE_RECEIVER)
      } else if (userCredentials || customUser === true) {
        // handle the added code if user is okay
        setErrorMessage('')
        setFieldValue('code', '')
        setShowClientDropdown(false)

        let credsString
        if (userCredentials) {
          credsString =
            userId + ' ' + userCredentials.firstName || '' + ' ' + userCredentials.lastName || ''

          updateCurrClient(userId, userCredentials)
        } else if (customUser === true) {
          credsString =
            (currClient.externalId !== '' ? currClient.externalId + ' ' : '') +
            (currClient.firstName !== '' ? currClient.firstName + ' ' : '') +
            (currClient.lastName !== '' ? currClient.lastName : '')

          updateCurrClient(currClient.externalId, currClient)
        }

        setFieldValue('client', credsString)

        const res = await fulfillmentApi.identifyFulfItem(trimmedCode)

        let newItem: IReceivedItem
        if (res.data) {
          const transformedPhotos = res.data.images.map((img: TBasicImageResponse) => img.imageUrl)
          newItem = {
            ...res.data,
            client: currClient,
            photos: transformedPhotos,
            type: res.type === 'parcel' ? 1 : 2,
          }
          if (!res.data.weight && !res.data.height && !res.data.length && !res.data.width) {
            const updInfo = await initiateFullScanFromComputer(res.data.code, currClient)
            newItem.weight = updInfo.weight
            newItem.length = updInfo.length
            newItem.width = updInfo.width
            newItem.height = updInfo.height
            newItem.photos = [...updInfo.photos, ...transformedPhotos]
          }
        } else {
          newItem = await initiateFullScanFromComputer(trimmedCode, currClient)
        }
        setReceivedItems((prev) => [newItem, ...prev])
      }
    }
  }

  const [usersLoading, setUsersLoading] = useState(false)
  const [usersResults, setUsersResults] = useState<IBasicSelect[]>([])

  const filterUsers = async (query: string) => {
    setUsersLoading(true)
    await getUsers(`search=${query}`)
      .then((res) => {
        const transformed = res?.data?.map((item) => {
          return {
            ...item,
            value: item.externalId || '',
            label: `${item.externalId} ${item.lastName || ''} ${item.firstName}` || '',
          }
        })
        setUsersResults(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  const handleParcel = async (values: IGeneralReceiveForm, actions: FormikValues) => {
    handleCode(values, actions.setFieldValue)
  }

  useEffect(() => {
    filterUsers('')
  }, [])

  useEffect(() => {
    const saveItemsToCell = async (itemsToSave: IReceivedItem[]) => {
      const responses = []
      for (const item of itemsToSave) {
        const {code, height, length, photos, weight, width, cell, type} = item
        const itemToSend: any = {
          weight: +weight,
          width: +width,
          length: +length,
          height: +height,
          cell: cell?.replace('#', '') || '',
          images: photos
            .filter((item) => item !== null)
            .map((item) => {
              // if there is a null, it means that getImageLink received a type-3-cell item
              return {image: item.replace('/uploads/parcel_image/', '')}
            }),
        }

        let res

        switch (type) {
          case 1:
            itemToSend.code = code
            itemToSend.user = currClient
            res = await fulfillmentApi.addParcel(itemToSend)
            break
          case 2:
            itemToSend.parcel = {
              code: code.split('-')[0],
            }

            if (code.split('-')[1].length === 3) {
              itemToSend.code = code
            }
            res = await fulfillmentApi.addParcelProduct(itemToSend)
            break
        }

        if (!res) {
          // responses.push('error')
          //push the data from request into the toResend array
          //  break
        } else {
          responses.push(res)
          // print sticker
        }
      }

      return responses
    }

    const submitAndClear = async function (e: KeyboardEvent) {
      if (e.key.toLowerCase() === 'enter') {
        e.preventDefault()
        setItemIdForUpdate(null)
        const firstItemIsACellNumber = receivedItems.length > 0 && receivedItems[0].type === 3
        const closestCellIndex = receivedItems.findIndex((item) => item.type === 3)
        const someParcels = receivedItems.slice(0, closestCellIndex).some((item) => item.type === 1)
        const noParcelsBeforeCell = closestCellIndex !== -1 && !someParcels

        if (firstItemIsACellNumber || noParcelsBeforeCell) {
          setErrorMessage(errors.ADD_PARCEL)
        } else {
          setErrorMessage('')
          const savedCellId = nanoid()
          const savedCell = {
            cell: hiddenCellRef.current?.value,
            code: savedCellId,
            client: initialContext.currClient,
            weight: '',
            length: '',
            height: '',
            width: '',
            photos: [],
            type: 3,
            addedToCluster: true,
          }

          const previousCellIndex = receivedItems.findIndex((item) => item.type === 3)

          const sliceItemsToSend =
            previousCellIndex !== -1 ? receivedItems.slice(0, previousCellIndex) : receivedItems

          const itemsToSend = sliceItemsToSend.map((item) => {
            return {...item, cell: hiddenCellRef.current?.value}
          })

          const savingFinished: ISaveResponse[] = await saveItemsToCell(itemsToSend)

          let updatedSavingFinished: IReceivedItem[] = []
          setReceivedItems((prev) => {
            const oldClusters = prev.filter((item) => item.addedToCluster === true)
            updatedSavingFinished = savingFinished.map((item) => {
              return {
                ...item,
                addedToCluster: true,
                type: item.parcel ? 2 : 1,
                photos: item.images.map((item) => item.imageUrl),
                client: {...currClient},
              }
            })

            const newReceivedItems = [savedCell, ...updatedSavingFinished, ...oldClusters]
            return newReceivedItems
          })

          // currently cell gets duplicated, but not prods or parcels, it was about identical keys/ids

          if (itemsToSend.some((item) => item.type === 2)) {
            const prods = updatePrintQueue(updatedSavingFinished)
            setPrintQueue(prods)
            setInitiatePrint(true)
          }
          // }
        }

        // refClearAndFocus(hiddenCellRef)
        setCustomUser(false)
        setCurrClient(initialContext.currClient)
        setInitiatePrint(false)
        refClearAndFocus(parcelNumberRef)
      } else {
        return
      }
    }

    const scannerListener = async function (event: KeyboardEvent) {
      const scanComb = event.ctrlKey && event.altKey && event.keyCode === 220
      const photoComb = event.ctrlKey && event.altKey && event.key === '/'
      const photoScanComb = event.ctrlKey && event.altKey && event.key === '|'
      const photoScanProdComb = event.ctrlKey && event.altKey && event.key === '*'
      const cellScanComb = event.key === '#'

      if (scanComb || photoComb || photoScanComb) {
        if (receivedItems.length > 0) {
          if (itemForUpdate === null && receivedItems[0].type !== 3) {
            setItemIdForUpdate(receivedItems[0].code)
          }
          // scan
          if (scanComb) {
            refClearAndFocus(hiddenDimensionsRef)
            hiddenDimensionsRef.current?.addEventListener('keydown', (e) =>
              scanFunction(
                e,
                hiddenDimensionsRef,
                setErrorMessage,
                receivedItems[0],
                setItemIdForUpdate,
                setReceivedItems
              )
            )

            return () =>
              hiddenDimensionsRef.current?.removeEventListener('keydown', (e) =>
                scanFunction(
                  e,
                  hiddenDimensionsRef,
                  setErrorMessage,
                  receivedItems[0],
                  setItemIdForUpdate,
                  setReceivedItems
                )
              )
          }
          // photo
          else if (photoComb) {
            photoFunction(receivedItems[0], setItemIdForUpdate, setReceivedItems, codeClearAndFocus)
          }
          // photoscan
          else if (photoScanComb) {
            photoFunction(receivedItems[0], setItemIdForUpdate, setReceivedItems, codeClearAndFocus)
            refClearAndFocus(hiddenDimensionsRef)
            hiddenDimensionsRef.current?.addEventListener('keydown', (e) =>
              scanFunction(
                e,
                hiddenDimensionsRef,
                setErrorMessage,
                receivedItems[0],
                setItemIdForUpdate,
                setReceivedItems
              )
            )

            return () =>
              hiddenDimensionsRef.current?.removeEventListener('keydown', (e) =>
                scanFunction(
                  e,
                  hiddenDimensionsRef,
                  setErrorMessage,
                  receivedItems[0],
                  setItemIdForUpdate,
                  setReceivedItems
                )
              )
          } else {
            return
          }
        } else {
          setErrorMessage(errors.ADD_PARCEL)
        }
      } // photoscanprod
      else if (photoScanProdComb) {
        setItemIdForUpdate(null)
        if (receivedItems.length > 0) {
          if (receivedItems[0].type === 3) {
            setErrorMessage(errors.ADD_PARCEL)
            // return
          } else {
            const closestParcel = findClosestParcel()

            if (!closestParcel) {
              setErrorMessage(errors.NO_PARCELS_FOR_PROD)
              refClearAndFocus(parcelNumberRef)
            } else {
              setErrorMessage('')
              refClearAndFocus(hiddenPhotoScanProdRef)

              hiddenPhotoScanProdRef.current?.addEventListener('keydown', (e) =>
                processPhotoScanProd(
                  e,
                  hiddenPhotoScanProdRef,
                  closestParcel,
                  setErrorMessage,
                  setReceivedItems,
                  codeClearAndFocus,
                  receivedItems
                )
              )

              return () =>
                hiddenPhotoScanProdRef.current?.removeEventListener('keydown', (e) =>
                  processPhotoScanProd(
                    e,
                    hiddenPhotoScanProdRef,
                    closestParcel,
                    setErrorMessage,
                    setReceivedItems,
                    codeClearAndFocus,
                    receivedItems
                  )
                )
            }
          }
        } else {
          setErrorMessage(errors.ADD_PARCEL)
        }
        refClearAndFocus(parcelNumberRef)
      }
      // scan cell
      else if (cellScanComb) {
        refClearAndFocus(hiddenCellRef)
        hiddenCellRef.current?.addEventListener('keydown', submitAndClear)

        return () => hiddenCellRef.current?.removeEventListener('keydown', submitAndClear)
      } else {
        return
      }
    }

    document.addEventListener('keydown', scannerListener)
    return () => {
      document.removeEventListener('keydown', scannerListener)
    }
  }, [receivedItems, receivedItems.length])

  return (
    <>
      <div className='d-flex flex-column-reverse flex-col-rev-gutter'>
        <input
          type='text'
          name='hiddenDimensionsRef'
          className='visually-hidden'
          id='hidden-ref'
          defaultValue={''}
          ref={hiddenDimensionsRef}
        />
        <input
          type='text'
          name='hiddenPhotoScanProdRef'
          className='visually-hidden'
          id='hidden-prod-ref'
          defaultValue={''}
          ref={hiddenPhotoScanProdRef}
        />

        <input
          type='text'
          name='hiddenCellRef'
          className='visually-hidden'
          id='hidden-cell-ref'
          defaultValue={''}
          ref={hiddenCellRef}
        />
      </div>{' '}
      <div className={`card mb-5 mb-xl-10 `} id='fulfillment-accept-form'>
        {' '}
        <div className='mt-3 fw-bold text-danger fs-6 text-center'>
          {' '}
          {errorMessage.split('/').map((item, index) => (
            <p className='mb-0' key={index}>
              {item}
            </p>
          ))}
        </div>{' '}
        <Formik
          validationSchema={receiveShipmentSchema}
          initialValues={inits}
          onSubmit={handleParcel}
          enableReinitialize={true}
        >
          {({values, errors, touched, setFieldValue}) => (
            <Form noValidate id='accept-parcel-form'>
              <div className='card-body pt-5 pb-8'>
                <div className='d-flex justify-content-between  flex-gutter align-items-center fs-7'>
                  {/* <div className='d-flex align-items-start flex-gutter fw-bolder fs-7'> */}
                  <div className={`fv-row d-flex w-75 flex-column`}>
                    <label
                      className={`d-flex position-relative fs-6 row-reverse justify-content-left fw-bold`}
                    >
                      <Field
                        type='text'
                        autoComplete='off'
                        className={`form-control form-control-lg form-control-solid fs-7`}
                        name='code'
                        placeholder='Номер'
                        innerRef={parcelNumberRef}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                          (e.target.placeholder = '')
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.placeholder = 'Номер'
                        }}
                      />
                    </label>
                  </div>

                  {/* {showClientDropdown === true ? ( */}
                  <ClientSelect
                    selectRef={clientSelectRef}
                    items={receivedItems}
                    setUsersLoading={setUsersLoading}
                    setUsersResults={setUsersResults}
                    options={usersResults}
                    isLoading={usersLoading}
                    updateCurrClient={updateCurrClient}
                    currentValue={{
                      ...currClient,
                      value: currClient.externalId || '',
                      label:
                        `${currClient.lastName || ''} ${currClient.firstName} ${
                          currClient.externalId
                            ? `(${currClient.externalId || currClient.externalId})`
                            : ''
                        }` || '',
                    }}
                  />
                  {/* ) : (
                    <InputTemplate
                      
                      required={true}
                      type='text'
                      placeholder='Клієнт'
                      inputName='client'
                      formValues={values}
                      fieldWidth='w-75'
                      inputFontSize='fs-7'
                      labelMargin='mb-0'
                      containerMarginBottom={false}
                      backgroundClass={errors.client && touched.client ? 'error-border' : ''}
                      errorMessage={false}
                    />
                  )} */}
                  {/* </div> */}

                  <div className=''>
                    <button type='submit' className='btn btn-sm btn-primary me-3'>
                      Прийняти
                    </button>
                  </div>
                </div>
              </div>{' '}
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
