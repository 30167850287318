import {Column} from 'react-table'
import {StoreCustomHeader} from './StoreCustomHeader'
import {IStore} from '../../../core/_models'
import {StoreDateCell} from './cells/StoreDateCell'
import {StoreActionsCell} from './cells/StoreActionsCell'
import {StoreTitleCell} from './cells/StoreTitleCell'
import {StoreWebsitesCell} from './cells/StoreWebsitesCell'
import {StoreBuyoutsCell} from './cells/StoreBuyoutsCell'

const storesColumns: ReadonlyArray<Column<IStore>> = [
  {
    Header: (props) => (
      <StoreCustomHeader
        tableProps={props}
        title={'STORES_COLUMNS_TITLE'}
        className='min-w-125px'
      />
    ),
    id: 'title',
    Cell: ({...props}) => <StoreTitleCell store={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader
        tableProps={props}
        title={'STORES_COLUMNS_WEBSITES'}
        className='min-w-150px'
      />
    ),
    id: 'websites',
    Cell: ({...props}) => <StoreWebsitesCell store={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader
        tableProps={props}
        title={'STORES_COLUMNS_CREATED'}
        className='min-w-50px'
      />
    ),
    id: 'date',
    Cell: ({...props}) => <StoreDateCell store={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader
        tableProps={props}
        title={'STORES_COLUMNS_BUYOUTS'}
        className='min-w-50px'
      />
    ),
    id: 'buyouts',
    Cell: ({...props}) => <StoreBuyoutsCell store={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <StoreCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <StoreActionsCell store={props.data[props.row.index]} />,
  },
]

export {storesColumns}
