import {useReceiveShipmentContext} from '../../core/ReceiveShipmentContext'
import {setFulfPrintMode} from './helpers/printModeLS'

export const PrintModeSelect = () => {
  const {printMode} = useReceiveShipmentContext()
  return (
    <label className='form-check form-check-sm form-check-custom form-check-solid mb-5'>
      <select
        className='form-select'
        defaultValue={printMode}
        onChange={(e) => {
          setFulfPrintMode(e.target.value)
        }}
      >
        <option value='1'>Почерговий друк</option>
        <option value='2'>Друк при збереженні</option>
        <option value='3'>Без друку</option>
      </select>
    </label>
  )
}
