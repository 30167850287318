import {Response} from '../../../../../_metronic/helpers'

export type IStatusTranslation = Record<string, Record<string, string>>

export interface IStatusForm {
  name: string
  active: number
}

export interface IStatus {
  value: string
  showClient: boolean
  description: string
  entityTypes: {
    buyout: boolean
    charge: boolean
    order: boolean
    payment: boolean
  }
  name: string
  // get by value - additional entries
  translations: IStatusTranslation
}

export type StatusesQueryResponse = Response<Array<IStatus>>

export const statusesLocales = ['uk', 'ru']
