import {Column} from 'react-table'
import {ReceiverCustomHeader} from './ReceiverCustomHeader'
import {Receiver} from '../../core/_models'
import {ReceiverCityCell} from './cells/ReceiverCityCell'
import {ReceiverStatusCell} from './cells/ReceiverStatusCell'
import {ReceiverTypeCell} from './cells/ReceiverTypeCell'
import {ReceiverAddressCell} from './cells/ReceiverAddressCell'
import {ReceiverActionsCell} from './cells/ReceiverActionsCell'
import {ReceiverNameCell} from './cells/ReceiverNameCell'
import {ReceiverVerifiedCell} from './cells/ReceiverVerifiedCell'
import {ReceiverOwnerCell} from './cells/ReceiverOwnerCell'

const receiversColumns: ReadonlyArray<Column<Receiver>> = [
  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'RECEIVERS_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'id',
    Cell: ({...props}) => <ReceiverNameCell receiver={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'RECEIVERS_COLUMNS_CITY'}
        className='min-w-125px text-center'
      />
    ),
    id: 'city',
    Cell: ({...props}) => <ReceiverCityCell receiver={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'RECEIVERS_COLUMNS_ADDRESS'}
        className='min-w-125px text-center'
      />
    ),
    id: 'address',
    Cell: ({...props}) => <ReceiverAddressCell receiver={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'RECEIVERS_COLUMNS_RECEIVER_TYPE'}
        className='min-w-80px text-center'
      />
    ),
    id: 'receiverType',
    Cell: ({...props}) => <ReceiverTypeCell receiver={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <ReceiverCustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_VERIFIED'}
  //       className='min-w-80px text-center'
  //     />
  //   ),
  //   id: 'verified',
  //   Cell: ({...props}) => <ReceiverVerifiedCell receiver={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => (
  //     <ReceiverCustomHeader
  //       tableProps={props}
  //       title={'RECEIVERS_COLUMNS_STATUS'}
  //       className='min-w-125px text-center'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => <ReceiverStatusCell receiver={props.data[props.row.index]} />,
  // },
  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'RECEIVERS_COLUMNS_OWNER'}
        className='min-w-125px'
      />
    ),
    id: 'user.externalId',
    Cell: ({...props}) => <ReceiverOwnerCell receiver={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ReceiverCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ReceiverActionsCell receiver={props.data[props.row.index]} />,
  },
]

export {receiversColumns}
