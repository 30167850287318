import {useEffect} from 'react'
import {useQueries} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {pollsApi} from './core/_requests'
import PollPage from './poll-page/PollPage'
import {PollsListWrapper} from './polls-list/PollsList'

const PollsPage = () => {
  const {config} = useLayout()
  const location = useLocation()

  useQueries([
    {
      queryKey: [QUERIES.POLL_TYPES_LIST],
      queryFn: pollsApi.getPollTypes,
    },
  ])

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PollsListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <PollPage id={location.pathname.split('/')[location.pathname.split('/').length - 1]} />
          }
        />
      </Route>
      <Route index element={<Navigate to='/polls/list' />} />
    </Routes>
  )
}

export default PollsPage
