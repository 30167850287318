import {FC} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IDeliveryType} from '../../../../core/_models'

type Props = {
  deliveryType: IDeliveryType
}

const DeliveryTypeStatusCell: FC<Props> = ({deliveryType}) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()
  return (
    <div>
      <StatusBar
        status={{
          trueOption: intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'}),
          falseOption: intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'}),
          currentBooleanValue: deliveryType.enabled,
        }}
        allowDelete={deliveryType.allowDelete}
        id={deliveryType.id}
        refetch={refetch}
      />
    </div>
  )
}

export {DeliveryTypeStatusCell}
