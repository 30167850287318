import StatisticsColumn from './StatisticsColumn'

export type TChatStatRowProps = {cols: TChatStatColProps[]}
export type TChatStatColProps = {
  label: string
  color: string // warning, danger, primary, etc.
  icon: string
  count?: number
  addContClass?: string
  link?: string
  time?: boolean
}

type TProps = {row: TChatStatRowProps}
const StatisticsRow = ({row}: TProps) => {
  return (
    <div className='row g-0'>
      {row.cols.map((col, index) => (
        <StatisticsColumn col={col} key={index} />
      ))}
    </div>
  )
}

export default StatisticsRow
