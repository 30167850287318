/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {QUERIES} from '../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {Formik, Form, FormikValues} from 'formik'
import {IDeliveryTypeForm} from '../core/_models'
import {deliveryTypesApi} from '../core/_requests'
import ScrollToFieldError from './ScrollToFieldError'
import {CreateDeliveryTypeModalHeader} from './CreateDeliveryTypeModalHeader'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {createDeliveryTypeSchema} from '../core/yup'
import {getDataForDeliveryTypeToSend} from '../../../../_metronic/helpers/custom/funcs/delivery-types'
import DelTypeCalculation from '../elements/DelTypeCalculation'
import VolWeightCalculation from '../elements/VolWeightCalculation'
import OversizeAddFee from '../elements/OversizeAddFee'
import VolWeight from '../elements/VolWeight'
import Direction from '../elements/Direction'
import Categories from '../elements/Categories'
import Name from '../elements/Name'
import Image from '../elements/Image'
import DeliveryTime from '../elements/DeliveryTime'
import PriceRangeType from '../elements/PriceRangeType'

export type TDelTwoFields = {weight: number; specificWeightPrice: number}
const CreateDeliveryTypeForm = () => {
  // queries and misc. data
  const queryClient = useQueryClient()
  const volumetricWeightPriceMethods: Record<string, string> =
    queryClient.getQueryData([QUERIES.VOL_WEIGHT_PRICE_METHODS]) || {}

  // context
  const {refetch} = useQueryResponse()
  // const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  // states
  const [isLoading, setIsLoading] = useState(false)
  const [deliveryTwoFields, setDeliveryTwoFields] = useState<TDelTwoFields[]>([
    {weight: 1, specificWeightPrice: 0},
  ])
  const [createResponse, setCreateResponse] = useState('')

  //  the rest
  const inits: IDeliveryTypeForm = {
    title: '',
    avatar: '',
    direction: '',
    deliveryTime: '',
    type: '1',
    price: '',
    max_weight: '',
    deliveryTwoItems: [],
    priceRangeType: 0,
    volumetricWeightType: '0',
    volumetricWeightToWeightRatio: '',
    volumetricWeightPriceMethod: Object.keys(volumetricWeightPriceMethods)[0],
    volumetricWeightDivisor: '',
    volumetricWeightCombinedRate: '',
    minimum_value: 0,
    palletLength: '',
    palletOversizeFee: '',
    palletWidth: '',
    categories: [],
  }

  const mutation = useMutation(deliveryTypesApi.addDeliveryType, {
    onSuccess: () => {
      refetch()
    },
  })

  const handleSubmit = (values: IDeliveryTypeForm, actions: FormikValues) => {
    const {
      avatar,
      deliveryTime,
      direction,
      max_weight,
      price,
      title,
      type,
      priceRangeType,
      volumetricWeightType,
      volumetricWeightMinValue,
      volumetricWeightPriceMethod,
      volumetricWeightCombinedRate,
      volumetricWeightDivisor,
      min_weight,
      min_price,
      minimum_value,
      palletWidth,
      palletLength,
      palletOversizeFee,
      categories,
    } = values

    const deliveryTypeData = {
      logo: avatar, // not saving logo, reverse order in the list
      productCategories: categories.map((item) => {
        return {id: item.value}
      }),
      rate: {
        type: +type,
        data: getDataForDeliveryTypeToSend(
          +type,
          min_weight,
          min_price,
          price,
          max_weight,
          deliveryTwoFields,
          +minimum_value
        ),
        priceRangeType: +priceRangeType,
      },
      deliveryTime: deliveryTime,
      direction: {id: direction},
      title,
      specialRates: [],
      volumetricWeightType: +volumetricWeightType,
      volumetricWeightMinValue: volumetricWeightMinValue ? +volumetricWeightMinValue : 0,
      volumetricWeightPriceMethod,
      volumetricWeightDivisor:
        volumetricWeightDivisor !== undefined &&
        (+volumetricWeightType === 1 || +volumetricWeightType === 2)
          ? +volumetricWeightDivisor
          : 0,
      volumetricWeightCombinedRate:
        volumetricWeightCombinedRate && volumetricWeightPriceMethod === 'combined'
          ? +volumetricWeightCombinedRate
          : 0,
      palletWidth: palletWidth === '' ? 0 : palletWidth && +palletWidth,
      palletLength: palletLength === '' ? 0 : palletLength && +palletLength,
      palletOversizeFee: palletOversizeFee === '' ? 0 : palletOversizeFee && +palletOversizeFee,
    }

    mutation.mutate(deliveryTypeData)

    actions.resetForm()
  }

  return (
    <Formik
      validationSchema={createDeliveryTypeSchema}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, errors, setFieldValue, isValid}) => (
        <Form className='form' noValidate id='kt_modal_create_direction_form'>
          <ScrollToFieldError />
          <CreateDeliveryTypeModalHeader isLoading={isLoading} isValid={isValid} />
          {/* {JSON.stringify(errors)} */}
          {/* {JSON.stringify(values.store?.id)} */}
          <div
            className='scroll-y bg-white modal-body p-0'
            style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
          >
            {' '}
            <div className='p-3 pb-6'>
              {' '}
              <div className='w-100'>
                <Name />
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <div className='w-100'>
                <Image />
                <Direction />
              </div>
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <Categories values={values} />
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <DeliveryTime />
              <PriceRangeType />
              <DelTypeCalculation
                values={values}
                setDeliveryTwoFields={setDeliveryTwoFields}
                deliveryTwoFields={deliveryTwoFields}
              />
            </div>{' '}
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              {' '}
              <VolWeight values={values} />
              <VolWeightCalculation values={values} />
            </div>
            <div className='separator bg-primary h-3px mb-3'></div>
            <div className='p-3'>
              <OversizeAddFee values={values} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {CreateDeliveryTypeForm}
