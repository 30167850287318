import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {useIntl} from 'react-intl'
import {IReactSelectProps} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  props: IReactSelectProps
}

export const customStyles = {
  // @ts-ignore
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0.625rem',
    border: 'none',
    color: 'var(--kt-form-select-disabled-color)',
    backgroundColor: 'var(--kt-form-select-disabled-bg)',
    minHeight: 'calc(1.5em + 1.65rem + 2px)',
  }),

  // @ts-ignore
  // menuList: (base) => ({
  //   ...base,

  //   '::-webkit-scrollbar': {
  //     width: '10px',
  //     height: '0px',
  //   },
  //   '::-webkit-scrollbar-track': {
  //     background: '#f1f1f1',
  //   },
  //   '::-webkit-scrollbar-thumb': {
  //     background: 'var(--kt-primary)',
  //   },
  //   '::-webkit-scrollbar-thumb:hover': {
  //     background: '#555',
  //   },
  // }),
}

export function ReactSelectTemplate({props}: Props) {
  const {
    options,
    isLoading,
    onInputChange,
    setFieldValue,
    isClearable,
    placeholder,
    selectName,
    currentValue,
    isMultiple = false,
    isCreatable = false,
    setObject = false,
    noOptionsMessage,
    defaultValue,
    handleChange,
    disabled = false,
    inputFontSize,
    optionFormat,
  } = props
  const intl = useIntl()
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage ? noOptionsMessage : intl.formatMessage({id: 'COMPONENTS.SearchSelect.NO_OPTIONS'})}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(options)
    }, 500)
  }

  const loadOptionsCreatable = (inputValue: string, callback: any) => {
    setTimeout(() => {
      const newOption = {label: inputValue, value: inputValue}
      const newOptions = [...options, newOption]
      callback(newOptions)
    }, 1000)
  }
  const selectProps = {
    ...(isMultiple && { isMulti: true }),
    ...(optionFormat && { formatOptionLabel: optionFormat }),
  };

  return (
    <>
      {isCreatable ? (
        <AsyncCreatableSelect
          {...selectProps}
          minMenuHeight={250}
          styles={customStyles}
          loadOptions={loadOptionsCreatable}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          formatCreateLabel={(userInput) => `Добавить "${userInput}"`}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            // @ts-ignore
            setFieldValue(selectName, isMultiple ? e : e?.value)

            if (handleChange) {
              handleChange()
            }
          }}
        />
      ) : (
        <AsyncSelect
          {...selectProps}
          minMenuHeight={250}
          styles={customStyles}
          loadOptions={loadOptions}
          defaultOptions={options}
          form={selectName}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
          placeholder={placeholder}
          isClearable={isClearable}
          isLoading={isLoading}
          value={currentValue}
          defaultValue={defaultValue ? defaultValue : undefined}
          onInputChange={(e) => {
            onInputChange(e)
          }}
          onChange={(e) => {
            if (setObject || isMultiple) {
              setFieldValue(selectName, e)
            } else {
              // @ts-ignore
              setFieldValue(selectName, e?.value)
            }

            if (handleChange) {
              handleChange()
            }
          }}
        />
      )}
    </>
  )
}
