import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ICategory} from '../../../_models'

interface Props {
  category: ICategory
}

const CategoryHeaderLangSwitcher = ({category}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const availableLangs = Object.keys(category.translations)

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <ul className='nav'>
      {availableLangs.map((key) => (
        <li className='nav-item' key={key}>
          <button
            type='button'
            onClick={() => {
              navigate(`/website-categories/blog/edit/${currTab}/${key}/${category.id}`)

              setActiveTab(key)
            }}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
              activeTab === key ? 'active' : ''
            } fw-bold px-4`}
          >
            {key}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default CategoryHeaderLangSwitcher
