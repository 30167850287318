import * as Yup from 'yup'
import {cyrillicOnly} from '../../regexs'

export const object_shape_basic_select = Yup.object().shape({
  label: Yup.string().nullable().notOneOf([null, ''], 'Заповніть поле'),
  value: Yup.string().nullable().notOneOf([null, ''], 'Заповніть поле'),
})

export const phoneNumberSchema = (intl: any, required?: boolean) => {
  return required
    ? Yup.string()
        .min(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
        .max(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
        .required(intl.messages.YUP_VALIDATION_GENERAL_ENTER_PHONE_NUMBER)
    : Yup.string()
        .min(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
        .max(13, intl.messages.YUP_VALIDATION_GENERAL_MISSING_NUMBERS)
}

export const basicNameSchema = (intl: any, rqrdMsg: string) =>
  Yup.string()
    .min(3, intl.messages.YUP_VALIDATION_GENERAL_MIN_SYMBOLS)
    .max(50, intl.messages.YUP_VALIDATION_GENERAL_MAX_SYMBOLS)
    .required(rqrdMsg)
    .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)

export const patronymicOptionalSchema = (intl: any) =>
  Yup.string()
    .matches(cyrillicOnly, intl.messages.YUP_VALIDATION_GENERAL_ENTER_CYRILLIC_NO_SYMBOLS)
    .when('noPatronymic', {
      is: false,
      then: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_PATRONYMIC),
    })
