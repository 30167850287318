import {useQuery, useQueryClient} from 'react-query'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {QUERIES} from '../../../../_metronic/helpers'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {IWebsite} from '../../websites/core/_models'
import {getPageById} from '../website-pages-list/core/_requests'
import {PageHeader} from './header/PageHeader'
import PageOverviewWrapper from './tabs/overview/PageOverviewWrapper'
import PageSeoWrapper from './tabs/seo/PageSeoWrapper'

type TProps = {
  id: string | number
}

const WebsitePagePage = ({id}: TProps) => {
  const {websiteId} = useParams()
  const {data: pageById} = useQuery(`${QUERIES.WEBSITE_PAGE_BY_ID}-${id}`, () => getPageById(id))

  const queryClient = useQueryClient()

  const invalidatePage = () => {
    queryClient.invalidateQueries(`${QUERIES.WEBSITE_PAGE_BY_ID}-${id}`)
  }

  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const currWebsite = websitesData?.find((item) => websiteId && +item.id === +websiteId)

  return (
    <>
      {pageById && currWebsite ? (
        <Routes>
          <Route
            element={
              <>
                <PageHeader page={pageById} website={currWebsite} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <PageOverviewWrapper
                    page={pageById}
                    invalidatePage={invalidatePage}
                    website={currWebsite}
                  />
                </>
              }
            />

            <Route
              path={`seo/:locale/:id`}
              element={
                <>
                  <PageSeoWrapper
                    page={pageById}
                    invalidatePage={invalidatePage}
                    website={currWebsite}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`seo/${currWebsite?.mainLocale}/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default WebsitePagePage
