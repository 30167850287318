import {TBasicImageResponse} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {fulfillmentApi} from '../_requests'

export const getImageLink = async (
  blob: Blob,
  type: 1 | 2 //'parcel'|'goods'
) => {
  let res: TBasicImageResponse
  if (type === 1) {
    res = await fulfillmentApi.uploadParcelImage(blob)
  } else if (type === 2) {
    res = await fulfillmentApi.uploadGoodsImage(blob)
  } else {
    res = {image: '', imageUrl: ''}
  }

  return res.imageUrl
}
