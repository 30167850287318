import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IWebsite} from '../../../websites/core/_models'
import {
  IPollAnswerTranslation,
  IPollFormAnswer,
  IPollFormQuestion,
  IPollQuestion,
  IPollQuestionTranslation,
} from '../_models'

export const transformFormQuestionsForCreateApi = (
  initialQuestions: IPollFormQuestion[],
  website: IBasicSelect & Partial<IWebsite>
): IPollQuestion[] => {
  const transformedQuestions = initialQuestions.map((question) => {
    const newQuestion: any = {
      allowOwnAnswer: question.allowOwnAnswer,
      translations:
        website?.enabledLocales?.reduce<IPollQuestionTranslation>((acc, locale) => {
          acc[locale] = {question: question?.question || ''}
          return acc
        }, {}) || {},
      answers:
        question.answers?.filter(Boolean).length > 0 &&
        website?.enabledLocales &&
        website?.enabledLocales.length > 0
          ? question.answers.map(({answer, image}) => ({
              image,
              translations: website.enabledLocales?.reduce<IPollAnswerTranslation>(
                (acc, locale) => {
                  acc[locale] = {answer: answer || ''}
                  return acc
                },
                {}
              ),
            }))
          : [],
    }

    // if (addGenQuestion) {
    //   newQuestion.question = question.question
    // }
    return newQuestion
  })

  return transformedQuestions as IPollQuestion[]
}

export const transformApiQuestionsForForm = (questions: IPollQuestion[], locale: string) => {
  const transformedQuestions = questions.map((question): IPollFormQuestion => {
    const item = {
      id: question?.id,
      question: question?.translations[locale]?.question,
      allowOwnAnswer: question?.allowOwnAnswer,
      allowDelete: question.allowDelete,
      answers: question?.answers?.map((answer): IPollFormAnswer => {
        return {
          id: answer?.id,
          answer: answer?.translations[locale]?.answer || '',
          allowDelete: answer.allowDelete,
          image: answer?.image || '',
          imageUrl: answer?.imageUrl || '',
        }
      }),
    }

    return item
  })

  return transformedQuestions
}
