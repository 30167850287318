import {useState} from 'react'
import {Formik, Form} from 'formik'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {FormattedMessage, useIntl} from 'react-intl'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {ICompanyDetails, ICompanyForm} from '../../../core/_models'
import {updateCompany} from '../../../core/_requests'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  company: ICompanyDetails | undefined
  invalidateCompany: () => void
}

export function SettingsOverview({company, invalidateCompany}: Props) {
  const intl = useIntl()

  const initialValues: Partial<ICompanyForm> = {
    userNameTranslit: company?.userNameTranslit === true ? 1 : 0,
  }

  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: Partial<ICompanyForm>) => {
    try {
      setLoading(true)
      const response = await updateCompany({
        userNameTranslit: values.userNameTranslit === 1 ? true : false,
      })
      if (response) {
        invalidateCompany()
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {company ? (
        <div className='card mb-5 mb-xl-10'>
          <Formik
            // validationSchema={profileOverviewSchema(intl)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({values, setFieldValue}) => (
              <Form noValidate className='form'>
                {/* {JSON.stringify(values)} */}

                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      <FormattedMessage id='COMPANY.TRANSLITERATION.LABEL' />{' '}
                    </label>
                    <div className='col-lg-8'>
                      <div className='row flex-nowrap'>
                        <div className='col-lg-6 fv-row me-4'>
                          <CheckboxSwitcher
                            inputName='userNameTranslit'
                            firstOption={intl.formatMessage({id: 'COMPANY.TRANSLITERATION.NEG'})}
                            secondOption={intl.formatMessage({id: 'COMPANY.TRANSLITERATION.POS'})}
                            value={values.userNameTranslit ? +values.userNameTranslit : 0}
                            setFieldValue={setFieldValue}
                            bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                          />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <CardFooter loading={loading} success={success} />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <PartialPageLoader />
      )}
    </>
  )
}
