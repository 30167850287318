import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'
import {regionsYupPartials} from './partials'

const createRegionSchema = (intl: any) =>
  Yup.object({
    name: regionsYupPartials.nameSchema(intl),
    deliveryService: regionsYupPartials.deliveryServiceSchema(intl),
    deliveryMethods: regionsYupPartials.deliveryMethodsSchema(intl),
    priority: regionsYupPartials.prioritySchema(intl),
    minWeight: regionsYupPartials.minWeightSchema(intl),
    maxWeight: regionsYupPartials.maxWeightSchema(intl),
    paidByClient: regionsYupPartials.paidByClientSchema(intl),
  })

const regionOverviewSchema = (intl: any) =>
  Yup.object({
    name: regionsYupPartials.nameSchema(intl),
    deliveryService: regionsYupPartials.deliveryServiceSchema(intl),
    deliveryMethods: regionsYupPartials.deliveryMethodsSchema(intl),
  })

const regionSettingsSchema = (intl: any) =>
  Yup.object({
    priority: regionsYupPartials.prioritySchema(intl),
    minWeight: regionsYupPartials.minWeightSchema(intl),
    maxWeight: regionsYupPartials.maxWeightSchema(intl),
    paidByClient: regionsYupPartials.paidByClientSchema(intl),
  })

export {createRegionSchema, regionOverviewSchema, regionSettingsSchema}
