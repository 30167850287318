import {FieldArrayRenderProps} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'

const AddQuestionButton = ({arrayHelpers}: {arrayHelpers: FieldArrayRenderProps}) => {
  return (
    <button
      className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
      type='button'
      onClick={() => {
        const newQuestion = {
          question: '',
          allowOwnAnswer: false,
          allowDelete: true,
          answers: [{answer: ''}],
        }

        arrayHelpers.push(newQuestion)
      }}
    >
      Додати питання
      {/* <FormattedMessage id='GENERAL.BUTTONS.ADD' values={{item: ''}} /> */}
      <KTSVG
        path='/media/icons/duotune/general/gen041.svg'
        className='svg-icon-1 svg-icon-primary d-block m-0 mx-2'
      />
    </button>
  )
}

export default AddQuestionButton
