import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {EmployeesQueryResponse} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const EMPLOYEE_URL = `${API_URL}/employees`

const getEmployees = (query: string): Promise<EmployeesQueryResponse> => {
  return axios
    .get(`${EMPLOYEE_URL}?${query}`)
    .then((d: AxiosResponse<EmployeesQueryResponse>) => d.data)
}

const deleteEmployee = (userId: ID): Promise<void> => {
  return axios.delete(`${EMPLOYEE_URL}/${userId}`).then(() => {})
}

const deleteSelectedEmployees = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${EMPLOYEE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getEmployees, deleteEmployee, deleteSelectedEmployees}
