import {IRegion} from '../../../../core/_models'

type TProps = {
  region: IRegion
}

const RegionPriorityCell = ({region}: TProps) => {
  return (
    <div>
      <p className='fw-bold text-dark mb-2'>{region.priority}</p>
    </div>
  )
}

export {RegionPriorityCell}
