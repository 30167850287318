import {Dispatch, SetStateAction} from 'react'
import {IBasicSelect} from './generalHelpers'
import {IShipmentAddressForm} from './profileHelpers'

export interface IAddressCities {
  id: string
  region: {name: string; type: string}
  country: string
  name: string
}

export interface ICityStreet {
  ref: string
  name: string
}

export interface INovaPoshtaOffice {
  id: string | number
  big: boolean
  name: string
  number: string | number
  pmw: string | number //Place max weight
  tmw: string | number //Total max weight
}

export interface IPostShipmentTitles {
  title?: string
  cityTitle?: string
  officeTitle?: string
}

export const postMainShipmentDefault = {
  title: 'COMPONENTS.PostShipment.MAIN_NP_TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
}

export const postReserveShipmentDefault = {
  title: 'COMPONENTS.PostShipment.RESERVE_NP.TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
}
export interface IPostShimpentProps {
  citiesLoading: boolean
  citiesResults: IBasicSelect[]
  offLoading: boolean
  offResults: IBasicSelect[]
  values: IShipmentAddressForm
  filterCities: (query: string) => Promise<void>
  setQuery: Dispatch<SetStateAction<string>>
  titles: IPostShipmentTitles
  withHeadTitles: boolean
}
