/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {IWebsitePage} from '../../core/_models'

type Props = {
  page: IWebsitePage
}

const WebsitePageNameCell: FC<Props> = ({page}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6 mb-1'>
          ID: {page.id}
        </a> */}
        <span className='text-muted fw-semobold text-muted d-block fs-7'>{page.name}</span>
      </div>
    </div>
  )
}

export {WebsitePageNameCell}
