import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {TemplatesListWrapper} from './templates-list/TemplatesList'
import LocationPage from './template-page/TemplatePage'

const TemplatesPage = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <TemplatesListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <LocationPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/email-templates/list' />} />
    </Routes>
  )
}

export default TemplatesPage
