/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {IPollResult} from '../../../../../../../core/_models'

type Props = {
  result: IPollResult
}

const AnswerCell = ({result}: Props) => {
  return (
    <div className='p-1 fs-7 S fw-semobold d-flex flex-column'>
      {result.votes.map((i, ind) => (
        <div className='d-flex flex-column' key={ind}>
          <span className='text-dark'>{i.question.question}</span>
          <span>{i.answer?.answer || i.ownAnswer}</span>
        </div>
      ))}
    </div>
  )
}

export {AnswerCell}
