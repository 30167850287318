import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {UserGroupsListToolbar} from './UserGroupsListToolbar'

const UserGroupsListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      <ListSearch updateState={updateState} />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <UserGroupsListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UserGroupsListHeader}
