import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {directionsApi} from '../directions/core/_requests'
import {CountriesListWrapper} from './countries-list/CountriesList'
import CountryPage from './country-page/CountryPage'

const CountriesPage = () => {
  const location = useLocation()

  useQuery(QUERIES.COUNTRY_ABBR_LIST, directionsApi.fetchDirectionCountriesList)

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<CountriesListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <CountryPage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/states/list' />} />
    </Routes>
  )
}

export default CountriesPage
