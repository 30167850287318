import {useEffect, useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import clsx from 'clsx'
import {NotType, usePageData} from '../../layout/core'
import {KTSVG} from '../../helpers'
import {SoundNotification} from './SoundNotification'

export function Notification(data: NotType) {
  const [show, setShow] = useState(true)
  const {removeNotification} = usePageData()
  const {status, type, body, soundSrc, sound = false} = data
  const handleClose = (id: string) => {
    setShow(false)
    removeNotification(id)
  }

  useEffect(() => {
    function play() {
      const audio = document.getElementById('audio')
      // @ts-ignore
      audio?.play()
    }

    play()
  }, [])

  return (
    <>
      {sound === true && (
        <SoundNotification
          src={
            soundSrc
              ? soundSrc
              : 'https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3'
          }
        />
      )}
      {body && (
        <Toast
          show={show}
          onClose={() => handleClose(data.id)}
          delay={3000}
          autohide
          className={`mx-3 my-2 bg-${type} ${clsx({'text-white': type})}`}
        >
          <Toast.Body>
            <div className='modal-dialog modal-dialog-centered mw-300px'>
              <div className='modal-content text-center h-150px d-flex flex-column align-items-center justify-content-center'>
                <div className={`swal2-icon swal2-${status} swal2-icon-show`}>
                  <div className='swal2-icon-content'>
                    {status === 'success' && '✓'}
                    {(status === 'warning' || status === 'danger') && '!'}
                    {!status && '✓'}
                  </div>
                </div>

                <p className='fs-5 fw-bold m-0'>
                  <FormattedMessage id={body} defaultMessage={body} />
                </p>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      )}
    </>
  )
}

export function Notifications() {
  const {notifications} = usePageData()
  return (
    <ToastContainer position='middle-center' className='p-3'>
      {notifications?.map((child: NotType) => (
        <Notification key={`${child.id}`} {...child} />
      ))}
    </ToastContainer>
  )
}
