import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {IOrder, IOrderBuyoutProps} from '../../../orders/core/_models/_orders-models'



export function BuyoutHeaderShipment({buyout}: IOrderBuyoutProps) {
  const {trackingNumbers} = buyout

  const [activeTab, setActiveTab] = useState('track')

  const showTable = trackingNumbers && trackingNumbers?.length > 0

  return (
    <div className='min-w-25'>
      <div className='d-flex flex-column align-items-center align-items-md-end fs-8 h-lg-50 my-3 mb-md-5'>
        {' '}
        {showTable === true && (
          <>
            <div className='card-toolbar mb-2'>
              <ul className='nav'>
                <li className='nav-item me-1'>
                  {' '}
                  <button
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                      activeTab === 'track' ? 'active' : ''
                    } fw-bold px-4`}
                    onClick={() => {
                      setActiveTab('track')
                    }}
                  >
                    <FormattedMessage id='GENERAL.ITEM_PAGE.TRACK_COLUMN' />
                  </button>
                </li>
              </ul>
            </div>
            <div className='text-center'>
              {activeTab === 'track' &&
                trackingNumbers?.map(({id, number}) => (
                  <p key={id} className='pe-2'>
                    {number}
                  </p>
                ))}
            </div>
          </>
        )}
      </div>

      {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Идёт отгрузка на складе...</span>
                
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
                <span className='fs-8'>Осталось 5 дней бесплатного хранения на складе</span>
              </div>
            </div> */}
    </div>
  )
}
