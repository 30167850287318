import {FC, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'

import {useListView} from '../../../../core/ListViewProvider'
import {IOrder, IOrderProps} from '../../../../core/_models/_orders-models'


const OrderTracksCell = ({order}:IOrderProps) => {
  const {number} = order
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleClick = async () => {
    if (itemIdForUpdate === order.id) {
      setItemIdForUpdate(undefined)
    } else {
      await setItemIdForUpdate(undefined)
      setItemIdForUpdate(order.id)
    }
  }

  const getFalseFields = (entries: Record<string, boolean>) => {
    return Object.entries(entries).filter(([key, value]) => value === false)
  }

  // const falseEntries = getFalseFields(track.field_state)

  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)

  // const missingTooltip = (
  //   <div className='fs-7 fw-normal' id='tooltip'>
  //     <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
  //     <ul className='m-0'>
  //       {falseEntries.map(([key, _]) => (
  //         <li key={key}>
  //           <FormattedMessage id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`} />
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // )

  const codeValueRef = useRef(null)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {order?.trackingNumbers && order?.trackingNumbers?.length > 0
          ? order.trackingNumbers?.map((item) => (
              <div className='d-flex align-items-center' key={item.id}>
                <div
                  className='d-flex align-items-center m-2 mb-1 cursor-pointer h-25px'
                  onClick={handleClick}
                >
                  <KTSVG
                    path='/media/icons/duotune/ecommerce/ecm010.svg'
                    className={`svg-icon-3 svg-icon-gray-600 cursor-pointer me-1`}
                  />
                  <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
                    {item.number}
                  </span>{' '}
                </div>
                {/* <div
              ref={target}
              onClick={() => {
                copyToClipboard(codeValueRef, setShow)
                setTimeout(() => {
                  setShow(false)
                }, 1000)
              }}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${
                  show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
                }`}
              />
            </div>{' '}
            <Overlay target={target.current} show={show} placement='right'>
              {(props) => (
                <Tooltip {...props}>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})} </Tooltip>
              )}
            </Overlay> */}
              </div>
            ))
          : '-'}

        {order?.parcel && (
          <div className='d-flex align-items-center' key={order?.parcel.id}>
            <div
              className='d-flex align-items-center m-2 mb-1 cursor-pointer h-25px'
              onClick={handleClick}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen017.svg'
                className={`svg-icon-3 svg-icon-gray-600 cursor-pointer me-1`}
              />
              <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
                {order?.parcel.code}
              </span>{' '}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {OrderTracksCell}
