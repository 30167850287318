import {FC} from 'react'
import {IShipment} from '../../../../core/_models/_models'

type Props = {
  shipment: IShipment
}

const ShipmentNumberCell: FC<Props> = ({shipment}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>{shipment.id}</p>
    </div>
  )
}

export {ShipmentNumberCell}
