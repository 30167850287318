import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {ListViewContextProps, QueryResponseContextProps} from '../../../../../_metronic/helpers'
import {Employee} from '../../../../pages/employees/employees-list/core/_models'
import {User} from '../../../../pages/users/core/_models'
import {CreateUserFormWrapper} from './CreateUserFormWrapper'

const modalsRoot = document.getElementById('root-modals') || document.body

type TProps = {
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<User | Employee>
}

const CreateUserModal = ({listView, queryResponse}: TProps) => {
  const {itemIdForUpdate} = listView
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog w-100 mw-md-auto mw-0 me-0 my-0'
      show={itemIdForUpdate !== undefined}
      backdrop={false}
      className='OnePageForm mw-md-auto mw-lg-auto mw-0 p-0 shadow-sm'
    >
      <div
        className='d-flex flex-column justify-content-between modal-content modal-content-container pt-20'
        style={{
          height: '100vh',
          borderRadius: 0,
          overflowY: 'auto',
        }}
      >
        <CreateUserFormWrapper listView={listView} queryResponse={queryResponse} />
      </div>
    </Modal>,
    modalsRoot
  )
}

export default CreateUserModal
