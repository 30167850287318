import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useLocation} from 'react-router-dom'
import {
  isNotEmpty,
  ListViewContextProps,
  QUERIES,
  QueryResponseContextProps,
} from '../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {
  reformTrackApiProductsToForm,
  reformTrackApiProductToForm,
} from '../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {Direction, DirectionsQueryResponse} from '../../../../pages/directions/core/_models'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'
import {IOrder} from '../../../../pages/orders/core/_models/_orders-models'
import {
  ITrackFormProd,
  ITrackProd,
  Track,
} from '../../../../pages/tracks/core/_models/_tracks-models'
import {getTrackById} from '../../../../pages/tracks/core/_requests'
import {CreateTrackOnePageForm} from './CreateTrackOnePageForm'

export const transformDirectionsForForm = (arr: Direction[], countries: Record<string, string>) => {
  return arr?.map((item) => {
    return {
      ...item,
      label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
      value: item.id,
    }
  })
}

type TProps = {
  listView: ListViewContextProps
  queryResponse: QueryResponseContextProps<Track | IOrder>
}

export const OnePageFormWrapper = ({listView, queryResponse}: TProps) => {
  const enabledQuery: boolean = isNotEmpty(listView.itemIdForUpdate)
  const queryClient = useQueryClient()
  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}

  const countries: Record<string, string> =
    queryClient.getQueryData(QUERIES.COUNTRY_ABBR_LIST) || {}

  const loc = useLocation()

  const {
    isLoading,
    data: track,
    error,
  } = useQuery(
    'trackEdit',
    () => {
      return getTrackById(listView.itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        listView.setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  const [goodsFields, setGoodsFields] = useState<ITrackFormProd[]>([])
  const setGoodsFieldsFunc = async (prods: ITrackProd[]) => {
    const res = await reformTrackApiProductsToForm(prods)
    setGoodsFields(res)
  }

  useEffect(() => {
    if (track?.products) {
      setGoodsFieldsFunc(track.products)
    }
  }, [])

  if (isLoading) {
    return (
      <div>
        <PartialPageLoader />
      </div>
    )
  }

  if (listView.itemIdForUpdate === null && directions?.data && countries) {
    return (
      <CreateTrackOnePageForm
        directions={transformDirectionsForForm(directions?.data, countries)}
        listView={listView}
        queryResponse={queryResponse}
      />
    )
  }

  if (!error && track && directions?.data && countries) {
    return (
      <CreateTrackOnePageForm
        track={track}
        directions={transformDirectionsForForm(directions?.data, countries)}
        listView={listView}
        queryResponse={queryResponse}
        goodsFields={goodsFields}
      />
    )
  }

  return null
}
