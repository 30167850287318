import {Form, Formik, FormikValues} from 'formik'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../../websites/core/_models'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {ICategory, IEditCategory, IEditCategoryForm} from '../../../../_models'
import {editCategory} from '../../../core/_requests'
import WebsitesSelect from '../../../../../../modules/custom/form-elements/selects/WebsitesSelect'

interface Props {
  category: ICategory
  type: number
  invalidateCategory: () => void
}
const CategoryOverview = ({category, type, invalidateCategory}: Props) => {
  const {locale = 'uk'} = useParams()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => item.id === category?.website?.id)

  const initialValues: IEditCategoryForm = {
    type,
    name: category.translations[locale].name,
    website: {...website, value: website?.id || '', label: website?.name || ''},
    slug: category.slug,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: IEditCategoryForm, actions: FormikValues) => {
    const {name, slug} = values
    setLoading(true)

    try {
      let translations = {
        ...category?.translations,
        [locale]: {name},
      }

      const dataToSend: Partial<IEditCategory> = {
        translations,
        website: values.website?.value,
        slug,
      }

      await editCategory(dataToSend, category.id)

      invalidateCategory()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Сайт</label>{' '}
                <div className='col-lg-8'>
                  {' '}
                  <WebsitesSelect withTitle={false} />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='name' required={true} type='text' />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id={'BLOG.ADD_MODAL.FORM.SLUG_LABEL'} />
                </label>

                <div className='col-lg-8'>
                  <InputTemplate
                    inputName='slug'
                    type='text'
                    titleFontSize='fs-5 text-dark'
                    handleChange={(string: string) => {
                      setFieldValue('slug', string.replaceAll(' ', ''))
                    }}
                  />
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CategoryOverview
