import {ID} from '../../../../../../_metronic/helpers'
import {IParcel} from '../../../core/_models/_models'
import ParcelTrackRow from './ParcelTrackRow'
import {editParcel} from '../../../core/_requests'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  parcel: IParcel
  setCurrParcel: (obj: IParcel) => void
  invalidateParcel: () => void
}

export function ParcelTracksTab({parcel, invalidateParcel}: Props) {
  const deleteTrack = async (id: ID) => {
    const tracksLeft = parcel.trackingNumbers.filter((track) => track.id !== id)

    await editParcel({trackingNumbers: tracksLeft}, parcel.id)
    invalidateParcel()
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      {parcel ? (
        <div className='card-body py-9 px-20 cardBody'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>
                    Номер треку
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_DATE_COLUMN' /> */}
                  </th>

                  <th className='min-w-100px mw-50'>
                    ID та ПІП
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_COLUMN' /> */}
                  </th>
                  <th className='min-w-50px'>
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.AUTHOR_COLUMN' /> */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {parcel.trackingNumbers.map((track) => (
                  <ParcelTrackRow track={track} deleteTrack={deleteTrack} key={track.id} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
