import {Formik, Form} from 'formik'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'

import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {IPaymentProvider, IPaymentProviderForm} from '../../../core/_models'
import {paymentProvidersApi} from '../../../core/_requests'

interface Props {
  provider: IPaymentProvider
  setCurrProvider: (obj: IPaymentProvider) => void
}

export function ProviderOverview({provider, setCurrProvider}: Props) {
  const initialValues: Partial<IPaymentProviderForm> = {
    name: provider.name,
  }

  const queryClient = useQueryClient()

  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const getCountries: Record<string, string> | [] = countriesList

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IPaymentProviderForm>) => {
    setLoading(true)

    const {name} = values
    const response = await paymentProvidersApi.editPaymentProvider(provider.id, {
      name,
    })

    if (response) {
      queryClient.invalidateQueries([QUERIES.DIRECTION_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  <FormattedMessage id='GENERAL.LABELS.NAME' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <InputTemplate
                        inputName='name'
                        type='text'
                        required={true}
                        titleFontSize='fs-5 text-dark'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
