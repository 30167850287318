import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {CourierActionsCell} from './cells/CourierActionsCell'
import {CourierNameCell} from './cells/CourierNameCell'
import {CourierDeliveryMethodsCell} from './cells/CourierDeliveryMethodsCell'
import {ICourier} from '../../../core/_models'
import {CourierCountryCell} from './cells/CourierCountryCell'

const couriersColumns: ReadonlyArray<Column<ICourier>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'COURIERS_COLUMNS_NAME'} className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CourierNameCell courier={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'WAREHOUSES_COLUMNS_COUNTRY'}
        className='min-w-125px'
      />
    ),
    id: 'country',
    Cell: ({...props}) => <CourierCountryCell courier={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COURIERS_COLUMNS_DEL_METHODS'}
        className='min-w-125px'
      />
    ),
    id: 'delMethods',
    Cell: ({...props}) => <CourierDeliveryMethodsCell courier={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <CourierActionsCell courier={props.data[props.row.index]} />,
  },
]

export {couriersColumns}
