/* eslint-disable jsx-a11y/anchor-is-valid */
import {TCurrencyData} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ITrackProd} from '../../../../../tracks/core/_models/_tracks-models'
import {IOrderProps} from '../../../../core/_models/_orders-models'
import currenciesLocal from '../../../../../../../data/general/currencies.json'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const OrderItemsInfoCell = ({order}: IOrderProps) => {
  const currSymbols: TCurrencyData = currenciesLocal

  const buyoutCurrSymbol =
    order?.direction?.buyoutCurrency &&
    currenciesLocal.hasOwnProperty(order.direction?.buyoutCurrency)
      ? `${currSymbols[order?.direction?.buyoutCurrency].symbol}`
      : ''
  return (
    <div>
      <div>
        {order.products.map((item, index) =>
          order.type === 'buyout' ? (
            <a
              href={item.url}
              className='d-block text-gray-400 fw-bold fs-7'
              target='_blank'
              rel='noreferrer noopener'
              key={index}
            >
              <ItemLine item={item} index={index} currency={buyoutCurrSymbol} />
            </a>
          ) : (
            <ItemLine item={item} index={index} key={index} currency={buyoutCurrSymbol} />
          )
        )}
      </div>

      {order.charges && order.charges?.length > 0 && (
        <div className='mt-2'>
          <OverlayTrigger placement='right' overlay={<Tooltip>Борг</Tooltip>}>
            <span className={`p-1 fw-normal StatusBar__status ${statusColor(false)}`}>
              {buyoutCurrSymbol}
              {order.charges.reduce((acc, i) => acc + +i.amount, 0)}
            </span>
          </OverlayTrigger>
        </div>
      )}
    </div>
  )
}

export {OrderItemsInfoCell}

type TProps = {
  item: ITrackProd
  index: number
  currency?: string
}

const ItemLine = ({item, index, currency}: TProps) => {
  return (
    <>
      {item.title ? (
        <p className='text-gray-400 m-0' key={index}>
          {`${item.title} ${item.quantity}x${currency}${item.priceForOne}`}
        </p>
      ) : (
        <p key={index}>-</p>
      )}
    </>
  )
}
