const ACCEPT_PRINT_KEY = 'print-mode'

// 1- print on saving, 0 - print every time a prod is created

export const setFulfPrintMode = (value: string) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.setItem(ACCEPT_PRINT_KEY, value)
  } catch (error) {
    console.error('PRINT LOCAL STORAGE SAVE ERROR', error)
  }
}

export const getFulfPrintMode = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(ACCEPT_PRINT_KEY)
  if (!lsValue) {
    return
  }

  if (lsValue) {
    return lsValue
  }
}

export const removeFulfPrintMode = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(ACCEPT_PRINT_KEY)
  } catch (error) {
    console.error('PRINT LOCAL STORAGE REMOVE ERROR', error)
  }
}
