import {FC} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../_metronic/helpers/custom/funcs/directions'
import {Direction} from '../../../../core/_models'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {directionsApi} from '../../../../core/_requests'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'

type Props = {
  direction: Direction
}

const DirectionNameCell: FC<Props> = ({direction}) => {
  const {data: countriesList} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    directionsApi.fetchDirectionCountriesList
  )
  const getCountries: Record<string, string> = countriesList

  const {refetch} = useQueryResponse()
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex align-items-center mb-3'>
        <FlagsDirectionBar
          chosenDirections={[{id: direction.id}]}
          minified={true}
          additionalClass='py-0'
        />
        <p className='fw-bold text-dark mb-0'>
          {directionsFuncs.formDirection(direction.countryFrom, direction.countryTo, getCountries)}
        </p>
      </div>
      <div>
        <StatusBar
          status={{
            trueOption: 'Активний',
            falseOption: 'Заблокований',
            currentBooleanValue: direction.enabled,
          }}
          allowDelete={direction.allowDelete}
          id={direction.id}
          refetch={refetch}
        />
      </div>
    </div>
  )
}

export {DirectionNameCell}
