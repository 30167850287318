import * as Yup from 'yup'

const receiveShipmentSchema = Yup.object({
  code: Yup.string().required('Номер посылки - обязательное поле'),
  client: Yup.string().label('Клиент'),
})

const editReceivedItemSchema = Yup.object({
  code: Yup.string().required('Номер - обязательное поле'),
  length: Yup.number()
    .required('Длина посылки - обязательное поле')
    .typeError('Допустимы только числа')
    .label('Длина'),
  width: Yup.number()
    .required('Ширина - обязательное поле')
    .typeError('Допустимы только числа')
    .label('Ширина'),
  height: Yup.number()
    .required('Высота - обязательное поле')
    .typeError('Допустимы только числа')
    .label('Высота'),
  weight: Yup.number()
    .typeError('Допустимы только числа')
    .required('Вес - обязательное поле')
    .label('Вес посылки'),
})

export {receiveShipmentSchema, editReceivedItemSchema}
