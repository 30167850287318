import {Link} from 'react-router-dom'
import { IPayment } from '../../../../core/_models'

type Props = {
  payment: IPayment
}

const PaymentUserCell = ({payment}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {payment?.user?.id ? (
          <Link
            to={`/users/edit/overview/${payment?.user?.id}`}
            className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
          >
            <span>{payment?.user?.externalId}</span>
            <span className='text-muted fs-6'>
              {[payment?.user?.lastName, payment.user?.firstName, payment.user?.patronymic].join(' ')}
            </span>
          </Link>
        ) : (
          '-'
        )}
      </p>
    </div>
  )
}

export {PaymentUserCell}
