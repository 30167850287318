import {auto} from '@popperjs/core'

interface Props {
  link: string
  sizes?: {width?: string; height?: string}
}

export function StickerBarcode({link, sizes = {width: 'auto', height: 'auto'}}: Props) {
  return <img src={link} alt='barcode' width={sizes.width} height={sizes.height} />
}
