import {FC} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../../../../_metronic/helpers'

import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {FlagsDirectionBar} from '../../../../../../../modules/custom/FlagsDirectionBar'
import {directionsApi} from '../../../../../../directions/core/_requests'
import {IPaymentProvider} from '../../../../core/_models'

type Props = {
  paymentProvider: IPaymentProvider
}

const PaymentProviderDirectionCell: FC<Props> = ({paymentProvider}) => {
  const {data: countriesList} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    directionsApi.fetchDirectionCountriesList
  )

  const allDirections = [...paymentProvider.orderDirections, ...paymentProvider.deliveryDirections]

  const {refetch} = useQueryResponse()
  return (
    <div className='d-flex align-items-center mb-3'>
      <FlagsDirectionBar
        chosenDirections={allDirections.filter((id, index) => allDirections.indexOf(id) === index)}
        minified={true}
        additionalClass='py-0'
      />
    </div>
  )
}

export {PaymentProviderDirectionCell}
