import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const StatusesListHeader = () => {
  const {updateState} = useQueryRequest()
  return (
    <div className='card-header border-0 pt-6  d-flex justify-content-between align-items-center'>
      <ListSearch updateState={updateState} />
    </div>
  )
}

export {StatusesListHeader}
