import {FieldArrayRenderProps} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'

const RemoveAnswerButton = ({
  arrayHelpers,
  answerIndex,
  disabled = false,
}: {
  arrayHelpers: FieldArrayRenderProps
  answerIndex: number
  disabled: boolean
}) => {
  return (
    <button
      className='btn btn-icon d-flex align-items-center color-primary m-0'
      type='button'
      disabled={disabled}
      onClick={() => arrayHelpers.remove(answerIndex)}
    >
      <KTSVG
        path='/media/icons/duotune/general/gen040.svg'
        className={`svg-icon-1 svg-icon-${disabled ? 'grey-300' : 'danger'} d-block m-0`}
      />
    </button>
  )
}

export default RemoveAnswerButton
