import axios, {AxiosResponse} from 'axios'
import {SetStateAction, Dispatch} from 'react'
import {ID} from '../../../../../_metronic/helpers'
import {IWebsitePage, WebsitePagesQueryResponse} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const PAGE_URL = '/website_pages'

const BASE_URL = `${API_URL}${PAGE_URL}`

const getPages = (): Promise<WebsitePagesQueryResponse> => {
  return axios.get(`${BASE_URL}`).then((d: AxiosResponse<WebsitePagesQueryResponse>) => {
    return d.data
  })
}

const getPagesByWebsiteId = (query: string, id: ID): Promise<WebsitePagesQueryResponse> => {
  return axios
    .get(`${BASE_URL}?${query}&website=${id}`)
    .then((d: AxiosResponse<WebsitePagesQueryResponse>) => {
      return d.data
    })
}

const getPageById = (id: ID, setState?: Dispatch<SetStateAction<number>>) => {
  return axios
    .get(`${BASE_URL}/${id}`)
    .then((response) => {
      if (setState) {
        setState(response.data.totalItems)
      }
      return response.data
    })
    .catch((err) => console.log(err))
}

const editWebsitePage = (id: ID, data: Partial<IWebsitePage>) => {
  return axios.put(`${BASE_URL}/${id}`, data).then((response) => {
    return response.data
  })
}

export {getPageById, getPages, editWebsitePage, getPagesByWebsiteId}
