import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IEditArticle} from '../../core/_models'

interface Props {
  article: IEditArticle
}

export function ArticleHeaderNav({article}: Props) {
  const {pathname} = useLocation()
  const {id, locale} = useParams()

  const tabs = [
    {value: 'overview', label: 'Основне'},
    {value: 'text', label: 'Стаття'},
    {value: 'meta', label: 'Мета теги'},
    {value: 'logs', label: 'Логи'},
  ]
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map((tab) => (
          <li className='nav-item' onClick={scrollToTabsStart} key={tab.value}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (pathname === `/blog/edit/${tab.value}/${locale}/${id}` && 'active')
              }
              to={`/blog/edit/${tab.value}/${locale}/${id}`}
            >
              {tab.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
