import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce} from '../../../../../_metronic/helpers'
import {InputTemplate} from '../InputTemplate'
import {IAcceptWarehouse} from '../../../../pages/tracks-accept/core/_models/_models'
import {getWarehouses} from '../../../../pages/warehouses/core/_requests'
import {ICreateShipmentForm} from '../../../../pages/shipments/core/_models/_models'

const WarehousesSelect = ({withTitle = false}) => {
  const intl = useIntl()
  const {values, setFieldValue, resetForm, dirty} = useFormikContext<{location: IAcceptWarehouse}>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IAcceptWarehouse[]>([])

  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData(QUERIES.COUNTRY_ABBR_LIST) || {}

  const filterWarehouses = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      getWarehouses('', true)
        .then((res) => {
          const reformed = res?.data?.map((item) => {
            return {
              ...item,
              label: item.addressName || '',
              value: item.id,
            }
          })
          if (reformed) {
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getWarehouses]
  )

  useEffect(() => {
    filterWarehouses()
  }, [])

  useEffect(() => {
    if (!values.location?.value && results.length > 0) {
      setFieldValue('location', results[0])
    }
  }, [results])

  return (
    <InputTemplate
      inputName='location'
      required={true}
      type='select'
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.WarehousesSelect.LABEL'}) : ''}
      titleFontSize='fs-5 text-dark'
      reactSelectprops={{
        currentValue: dirty ? values.location : results[0],
        options: results,
        optionFormat: ({value, label, countryConfig}) => (
          <div className='tagify__dropdown__item d-flex align-items-center'>
            <div className='d-flex flex-column text-gray-800'>
              <strong>{label}</strong>
              {countries[countryConfig?.country] && (
                <span className='fs-6 fw-normal'>{countries[countryConfig?.country]}</span>
              )}
            </div>
          </div>
        ),
        selectName: 'location',
        isLoading: resultsLoading,
        onInputChange: filterWarehouses,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'COMPONENTS.WarehousesSelect.PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'COMPONENTS.WarehousesSelect.NO_MESSAGE',
        }),
      }}
    />
  )
}

export default WarehousesSelect
