import {ITrackFormProd} from '../../../../../pages/tracks/core/_models/_tracks-models'

interface Props {
  item: ITrackFormProd
  bgColor?: string
}

export function TrackGoodsItemMinifiedHeader({item, bgColor = ''}: Props) {
  const defImg = '/media/icons/duotune/ecommerce/ecm002.svg'
  return (
    <div className={`table-responsive ${bgColor}`}>
      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
        <tbody>
          <tr>
            <td className='py-0'>
              <img
                width='45'
                height='45'
                className='rounded-circle w-45px me-2'
                src={
                  `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${item?.category?.logoUrl}` ||
                  defImg
                }
                alt='goods logo'
              />
            </td>
            {item.category?.label && <td className='py-0'>{item.category?.label} </td>}
            {item.qty && item.cost && (
              <td className='py-0'>
                {item.qty} X {item.cost}$ {item.total && `(${item.total}$)`}{' '}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
