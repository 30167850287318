import {FlagsDirectionBar} from '../../../../modules/custom/FlagsDirectionBar'
import {IDeliveryType} from '../../core/_models'

interface Props {
  deliveryType: IDeliveryType
}

export function DeliveryTypeHeaderName({deliveryType}: Props) {
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {deliveryType.title}
              </p>
            </div>{' '}
          </div>{' '}
          <FlagsDirectionBar chosenDirections={[{id: deliveryType.direction.id}]} minified={true} />
        </div>
      </div>
    </div>
  )
}
