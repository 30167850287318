/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {WarehouseHeaderName} from './WarehouseHeaderName'
import {WarehouseHeaderNav} from './WarehouseHeaderNav'
import {IWarehouse} from '../../core/_models'
import WarehouseHeaderLangSwitcher from './WarehouseHeaderLangSwitcher'

interface Props {
  warehouse: IWarehouse
}

const WarehouseHeader = ({warehouse}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/technology/teh005.svg')}
                  alt='Location'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <WarehouseHeaderName warehouse={warehouse} />{' '}
            </div>
          </div>
          <WarehouseHeaderLangSwitcher warehouse={warehouse} />{' '}
        </div>

        <WarehouseHeaderNav warehouse={warehouse} />
      </div>
    </div>
  )
}

export {WarehouseHeader}
