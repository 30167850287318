import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {CategoriesQueryResponse, ICategory, ICreateCategory, IEditCategory} from '../../_models'

const API_URL = process.env.REACT_APP_API_PATH
const CATEGORIES_URL = `/website_shop_categories`
const BASE_URL = `${API_URL}${CATEGORIES_URL}`

const getCategories = (query: string): Promise<CategoriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<CategoriesQueryResponse>) => {
      return d.data
    })
}

const getCategoriesByWebsite = (website: ID) => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?website=${website}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d) => {
      return d.data.data
    })
}

const getCategoryById = async (id: ID): Promise<ICategory | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const addCategory = (category: ICreateCategory): Promise<ICategory | undefined> => {
  return axios
    .post(`${BASE_URL}`, category)
    .then((response: AxiosResponse<Response<ICategory>>) => response.data)
    .then((response: Response<ICategory>) => response.data)
}

const editCategory = (category: Partial<IEditCategory>, id: ID): Promise<ICategory | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, category)
    .then((response: AxiosResponse<Response<ICategory>>) => response.data)
    .then((response: Response<ICategory>) => response.data)
}

const uploadImage = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const deleteCategory = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {
  getCategories,
  getCategoriesByWebsite,
  getCategoryById,
  addCategory,
  uploadImage,
  editCategory,
  deleteCategory,
}
