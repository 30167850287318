import {AddTrackForm, ITrackFormProd} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {Accordion} from 'react-bootstrap'
import {TrackGoodsItemBody} from './TrackGoodsItemBody'
import {TrackGoodsItemMinifiedHeader} from './TrackGoodsItemMinifiedHeader'
import {useListView} from '../../../../../pages/tracks/core/ListViewProvider'
import {useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IAcceptWarehouse} from '../../../../../pages/tracks-accept/core/_models/_models'
import {ListViewContextProps} from '../../../../../../_metronic/helpers'

interface Props {
  item: ITrackFormProd
  index: number
  arrayHelpers: any
  prods: ITrackFormProd[]
  setFieldValue: ISetFieldValue
  values: {prods: ITrackFormProd[]; location?: IAcceptWarehouse}
  listView: ListViewContextProps
}

export function TrackGoodsItem({
  item,
  index,
  arrayHelpers,
  prods,
  setFieldValue,
  values,
  listView,
}: Props) {
  const {itemIdForUpdate} = listView
  const {errors} = useFormikContext<AddTrackForm>()

  return (
    <>
      {index !== prods.length - 1 || itemIdForUpdate ? (
        <Accordion>
          <Accordion.Item eventKey={index.toString()}>
            <div
              className={`custom-recent-invoices-accordion-header ${
                errors.prods && errors.prods[index] ? 'error' : ''
              }`}
            >
              <Accordion.Header
                style={{
                  cursor: 'pointer',
                  width: '91.5%',
                }}
              >
                <TrackGoodsItemMinifiedHeader item={item} />
              </Accordion.Header>
            </div>

            <Accordion.Body>
              <TrackGoodsItemBody
                index={index}
                item={item}
                arrayHelpers={arrayHelpers}
                prods={prods}
                setFieldValue={setFieldValue}
                values={values}
                listView={listView}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <TrackGoodsItemBody
          index={index}
          item={item}
          arrayHelpers={arrayHelpers}
          prods={prods}
          setFieldValue={setFieldValue}
          values={values}
          listView={listView}
        />
      )}
    </>
  )
}
