import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IShipment} from '../../core/_models/_models'

interface Props {
  shipment: IShipment
}

export function ShipmentHeaderNav({shipment}: Props) {
  const {pathname} = useLocation()
  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/shipments/edit/overview/${shipment.id}` && 'active')
            }
            to={`/shipments/edit/overview/${shipment.id}`}
          >
            Основне
            {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (pathname === `/shipments/edit/tracks/${shipment.id}` && 'active')
            }
            to={`/shipments/edit/tracks/${shipment.id}`}
          >
            Треки
            {/* <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS' /> */}
          </Link>
        </li>
      </ul>
    </div>
  )
}
