export const getImage = async (): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    fetch('http://127.0.0.1:8000/image', {cache: 'no-store'})
      .then((res) => res.blob())
      .then((blob) => {
        resolve(blob)
      })
      .catch((err) => {
        console.log(err)

        reject(err)
      })
  })
}

export const getFullUpdate = async (id: string | number): Promise<any> => {
  try {
    const response = await fetch(`http://127.0.0.1:8000/update/${id}`, {
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      cache: 'no-store',
    })

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getUpdImage = async (id: string | number): Promise<string> => {
  try {
    const response = await fetch(`http://127.0.0.1:8000/update-image/${id}`, {
      headers: {
        'Content-Type': 'image/jpeg',
      },
      cache: 'no-store',
    })

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}
export const getData = async (id: string | number): Promise<any> => {
  try {
    const response = await fetch(`http://127.0.0.1:8000/update-data/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
    })

    if (!response.ok) {
      throw new Error('Request failed with status ' + response.status)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}
