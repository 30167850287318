import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  symbol?: string
  children: any
  svg?: string
  addClass?: string
  containerClass?: string
  addSvgClass?: string
}

export function ModalNotification({
  symbol,
  svg,
  children,
  addClass,
  containerClass,
  addSvgClass,
}: Props) {
  return (
    <div className={`ModalNotification ${containerClass ? containerClass : ''}`}>
      {symbol && (
        <div className={'ModalNotification__label fw-bold position-relative'}>
          <span> {symbol}</span>

          <div className={`ModalNotification__extended bg-white ${addClass ? addClass : ''}`}>
            {children}
          </div>
        </div>
      )}
      {svg && (
        <div className={'position-relative'}>
          <KTSVG path={svg} className={`svg-icon-4 ${addSvgClass ? addSvgClass : ''}`} />
          <div className={`ModalNotification__extended bg-white ${addClass ? addClass : ''}`}>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}
