/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {DirectionHeaderName} from './DirectionHeaderName'
import {DirectionHeaderInfo} from './DirectionHeaderInfo'
import {DirectionHeaderNav} from './DirectionHeaderNav'
import {Direction} from '../../core/_models'

interface Props {
  direction: Direction
}

const DirectionHeader = ({direction}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr030.svg')} alt='Location' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <DirectionHeaderName direction={direction} />{' '}
            </div>
          </div>
          <DirectionHeaderInfo direction={direction} />
        </div>

        <DirectionHeaderNav direction={direction} />
      </div>
    </div>
  )
}

export {DirectionHeader}
