import {useEffect, useState} from 'react'
import {Formik, Form, FormikHelpers} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {CreateCategoryModalHeader} from './CreateCategoryModalHeader'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {
  ListViewContextProps,
  QUERIES,
  QueryResponseContextProps,
} from '../../../../../_metronic/helpers'
import {categoriesLocales, ICategoryForm, ICategoryTranslation} from '../core/_models'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {createCategory} from '../core/_requests'

const CreateCategoryForm = () => {
  const intl = useIntl()
  const {selectedLang} = getConfig()

  const {setItemIdForUpdate} = useListView()

  const {refetch} = useQueryResponse()

  const inits: Partial<ICategoryForm> = {
    name: '',
  }

  const [initValues] = useState<Partial<ICategoryForm>>(inits)

  const [isLoading, setIsLoading] = useState(false)

  //mutation
  const createCategoryMutation = useMutation(createCategory, {
    onMutate: () => {
      setIsLoading(true)
      //   setCreateResponse('loading')
    },
    onSuccess: (data) => {
      //   setCreateResponse('success')
      setItemIdForUpdate(undefined)
    },
    onError: (error) => {
      //   setCreateResponse('error')
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
      refetch()
    },
  })

  const handleSubmit = (
    values: Partial<ICategoryForm>,
    formikHelpers: FormikHelpers<Partial<ICategoryForm>>
  ) => {
    let translations: ICategoryTranslation = {}

    categoriesLocales.forEach((locale) => {
      translations[locale] = {
        name: values.name || '',
      }
    })
    createCategoryMutation.mutate({...values, translations})
    formikHelpers.resetForm()
  }

  useEffect(() => {
    document.body.style.overflow = ''
  }, [])

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().min(3).required('Вкажіть назву'),
      })}
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({values, setFieldValue, isValid, errors, touched}) => {
        return (
          <Form className='form h-100' noValidate id='kt_modal_create_article_form'>
            <CreateCategoryModalHeader isLoading={isLoading} isValid={isValid} />
            <div
              className='scroll-y bg-white modal-body p-0 h-100'
              style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
            >
              <div className='p-3 text-primary'>
                {/* {JSON.stringify(errors)}
                {JSON.stringify(values)} */}
                <div className='w-100'>
                  <div className='fv-row mb-5'>
                    {' '}
                    <InputTemplate
                      inputName='name'
                      type='text'
                      required={true}
                      title={intl.formatMessage({
                        id: 'WEBSITE_CATEGORIES.LIST.ADD_MODAL.FORM.TITLE_LABEL',
                      })}
                      titleFontSize='fs-5 text-dark'
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export {CreateCategoryForm}
