import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'

import {Notification} from '../../../Notification'
import {useListView as tracksUseListView} from '../../../../../pages/tracks/core/ListViewProvider'
import {InvoiceCard} from './InvoiceCard'
import {ListOfRecentInvoices} from './ListOfRecentInvoices'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {
  AddTrackForm,
  IInvoice,
  IRecentInvoiceRes,
} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {uploadInvoice} from '../../../../../pages/tracks/core/_requests'
import {LinksArray} from '../../../form-elements/LinksArray'

interface Props {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  values: AddTrackForm
  invoiceArray: IInvoice[] | []
  setInvoiceArray: Dispatch<SetStateAction<IInvoice[] | []>>
  recentInvoicesData: IRecentInvoiceRes[]
}

export function TrackInvoicesTab({
  setIsLoading,
  values,
  invoiceArray,
  setInvoiceArray,
  recentInvoicesData,
}: Props) {
  const intl = useIntl()
  const {itemIdForUpdate: trackItemIdForUpdate} = tracksUseListView()

  const handleInvoiceArray = (
    item: IInvoice,
    itemIsAdded: boolean,
    setFieldValue: ISetFieldValue
  ) => {
    setFieldValue('invoice_file', item)
    itemIsAdded === true
      ? setInvoiceArray((prev) => {
          if (prev.length === 1) {
            setFieldValue('invoice_file', null)
          }

          return prev.filter((inv) => inv.file.filename !== item.file.filename)
        })
      : setInvoiceArray((prev) => [...prev, item])
  }

  const saveInvoice = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    targetValue: string
  ) => {
    setIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'invoice_file',
      'file',
      uploadInvoice
    )

    if (linkResponse) {
      const localFileName = targetValue.split('\\')[targetValue.split('\\').length - 1]
      const newObject = {
        name: localFileName,
        file: {base_url: linkResponse.base_url, filename: linkResponse.filename},
      }
      if (invoiceArray === []) {
        setInvoiceArray([newObject])
      } else {
        setInvoiceArray((prev) => [...prev, newObject])
      }

      setFieldValue('invoice_file', newObject)
    } else {
      console.log('ошибка')
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className='w-100'>
        <div className='fv-row mb-5'>
          <div className='mb-3'>
            <h4
              className={`fw-bold fs-6 ${
                +values.require_invoice === 0 ? false : !invoiceArray[0] ? 'required' : ''
              }`}
              style={{color: 'inherit'}}
            >
              <FormattedMessage id={'TRACK_PAGE.INVOICE_TAB.LABEL'} />
            </h4>
          </div>
          <LinksArray
            currentLinks={values?.invoiceLinks || []}
            inputName='invoiceLinks'
            floating={true}
          />
        </div>
        <div className='fv-row'></div>
        <div className='d-flex flex-gutter flex-wrap align-items-start'>
          {invoiceArray.map((item, index) => (
            <InvoiceCard
              key={index}
              title={item.name}
              id={item.file.filename}
              icon='/media/svg/files/pdf.svg'
              handleDelete={setInvoiceArray}
              fileType={item.file.filename.split('.')[item.file.filename.split('.').length - 1]}
              uploadedFile={{
                base: item.file.base_url,
                link: item.file.filename || '',
              }}
              linkEndpoint='/files'
              loading={false}
              invoiceArray={invoiceArray}
              handleInvoiceArray={handleInvoiceArray}
            />
          ))}
        </div>
        {!trackItemIdForUpdate && (
          <div className='fv-row mb-10'>
            <ListOfRecentInvoices
              handleInvoiceArray={handleInvoiceArray}
              addedInvoices={invoiceArray}
              uploadedFiles={invoiceArray}
              uploadFunction={saveInvoice}
            />
          </div>
        )}
        <div className='my-8'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INVOICE_FORMATS_EXPL',
            })}
          />
        </div>
      </div>
    </>
  )
}
