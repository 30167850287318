import {Link} from 'react-router-dom'
import {IOrder, IOrderProps} from '../../../../core/_models/_orders-models'

const OrderUserCell = ({order}: IOrderProps) => {
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {order?.user?.id ? (
          <Link
            to={`/users/edit/overview/${order?.user?.id}`}
            className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
          >
            <span>{order?.user?.externalId}</span>
            <span className='text-muted fs-6'>
              {' '}
              {[order?.user?.lastName, order.user?.firstName, order.user?.patronymic].join(' ')}
            </span>
          </Link>
        ) : (
          '-'
        )}
      </p>
    </div>
  )
}

export {OrderUserCell}
