import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {RegionActionsCell} from './cells/RegionActionsCell'
import {RegionNameCell} from './cells/RegionNameCell'
import {RegionDeliveryMethodsCell} from './cells/RegionDeliveryMethodsCell'
import {IRegion} from '../../../core/_models'
import {RegionCourierCell} from './cells/RegionCourierCell'
import {RegionPriorityCell} from './cells/RegionPriorityCell'

const regionsColumns: ReadonlyArray<Column<IRegion>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'REGIONS_COLUMNS_NAME'} className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <RegionNameCell region={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'REGIONS_COLUMNS_COURIERS'} className='min-w-125px' />
    ),
    id: 'courier',
    Cell: ({...props}) => <RegionCourierCell region={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'REGIONS_COLUMNS_DEL_METHODS'}
        className='min-w-125px'
      />
    ),
    id: 'delMethods',
    Cell: ({...props}) => <RegionDeliveryMethodsCell region={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'REGIONS_COLUMNS_PRIORITY'} className='min-w-125px' />
    ),
    id: 'priority',
    Cell: ({...props}) => <RegionPriorityCell region={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <RegionActionsCell region={props.data[props.row.index]} />,
  },
]

export {regionsColumns}
