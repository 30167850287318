import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {IOrderHistory} from '../../orders/core/_models/_orders-models'
import {ArticlesQueryResponse, IArticle, ICreateArticle, IEditArticle, TArticleLog} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const BLOG_URL = `/website_articles`
const BASE_URL = `${API_URL}${BLOG_URL}`

const getArticles = (query: string): Promise<ArticlesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ArticlesQueryResponse>) => {
      return d.data
    })
}

const getArticleById = async (id: ID): Promise<IArticle | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const addArticle = (article: ICreateArticle): Promise<IArticle | undefined> => {
  return axios
    .post(`${BASE_URL}`, article)
    .then((response: AxiosResponse<Response<IArticle>>) => response.data)
    .then((response: Response<IArticle>) => response.data)
}

const editArticle = (article: Partial<IEditArticle>, id: ID): Promise<IArticle | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, article)
    .then((response: AxiosResponse<Response<IArticle>>) => response.data)
    .then((response: Response<IArticle>) => response.data)
}

const getArticleLogsById = async (id: ID): Promise<TArticleLog[] | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}/history`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const uploadImage = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const deleteArticle = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

export {
  getArticles,
  getArticleById,
  uploadImage,
  addArticle,
  editArticle,
  deleteArticle,
  getArticleLogsById,
}
