/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Portal} from '../../../../../../_metronic/partials'
import {BuyoutItemCard} from './BuyoutItemCard'
import {EditBuyoutProductsForm} from './_modals/EditBuyoutProductsForm'
import {CreateItemCard} from '../../../../../modules/custom/sections/CreateItemCard'
import {FormattedMessage, useIntl} from 'react-intl'
import {IOrderBuyoutProps} from '../../../../orders/core/_models/_orders-models'

interface Props {
  invalidateBuyout: () => void
}

export function BuyoutContent({buyout, invalidateBuyout}: Props & IOrderBuyoutProps) {
  const {products} = buyout
  const intl = useIntl()

  // const sortedProds = Object.values(products).sort((a, b) => a.unavaliable - b.unavaliable)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      {modalOpen && (
        <Portal
          children={
            <EditBuyoutProductsForm
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              buyout={buyout}
              invalidateBuyout={invalidateBuyout}
            />
          }
          className='modal-root'
        />
      )}
      <div className='d-flex flex-wrap flex-stack mb-10 '>
        <h3 className='fw-bolder my-2'>
          <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.HEADING' />
        </h3>
        <div className='d-flex flex-wrap'></div>
      </div>{' '}
      <div className='row g-6 g-xl-9'>
        {products.map((item) => (
          <BuyoutItemCard
            key={item.id}
            item={item}
            buyout={buyout}
            invalidateBuyout={invalidateBuyout}
          />
        ))}

        <CreateItemCard
          itemName={intl.formatMessage({id: 'BUYOUT_PAGE.CONTENT_TAB.CREATE_GOODS'})}
          setModalOpen={setModalOpen}
        />
      </div>
    </>
  )
}
