import {ErrorMessage, useFormikContext} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {handleFileUpload} from '../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FileInput} from '../../../modules/custom/form-elements/file-inputs/FileInput'
import {IDeliveryType} from '../core/_models'
import {deliveryTypesApi} from '../core/_requests'

interface IProps {
  deliveryType?: IDeliveryType
  withTitle?: boolean
}

const Image = ({deliveryType, withTitle = true}: IProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()
  const [loading, setLoading] = useState(false)

  const [imageObj, setImageObj] = useState<{logo: string; logoUrl: string} | null>({
    logo: deliveryType?.logo || '',
    logoUrl: deliveryType?.logoUrl || '',
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    try {
      setLoading(true)

      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        'avatar',
        'logo',
        deliveryTypesApi.uploadLogo,
        false
      )

      setImageObj(linkResponse)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('avatar', '')

    setImageObj(null)
  }
  return (
    <div className='fv-row'>
      <FileInput
        title={
          withTitle
            ? intl.formatMessage({
                id: 'GENERAL.UPLOAD_IMAGE_LABEL',
              })
            : ''
        }
        inputName='avatar'
        inputId={`${deliveryType?.id}-file-input`}
        uploadedFile={{
          base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
          link: imageObj?.logoUrl || '',
        }}
        setFieldValue={setFieldValue}
        uploadFunction={saveImage}
        deleteFunction={deleteImage}
        loading={loading}
      />
    </div>
  )
}

export default Image
