import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {ICollection} from '../../../../../_models'

type Props = {
  collection: ICollection
}

const CollectionTitleCell = ({collection}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={toAbsoluteUrl('/media/icons/duotune/art/art008.svg')}
          alt=''
          style={{objectFit: 'contain'}}
        />
      </div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>{collection.name}</p>
    </div>
  )
}

export {CollectionTitleCell}
