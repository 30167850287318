/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {
  getSavedFilter,
  initialQueryState,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponsePagination} from '../../../../core/QueryResponseProvider'
import {FormattedMessage} from 'react-intl'
import {getDateForBackend} from '../../../../../../chats/core/_funcs'
import GeneralFilter from './elements/GeneralFilter'
import DatePicker from './elements/DatePicker'

const ListFilter = () => {
  const pagination = useQueryResponsePagination()
  const {updateState, state} = useQueryRequest()

  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})

  useEffect(() => {
    MenuComponent.reinitialization()
    const savedFilter = getSavedFilter(QUERIES.PAYMENTS_LIST)
    if (savedFilter) {
      setSavedFilter(savedFilter)
    }
  }, [])

  useEffect(() => {
    filterData()
  }, [
    savedFilter?.createdAfter,
    savedFilter?.createdBefore,
    savedFilter?.user?.value,
    savedFilter?.paymentProvider?.value,
  ])

  const [dateState, setDateState] = useState<any>({
    startDate: savedFilter?.createdAfter || undefined,
    endDate: savedFilter?.createdBefore || undefined,
  })

  const resetFilter = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setDateState(undefined)
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.PAYMENTS_LIST), '{}')

    const allMenus = document.querySelectorAll('.menu.show')
    const allMenusArray = Array.from(allMenus)
    allMenusArray.map((menu) => menu.classList.remove('show'))
  }

  const filterData = () => {
    let createdAfter: string | undefined = dateState?.startDate
      ? getDateForBackend(dateState?.startDate, true)
      : undefined
    let createdBefore: string | undefined = dateState?.endDate
      ? getDateForBackend(dateState?.endDate, false)
      : undefined

    const newFilters: {[key: string]: any} = {
      'status.value': savedFilter?.status,
      activeDateSelect: savedFilter?.activeDateSelect || undefined,
      'createdAt[after]': createdAfter,
      'createdAt[before]': createdBefore,
      'user.externalId': savedFilter?.user?.externalId,
      paymentProvider: savedFilter?.paymentProvider?.value,
    }

    if (dateState?.endDate && createdAfter === createdBefore) {
      let date = dateState?.endDate
      date.setDate(date.getDate() + 1)
      createdBefore = getDateForBackend(date, false)
      newFilters['createdAt[before]'] = createdBefore
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.PAYMENTS_LIST),
      JSON.stringify(newFilters)
    )
  }

  return (
    <div className='d-flex justify-content-end'>
      {savedFilter && Object.keys(savedFilter)?.length > 0 && (
        <button type='button' className='btn btn-sm btn-light-primary me-3' onClick={resetFilter}>
          <FormattedMessage id='GENERAL.TABLE_FILTER.RESET_OUTER_BUTTON' />{' '}
        </button>
      )}
      <DatePicker
        resetFilter={resetFilter}
        filterData={filterData}
        dateState={dateState}
        setDateState={setDateState}
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
      />{' '}
      <GeneralFilter
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
        resetFilter={resetFilter}
        filterData={filterData}
      />
      {/* {JSON.stringify(savedFilter)} */}
    </div>
  )
}

export {ListFilter}
