import {Dispatch, SetStateAction} from 'react'
import {useQueryClient} from 'react-query'
import {useLocation, useParams} from 'react-router-dom'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IWebsite} from '../../../websites/core/_models'
import {IEditStore} from '../../core/_models'
import StoreHeaderLangSwitcher from './StoreHeaderLangSwitcher'
import {StoreHeaderName} from './StoreHeaderName'
import {StoreHeaderNav} from './StoreHeaderNav'

interface Props {
  store: IEditStore
}

const StoreHeader = ({store}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const params = useParams()
  const path = Object.values(params)[0]?.split('/')[0]
  const showLangSwitcher = path !== 'links' && path !== 'images'
  const website = websitesData?.find((item) => item.id === store.website.id)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={toAbsoluteUrl(
                    store?.logoUrl
                      ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + store?.logoUrl
                      : '/media/icons/duotune/ecommerce/ecm002.svg'
                  )}
                  alt='Template'
                  style={{objectFit: 'contain'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <StoreHeaderName store={store} />
              {store.categories.map((item) => (
                <div className='d-flex' key={item.id}>
                  {' '}
                  <span className='fw-bold me-2'>{item.name}: </span>
                  <a
                    href={`${process.env.REACT_APP_HTTP}${website?.host}/informaciya-dlya-klientov/katalog-magazinov/${item.slug}/${store.slug}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com001.svg'
                      className='svg-icon-3 link-primary me-1'
                    />
                    <span className='text-dark text-hover-primary'>{website?.host}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          {showLangSwitcher && <StoreHeaderLangSwitcher store={store} website={website} />}
        </div>

        <StoreHeaderNav store={store} />
      </div>
    </div>
  )
}

export {StoreHeader}
