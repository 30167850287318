import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {ReceiversListWrapper} from './receivers-list/ReceiversList'
import ReceiverPage from './receiver-page/ReceiverPage'
import {QUERIES} from '../../../../_metronic/helpers'
import {couriersApi} from '../../couriers/core/_requests'
import {directionsApi} from '../../directions/core/_requests'
import {getCountries} from '../../countries/countries-list/core/_requests'
import {useQueries} from 'react-query'

const FulfReceiversPage = () => {
  const location = useLocation()
  useQueries([
    {queryKey: [QUERIES.COUNTRIES_LIST], queryFn: () => getCountries('filter[receive]=true')},
    //  {queryKey: [QUERIES.COURIER_TYPES], queryFn: couriersApi.fetchCourierTypes},
    {queryKey: [QUERIES.COUNTRY_ABBR_LIST], queryFn: directionsApi.fetchDirectionCountriesList},
  ])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<ReceiversListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <ReceiverPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/fulfillment/receivers/list' />} />
    </Routes>
  )
}

export default FulfReceiversPage
