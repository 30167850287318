import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Location} from '../../../../core/_models'

type Props = {
  location: Location
}

const LocationActionsCell: FC<Props> = ({location}) => {
  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      <Link
        to={`/locations/edit/overview/${location.id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
      </Link>
    </div>
  )
}

export {LocationActionsCell}
