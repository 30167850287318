import {FormattedMessage, FormattedNumber, FormattedTime, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import secondsToHoursMinsSeconds from '../../../../../../_metronic/helpers/custom/funcs/secondsToHoursMinsSeconds'
import {TChatStatColProps} from './StatisticsRow'

type TProps = {
  col: TChatStatColProps
}

const StatisticsColumn = ({col}: TProps) => {
  const {label, icon, color, count, link = '', addContClass = '', time = false} = col
  const intl = useIntl()

  const convertedTime = count ? secondsToHoursMinsSeconds(count) : undefined

  return (
    <>
      {!!count && (
        <>
          {!!link ? (
            <Link
              to={link}
              className={`col d-flex justify-content-between align-items-center Dashboard__link bg-light-${color} px-6 py-8 rounded-2 shadow-xs ${addContClass}`}
            >
              <div className='d-flex flex-column'>
                <KTSVG path={icon} className={`svg-icon-3x svg-icon-${color} d-block my-2`} />
                <span className={`text-${color} fw-semobold fs-6`}>{label}</span>
              </div>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 me-3'>{count}</span>
            </Link>
          ) : (
            <div
              className={`col d-flex justify-content-between align-items-center bg-light-${color} px-6 py-8 rounded-2 shadow-xs ${addContClass}`}
            >
              {' '}
              <div className='d-flex flex-column'>
                <KTSVG path={icon} className={`svg-icon-3x svg-icon-${color} d-block my-2`} />
                <span className={`text-${color} fw-semobold fs-6`}>{label} </span>{' '}
              </div>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 me-3'>
                {time && convertedTime ? (
                  <>
                    {!!convertedTime.days && (
                      <span>
                        <FormattedMessage
                          id='GENERAL.COUNTABLES.DAYS'
                          values={{dayCount: convertedTime.days}}
                        />
                      </span>
                    )}
                    <span>
                      <FormattedTime
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertedTime.hours,
                            convertedTime.minutes,
                            convertedTime.remainingSecondsFinal
                          )
                        }
                        hour='numeric'
                        minute='numeric'
                        second='numeric'
                      />
                    </span>
                  </>
                ) : (
                  <FormattedNumber value={count} />
                )}
              </span>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default StatisticsColumn
