import {useFormikContext} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce} from '../../../../../_metronic/helpers'
import {directionsApi} from '../../../../pages/directions/core/_requests'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {Direction} from '../../../../pages/directions/core/_models'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {AddTrackForm} from '../../../../pages/tracks/core/_models/_tracks-models'
import {FlagsDirectionBar} from '../../FlagsDirectionBar'
import {InputTemplate} from '../InputTemplate'

const DirectionsSelect = ({withTitle = true}) => {
  const intl = useIntl()
  const {values, setFieldValue, dirty} = useFormikContext<{
    direction: IBasicSelect & Partial<Direction>
    sendAsTrack?: boolean
  }>()
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterDirections = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      directionsApi
        .fetchDirectionsWithIds('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: directionsFuncs.formDirection(item.countryFrom, item.countryTo, countries),
                value: item.id,
              }
            })
            setResults(reformed)
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [directionsApi.fetchDirectionsWithIds]
  )

  useEffect(() => {
    filterDirections()
  }, [])

  useEffect(() => {
    if (!values.direction?.value && results.length > 0) {
      setFieldValue('directions', results[0])
      if (values.sendAsTrack) {
        setFieldValue('sendAsTrack', values.direction?.parcelIdentByOrderNumber)
      }
    }
  }, [results])

  return (
    <InputTemplate
      inputName='direction'
      required={true}
      type='select'
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.DirectionsSelect.LABEL'}) : ''}
      titleFontSize='text-black fs-6'
      objectErrorKey='direction.label'
      reactSelectprops={{
        currentValue: dirty ? values.direction : results[0],
        options: results,
        optionFormat: ({value, label}) => (
          <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
            <FlagsDirectionBar chosenDirections={[value]} minified={true} />

            <div className='d-flex flex-column fw-bold text-gray-800 '>{label}</div>
          </div>
        ),
        selectName: 'direction',
        isLoading: resultsLoading,
        onInputChange: filterDirections,
        setFieldValue: setFieldValue,
        handleChange: () => {
          values.direction.parcelIdentByOrderNumber === true
            ? setFieldValue('sendAsTrack', false)
            : setFieldValue('sendAsTrack', true)
        },
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'TRACKS.ADD_MODAL.FORM.DIRECTIONS_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'TRACKS.ADD_MODAL.FORM.NO_DIRECTIONS_MESSAGE',
        }),
      }}
    />
  )
}

export default DirectionsSelect
