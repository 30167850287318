import {ID, KTSVG, QUERIES} from '../../../_metronic/helpers'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {useQuery, useQueryClient} from 'react-query'
import {directionsApi} from '../../pages/directions/core/_requests'
import {directionsFuncs} from '../../../_metronic/helpers/custom/funcs/directions'
import FlagsDirectionItem from './FlagsDirectionItem'
import {DirectionsQueryResponse} from '../../pages/directions/core/_models'

interface Props {
  chosenDirections: {id: ID}[]
  additionalClass?: string
  minified?: boolean
}

function FlagsDirectionBar({chosenDirections, additionalClass, minified = false}: Props) {
  const queryClient = useQueryClient()
  const generalDirections: DirectionsQueryResponse | undefined = queryClient.getQueryData([
    QUERIES.DIRECTIONS_WITH_IDS_LIST,
  ])

  return (
    <ul
      className={`FlagsDirectionBar me-2 ${additionalClass || ''} ${
        minified === true ? 'px-0 mb-0' : ''
      }`}
    >
      {' '}
      {generalDirections?.data &&
        directionsFuncs
          .parseAvailableDirections(generalDirections?.data, chosenDirections)
          .map((item, index) => (
            <FlagsDirectionItem key={index} from={item.from} to={item.to} minified={minified} />
          ))}
    </ul>
  )
}

export {FlagsDirectionBar}
