/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../_metronic/helpers'
import {ItemStep} from '../../modules/custom/ItemStep'
import {TStep} from '../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import {EditWebsite} from './_modals/EditWebsite'
import {Direction} from '../directions/core/_models'
import {IEditWebsite} from './core/_models'
import {Portal} from '../../../_metronic/partials'
import {StatusBar} from '../../modules/custom/StatusBar'
import {FlagsDirectionBar} from '../../modules/custom/FlagsDirectionBar'

interface Props {
  steps: TStep[]
  status: boolean
  dataForEditing: IEditWebsite
}

function WebsitesItem({steps, status, dataForEditing}: Props) {
  const [modalOpen, setModalOpen] = useState(false)
  const availableLangs = dataForEditing ? dataForEditing.enabledLocales : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }
  return (
    <>
      {modalOpen && (
        <Portal
          children={
            <EditWebsite
              setIsOpen={setModalOpen}
              isOpen={modalOpen}
              id={dataForEditing.id}
              dataForEditing={dataForEditing}
            />
          }
          className='modal-root'
        />
      )}
      <div className=' col-xl-6'>
        {' '}
        <div className='card mb-5 mb-xl-8'>
          {/* begin::Header */}
          <div className='card-header align-items-center border-0 mt-4'>
            <div>
              {' '}
              <h3
                className='card-title align-items-start WebsitesItem__h3 fw-bold text-dark WebsitesItem__title text-hover-primary cursor-pointer'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => setModalOpen(true)}
              >
                {dataForEditing.name}
              </h3>
              <div className='d-flex align-items-center'>
                {' '}
                <StatusBar
                  status={{
                    trueOption: 'Активно',
                    falseOption: 'Заблокировано',
                    currentBooleanValue: status === true ? true : false,
                  }}
                  allowDelete={dataForEditing.allowDelete}
                  id={dataForEditing.id}
                />{' '}
                <a
                  href={`https://${dataForEditing.host}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='fw-bold text-gray-400 text-hover-primary'
                >
                  {dataForEditing.host}
                </a>
              </div>
              <FlagsDirectionBar chosenDirections={dataForEditing.directions} />
            </div>

            <div className='card-toolbar'>
              <Link
                to={`/websites/edit/tos/${availableLangs[0]}/${dataForEditing.id}`}
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              >
                <KTSVG path='/media/icons/duotune/ecommerce/ecm008.svg' className='svg-icon-1' />
              </Link>
              <Link
                to={`/websites/translation/${dataForEditing.id}`}
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                target='_blank'
                rel='noopener noreferrer'
              >
                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-2' />
              </Link>
            </div>
          </div>
          {steps.map(({step, title, description, iconPath, stat, elementText, link}) => (
            <ItemStep
              key={step}
              step={step}
              title={title}
              description={description}
              iconPath={iconPath}
              stat={stat}
              elementText={elementText}
              link={link}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export {WebsitesItem}
