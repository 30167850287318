import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ID, selectDebounce} from '../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../InputTemplate'
import {couriersApi} from '../../../../pages/couriers/core/_requests'
import {ICourier} from '../../../../pages/couriers/core/_models'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const CouriersSelect = ({withTitle = true}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<{
    deliveryService: IBasicSelect & Partial<ICourier>
    deliveryMethods?: ID[]
    sendAsTrack?: boolean
  }>()

  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<(IBasicSelect & Partial<ICourier>)[]>([])

  const filterCouriers = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      couriersApi
        .fetchCouriers('')
        .then((res) => {
          if (res.data) {
            const reformed = res?.data?.map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.id,
              }
            })
            if (reformed) {
              setResults(reformed)
            }
            if (callback) {
              callback(reformed)
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [couriersApi.fetchCouriers]
  )

  useEffect(() => {
    filterCouriers()
  }, [filterCouriers])

  useEffect(() => {
    if (!values.deliveryService?.value && results.length > 0) {
      setFieldValue('deliveryService', results[0])

      if (
        values.deliveryMethods &&
        results[0].deliveryMethods &&
        results[0].deliveryMethods[0]?.id
      ) {
        setFieldValue('deliveryMethods', [results[0].deliveryMethods[0]?.id.toString()])
      }
    }
  }, [results])

  useEffect(() => {
    if (results.length > 0) {
      if (
        values.deliveryMethods &&
        values.deliveryService.deliveryMethods &&
        values.deliveryService.deliveryMethods[0]?.id
      ) {
        setFieldValue('deliveryMethods', [values.deliveryService.deliveryMethods[0]?.id.toString()])
      }
    }
  }, [values.deliveryService.value])

  return (
    <InputTemplate
      inputName='deliveryService'
      required={true}
      type='select'
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.CouriersSelect.LABEL'}) : ''}
      titleFontSize='text-black fs-6'
      objectErrorKey='deliveryService.label'
      reactSelectprops={{
        currentValue: values.deliveryService || results[0],
        options: results,
        optionFormat: ({logoUrl, name}) => (
          <div className='tagify__dropdown__item d-flex align-items-center'>
            <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
              <img
                alt=''
                className='rounded-circle w-25px me-2'
                src={logoUrl ? `${BASE_URL}${logoUrl}` : '/media/icons/duotune/maps/map005.svg'}
              />
            </div>

            <div className='d-flex flex-column text-gray-800'>
              <strong>{name}</strong>
            </div>
          </div>
        ),
        selectName: 'deliveryService',
        isLoading: resultsLoading,
        onInputChange: filterCouriers,
        setFieldValue: setFieldValue,

        setObject: true,
        placeholder: intl.formatMessage({
          id: 'TRACKS.ADD_MODAL.FORM.DIRECTIONS_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'TRACKS.ADD_MODAL.FORM.NO_DIRECTIONS_MESSAGE',
        }),
      }}
    />
  )
}

export default CouriersSelect
