import ListSearch from '../../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../../core/QueryRequestProvider'

const TranslationsListHeader = () => {
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6 justify-content-between align-items-start'>
      <ListSearch updateState={updateState} />
    </div>
  )
}

export {TranslationsListHeader}
