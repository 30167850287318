import React, {useEffect} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {useState} from 'react'

import {CardFooter} from '../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CheckboxSwitcher} from '../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {EmailTemplate} from '../templates-list/core/_models'
import {FormattedMessage, useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {editEmailTemplate} from '../templates-list/core/_requests'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

// import {templateOverviewSchema} from '../templates-list/core/yup/template-page'

interface Props {
  template: EmailTemplate
  setCurrTemplate: (obj: EmailTemplate) => void
}

export function TemplateFieldsTab({template, setCurrTemplate}: Props) {
  const intl = useIntl()

  const {locale} = useParams()
  const initialValues: Record<string, string> =
    template?.translations && locale
      ? {...template.translations[locale].bodyTranslations}
      : {key: 'value'}

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Record<string, string>) => {
    setLoading(true)

    try {
      const translations =
        template.translations && locale
          ? {
              translations: {
                ...template.translations,
                [locale]: {...template.translations[locale], bodyTranslations: {...values}},
              },
            }
          : template

      const response = await editEmailTemplate(template.id, translations)

      if (response) {
        setCurrTemplate(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      {template?.translations && locale && template?.translations[locale] ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body border-top p-9'>
                {/* @ts-ignore */}
                {Object.entries(template?.translations[locale]?.bodyTranslations).map(
                  ([fieldKey, fieldValue]) => (
                    <div className='row align-items-center' key={fieldKey}>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <p>{fieldKey}</p>
                        <p className='fs-8 text-muted'>{fieldValue}</p>
                      </label>

                      <div className='col-lg-8'>
                        <InputTemplate inputName={fieldKey} required={false} type='textarea' />
                      </div>
                    </div>
                  )
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
