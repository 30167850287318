import axios from 'axios'
import {IFulfGoodsToSend, IFulfParcelToSend} from './_models'

const API = process.env.REACT_APP_API_PATH

const PARCELS_ENDPOINT_BASE = `/parcels`
const PRODUCTS_ENDPOINT_BASE = `/parcel_products`

const identifyFulfItem = async (id: string) => {
  const {data: response} = await axios.get(`${API}/parcel-or-product/${id}`)

  return response
}

// upload images
const uploadParcelImage = async (blob: Blob) => {
  let formData = new FormData()

  formData.append('image', blob as any)

  try {
    const {data} = await axios.post(`${API}/parcel_image/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}
const uploadGoodsImage = async (blob: Blob) => {
  let formData = new FormData()

  formData.append('image', blob as any)

  try {
    const {data} = await axios.post(`${API}/parcel_product_image/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

// create

const addParcel = async (data: IFulfParcelToSend) => {
  const {data: response} = await axios.post(`${API}${PARCELS_ENDPOINT_BASE}/from-camera`, data)

  return response
}
const addParcelProduct = async (data: IFulfGoodsToSend) => {
  const {data: response} = await axios.post(`${API}${PRODUCTS_ENDPOINT_BASE}/from-camera`, data)

  return response
}
// etc

const getUserByExternalId = async (
  id: string | number
): Promise<{firstName: string; lastName: string}> => {
  try {
    const {data} = await axios.get(`${API}/users/by-external-id/${id}`)
    return {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fulfillmentApi = {
  addParcel,
  addParcelProduct,
  getUserByExternalId,
  identifyFulfItem,
  uploadParcelImage,
  uploadGoodsImage,
}
