import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../directions/core/_requests'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {getCountries} from '../countries/countries-list/core/_requests'
import WarehousePage from './warehouse-page/WarehousePage'
import {WarehousesListWrapper} from './warehouses-list/WarehousesList'

const WarehousesPage = () => {
  const location = useLocation()
  useQuery(QUERIES.COUNTRIES_LIST, () => getCountries(''))

  useQuery(QUERIES.COUNTRY_ABBR_LIST, directionsApi.fetchDirectionCountriesList)

  const {config} = useLayout()

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <WarehousesListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <WarehousePage
              id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/warehouses/list' />} />
    </Routes>
  )
}

export default WarehousesPage
