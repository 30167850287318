import {FC} from 'react'
import {IWarehouse} from '../../../../core/_models'

type Props = {
  warehouse: IWarehouse
}

const WarehouseNameCell: FC<Props> = ({warehouse}) => {
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold d-block fs-6 me-1'>{warehouse.name}</p>
    </div>
  )
}

export {WarehouseNameCell}
