import {Column} from 'react-table'
import {EmployeeInfoCell} from './general/EmployeeInfoCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'
import {EmployeeBalance} from './activity/EmployeeBalance'
import {EmployeeAddress} from './general/EmployeeAddress'
import {EmployeeStatNumber} from './activity/EmployeeStatNumber'
import {EmployeeContacts} from './general/EmployeeContacts'

const employeesColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='ПІП' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <EmployeeInfoCell employee={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Адреса' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <EmployeeAddress employee={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Контакти' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <EmployeeContacts employee={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        iconAlt='Викупи'
      />
    ),
    id: 'buyouts',
    Cell: ({...props}) => (
      <EmployeeStatNumber iconAlt='Викупи' icon='/media/icons/duotune/ecommerce/ecm005.svg' />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/ecommerce/ecm010.svg'
        iconAlt='Треки'
      />
    ),
    id: 'tracks',
    Cell: ({...props}) => (
      <EmployeeStatNumber iconAlt='Треки' icon='/media/icons/duotune/ecommerce/ecm010.svg' />
    ),
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/general/gen017.svg'
        iconAlt='Посилки'
      />
    ),
    id: 'parcels',
    Cell: ({...props}) => (
      <EmployeeStatNumber iconAlt='Посилки' icon='/media/icons/duotune/general/gen017.svg' />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/general/gen005.svg'
        iconAlt='ТТН'
      />
    ),
    id: 'invoices',
    Cell: ({...props}) => (
      <EmployeeStatNumber iconAlt='ТТН' icon='/media/icons/duotune/general/gen005.svg' />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/finance/fin010.svg'
        iconAlt='Баланс'
      />
    ),
    id: 'balance',
    Cell: ({...props}) => (
      <EmployeeBalance
        employee={props.data[props.row.index]}
        iconAlt='Баланс'
        icon='/media/icons/duotune/finance/fin010.svg'
      />
    ),
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader
        className='text-center ps-0'
        tableProps={props}
        icon='/media/icons/duotune/communication/com014.svg'
        iconAlt='Отримувачі'
      />
    ),
    id: 'receiversNumber',
    Cell: ({...props}) => (
      <EmployeeStatNumber
        iconAlt='Отримувачі'
        icon='/media/icons/duotune/communication/com014.svg'
      />
    ),
  },
]

export {employeesColumns}
