import {ReceiversListFilter} from './ReceiversListFilter'

const ReceiversListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ReceiversListFilter /> */}

      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
    </div>
  )
}

export {ReceiversListToolbar}
