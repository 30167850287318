const objectToArray = (obj: Record<string, string> | Record<any, any>) => {
  const entries = Object.entries(obj).map(function (entry) {
    return entry
  })

  const array = entries.map((item) => {
    const countryObj = {code: item[0], name: item[1]}
    return countryObj
  })
  return array
}

const objectToArray2 = (obj: Record<any, any>) => {
  const entries = Object.entries(obj).map(function (entry) {
    return entry
  })

  const array = entries.map((item) => {

    const countryObj = {weight: +item[0], specificWeightPrice: +item[1]}
    return countryObj
  })

  return array
}
export {objectToArray, objectToArray2}
