import {ICourier} from '../../../../core/_models'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useIntl} from 'react-intl'

type TProps = {
  courier: ICourier
}

const CourierNameCell = ({courier}: TProps) => {
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  return (
    <div className='d-flex'>
      <Img courier={courier} />
      <div className='d-flex flex-column'>
        <p className='fw-bold text-dark mb-2'>{courier.name}</p>
        <div>
          <StatusBar
            status={{
              trueOption: intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'}),
              falseOption: intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'}),
              currentBooleanValue: courier.enabled,
            }}
            id={courier.id}
            refetch={refetch}
            settings={false}
          />
        </div>
      </div>
    </div>
  )
}

export {CourierNameCell}

const Img = ({courier}: TProps) => {
  const {logo, name, logoUrl} = courier

  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const imgLink = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + logoUrl
  return (
    <div className='symbol symbol-50px me-5'>
      {logoUrl ? (
        <div className='cursor-zoom-in'>
          <div className='symbol symbol-fixed position-relative'>
            <img
              src={imgLink}
              width='50'
              height='50'
              alt='courier'
              onClick={openLightbox}
              style={{objectFit: 'contain'}}
            />

            <ImageLightbox
              viewerIsOpen={viewerIsOpen}
              closeLightbox={closeLightbox}
              currentImage={currentImage}
              images={[
                {
                  src: imgLink,
                  title: name,
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <img src={toAbsoluteUrl('/media/icons/duotune/maps/map005.svg')} alt='courier' />
      )}
    </div>
  )
}
