import {FC} from 'react'
import {Websites} from './Websites'
import {ListViewProvider} from './core/ListViewProvider'

const WebsitesWrapper: FC = () => {
  return (
    <>
      <ListViewProvider>
        <Websites />
      </ListViewProvider>
    </>
  )
}

export default WebsitesWrapper
