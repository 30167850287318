import {IRegion} from '../../../../core/_models'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {useIntl} from 'react-intl'

type TProps = {
  region: IRegion
}

const RegionNameCell = ({region}: TProps) => {
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  return (
    <div className='d-flex'>
      <Img region={region} />
      <div className='d-flex flex-column'>
        <p className='fw-bold text-dark mb-2'>{region.name}</p>
      </div>
    </div>
  )
}

export {RegionNameCell}

const Img = ({region}: TProps) => {
  return (
    <div className='symbol symbol-50px me-5'>
      <img src={toAbsoluteUrl('/media/icons/duotune/maps/map002.svg')} alt={region.name} />
    </div>
  )
}
