import {FormikErrors, useFormikContext} from 'formik'
import {useEffect} from 'react'
import {ICreateCategory} from '../_models'

const getFieldErrorNames = (formikErrors: FormikErrors<ICreateCategory>): string[] => {
  const transformObjectToDotNotation = (
    obj: Record<string, any>,
    prefix = '',
    result: string[] = []
  ): string[] => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

const ScrollToFieldError = ({
  scrollBehavior = {behavior: 'smooth', block: 'center'},
}: {
  scrollBehavior?: ScrollIntoViewOptions
}) => {
  const {submitCount, isValid, errors} = useFormikContext()


  useEffect(() => {
    if (isValid) return

    const fieldErrorNames = getFieldErrorNames(errors)
    if (fieldErrorNames.length <= 0) return

    const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`)
    if (!element) return

    element.scrollIntoView(scrollBehavior)
  }, [submitCount]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default ScrollToFieldError
