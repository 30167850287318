import {Formik, Form} from 'formik'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import CouriersSelect from '../../../../../modules/custom/form-elements/selects/CouriersSelect'
import DeliveryMethods from '../../../../couriers/modules/DeliveryMethods'
import {delMethods} from '../../../../couriers/_modals/CreateCourierForm'
import {regionSettingsSchema} from '../../../core/yup/yup'
import {IRegion, ICreateNewRegion, ICreateNewRegionForm} from '../../../core/_models'
import {regionsApi} from '../../../core/_requests'

interface Props {
  region: IRegion
  setCurrRegion: (obj: IRegion) => void
}

export function RegionSettings({region, setCurrRegion}: Props) {
  const intl = useIntl()
  const initialValues: Partial<ICreateNewRegionForm> = {
    minWeight: region.minWeight || '',
    maxWeight: region.maxWeight || '',
    paidByClient: region.paidByClient ? 2 : 1,
    priority: region.priority,
  }

  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ICreateNewRegionForm>) => {
    try {
      setLoading(true)

      const {maxWeight, minWeight, paidByClient, priority} = values
      const dataTosend: Partial<ICreateNewRegion> = {
        maxWeight: maxWeight ? +maxWeight : null,
        minWeight: minWeight ? +minWeight : null,
        paidByClient: paidByClient && +paidByClient === 2 ? true : false,
        priority,
      }
      await regionsApi.editRegion(region.id, dataTosend)

      queryClient.invalidateQueries([QUERIES.REGION_BY_ID])
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={regionSettingsSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <FormattedMessage id='REGIONS.ADD_MODAL.FORM.PRIORITY_L' />
                </label>
                <div className='col-lg-8'>
                  <div className='row flex-nowrap'>
                    <div className='col-lg-6 fv-row me-4'>
                      <InputTemplate
                        inputName={`priority`}
                        fieldWidth='w-50'
                        type='number'
                        required={true}
                        value={values.priority}
                        numberWithControls={true}
                      />{' '}
                    </div>{' '}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id='REGIONS.ADD_MODAL.FORM.MIN_WEIGHT_L' />
                </label>
                <div className='col-lg-8'>
                  <div className='row flex-nowrap'>
                    <InputTemplate
                      inputName={`minWeight`}
                      fieldWidth='w-50'
                      type='number'
                      value={values.minWeight}
                      numberWithControls={true}
                    />
                  </div>
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id='REGIONS.ADD_MODAL.FORM.MAX_WEIGHT_L' />
                </label>
                <div className='col-lg-8'>
                  <div className='row flex-nowrap'>
                    <InputTemplate
                      inputName={`maxWeight`}
                      fieldWidth='w-50'
                      type='number'
                      value={values.maxWeight}
                      numberWithControls={true}
                    />
                  </div>
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <FormattedMessage id='REGIONS.ADD_MODAL.FORM.PAID_BY' />
                </label>
                {/* {JSON.stringify(values.paidByClient)} */}
                <div className='col-lg-8'>
                  <div className='row flex-nowrap'>
                    <div className='col-lg-6 fv-row me-4'>
                      <InputTemplate
                        inputName='paidByClient'
                        required={false}
                        type='radio'
                        value={1}
                        title={intl.formatMessage({
                          id: 'REGIONS.ADD_MODAL.FORM.PAID_BY.COMPANY',
                        })}
                        checkboxProps={{
                          checked: values.paidByClient ? +values.paidByClient === 1 : false,
                          disabled: false,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'paidByClient',
                          type: 'radio',
                        }}
                      />
                      <InputTemplate
                        inputName='paidByClient'
                        required={false}
                        type='radio'
                        value={2}
                        title={intl.formatMessage({id: 'REGIONS.ADD_MODAL.FORM.PAID_BY.CLIENT'})}
                        checkboxProps={{
                          checked: values.paidByClient ? +values.paidByClient === 2 : false,
                          disabled: false,
                          setFieldValue: setFieldValue,
                          value: 2,
                          inputName: 'paidByClient',
                          type: 'radio',
                        }}
                      />{' '}
                    </div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
