import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../_metronic/helpers/custom/funcs/directions'
import {FlagsDirectionBar} from '../../../../modules/custom/FlagsDirectionBar'
import {IWebsite} from '../../core/_models'

interface Props {
  website: IWebsite
}

export function WebsiteHeaderName({website}: Props) {
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {website?.name}
              </p>
            </div>
          </div>
          <a
            href={`${process.env.REACT_APP_HTTP}${website?.host}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            <KTSVG
              path='/media/icons/duotune/coding/cod006.svg'
              className='svg-icon-3 link-primary me-1'
            />
            <span className='text-dark text-hover-primary'>{website?.host}</span>
          </a>
        </div>
      </div>
    </div>
  )
}
