import {Formik, Form, Field, ErrorMessage} from 'formik'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {Notification} from '../../../../../modules/custom/Notification'
import {Direction, ICreateNewDirection} from '../../../core/_models'
import {directionsApi} from '../../../core/_requests'

interface Props {
  direction: Direction
  setCurrDirection: (obj: Direction) => void
}

export function DirectionOverview({direction, setCurrDirection}: Props) {
  const initialValues: Partial<ICreateNewDirection> = {
    countryFrom: direction.countryFrom,
    countryTo: direction.countryTo,
    senderTypes: direction.senderTypes,
  }

  const queryClient = useQueryClient()

  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const getCountries: Record<string, string> | [] = countriesList

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<ICreateNewDirection>) => {
    setLoading(true)

    const {countryFrom, countryTo, senderTypes} = values
    const response = await directionsApi.editDirection(direction.id, {
      countryFrom,
      countryTo,
      senderTypes,
    })

    if (response) {
      queryClient.invalidateQueries([QUERIES.DIRECTION_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  {/* <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' /> */}
                  Країна відправника
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        as='select'
                        disabled={true}
                        name='countryFrom'
                        className='form-select form-select-solid'
                      >
                        <option></option>

                        {objectToArray(getCountries)?.map(({code, name}, index) => (
                          <option value={code} key={index}>
                            {name}
                          </option>
                        ))}
                      </Field>
                      <div className='text-danger'>
                        <ErrorMessage name='countryFrom' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  {/* <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' /> */}
                  Країна отримувача
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <Field
                        as='select'
                        disabled={true}
                        name='countryTo'
                        className='form-select form-select-solid'
                      >
                        <option></option>

                        {objectToArray(getCountries)?.map(({code, name}, index) => (
                          <option value={code} key={index}>
                            {name}
                          </option>
                        ))}
                      </Field>
                      <div className='text-danger'>
                        <ErrorMessage name='countryTo' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'></label>
                <div className='col-lg-8'>
                  <Notification
                    noteText='Для отправителя и получателя можно использовать одну и ту же страну, если ваша компания занимается доставкой в пределах одной страны.'
                    borderTop={true}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  {/* <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' /> */}
                  Тип напрямку
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <label className='d-flex flex-stack cursor-pointer mb-5'>
                        <span className='d-flex align-items-center me-2'>
                          <span className='symbol symbol-50px me-6'>
                            <span className='symbol-label bg-light-warning'>
                              <KTSVG
                                path='/media/icons/duotune/ecommerce/ecm005.svg'
                                className='svg-icon-1'
                              />
                            </span>
                          </span>

                          <span className='d-flex flex-column'>
                            <span className='fw-bolder fs-6'>
                              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW' />
                            </span>

                            <span className='fs-7 text-muted'>
                              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW.TOOLTIP' />
                            </span>
                          </span>
                        </span>

                        <span className='form-check form-check-custom form-check-solid'>
                          <Field
                            className='form-check-input'
                            type='checkbox'
                            name='senderTypes'
                            value='1'
                          />
                        </span>
                      </label>

                      <label className='d-flex flex-stack cursor-pointer mb-5'>
                        <span className='d-flex align-items-center me-2'>
                          <span className='symbol symbol-50px me-6'>
                            <span className='symbol-label bg-light-success'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen017.svg'
                                className='svg-icon-1'
                              />
                            </span>
                          </span>

                          <span className='d-flex flex-column'>
                            <span className='fw-bolder fs-6'>
                              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL' />
                            </span>
                            <span className='fs-7 text-muted'>
                              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL.TOOLTIP' />
                            </span>
                          </span>
                        </span>

                        <span className='form-check form-check-custom form-check-solid'>
                          <Field
                            className='form-check-input'
                            type='checkbox'
                            name='senderTypes'
                            value='2'
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>{' '}
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
