import {Formik, Form, FieldArray} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {updateKey} from '../../core/_requests'
import {ITranslationObject} from '../../../../core/_models'
import PartialPageLoader from '../../../../../../../_metronic/layout/core/PartialPageLoader'
import ReactQuill from 'react-quill'

interface Props {
  translation: ITranslationObject
  invalidateTranslation: () => void
}

const modules = {
  toolbar: [
    [{header: [1, 2, 3, 'normal']}],
    ['bold', 'italic', 'underline', 'link'],
    [{list: 'ordered'}, {list: 'bullet'},],
    [{ align: [] },],
    ['clean'],
  ],
}
export function TranslationTab({translation, invalidateTranslation}: Props) {
  const {locale} = useParams()
  const currLocale = locale || 'uk'

  const translationArray =
    translation?.translations &&
    translation.translations
      .find((item) => item.locale === currLocale)
      ?.value.split(/<\/?p>/)
      .map((item) => item.trim())
      .filter(function (el) {
        return el !== ''
      })

  const initialValues = {
    translation: translationArray && translationArray?.length > 0 ? translationArray : [''],
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {translation: string[]}) => {
    setLoading(true)

    try {
      const translationsToSend = {
        ...translation,
        translations: [
          ...translation.translations.filter((item) => item.locale !== currLocale),
          {
            locale: currLocale,
            value: values.translation.reduce((acc, curr) => acc + `${curr.trim()}`, ''),
          },
        ],
      }

      await updateKey(translationsToSend, translation.id)

      invalidateTranslation()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <div className='card-header pt-5'>
        <p className='fw-bolder'> {translation.keyName}</p>
        <p className='fs-8 text-muted'>{initialValues.translation}</p>
      </div>
      {translation?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-client-tr'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                {translation?.translations && (
                  <div>
                    <FieldArray
                      name='translation'
                      render={(arrayHelpers) => (
                        <div className='d-flex flex-column flex-col-gutter'>
                          {values.translation.map((p, index) => (
                            <div
                              key={index}
                              className={`row ${
                                index === values.translation.length - 1
                                  ? 'd-flex align-items-center'
                                  : ''
                              }`}
                            >
                              <div className='col-lg-3 col-form-label fw-bold fs-6 d-flex flex-column flex-col-gutter align-items-center'>
                                {values.translation.length > 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary m-0'
                                      type='button'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen040.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0'
                                      />
                                    </button>
                                  </div>
                                )}
                                {index === values.translation.length - 1 && (
                                  <div>
                                    <button
                                      className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                                      type='button'
                                      onClick={() => {
                                        arrayHelpers.push('')
                                      }}
                                    >
                                      Додати абзац
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen041.svg'
                                        className='svg-icon-1x svg-icon-primary d-block m-0 mx-2'
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-9'>
                                <ReactQuill
                                  theme='snow'
                                  modules={modules}
                                  value={values.translation[index]}
                                  onChange={(e) => {
                                    setFieldValue(`translation.${index}`, e)
                                  }}
                                />
                                <InputTemplate
                                  inputName={`translation.${index}`}
                                  type='textarea'
                                  fieldWidth='d-none'
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
