import {ID, Response} from '../../../../_metronic/helpers'

export const chatCategories = {
  buyout: '/media/icons/duotune/ecommerce/ecm005.svg',
  other: '/media/icons/duotune/general/gen008.svg',
  trackingNumber: '/media/icons/duotune/ecommerce/ecm010.svg',
  parcel: '/media/icons/duotune/general/gen017.svg',
  user: '/media/icons/duotune/communication/com013.svg',
}

export type TChatUser = {
  id: ID
  externalId: string
  firstName: string
  lastName: string | null
  patronymic: string | null
}
export interface IChat {
  status: {
    status: number
    createdAt: string
    message: string
  }
  direction: {countryFrom: string; countryTo: string} | null
  outcome: string | null
  createdAt: string
  title?: string
  fullTitle: string
  firstUser: TChatUser
  firstReplyTime: number
  firstReplyUser: TChatUser
  subjectType: keyof typeof chatCategories
  openTime: number
}

export type ChatsQueryResponse = Response<Array<IChat>>
