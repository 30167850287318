import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ICreateUser} from '../../../modules/custom/users/create-client-or-employee/_models'
import {User, UserQueryResponse, UsersQueryResponse} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const USER_URL = `${API_URL}/users`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${USER_URL}/${id}`).then((response: AxiosResponse<User>) => response.data)
}

const getUserHistory = async (id: ID) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get(`${USER_URL}/${id}/history`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return data
}

const createClient = (user: Partial<ICreateUser>): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/create`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const editUserProfile = (user: Partial<User>, id: ID): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/${id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const resendUserToExternalWebsite = (id: ID): Promise<string | undefined> => {
  return axios
    .post(`${USER_URL}/${id}/sync-to-vector`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

export function confirmEmailResend(id: ID) {
  return axios.post(`${USER_URL}/${id}/registration-resend`, {})
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createClient,
  updateUser,
  editUserProfile,
  resendUserToExternalWebsite,
  getUserHistory,
}
