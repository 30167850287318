import {useFormikContext} from 'formik'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import {CurrencySelect} from '../../../../../modules/custom/form-elements/CurrencySelect'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import PaymentProvidersSelect from '../../../../../modules/custom/form-elements/selects/PaymentProvidersSelect'
import {ModalNotification} from '../../../../../modules/custom/modals/ModalNotification'
import {useListView} from '../../../core/ListViewProvider'
import currenciesLocal from '../../../../../../data/general/currencies.json'
import {TCurrencyData} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

const BuyoutSection = () => {
  const {values, setFieldValue} = useFormikContext<any>()
  const {itemIdForUpdate} = useListView()

  const queryClient = useQueryClient()
  const currSymbols: TCurrencyData = currenciesLocal

  const currenciesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.CURRENCIES_LIST]) || {}
  return (
    <>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
          <h3>Викуп</h3>
        </label>

        <div className='col-lg-8'></div>
      </div>
      {currenciesList && (
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
            <FormattedMessage id='GENERAL.LABELS.CURRENCY' />
          </label>

          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <InputTemplate
                  inputName='buyoutCurrency'
                  type='select'
                  setFieldValue={setFieldValue}
                  value={values.buyoutCurrency}
                  custom={
                    <CurrencySelect
                      name='buyoutCurrency'
                      currencies={objectToArray(currenciesList)}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
          <FormattedMessage id='COMPONENTS.PaymentProvidersSelect.LABEL' />
        </label>

        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <PaymentProvidersSelect withTitle={false} inputName='orderPaymentProvider' />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-6'>
        <div className='col-lg-4 col-form-label d-flex'>
          <label className='fw-bold fs-6 required'>
            <span>
              <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.MIN_BUYOUT_PRICE' />{' '}
            </span>
          </label>{' '}
          <ModalNotification
            svg='/media/icons/duotune/general/gen046.svg'
            addSvgClass='svg-icon-3'
            addClass='ModalNotification__position'
            children={
              <div className='fs-7 fw-normal text-black'>
                Система автоматично блокуватиме викупи меншої вартості.
              </div>
            }
          />
        </div>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              {' '}
              <InputTemplate
                inputName={`minBuyoutPrice`}
                disabled={!!itemIdForUpdate}
                type='number'
                addSymbol={currSymbols[values.buyoutCurrency]?.symbol}
                symbolMarginTop={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyoutSection
