import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {LayoutSetup, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {CategoriesListWrapper} from './categories/CategoriesList'
import CategoriesPage from './categories/CategoriesPage'
import StatusesPage from './statuses/StatusesPage'

const ListsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='categories/*' element={<CategoriesPage />} />
        <Route path='statuses/*' element={<StatusesPage />} />
      </Route>
      <Route index element={<Navigate to='/lists/categories' />} />
    </Routes>
  )
}

export default ListsPage
