import {FC, useState} from 'react'
import * as Yup from 'yup'
import {Field, FormikValues, Formik, Form} from 'formik'
import {updateKey} from '../../core/_requests'
import {ITranslationObject} from '../../../../core/_models'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

type Props = {
  isUserLoading: boolean
  translation: ITranslationObject
}

const editUserSchema = Yup.object().shape({
  translations: Yup.array()
    .of(Yup.object().shape({locale: Yup.string(), value: Yup.string()}))
    .label('Переводы'),
})

const TranslationEditModalForm: FC<Props> = ({translation, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [translationsForEdit] = useState<any>({translations: translation.translations})
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      await updateKey(values, translation.id)
    } catch (ex) {
      console.error(ex)
    } finally {
      actions.setSubmitting(true)
      cancel(true)
    }
  }
  return (
    <>
      <Formik
        validationSchema={editUserSchema}
        initialValues={translationsForEdit}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className='form' noValidate id='kt_modal_create_app_form'>
            <div data-kt-stepper-element='content'>
              <div className='w-100'>
                {translation.translations.map((item, index) => (
                  <div key={index} className='d-flex w-100'>
                    <div className='fv-row mb-10 TranslationEditModalForm__input-container'>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid TranslationEditModalForm__locale-input'
                        name={`translations.${index}.locale`}
                        disabled={true}
                      />
                    </div>
                    <div className='fv-row mb-10 w-100'>
                      <Field
                        as='textarea'
                        className='form-control form-control-solid mb-8 TranslationEditModalForm__translation-textarea'
                        autoComplete='off'
                        // disabled={formik.isSubmitting || isUserLoading}
                        name={`translations.${index}.value`}
                        placeholder='Перевод ключа'
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* begin::Actions */}
              <div className='text-center pt-15'>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                  // disabled={formik.isSubmitting || isUserLoading}
                >
                  Отмена
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  // disabled={
                  //   isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                  // }
                >
                  <span className='indicator-label'>Сохранить изменения</span>
                  {/* {(formik.isSubmitting || isUserLoading) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )} */}
                </button>
              </div>
              {/* end::Actions */}
            </div>
          </Form>
        )}
        {/* {(formik.isSubmitting || isUserLoading) && <UsersListLoading />} */}
      </Formik>
    </>
  )
}

export {TranslationEditModalForm}
