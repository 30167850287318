import {useListView} from '../../core/ListViewProvider'
import {CountriesListToolbar} from './CountriesListToolbar'
import {CountriesListGrouping} from './CountriesListGrouping'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'

const CountriesListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      <ListSearch updateState={updateState} />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CountriesListGrouping /> : <CountriesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CountriesListHeader}
