import {FC} from 'react'
import {IParcel} from '../../../../core/_models/_models'

type Props = {
  parcel: IParcel
}

const ParcelNumberCell: FC<Props> = ({parcel}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>{parcel.code}</p>
    </div>
  )
}

export {ParcelNumberCell}
