import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {IShipment} from '../../../core/_models/_models'
import {ShipmentNumberCell} from './cells/ShipmentNumberCell'
import {ShipmentDateCell} from './cells/ShipmentDateCell'
import {ShipmentActionsCell} from './cells/ShipmentActionsCell'
import {ShipmentTracksCell} from './cells/ShipmentTracksCell'
import {ShipmentWarehouseCell} from './cells/ShipmentWarehouseCell'
import {AddTrackCell} from './cells/AddTrackCell'

const shipmentsColumns: ReadonlyArray<Column<IShipment>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'SHIPMENTS_COLUMNS_NUMBER'} className='min-w-50px' />
    ),
    id: 'number',
    Cell: ({...props}) => <ShipmentNumberCell shipment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'SHIPMENTS_COLUMNS_DATE'} className='' />
    ),
    id: 'date',
    Cell: ({...props}) => <ShipmentDateCell shipment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'SHIPMENTS_COLUMNS_TRACKS'}
        className='min-w-80px text-center'
      />
    ),
    id: 'tracks-number',
    Cell: ({...props}) => <ShipmentTracksCell shipment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'SHIPMENTS_COLUMNS_WAREHOUSE'}
        className='min-w-80px text-center'
      />
    ),
    id: 'warehouse',
    Cell: ({...props}) => <ShipmentWarehouseCell shipment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'add-track',
    Cell: ({...props}) => <AddTrackCell shipment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ShipmentActionsCell shipment={props.data[props.row.index]} />,
  },
]

export {shipmentsColumns}
