import {
  IBasicSelect,
  ISetFieldValue,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useIntl} from 'react-intl'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {ErrorMessage} from 'formik'
import {useCallback} from 'react'
import {customStyles} from '../../../../../modules/custom/form-elements/ReactSelectTemplate'

interface Props {
  setFieldValue: ISetFieldValue
  value: IBasicSelect
  options: IBasicSelect[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  onInputChange: (query: string, callback?: any) => void
  title?: string
}

export function GroupSelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage,
  disabled = false,
  options,
  onInputChange,
  title,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage?noOptionsMessage:intl.formatMessage({id: 'COMPONENTS.SearchSelect.NO_OPTIONS'})}</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = useCallback(
    (inputValue: string, callback: any) => {
      if (!inputValue) {
        callback(options)
        return
      }

      onInputChange(inputValue, callback)
    },
    [onInputChange, options]
  )

  const handleOnChange = (selectedValue: IBasicSelect | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <div className={`fv-row d-flex w-100 flex-column selectIndex `}>
        <label
          className={`d-flex position-relative fs-5 flex-column fw-bold mb-2 w-100`}
          htmlFor={inputName}
        >
          {title && (
            <div className='mb-2 d-flex'>
              {' '}
              <span className={'fs-5 text-dark'}>{title}</span>
            </div>
          )}
          <AsyncSelect
            styles={customStyles}
            loadOptions={loadOptions}
            defaultOptions={options}
            form={inputName}
            isClearable={value.value === '' ? false : true}
            isDisabled={disabled}
            components={{NoOptionsMessage}}
            loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
            isLoading={isLoading}
            value={value}
            onInputChange={(e) => {
              onInputChange(e)
            }}
            onChange={handleOnChange}
          />
        </label>

        <div className='text-danger'>
          <ErrorMessage name={`${inputName}.value`} />
        </div>
      </div>
    </>
  )
}
