import {Form, Formik, useFormikContext} from 'formik'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ICreateWarehouse, ICreateWarehouseForm, IWarehouse} from '../../../core/_models'
import {updateWarehouse} from '../../../core/_requests'

interface Props {
  warehouse: IWarehouse
  invalidateWarehouse: () => void
}
const WarehouseOverview = ({warehouse, invalidateWarehouse}: Props) => {
  const {locale = 'uk'} = useParams()

  const initialValues: Partial<ICreateWarehouseForm> = {
    name: warehouse.translations[locale]?.name || '',
    acceptType: warehouse.acceptType || 'none',
    shipmentType: warehouse.shipmentLocal
      ? 1
      : warehouse.shipmentInternational
      ? 2
      : warehouse.shipmentToClient
      ? 3
      : 1,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ICreateWarehouseForm>) => {
    const {name, acceptType, shipmentType} = values
    setLoading(true)

    try {
      let translations = {
        ...warehouse?.translations,
        [locale]: {...warehouse?.translations[locale], name: name || ''},
      }

      const dataToSend: Partial<ICreateWarehouse> = {
        translations,
        acceptType,
        shipmentLocal: shipmentType ? +shipmentType === 1 : false,
        shipmentInternational: shipmentType ? +shipmentType === 2 : false,
        shipmentToClient: shipmentType ? +shipmentType === 3 : false,
      }

      await updateWarehouse(warehouse.id, dataToSend)

      invalidateWarehouse()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-warehouse'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва складу</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='name' required={true} type='text' />
                </div>
              </div>{' '}
              <ContentType />
              <ShipmentType />
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default WarehouseOverview

const ContentType = () => {
  const {setFieldValue, values} = useFormikContext<Partial<ICreateWarehouseForm>>()
  return (
    <div className='row'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        Вміст треку при прийомі на склад
      </label>

      <div className='col-lg-8'>
        {[
          {labelIntl: 'не відображати', value: 'none'},
          {labelIntl: 'тільки кількість', value: 'amount'},
          {labelIntl: 'повна інформація', value: 'full'},
          // @ts-ignore
        ].map(({labelIntl, value}) => (
          <InputTemplate
            key={value}
            inputName='acceptType'
            type='radio'
            value={value}
            title={
              labelIntl
              // intl.formatMessage({
              // id: 'RECEIVERS.ADD_REC_MODAL.ADDRESS_DELIVERY',
              // })
            }
            checkboxProps={{
              checked: values.acceptType === value ? true : false,
              disabled: false,
              setFieldValue: setFieldValue,
              value: value,
              inputName: 'acceptType',
              type: 'radio',
            }}
          />
        ))}
      </div>
    </div>
  )
}

const ShipmentType = () => {
  const {setFieldValue, values} = useFormikContext<Partial<ICreateWarehouseForm>>()
  return (
    <div className='row'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>Тип відправки </label>

      <div className='col-lg-8'>
        {[
          {labelIntl: 'внутрішня', value: 1},
          {labelIntl: 'міжнародна', value: 2},
          {labelIntl: 'на клієнта', value: 3},
          // @ts-ignore
        ].map(({labelIntl, value}) => (
          <InputTemplate
            key={value}
            inputName='shipmentType'
            type='radio'
            value={value}
            title={
              labelIntl
              // intl.formatMessage({
              // id: 'RECEIVERS.ADD_REC_MODAL.ADDRESS_DELIVERY',
              // })
            }
            checkboxProps={{
              checked: values.shipmentType && +values.shipmentType === +value ? true : false,
              disabled: false,
              setFieldValue: setFieldValue,
              value: value,
              inputName: 'shipmentType',
              type: 'radio',
            }}
          />
        ))}
      </div>
    </div>
  )
}
