/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {TDeliveryTwoItem} from '../../../../../_metronic/helpers/custom/tsHelpers/deliveryTypesHelpers'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  index: number
  item: TDeliveryTwoItem
  removeField: (index: number) => void
  handleChange: (index: number, name: string, value: number) => void
  setFieldValue: ISetFieldValue
  deliveryTwoFields: TDeliveryTwoItem[]
}

function CalculationTypeTwoItem({
  index,
  item,
  deliveryTwoFields,
  removeField,
  handleChange,
  setFieldValue,
}: Props) {
  return (
    <div className={`d-flex ${index === 0 ? 'align-items-end  ' : 'align-items-center'}  mb-3`}>
      <div className='fv-row me-5'>
        {' '}
        {index === 0 && (
          <label className='d-flex align-items-center fs-6 fw-normal mb-2'>Вага (кг)</label>
        )}
        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`deliveryTwoItems.${index}.weight`}
          placeholder=''
          value={index + 1}
          disabled={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(index, 'weight', +e.target.value)
          }}
        />
        <div className='text-danger'>
          <ErrorMessage name={`deliveryTwoItems.${index}.weight`} />
        </div>
      </div>
      <div className='fv-row'>
        {' '}
        {index === 0 && (
          <label className='d-flex align-items-center fs-6 fw-normal mb-2'>
            <span className='required'>Вартість ($)</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Укажите стоимость'
            ></i>
          </label>
        )}
        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name={`deliveryTwoItems.${index}.specificWeightPrice`}
          placeholder=''
          value={+item.specificWeightPrice}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('deliveryTwoItems', [
              ...deliveryTwoFields,
              {weight: index + 1, specificWeightPrice: +e.target.value},
            ])

            handleChange(index, 'specificWeightPrice', +e.target.value)
          }}
        />
        <div className='text-danger'>
          <ErrorMessage name={`deliveryTwoItems.${index}.specificWeightPrice`} />
        </div>
      </div>{' '}
      {deliveryTwoFields.length > 1 && (
        <div className='CreateDeliveryType__button-container'>
          <button className='btn' onClick={() => removeField(index)}>
            <KTSVG
              path='/media/icons/duotune/general/gen040.svg'
              className='svg-icon-2x svg-icon-primary'
            />
          </button>
        </div>
      )}
      <div className='text-danger'>
        <ErrorMessage name='deliveryTwoItems' />
      </div>
    </div>
  )
}

export {CalculationTypeTwoItem}
