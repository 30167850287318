import {useRef, useEffect, ReactNode} from 'react'
import {Field, useFormikContext} from 'formik'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  type: string
  inputName: string
  placeholder?: string
  backgroundClass?: string
  autoFocus?: boolean
  inputFontSize?: string
  handleChange?: any
  handleBlur?: any
  disabled?: boolean
  prependedFieldImage?: ReactNode
}

export function NumberTextInput({
  type,
  inputName,
  placeholder,
  backgroundClass,
  autoFocus,
  inputFontSize,
  handleChange,
  handleBlur,
  disabled = false,
  prependedFieldImage,
}: Props) {
  const autoFocusRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autoFocus === true) {
      autoFocusRef.current?.focus()
    }
  }, [autoFocus])
  const {setFieldValue} = useFormikContext()

  return (
    <div className={`position-relative`}>
      {prependedFieldImage}
      <Field
        type={type}
        disabled={disabled}
        className={`form-control form-control-lg form-control-solid text-dark  ${
          prependedFieldImage ? 'ps-20 pe-2' : ''
        } ${inputFontSize ? inputFontSize : ''} ${backgroundClass ? backgroundClass : ''}`}
        name={inputName}
        placeholder={placeholder ? placeholder : ''}
        innerRef={autoFocusRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(inputName, e.target.value)
          if (handleChange) {
            type === 'number' ? handleChange(+e.target.value) : handleChange(e.target.value)
          }
        }}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => (e.target.placeholder = '')}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          e.target.placeholder = `${placeholder ? placeholder : ''}`
          if (handleBlur) {
            handleBlur(e.target.value)
          }
        }}
      />
    </div>
  )
}
