import {Field} from 'formik'
import {useQueryClient} from 'react-query'
import {objectToArray} from '../../../../_metronic/helpers/custom/funcs/objectToArray'

const PriceRangeType = ({withTitle = true}) => {
  const queryClient = useQueryClient()
  const priceRangeTypes = queryClient.getQueryData(['priceRangeTypes']) || {}
  return (
    <div className='fv-row mb-5'>
      {withTitle && (
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span className='required'> Зона дії</span>{' '}
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Оберіть зону дії тарифа'
          ></i>
        </label>
      )}
      <Field as='select' name='priceRangeType' className='form-select form-select-solid'>
        {priceRangeTypes &&
          typeof priceRangeTypes === 'object' &&
          objectToArray(priceRangeTypes)?.map(({code, name}, index) => (
            <option key={index} value={code}>
              {name}
            </option>
          ))}
      </Field>
    </div>
  )
}

export default PriceRangeType
