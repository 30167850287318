import {useQueryClient} from 'react-query'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponse} from '../../../core/QueryResponseProvider'

const LocationsListGrouping = () => {
  const {selected, clearSelected, setItemIdForUpdate} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  // const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
  //     clearSelected()
  //   },
  // })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        Обрано <span className='me-1'>{selected.length}</span>
      </div>

      <button
        type='button'
        className='btn btn-primary'
        // onClick={async () => await deleteSelectedItems.mutateAsync()}
        onClick={() => setItemIdForUpdate(null)}
      >
        Додати регіон{' '}
      </button>
    </div>
  )
}

export {LocationsListGrouping}
