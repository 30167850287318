import {Form, Formik, FormikValues} from 'formik'
import {useState} from 'react'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {ICategory, ICategoryForm} from '../../../core/_models'
import {updateCategory} from '../../../core/_requests'
import {CategorySynonyms} from '../../../components/synonyms/CategorySynonyms'

interface Props {
  category: ICategory
  invalidateCategory: () => void
}
const CategorySynonymsTab = ({category, invalidateCategory}: Props) => {
  const initialValues: {synonyms: string[]} = {
    synonyms: category.synonyms,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<ICategoryForm>, actions: FormikValues) => {
    setLoading(true)

    try {
      await updateCategory({synonyms: values.synonyms, id: category.id})

      invalidateCategory()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
      actions.resetForm()
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Синоніми</label>

                <div className='col-lg-8'>
                  <CategorySynonyms
                    synonyms={values.synonyms || []}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>{' '}
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CategorySynonymsTab
