/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import ListSearch from '../../../../../modules/custom/lists/ListSearch'
import {OrdersListToolbar} from './OrdersListToolbar'

const OrdersListHeader = () => {
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6 justify-content-between align-items-start'>
      <ListSearch updateState={updateState} />
      <div className='card-toolbar'>
        <OrdersListToolbar />
      </div>
    </div>
  )
}

export {OrdersListHeader}
