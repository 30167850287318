import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {ImageLightbox} from '../../../../../../modules/custom/images/ImageLightbox'
import {IArticle} from '../../../../core/_models'

type Props = {
  article: IArticle
}

const ArticleTitleCell = ({article}: Props) => {
  const [currentImage, setCurrentImage] = useState(0)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        {article.imageUrl ? (
          <div className='cursor-zoom-in'>
            <div className='symbol symbol-fixed position-relative'>
              <img
                src={`${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + article.imageUrl}
                width='45'
                height='45'
                alt='blog-article'
                onClick={openLightbox}
                style={{objectFit: 'contain'}}
              />

              <ImageLightbox
                viewerIsOpen={viewerIsOpen}
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                images={[
                  {
                    src:
                      `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` +
                      article.imageUrl,
                    title: `photo`,
                  },
                ]}
              />
            </div>
          </div>
        ) : (
          <img src={toAbsoluteUrl('/media/icons/duotune/art/art008.svg')} alt='blog-article' />
        )}
      </div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>{article.name}</p>
    </div>
  )
}

export {ArticleTitleCell}
