import * as Yup from 'yup'
import {object_shape_basic_select} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'

const nameSchema = (intl: any) =>
  Yup.string().required(intl?.messages?.YUP_VALIDATION_REGION_ERR_NAME)

const deliveryServiceSchema = (intl: any) =>
  object_shape_basic_select.required(intl?.messages?.YUP_VALIDATION_REGION_ERR_COURIER)

const deliveryMethodsSchema = (intl: any) =>
  Yup.array().min(1, intl?.messages?.YUP_VALIDATION_REGION_ERR_DELMETHOD).required()

const prioritySchema = (intl: any) =>
  Yup.number().required(intl?.messages?.YUP_VALIDATION_REGION_ERR_PRIORITY)

const minWeightSchema = (intl: any) => Yup.number()

const maxWeightSchema = (intl: any) => Yup.number()

const paidByClientSchema = (intl: any) =>
  Yup.number().required(intl.messages.YUP_VALIDATION_REGION_ERR_PAID_BY)

export const regionsYupPartials = {
  nameSchema,
  deliveryServiceSchema,
  deliveryMethodsSchema,
  prioritySchema,
  minWeightSchema,
  maxWeightSchema,
  paidByClientSchema,
}
