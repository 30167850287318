import {Column} from 'react-table'
import {TranslationsKeyCell} from './TranslationsKeyCell'
import {TranslationActionsCell} from './TranslationActionsCell'
import {TranslationsCustomHeader} from './TranslationsCustomHeader'
import {ITranslationObject} from '../../../../../core/_models'

import {TranslationsCell} from './TranslationsCell'

const translationColumns: ReadonlyArray<Column<ITranslationObject>> = [
  {
    Header: (props) => (
      <TranslationsCustomHeader tableProps={props} title='Ключ' className='min-w-125px' />
    ),
    id: 'key',
    Cell: ({...props}) => <TranslationsKeyCell translation={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TranslationsCustomHeader tableProps={props} title='Перевод' className='min-w-150px' />
    ),
    id: 'translations',
    Cell: ({...props}) => <TranslationsCell translation={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TranslationsCustomHeader
        tableProps={props}
        title='Действия'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TranslationActionsCell translation={props.data[props.row.index]} />,
  },
]

export {translationColumns}
