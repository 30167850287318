import {ErrorMessage, FieldArray, FieldArrayRenderProps, useFormikContext} from 'formik'
import {ReactNode} from 'react'
import {Accordion} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IPollForm} from '../core/_models'
import QuestionAnswerItemBody from './QuestionAnswerItemBody'
import {QuestionAnswerItemMinifiedHeader} from './QuestionAnswerItemMinifiedHeader'

export interface IQAItemProps {
  index: number
  field: any
  values: Partial<IPollForm>
  questionArrayHelpers: FieldArrayRenderProps
  editingFormat: boolean
}
const QuestionAnswerItem = ({
  index,
  field,
  values,
  questionArrayHelpers,
  editingFormat,
}: IQAItemProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()
  return (
    <>
      {!editingFormat ? (
        <>
          {values.questions && index !== values.questions?.length - 1 ? (
            <Accordion>
              <Accordion.Item eventKey={index.toString()}>
                <div className={`custom-recent-invoices-accordion-header`}>
                  <Accordion.Header
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <QuestionAnswerItemMinifiedHeader item={field} />
                  </Accordion.Header>
                </div>

                <Accordion.Body>
                  <QuestionAnswerItemBody
                    index={index}
                    questionArrayHelpers={questionArrayHelpers}
                    values={values}
                    editingFormat={editingFormat}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            <QuestionAnswerItemBody
              index={index}
              questionArrayHelpers={questionArrayHelpers}
              values={values}
              editingFormat={editingFormat}
            />
          )}
        </>
      ) : (
        <QuestionAnswerItemBody
          index={index}
          questionArrayHelpers={questionArrayHelpers}
          values={values}
          editingFormat={editingFormat}
        />
      )}
    </>
  )
}

export default QuestionAnswerItem
