import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ICreateParcel, IParcel, ParcelsQueryResponse} from './_models/_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const PARCELS_URL = `/parcels`
const BASE_URL = `${APP_URL}${API_URL}${PARCELS_URL}`

const getParcels = (query: string): Promise<ParcelsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ParcelsQueryResponse>) => d.data)
}

const getParcelById = (id: ID): Promise<IParcel | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IParcel>) => d.data)
}

const createParcel = (item: Partial<ICreateParcel>): Promise<IParcel | undefined> => {
  return axios
    .post(`${BASE_URL}`, item)
    .then((response: AxiosResponse<Response<IParcel>>) => response.data)
    .then((response: Response<IParcel>) => response.data)
}

const editParcel = (store: Partial<ICreateParcel>, id: ID): Promise<IParcel | undefined> => {
  return axios
    .put(`${BASE_URL}/${id}`, store)
    .then((response: AxiosResponse<Response<IParcel>>) => response.data)
    .then((response: Response<IParcel>) => response.data)
}

export {getParcels, getParcelById, createParcel, editParcel}
