/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {
  getSavedFilter,
  initialQueryState,
  isNotEmpty,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {useQueryResponse, useQueryResponsePagination} from '../../../core/QueryResponseProvider'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLocation, useParams} from 'react-router-dom'
import {filterShipmentOptions} from '../../../core/_funcs'
import {customStyles} from '../../../../../modules/custom/form-elements/ReactSelectTemplate'
import TypeFilter from './modules/TypeFilter'
import {useListView} from '../../../core/ListViewProvider'
import PaymentStatusFilter from './modules/PaymentStatusFilter'
import DirectionFilter from './modules/DirectionFilter'

interface TProps {}

const OrdersListFilter = ({}: TProps) => {
  const pagination = useQueryResponsePagination()
  const {response} = useQueryResponse()
  const {updateState, state} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})
  const {currPage} = useListView()
  useEffect(() => {
    MenuComponent.reinitialization()
    const savedFilter = getSavedFilter(QUERIES.ORDERS_LIST)
    if (savedFilter) {
      setSavedFilter(savedFilter)
    }
  }, [])

  const resetFilter = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.ORDERS_LIST), '{}')
  }

  const filterData = () => {
    const newFilters = {
      type: savedFilter?.type,
      direction: savedFilter?.directions?.value,
      paid: savedFilter?.paid,
    }

    if (currPage === 'buyouts') {
      newFilters.type = 'buyout'
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.ORDERS_LIST),
      JSON.stringify(newFilters)
    )
  }

  return (
    <div className='d-flex justify-content-end'>
      {savedFilter && Object.keys(savedFilter).length > 0 && (
        <button type='button' className='btn btn-sm btn-light-primary me-3' onClick={resetFilter}>
          <FormattedMessage id='GENERAL.TABLE_FILTER.RESET_OUTER_BUTTON' />{' '}
        </button>
      )}
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm shadow-sm btn-${
          savedFilter && Object.keys(savedFilter).length > 0
            ? 'primary'
            : 'light-primary btn-color-muted'
        } me-3`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />: {response?.totalItems}
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
        id='filter-menu'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'> Фільтр</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <PaymentStatusFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter} />
          {currPage !== 'buyouts' && (
            <TypeFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter} />
          )}
          <DirectionFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter} />
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetFilter}
              className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.RESET' />
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.APPLY' />
            </button>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}

export {OrdersListFilter}
