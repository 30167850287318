import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {EmailTemplate} from '../../templates-list/core/_models'
import TemplateHeaderLangSwitcher from './TemplateHeaderLangSwitcher'
import {TemplateHeaderName} from './TemplateHeaderName'
import {TemplateHeaderNav} from './TemplateHeaderNav'

interface Props {
  template: EmailTemplate
}

const TemplateHeader = ({template}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/communication/com002.svg')}
                  alt='Template'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <TemplateHeaderName template={template} />{' '}
            </div>
          </div>
          <TemplateHeaderLangSwitcher template={template} />
        </div>

        <TemplateHeaderNav />
      </div>
    </div>
  )
}

export {TemplateHeader}
