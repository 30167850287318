/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {QUERIES} from '../../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {Formik, Form, FormikValues} from 'formik'
import ScrollToFieldError from './ScrollToFieldError'
import {CreateProviderModalHeader} from './CreateProviderModalHeader'
import {useIntl} from 'react-intl'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {IPaymentProviderForm} from '../core/_models'
import {paymentProvidersApi} from '../core/_requests'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'

const CreateProviderForm = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)

  const [createResponse, setCreateResponse] = useState('')

  const providerTypes: string[] =
    queryClient.getQueryData([QUERIES.PAYMENT_PROVIDER_TYPES_LIST]) || []
  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const inits: IPaymentProviderForm = {
    name: '',
    type: providerTypes[0],
  }
  const mutation = useMutation(paymentProvidersApi.addPaymentProvider, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.DIRECTIONS_WITH_IDS_LIST)
      setCreateResponse('success')
      refetch()
    },
    onMutate: () => {
      setCreateResponse('loading')
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
  })

  const handleSubmit = (values: IPaymentProviderForm, actions: FormikValues) => {
    mutation.mutate(values)

    actions.resetForm()
  }

  return (
    <Formik
      validationSchema={null}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, errors, setFieldValue, isValid}) => (
        <Form className='form' noValidate id='kt_modal_create_payment_provider_form'>
          <ScrollToFieldError />
          <CreateProviderModalHeader isLoading={isLoading} isValid={isValid} />
          {/* {JSON.stringify(errors)} */}
          {/* {JSON.stringify(values.store?.id)} */}
          <div
            className='scroll-y bg-white modal-body p-0'
            style={{height: 'calc(100vh-200px)', overflowY: 'scroll'}}
          >
            <div className='p-3'>
              <div className='w-100'>
                <h3 className='required fs-5 fw-bold mb-4 me-10'> Тип оплати</h3>
                <div className='fv-row'>
                  {providerTypes.map((item) => (
                    <InputTemplate
                      inputName='type'
                      required={false}
                      type='radio'
                      value={item}
                      title={item.charAt(0).toUpperCase() + item.slice(1)}
                      key={item}
                      checkboxProps={{
                        checked: !!values.type && values.type === item,
                        disabled: false,
                        setFieldValue: setFieldValue,
                        value: item,
                        inputName: 'type',
                        type: 'radio',
                      }}
                    />
                  ))}
                </div>
                <div className='fv-row'>
                  <InputTemplate
                    inputName='name'
                    type='text'
                    required={true}
                    title={intl.formatMessage({id: 'PAYMENT_PROVIDERS.ADD_MODAL.FORM.NAME'})}
                    titleFontSize='fs-5 text-dark'
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export {CreateProviderForm}
