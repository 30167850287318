import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IUserGroupEdit} from '../../user-groups-list/core/_models'
import {UserGroupHeaderName} from './UserGroupHeaderName'
import {UserGroupHeaderNav} from './UserGroupHeaderNav'

interface Props {
  group: IUserGroupEdit
}

const UserGroupHeader = ({group}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/abstract/abs031.svg')}
                  alt='Template'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <UserGroupHeaderName group={group} />{' '}
            </div>
          </div>
        </div>

        <UserGroupHeaderNav group={group} />
      </div>
    </div>
  )
}

export {UserGroupHeader}
