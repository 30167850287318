import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {Field} from 'formik'

interface Props {
  setFieldValue: ISetFieldValue
  title: string
  value: boolean
  addContClass?: string
}

function PatronymicCheckbox({setFieldValue, title, value, addContClass = ''}: Props) {
  return (
    <div className={`d-flex ${addContClass ? addContClass : 'mb-2'}`}>
      <label
        className='d-flex position-relative fs-6 justify-content-left     
         fw-bold me-1'
      >
        <Field
          className='form-check-input me-2'
          type='checkbox'
          name='noPatronymic'
          value={1}
          onChange={(e: any) => {
            setFieldValue('noPatronymic', e.target.checked)
          }}
          checked={value}
        />

        <div>
          {' '}
          <span className='fs-6'>{title}</span>
        </div>
      </label>
    </div>
  )
}

export {PatronymicCheckbox}
