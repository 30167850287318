/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
} from '../../../../_metronic/helpers'
import {RegionsTable} from './table/RegionsTable'
import {RegionsListHeader} from './components/header/RegionsListHeader'
import {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'

const RegionsList = () => {
  const {filterUsers, setItemIdForUpdate, itemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.REGIONS_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage id='REGIONS.PAGE.HEADER' />
        </h3>
      </div>
      <KTCard>
        <RegionsListHeader />
        <RegionsTable />
      </KTCard>
    </>
  )
}

const RegionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RegionsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RegionsListWrapper}
