import {useListView} from '../../core/ListViewProvider'
import {ReceiversListToolbar} from './ReceiversListToolbar'
import {ReceiversListGrouping} from './ReceiversListGrouping'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import ListSearch from '../../../../../../modules/custom/lists/ListSearch'

const ReceiversListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      <ListSearch updateState={updateState} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ReceiversListGrouping /> : <ReceiversListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ReceiversListHeader}
