import {useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {IDeliveryTypeForm} from '../../core/_models'

interface IProps {
  k: string
  v: string
  values: Partial<IDeliveryTypeForm>
}

const MethodRadio = ({k, v, values}: IProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      {' '}
      <InputTemplate
        inputName='volumetricWeightPriceMethod'
        type='radio'
        formValues={values}
        value={k}
        title={v}
        key={k}
        checkboxProps={{
          checked: values.volumetricWeightPriceMethod === k,
          disabled: false,
          setFieldValue: setFieldValue,
          value: k,
          inputName: 'volumetricWeightPriceMethod',
          type: 'radio',
          optionPrompt: intl.formatMessage({
            id: `DELIVERY_TYPES.ADD_TYPE.VOL_WEIGHT.PRICE_METHOD.${k?.toUpperCase()}_PROMPT`,
          }),
        }}
      />
    </>
  )
}

export default MethodRadio
