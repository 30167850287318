import {Dispatch, MutableRefObject, RefObject, SetStateAction} from 'react'
import {IUserSelect} from '../../../app/pages/users/core/_models'
import {getSavedPagination} from './helpers'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  itemsPerPage: 10 | 30 | 50 | 100 | number
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: Record<string, any>
}

export type SearchState = {
  search?: string
}

export type TypeState = {
  type?: string
}

export type Response<T> = {
  data?: T
  currentPage?: number
  itemsPerPage?: number
  totalItems?: number
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
    // chats
  }
  openCount?: number
  closedCount?: number
  firstReplyTimeAvg?: number
  openTimeAvg?: number
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & TypeState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  itemsPerPage: getSavedPagination(),
  order: 'desc',
  sort: 'id',
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemForUpdate?: any
  setItemForUpdate?: Dispatch<SetStateAction<any>>
  isAllSelected: boolean
  isArchive?: boolean
  setIsArchive?: Dispatch<SetStateAction<boolean>>
  disabled: boolean
  usersLoading?: boolean
  setUsersLoading?: Dispatch<SetStateAction<boolean>>
  usersResults?: IUserSelect[]
  setUsersResults?: Dispatch<SetStateAction<Array<IUserSelect>>>
  filterUsers?: (query: string) => void
  currPage?: string
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemForUpdate: () => {},
  isAllSelected: false,
  isArchive: false,
  setIsArchive: () => {},
  disabled: false,
  usersLoading: false,
  setUsersLoading: () => {},
  usersResults: [],
  setUsersResults: () => {},
  filterUsers: (query: string) => {},
  currPage: '',
}

export type PageViewContextProps = {
  anyChanges: boolean
  setAnyChanges: Dispatch<SetStateAction<boolean>>
  formsToSave: MutableRefObject<HTMLFormElement>[]
  setFormsToSave: Dispatch<SetStateAction<Array<MutableRefObject<HTMLFormElement>>>>
}
export const initialPageView: PageViewContextProps = {
  anyChanges: false,
  setAnyChanges: () => {},
  formsToSave: [],
  setFormsToSave: () => {},
}
