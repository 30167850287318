import {Direction} from '../../../directions/core/_models'

function getWeightUnit(direction: Direction, fullName?: boolean) {
  switch (direction?.weightUnit) {
    case 'kg':
      if (fullName) {
        return 'кілограм'
      } else {
        return 'кг'
      }

    case 'lb':
      return 'фунт'
    default:
      // console.log(directionId)

      return '¤'
  }
}

function getSizeUnit(direction: Direction) {
  switch (direction?.sizeUnit) {
    case 'cm':
      return 'см'

    case 'in':
      return 'дюйм'
    default:
      // console.log(directionId)

      return '¤'
  }
}

export {getWeightUnit, getSizeUnit}
