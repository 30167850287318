import {FC} from 'react'
import {useQuery} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {directionsFuncs} from '../../../../../../../_metronic/helpers/custom/funcs/directions'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {FlagsDirectionBar} from '../../../../../../modules/custom/FlagsDirectionBar'
import {directionsApi} from '../../../../../directions/core/_requests'
import {IDeliveryType} from '../../../../core/_models'

type Props = {
  deliveryType: IDeliveryType
}

const DeliveryTypeNameCell: FC<Props> = ({deliveryType}) => {
  const {data: countriesList} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    directionsApi.fetchDirectionCountriesList
  )
  const getCountries: Record<string, string> = countriesList

  const {refetch} = useQueryResponse()
  return (
    <div className='d-flex align-items-center mb-3'>
      <div className='symbol symbol-25px me-5'>
        <img
          src={
            deliveryType.logoUrl
              ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${deliveryType.logoUrl}`
              : toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm006.svg')
          }
          alt={deliveryType.title}
        />
      </div>{' '}
      <p className='text-dark m-0'>{deliveryType.title}</p>
    </div>
  )
}

export {DeliveryTypeNameCell}
