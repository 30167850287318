import {Dispatch, SetStateAction} from 'react'
import {IReceivingUser} from '../../_models'
import {getFulfPrintMode} from '../components/printing/helpers/printModeLS'
import {IReceivedItem} from './_models'

export type ReceiveShipmentContextProps = {
  itemForUpdate: string | null
  setItemIdForUpdate: Dispatch<SetStateAction<string | null>>
  errorMessage: string
  setErrorMessage: Dispatch<SetStateAction<string>>
  currClient: IReceivingUser
  setCurrClient: Dispatch<SetStateAction<IReceivingUser>>
  receivedItems: IReceivedItem[]
  setReceivedItems: Dispatch<SetStateAction<IReceivedItem[]>>
  initiatePrint: boolean
  setInitiatePrint: Dispatch<SetStateAction<boolean>>
  printMode: string
  printQueue: TPrintGoodsItem[]
  setPrintQueue: Dispatch<SetStateAction<TPrintGoodsItem[]>>
  customUser: boolean
  setCustomUser: Dispatch<SetStateAction<boolean>>
}

export type TPrintGoodsItem = {
  clientId: string
  goodsNumber: string
}

export const initialContext: ReceiveShipmentContextProps = {
  itemForUpdate: null,
  setItemIdForUpdate: () => {},
  currClient: {externalId: '', firstName: '', lastName: ''},
  setCurrClient: () => {},
  receivedItems: [],
  setReceivedItems: () => {},
  initiatePrint: false,
  printMode: getFulfPrintMode() || '2',
  printQueue: [],
  setPrintQueue: () => {},
  setInitiatePrint: () => {},
  errorMessage: '',
  setErrorMessage: () => {},
  customUser: false,
  setCustomUser: () => {},
}
