import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {CountriesQueryResponse, ICountry} from './_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const COUNTRIES_URL = `/country_configs`
const BASE_URL = `${APP_URL}${API_URL}${COUNTRIES_URL}`

const getCountries = (query: string): Promise<CountriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<CountriesQueryResponse>) => d.data)
}

const getCountryById = (id: ID): Promise<ICountry | undefined> => {
  const {selectedLang} = getConfig()
  console.log('id', id)

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ICountry>) => d.data)
}

const updateCountry = (id: ID, data: Partial<ICountry>): Promise<ICountry | undefined> => {
  const {selectedLang} = getConfig()
  console.log('id', id)

  return axios
    .put(`${BASE_URL}/${id}`, data, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ICountry>) => d.data)
}

export {getCountries, getCountryById, updateCountry}
