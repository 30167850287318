import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LayoutSetup, PageTitle, useLayout} from '../../../../_metronic/layout/core'
import {TelegramTranslationsListWrapper} from './translations-list/TelegramTranslationsList'
import {PageViewProvider} from './core/PageViewProvider'
import TelegramTranslationPage from './translation-page/TelegramTranslationPage'

const TelegramTranslationsPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='list' element={<TelegramTranslationsListWrapper />} />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <TelegramTranslationPage
                id={location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/translations/telegram/list`} />} />
    </Routes>
  )
}

export default TelegramTranslationsPage
