import {IChatTranslation} from '../../../../core/_models'

type Props = {
  translation: IChatTranslation
}

const TranslationKeyCell = ({translation}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold fs-6 m-0'>{translation.keyName}</p>
    </div>
  )
}

export {TranslationKeyCell}
