import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {WebsitePagesListHeader} from './components/header/WebsitePagesListHeader'
import {WebsitePagesTable} from './table/WebsitePagesTable'
import {KTCard, QUERIES} from '../../../../_metronic/helpers'
import {IWebsite} from '../../websites/core/_models'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'

const WebsitePagesList = () => {
  const {websiteId} = useParams()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] = queryClient.getQueryData(QUERIES.WEBSITES_LIST) || []
  const currWebsite = websitesData?.find((item) => websiteId && +item.id === +websiteId)

  return (
    <>
      <h3 className='mb-8'>{`Сторінки ${currWebsite?.host}`}</h3>
      <KTCard>
        <WebsitePagesListHeader />
        <WebsitePagesTable />
      </KTCard>
    </>
  )
}

const WebsitePagesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <WebsitePagesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WebsitePagesListWrapper}
