import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {IWebsite} from '../../../../../websites/core/_models'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IPoll} from '../../../../core/_models'
import {pollsApi} from '../../../../core/_requests'

type Props = {
  poll: IPoll
}

const PollActionsCell: FC<Props> = ({poll}) => {
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => item.id === poll.website.id)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {id} = poll
  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await pollsApi.deletePoll(id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const availableLangs = website ? website.enabledLocales : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      <Link
        to={`/polls/edit/overview/${availableLangs[0]}/${id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>

      {poll.allowDelete && (
        <>
          {' '}
          {deleteModalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({id: 'POLLS.PAGE.LIST.ACTIONS.TRASH_CONF'})}
                  setModalOpen={setDeleteModalOpen}
                  handleAction={() => deleteMutation.mutate()}
                  id={id}
                  isOpen={deleteModalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
          >
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setDeleteModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </a>
          </OverlayTrigger>
        </>
      )}
    </div>
  )
}

export {PollActionsCell}
