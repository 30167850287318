import {Formik, Form, ErrorMessage, Field} from 'formik'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {useListView} from '../../../core/ListViewProvider'
import {Direction, ICreateNewDirection} from '../../../core/_models'
import {directionsApi} from '../../../core/_requests'
import BuyoutSection from './BuyoutSection'
import DeliveryAndEtcSection from './DeliveryAndEtcSection'
import TaxFreeSection from './TaxFreeSection'

interface Props {
  direction: Direction
  setCurrDirection: (obj: Direction) => void
}

export function DirectionParams({direction, setCurrDirection}: Props) {
  const intl = useIntl()
  const {deliveryPaymentProvider, orderPaymentProvider} = direction
  const initialValues: Partial<ICreateNewDirection> = {
    ...direction,
    deliveryPaymentProvider: {
      ...deliveryPaymentProvider,
      value: deliveryPaymentProvider?.id,
      label: deliveryPaymentProvider?.name,
    },
    orderPaymentProvider: {
      ...orderPaymentProvider,
      value: orderPaymentProvider?.id,
      label: orderPaymentProvider?.name,
    },
  }

  const queryClient = useQueryClient()
  const weightUnits: string[] = queryClient.getQueryData([QUERIES.WEIGHT_UNITS]) || []
  const sizeUnits: string[] = queryClient.getQueryData([QUERIES.SIZE_UNITS]) || []
  const currenciesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.CURRENCIES_LIST]) || {}
  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const getCountries: Record<string, string> | [] = countriesList
  const {itemIdForUpdate} = useListView()

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<ICreateNewDirection>) => {
    setLoading(true)

    const {
      brokerFee,
      buyoutCurrency,
      minBuyoutPrice,
      serviceCurrency,
      sizeUnit,
      taxFreeLimit,
      taxFreeLimitCurrency,
      weightUnit,
      deliveryPaymentProvider,
      orderPaymentProvider,
    } = values
    const response = await directionsApi.editDirection(direction.id, {
      brokerFee,
      buyoutCurrency,
      minBuyoutPrice: minBuyoutPrice ? +minBuyoutPrice : undefined,
      serviceCurrency,
      sizeUnit,
      taxFreeLimit: taxFreeLimit ? +taxFreeLimit : undefined,
      taxFreeLimitCurrency,
      weightUnit,
      deliveryPaymentProvider: {id: deliveryPaymentProvider?.value},
      orderPaymentProvider: {id: orderPaymentProvider?.value},
    })

    if (response) {
      queryClient.invalidateQueries([QUERIES.DIRECTION_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.WEIGHT_UNIT' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <Field
                        as='select'
                        name='weightUnit'
                        className='form-select form-select-solid'
                      >
                        <option></option>
                        {weightUnits?.map((item: any, index: any) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </Field>
                      <div className='text-danger'>
                        <ErrorMessage name='weightUnit' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.SIZE_UNIT' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <Field as='select' name='sizeUnit' className='form-select form-select-solid'>
                        <option></option>
                        {sizeUnits?.map((item: any, index: any) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </Field>
                      <div className='text-danger'>
                        <ErrorMessage name='sizeUnit' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {values.senderTypes?.includes('1') && <BuyoutSection />}
              <DeliveryAndEtcSection />
              <TaxFreeSection />
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  <FormattedMessage id='DIRECTIONS.ADD_MODAL.FORM.BROKER_FEE' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <InputTemplate
                        inputName={`brokerFee`}
                        disabled={!!itemIdForUpdate}
                        type='number'
                        value={values.brokerFee}
                        numberWithControls={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
