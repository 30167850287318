import {FC} from 'react'
import {Link} from 'react-router-dom'

import {IShipment} from '../../../../core/_models/_models'

type Props = {
  shipment: IShipment
}

const ShipmentTracksCell: FC<Props> = ({shipment}) => {
  return (
    <div className='d-flex align-items-center'>
      <Link
        to={`/shipments/edit/tracks/${shipment.id}`}
        className='fw-semobold d-block fs-6 me-1'
      >
        {shipment.trackingNumbers.length}
      </Link>
    </div>
  )
}

export {ShipmentTracksCell}
