import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {IWebsite} from '../../../websites/core/_models'

import {IEditArticle} from '../../core/_models'

interface Props {
  article: IEditArticle
  website: IWebsite | undefined
}

const ArticleHeaderLangSwitcher = ({article, website}: Props) => {
  const {locale} = useParams()

  const [activeTab, setActiveTab] = useState(locale)

  const navigate = useNavigate()
  const location = useLocation()

  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  const availableLangs = website ? website.enabledLocales : []

  if (availableLangs.includes('uk')) {
    const ukIndex = availableLangs.indexOf('uk')
    availableLangs.splice(ukIndex, 1)
    availableLangs.unshift('uk')
  }

  return (
    <ul className='nav'>
      {website &&
        availableLangs.map((locale) => (
          <li className='nav-item' key={locale}>
            <button
              type='button'
              onClick={() => {
                navigate(`/blog/edit/${currTab}/${locale}/${article.id}`)

                setActiveTab(locale)
              }}
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                activeTab === locale ? 'active' : ''
              } fw-bold px-4`}
            >
              {locale}
            </button>
          </li>
        ))}
    </ul>
  )
}

export default ArticleHeaderLangSwitcher
