/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {IStatus} from '../../core/_models'

type Props = {
  status: IStatus
}

const DescriptionCell: FC<Props> = ({status}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>{status.description}</div>
    </div>
  )
}

export {DescriptionCell}
