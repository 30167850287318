import {FieldArrayRenderProps, useFormikContext} from 'formik'
import React, {useState} from 'react'
import {handleFileUpload} from '../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FileInput} from '../../../modules/custom/form-elements/file-inputs/FileInput'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {} from '../../fulfillment/accept-and-unpack/_models'
import {TImage} from '../../stores/store-page/tabs/images/StoreImages'
import {pollAnswersApi} from '../core/_answers-requests'
import {IPollForm} from '../core/_models'
import RemoveAnswerButton from './RemoveAnswerButton'

const AnswerItem = ({
  arrayHelpers,
  questionIndex,
  answerIndex,
  values,
  editingFormat,
}: {
  arrayHelpers: FieldArrayRenderProps
  questionIndex: number
  answerIndex: number
  values: Partial<IPollForm>
  editingFormat: boolean
}) => {
  const {setFieldValue} = useFormikContext()
  const [imageObj, setImageObj] = useState<TImage | null>({
    image: values?.questions ? values?.questions[questionIndex]?.answers[answerIndex]?.image : '',
    imageUrl: values?.questions
      ? values?.questions[questionIndex]?.answers[answerIndex]?.imageUrl
      : '',
    loading: false,
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue,
    field: string
  ) => {
    try {
      setImageObj((prev: any) =>
        prev
          ? {
              ...prev,
              loading: true,
            }
          : {loading: true}
      )
      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        field,
        'image',
        pollAnswersApi.uploadImage,
        false,
        `image`
      )

      setImageObj(linkResponse)

      //   invalidateStore()
    } catch (error) {
      console.log(error)
    } finally {
      setImageObj((prev: any) =>
        prev
          ? {
              ...prev,
              loading: false,
            }
          : {loading: false}
      )
    }
  }

  const deleteImage = async (setFieldValue: ISetFieldValue, field: string) => {
    setFieldValue(field, '')

    setImageObj(null)
  }

  return (
    <>
      {values.questions && values.questions[questionIndex].answers?.length > 1 && (
        <div>
          <RemoveAnswerButton
            arrayHelpers={arrayHelpers}
            answerIndex={answerIndex}
            disabled={!values.questions[questionIndex].answers[answerIndex].allowDelete}
          />
        </div>
      )}{' '}
      <InputTemplate
        key={answerIndex}
        containerMarginBottom={false}
        inputName={`questions[${questionIndex}].answers[${answerIndex}].answer`}
        type='text'
        titleFontSize='fs-5 text-dark'
      />
      {editingFormat && (
        <div className='col-lg-6 ms-3'>
          <FileInput
            title={''}
            inputName={`questions[${questionIndex}].answers[${answerIndex}].image`}
            inputId={`q-${questionIndex}-a-${answerIndex}-img-file-input`}
            uploadedFile={{
              base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
              link: imageObj?.imageUrl || '',
            }}
            setFieldValue={setFieldValue}
            uploadFunction={saveImage}
            deleteFunction={deleteImage}
            loading={imageObj?.loading || false}
            noExtraMargins={true}
          />
        </div>
      )}
    </>
  )
}

export default AnswerItem
