import {useRef, useState} from 'react'
import {Overlay, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {TShipmentTrackingNumber} from '../../../core/_models/_models'
import {editShipment} from '../../../core/_requests'

type TProps = {track: TShipmentTrackingNumber; deleteTrack: (id: ID) => void}
const ShipmentTrackRow = ({track, deleteTrack}: TProps) => {
  const intl = useIntl()

  const [show, setShow] = useState(false)
  const target = useRef(null)
  const codeValueRef = useRef(null)

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div
            className='d-flex flex-column m-2 mb-1 cursor-pointer h-25px'
            // onClick={handleClick}
          >
            <div className='d-flex align-items-center'>
              <span className='text-dark fw-bold fs-6' ref={codeValueRef}>
                {track.number}
              </span>{' '}
              <div
                ref={target}
                onClick={() => {
                  copyToClipboard(codeValueRef, setShow)
                  setTimeout(() => {
                    setShow(false)
                  }, 1000)
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen054.svg'
                  className={`svg-icon-3 ${
                    show ? 'svg-icon-dark' : 'svg-icon-gray-600 cursor-pointer'
                  }`}
                />
              </div>{' '}
              <Overlay target={target.current} show={show} placement='right'>
                {(props) => (
                  <Tooltip {...props}>
                    {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                  </Tooltip>
                )}
              </Overlay>
            </div>
            {track.order && (
              <span className={`text-muted fw-semobold text-muted d-block fs-7`}>
                {track.order.number}
              </span>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'>
          <span>{track?.user?.externalId}</span>
          <span className='text-muted fs-6'>
            {[track?.user?.lastName, track.user?.firstName, track.user?.patronymic].join(' ')}
          </span>
        </div>
      </td>{' '}
      <td>
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => deleteTrack(track.id)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </td>{' '}
    </tr>
  )
}

export default ShipmentTrackRow
