import {
  initialQueryState,
  PaginationState,
  QueryResponseContextProps,
} from '../../../../_metronic/helpers'

type TProps = {
  queryResponse: QueryResponseContextProps<any>
}

const useQueryResponsePagination = ({queryResponse}: TProps) => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = queryResponse

  if (response && response.totalItems && response.currentPage && response.itemsPerPage) {
    const nPages = Math.ceil(response.totalItems / response.itemsPerPage)
    const prevPage = response.currentPage === 1
    const nextPage = response.currentPage === nPages

    let links = [
      {
        url: prevPage ? null : `/page=${response.currentPage - 1}`,
        label: '❮',
        active: false,
        page: prevPage ? null : response.currentPage - 1,
      },
    ]

    const pagesBetweenDots = 1

    if (nPages <= pagesBetweenDots * 2 + 1) {
      for (let page = 1; page <= nPages; page++) {
        links.push({
          url: page !== response.currentPage ? `/page=${page}` : null,
          label: `${page}`,
          active: page === response.currentPage,
          page: page,
        })
      }
    } else {
      for (let page = 1; page <= nPages; page++) {
        if (
          page <= pagesBetweenDots ||
          (page >= response.currentPage - pagesBetweenDots &&
            page <= response.currentPage + pagesBetweenDots) ||
          page > nPages - pagesBetweenDots
        ) {
          links.push({
            url: page !== response.currentPage ? `/page=${page}` : null,
            label: `${page}`,
            active: page === response.currentPage,
            page: page,
          })
        } else if (
          (page === pagesBetweenDots + 1 &&
            response.currentPage - pagesBetweenDots > pagesBetweenDots + 1) ||
          (page === nPages - pagesBetweenDots &&
            response.currentPage + pagesBetweenDots < nPages - pagesBetweenDots)
        ) {
          links.push({url: '', active: false, page: 0, label: '...'})
        }
      }
    }

    links.push({
      url: nextPage ? null : `/page=${response.currentPage + 1}`,
      label: '❯',
      active: false,
      page: nextPage ? null : response.currentPage + 1,
    })

    return {
      page: response?.currentPage,
      total: response?.totalItems,
      itemsPerPage: response?.itemsPerPage,
      links: links,
    }
  }

  return defaultPaginationState
}

export default useQueryResponsePagination
