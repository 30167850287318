import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {FULF_RECEIVER_VERIFICATION_STATUS} from '../../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {idZeroPad} from '../../../core/funcs'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

const ReceiverNameCell: FC<Props> = ({receiver}) => {
  const forStatusColor = (stat: string) =>
    stat === VERIFIED ? 1 : stat === WAITING ? 'on hold' : 0
  return (
    <div className='d-flex align-items-center'>
      <Link to={`/receivers/edit/overview/${receiver.id}`}>
        {' '}
        <div className='d-flex flex-column'>
          <span className='fw-semobold d-block fs-6 me-1'>
            {idZeroPad(receiver.id, 5) || '-'}
          </span>{' '}
          <span className='fw-normal d-block fs-7'>
            {receiver.firstName} {receiver.lastName}{' '}
            {receiver.patronymic ? receiver.patronymic : ''}
          </span>
          <div className='d-flex flex-gutter'>
            {receiver.verifStatus && (
              <span
                className={`p-1  fs-8 fw-semobold  StatusBar__status ${statusColor(
                  forStatusColor(receiver.verifStatus)
                )}`}
              >
                {receiver.verifStatus === VERIFIED && (
                  <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
                )}

                {receiver.verifStatus === CHECKED && (
                  <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
                )}
                {receiver.verifStatus === WAITING && (
                  <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
                )}
              </span>
            )}
            <span
              className={`p-1 fs-8 me-auto fw-semobold  StatusBar__status cursor-pointer ${statusColor(
                receiver.enabled
              )}`}
            >
              {receiver.enabled ? (
                <FormattedMessage id='GENERAL.STATUSES.ACTIVE' />
              ) : (
                <FormattedMessage id='GENERAL.STATUSES.BLOCKED' />
              )}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export {ReceiverNameCell}
