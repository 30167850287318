const ADD_PARCEL = 'Додайте, як мінімум, одну посилку у кластер!'
const NO_PARCELS_FOR_PROD =
  'У даному кластері немає жодної посилки, до якої можна прив’язати товар.'
const DIFFERENT_CLIENTS = 'В одному кластері не можуть бути товари та посилки різних клієнтів.'
const INDICATE_RECEIVER = 'Вкажіть отримувача'
const ODD_DATA_MESSAGE = 'Введено сторонні дані'
const SIZES_ERROR = 'Помилка у розмірах!'
const HEIGHT_ERROR = 'Помилка у значенні висоти.'
const OVERSIZE_ERROR =
  "Замірюваний об'єкт не влізає в робочий діапазон камери. Скоригуйте розташування об'єкту або внесіть розміри вручну."
// unpack
const EXISTING_PROD_ERROR = 'У кластер розпаковки не можна додати існуючий товар'
const ONLY_ONE_PARCEL_ALLOWED_ERROR = 'У кластер розпаковки не можна додати більше однієї посилки'

export const errors = {
  ADD_PARCEL,
  NO_PARCELS_FOR_PROD,
  DIFFERENT_CLIENTS,
  INDICATE_RECEIVER,
  ODD_DATA_MESSAGE,
  SIZES_ERROR,
  HEIGHT_ERROR,
  OVERSIZE_ERROR,
  EXISTING_PROD_ERROR,
  ONLY_ONE_PARCEL_ALLOWED_ERROR,
}
