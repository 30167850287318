const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

interface Props {
  link: string
  alt: string
}

function LogoThumb({link, alt}: Props) {
  return (
    <img
      src={`${BASE_URL}${link}`}
      alt={alt}
      className='img-thumbnail mt-2 LogoThumb'
      height={100}
      width={100}
    />
  )
}

export {LogoThumb}
