import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {TranslationHeader} from './header/TranslationHeader'
import {getTranslationByKey} from '../core/_requests'
import {TranslationTab} from './content/TranslationTab'
import {IChatTranslation} from '../core/_models'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string
}

const ChatTranslationPage = ({id}: Props) => {
  const {data: translationById} = useQuery(`chat-translation-${id}`, () => getTranslationByKey(id))

  const [currTranslation, setCurrTranslation] = useState<IChatTranslation | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateTranslation = () => {
    queryClient.invalidateQueries(`chat-translation-${id}`)
  }

  useEffect(() => {
    if (translationById) {
      setCurrTranslation(translationById)
    }
  }, [translationById])

  return (
    <>
      {currTranslation && translationById ? (
        <Routes>
          <Route
            element={
              <>
                <TranslationHeader translation={currTranslation} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <TranslationTab
                    translation={currTranslation}
                    invalidateTranslation={invalidateTranslation}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`/translations/chat/list`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ChatTranslationPage
