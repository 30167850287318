import {Dispatch, SetStateAction} from 'react'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {ICountry} from '../../../countries-list/core/_models'
import AddressForm from './AddressForm'

interface Props {
  country: ICountry
  setCurrCountry: Dispatch<SetStateAction<ICountry | undefined>>
}
const CountryAddress = ({country, setCurrCountry}: Props) => {
  return (
    <>
      {country ? (
        <div className='card mb-5 mb-xl-10 pt-10'>
          <AddressForm country={country} setCurrCountry={setCurrCountry} />
        </div>
      ) : (
        <PartialPageLoader />
      )}
    </>
  )
}

export default CountryAddress
