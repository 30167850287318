import {Link} from 'react-router-dom'
import {IChat} from '../../../../core/_models'

type Props = {
  chat: IChat
}

const ChatUserCell = ({chat}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {chat?.firstUser?.id ? (
          <Link
            to={`/users/edit/overview/${chat?.firstUser?.id}`}
            className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
          >
            <span>{chat?.firstUser?.externalId}</span>
            <span className='text-muted fs-6'>
              {[
                chat?.firstUser?.lastName,
                chat.firstUser?.firstName,
                chat.firstUser?.patronymic,
              ].join(' ')}
            </span>
          </Link>
        ) : (
          '-'
        )}
      </p>
    </div>
  )
}

export {ChatUserCell}
