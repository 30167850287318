import {CreateCollectionForm, TProps} from './CreateCollectionForm'

export const CreateCollectionFormWrapper = ({
  listView,
  queryResponse,
  addCollectionRequest,
}: TProps) => {
  const {itemIdForUpdate, setItemIdForUpdate} = listView

  if (itemIdForUpdate === null) {
    return (
      <CreateCollectionForm
        listView={listView}
        queryResponse={queryResponse}
        addCollectionRequest={addCollectionRequest}
      />
    )
  }

  return null
}
