/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IOrderHistory} from '../../../../orders/core/_models/_orders-models'
import {getUserById} from '../../../../users/core/_requests'
import {IEditArticle, TArticleLog} from '../../../core/_models'

interface Props {
  article: IEditArticle
  log: TArticleLog
}

function ArticleLogsItem({article, log}: Props) {
  const intl = useIntl()

  const processValue = (key: string, value: any) => {
    if ((typeof value === 'string' || typeof value === 'number') && key !== 'image') {
      return value
    } else if (typeof value === 'boolean' || value === null || value === undefined) {
      return value ? (
        <KTSVG
          path='/media/icons/duotune/general/gen043.svg'
          className='svg-icon-3 svg-icon-success'
        />
      ) : (
        <KTSVG
          path='/media/icons/duotune/general/gen040.svg'
          className='svg-icon-3 svg-icon-danger svg-bg-white'
        />
      )
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        return (
          <KTSVG
            path='/media/icons/duotune/general/gen040.svg'
            className='svg-icon-3 svg-icon-danger svg-bg-white'
          />
        )
      }
    } else if (key === 'image') {
      return (
        <div className='symbol symbol-75px symbol-fixed fit-content position-relative'>
          <img
            src={toAbsoluteUrl(
              article?.imageUrl
                ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` + article?.imageUrl
                : '/media/icons/duotune/art/art008.svg'
            )}
            alt='Template'
            style={{objectFit: 'cover'}}
          />
        </div>
      )
    } else if (key === 'translations') {
      return (
        <>
          {' '}
          {Object.keys(value).map((language) => (
            <div key={language}>
              <p className='mb-0'>
                <FormattedMessage id={`ARTICLE_PAGE.LOGS_TAB.LANGUAGE.${language.toUpperCase()}`} />
              </p>
              <ul>
                {Object.entries(value[language]).map(([field, v]) => (
                  <li key={field}>
                    <FormattedMessage
                      id={`ARTICLE_PAGE.LOGS_TAB.${field.toUpperCase()}`}
                      values={{v: String(v).replace(/<[^>]+>/g, '')}}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      )
    }

    return 'unknown'
  }
  return (
    <tr>
      <td className='mw-100px'>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={log.date} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime value={log.date} hour='numeric' minute='numeric' second='numeric' />
          </span>
        </div>
      </td>

      <td>
        {Object.entries(log.data)
          .filter(([k, _]) => k !== 'type')
          .map(([k, v]) => (
            <p key={k} className='d-flex align-items-center mb-0'>
              {' '}
              <span className='col fw-bold me-2'>
                <FormattedMessage id={`ARTICLE_PAGE.LOGS_TAB.${k.toUpperCase()}`} />:
              </span>{' '}
              <span className='col-8 text-dark d-block fs-6'>{processValue(k, v)}</span>
            </p>
          ))}
      </td>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{log.user.name}</span>
      </td>
    </tr>
  )
}

export {ArticleLogsItem}
