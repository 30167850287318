import {FC, useState, createContext, useContext} from 'react'
import {ID, WithChildren} from '../../../../../../_metronic/helpers'
import {IReceivedItem} from './_models'
import {initialContext, TPrintGoodsItem, ReceiveShipmentContextProps} from './_contextHelpers'

const ReceiveShipmentContext = createContext<ReceiveShipmentContextProps>(initialContext)

const ContextProvider: FC<WithChildren> = ({children}) => {
  const [itemForUpdate, setItemIdForUpdate] = useState<string | null>(initialContext.itemForUpdate)
  const [receivedItems, setReceivedItems] = useState<IReceivedItem[]>(initialContext.receivedItems)
  const [currClient, setCurrClient] = useState(initialContext.currClient)
  const [errorMessage, setErrorMessage] = useState('')
  const printMode = initialContext.printMode
  const [initiatePrint, setInitiatePrint] = useState(initialContext.initiatePrint)
  const [printQueue, setPrintQueue] = useState<TPrintGoodsItem[]>(initialContext.printQueue)
  const [customUser, setCustomUser] = useState(initialContext.customUser)

  return (
    <ReceiveShipmentContext.Provider
      value={{
        itemForUpdate,
        setItemIdForUpdate,
        receivedItems,
        setReceivedItems,
        initiatePrint,
        setInitiatePrint,
        printMode,
        printQueue,
        setPrintQueue,
        currClient,
        setCurrClient,
        errorMessage,
        setErrorMessage,
        customUser,
        setCustomUser,
      }}
    >
      {children}
    </ReceiveShipmentContext.Provider>
  )
}

const useReceiveShipmentContext = () => useContext(ReceiveShipmentContext)

export {ContextProvider, useReceiveShipmentContext}
