import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {StatusesQueryResponse, IStatus} from './_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const BASE_URL = `${API_URL}/statuses`

const getStatuses = (query: string): Promise<StatusesQueryResponse> => {
  return axios.get(`${BASE_URL}?${query}`).then((d: AxiosResponse<StatusesQueryResponse>) => {
    return d.data
  })
}

const getStatusById = (id: ID): Promise<IStatus | undefined> => {
  return axios.get(`${BASE_URL}/${id}`).then((response: AxiosResponse<IStatus>) => response.data)
}

const updateStatus = (status: Partial<IStatus>): Promise<IStatus | undefined> => {
  return axios
    .put(`${BASE_URL}/${status.value}`, status)
    .then((response: AxiosResponse<Response<IStatus>>) => response.data)
    .then((response: Response<IStatus>) => response.data)
}

export {getStatuses, getStatusById, updateStatus}
