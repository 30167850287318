/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {TracksTable} from './table/TracksTable'
import {TracksListHeader} from './components/header/TracksListHeader'
import {useLocation} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

import OnePageModal from '../../../modules/custom/track-or-order/_modals/OnePageModal'

const TracksList = () => {
  const listView = useListView()
  const {itemIdForUpdate, setItemIdForUpdate, isArchive} = useListView()

  const location = useLocation()
  const {state: locState}: {pathname: string; state: any} = location
  const {updateState, state} = useQueryRequest()
  const queryResponse = useQueryResponse()

  const openAddTrackModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.TRACKS_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.TRACKS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  useEffect(() => {
    if (locState?.create === true) {
      setItemIdForUpdate(null)
    } else {
      setItemIdForUpdate(undefined)
    }
  }, [locState])

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Треки</h3>
        <div className='d-flex flex-gutter'>
          {isArchive === false && (
            <>
              {/* <TracksListFilter /> */}
              <button type='button' className='btn btn-sm btn-primary' onClick={openAddTrackModal}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <FormattedMessage id='TRACKS.PAGE.LIST.ADD_BUTTON' />
              </button>
            </>
          )}
        </div>
      </div>
      <KTCard>
        <TracksListHeader />
        <TracksTable />

        {itemIdForUpdate !== undefined && (
          <OnePageModal listView={listView} queryResponse={queryResponse} />
        )}
      </KTCard>
    </>
  )
}

const TracksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TracksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TracksListWrapper}
