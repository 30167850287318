import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {ICompanyDetails} from '../../core/_models'

interface Props {
  company: ICompanyDetails | undefined
}

export function SettingsHeaderName({company}: Props) {
  return (
    <div className=''>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 w-75'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {company?.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
