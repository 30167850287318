/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG, QUERIES} from '../../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {ICategory} from '../../../../../_models'
import {useMutation, useQueryClient} from 'react-query'
import {IWebsite} from '../../../../../../websites/core/_models'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {Portal} from '../../../../../../../../_metronic/partials'
import {deleteCategory} from '../../../../core/_requests'

type Props = {
  category: ICategory
}

const CategoryActionsCell: FC<Props> = ({category}: Props) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteCategory(category.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='d-flex flex-gutter justify-content-end flex-shrink-0'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.BUTTONS.EDIT'})}</Tooltip>}
      >
        <Link
          to={`/website-categories/stores/edit/overview/${
            Object.values(category.locales).includes('uk')
              ? 'uk'
              : Object.values(category.locales)[0]
          }/${category.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </OverlayTrigger>
      {!category.itemsCount && (
        <>
          {deleteModalOpen && (
            <Portal
              children={
                <ActionConfirmation
                  actionName={intl.formatMessage({
                    id: 'WEBSITE_CATEGORIES.LIST.ACTIONS.TRASH_CONF',
                  })}
                  setModalOpen={setDeleteModalOpen}
                  handleAction={() => deleteMutation.mutate()}
                  id={category.id}
                  isOpen={deleteModalOpen}
                />
              }
              className='modal-root'
            />
          )}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
          >
            <a
              href='#'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setDeleteModalOpen(true)}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </a>
          </OverlayTrigger>
        </>
      )}
    </div>
  )
}

export {CategoryActionsCell}
