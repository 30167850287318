import {IWebsite, IEditWebsite} from './_models'
import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ID} from '../../../../_metronic/helpers/crud-helper/models'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const ENDPOINT_BASE = `/websites`

const fetchWebsites = async () => {
  const {data} = await axios.get<IWebsite[]>(`${API_URL}${ENDPOINT_BASE}`)

  const reformedData = data.map((website) => {
    website.directions = website.directions.map((direction) => {
      return {id: direction?.id}
    })
    return website
  })

  return reformedData
}

const getWebsiteById = (id: ID): Promise<IWebsite | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${API_URL}${ENDPOINT_BASE}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IWebsite>) => d.data)
}

const uploadImages = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${API_URL}/website/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const addWebsite = async (data: Partial<IEditWebsite>) => {
  const {data: response} = await axios.post(`${API_URL}${ENDPOINT_BASE}`, data)
  return response
}

const deleteWebsite = async (id: ID) => {
  const response = await axios.delete(`${API_URL}${ENDPOINT_BASE}/${id}`)
  return response
}

const patchStatus = async (id: ID, newStatus: boolean) => {
  const response = await axios.put<any>(`${API_URL}${ENDPOINT_BASE}/${id}`, {enabled: newStatus})
  return response
}

const editWebsite = async (id: ID, data: Partial<IEditWebsite>) => {
  const {data: response} = await axios.put<any>(`${API_URL}${ENDPOINT_BASE}/${id}`, data)

  return response
}

const getTranslationByWebsiteId = async (id: number) => {
  const {data: response} = await axios.get<any>(`${API_URL}/website_translation_keys?website=${id}`)

  return response
}

const fetchWebsiteLanguagesList = async () => {
  const {data} = await axios.get(`${API_URL}/public${ENDPOINT_BASE}/locales`)
  return data
}

export const websitesApi = {
  fetchWebsites,
  getWebsiteById,
  uploadImages,
  addWebsite,
  deleteWebsite,
  patchStatus,
  editWebsite,
  getTranslationByWebsiteId,
  fetchWebsiteLanguagesList,
}
