import axios from 'axios'

const getDeliveryPriceRangeTypes = async () => {
  const {data} = await axios.get<any>('api/delivery_rate/price_range_types')

  return data
}

export const deliveryRateApi = {
  getDeliveryPriceRangeTypes,
}
