import {useQuery} from 'react-query'
import {ID, QUERIES} from '../../../../../../../_metronic/helpers'
import PartialPageLoader from '../../../../../../../_metronic/layout/core/PartialPageLoader'
import {getReceiverHistory} from '../../../receivers-list/core/_requests'
import {ReceiverLogs} from './ReceiverLogs'

type Props = {id: ID}

export const ReceiverLogsWrapper = ({id}: Props) => {
  const {data: receiverHistory} = useQuery(QUERIES.RECEIVER_HISTORY, () => getReceiverHistory(id))

  return <>{receiverHistory ? <ReceiverLogs logs={receiverHistory} /> : <PartialPageLoader />}</>
}
