/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IParcel} from '../../core/_models/_models'
import {ParcelHeaderName} from './ParcelHeaderName'
import {ParcelHeaderNav} from './ParcelHeaderNav'

interface Props {
  parcel: IParcel
}

const ParcelHeader = ({parcel}: Props) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen017.svg')} alt='Parcel' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ParcelHeaderName parcel={parcel} />{' '}
              <div className='d-none d-md-block'>{/* <ParcelHeaderInfo parcel={parcel} /> */}</div>
            </div>
          </div>
          <div className='d-md-none'>{/* <ParcelHeaderInfo parcel={parcel} /> */}</div>
        </div>

        <ParcelHeaderNav parcel={parcel} />
      </div>
    </div>
  )
}

export {ParcelHeader}
