import {FormattedMessage} from 'react-intl'
import {Direction} from '../../core/_models'

interface Props {
  direction: Direction
}

export function DirectionHeaderInfo({direction}: Props) {
  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex flex-column fw-bold fs-6 mb-4 pe-2'>
        <div className='btn btn-sm btn-light-primary fw-bold px-4'>
          {direction.senderTypes.includes('1') && (
            <p className='fw-semobold text-black d-block fs-6 mb-1'>
              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW' />{' '}
            </p>
          )}
          {direction.senderTypes.includes('2') && (
            <p className='fw-semobold text-black d-block fs-6 mb-0'>
              <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL' />{' '}
            </p>
          )}
        </div>
      </div>
      <div className='card-toolbar mb-2'></div>
    </div>
  )
}
