import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {IPoll, ISavePoll, PollsQueryResponse} from './_models'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH

const ENDPOINT = `/polls`

const BASE_URL = `${API_URL}${ENDPOINT}`

const getPolls = (query: string): Promise<PollsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<PollsQueryResponse>) => d.data)
}

const getPollTypes = (): Promise<string[]> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/types`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<string[]>) => d.data)
}

const getPollById = (id: ID): Promise<IPoll | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<IPoll>) => d.data)
}

const addPoll = async (data: ISavePoll) => {
  const {data: response} = await axios.post(`${BASE_URL}`, data)

  return response
}

const deletePoll = async (id: ID) => {
  const response = await axios.delete(`${BASE_URL}/${id}`)
  return response
}

const editPoll = async (data: any, id: ID) => {
  const {data: response} = await axios.put<any>(`${BASE_URL}/${id}`, data)

  return response
}

const downloadExcelResultsById = async (id: ID, name: string) => {
  const {selectedLang} = getConfig()

  return axios
    .post(
      `${BASE_URL}/${id}/results`,
      {},
      {
        headers: {
          'Accept-Language': selectedLang,
        },
        responseType: 'arraybuffer',
      }
    )
    .then((d) => {
      const blob = new Blob([d.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const downloadingUrl = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = downloadingUrl
      a.download = `${name}.xlsx`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(downloadingUrl)
      document.body.removeChild(a)
    })
}

// const uploadLogo = async (name: string, file: File) => {
//   let formData = new FormData()
//   formData.append(name, file as any)

//   try {
//     const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//     return data
//   } catch (err) {
//     console.log(err)
//   }
// }

export const pollsApi = {
  getPolls,
  getPollTypes,
  addPoll,
  deletePoll,
  editPoll,
  getPollById,
  downloadExcelResultsById,
}
