import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {AcceptedTrackOrOrderResponse} from '../../tracks-accept/core/_models/_models'
import {ICreateNewOrder, IOrder, IOrderHistory, OrdersQueryResponse} from './_models/_orders-models'

const API_URL = process.env.REACT_APP_API_PATH
const ORDERS_URL = `/orders`
const BASE_URL = `${API_URL}${ORDERS_URL}`

const getOrders = (query: string): Promise<OrdersQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<OrdersQueryResponse>) => {
      return d.data
    })
}

const getOrderById = async (id: ID): Promise<IOrder | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const getOrderLogsById = async (id: ID): Promise<IOrderHistory[] | undefined> => {
  const {selectedLang} = getConfig()

  const response = await axios.get(`${BASE_URL}/${id}/history`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  return response.data
}

const getRelatedInfoByOrderNumber = (
  number: string,
  location: ID
): Promise<AcceptedTrackOrOrderResponse | undefined> => {
  return axios
    .get(`${BASE_URL}/get_accept/${number}?location=${location}`)
    .then((response: AxiosResponse<AcceptedTrackOrOrderResponse>) => response.data)
}

const addOrder = (order: Partial<ICreateNewOrder>): Promise<IOrder | undefined> => {
  return axios
    .post(`${BASE_URL}/create-as-company`, order)
    .then((response: AxiosResponse<Response<IOrder>>) => response.data)
    .then((response: Response<IOrder>) => response.data)
}

const editOrder = (order: Partial<ICreateNewOrder>): Promise<IOrder | undefined> => {
  return axios
    .put(`${BASE_URL}/${order.id}`, order)
    .then((response: AxiosResponse<Response<IOrder>>) => response.data)
    .then((response: Response<IOrder>) => response.data)
}

const confirmOrder = (id: ID) => {
  return axios.post(`${BASE_URL}/${id}/confirm`, {})
}

const cancelOrder = (id: ID) => {
  return axios.post(`${BASE_URL}/${id}/cancel`, {})
}

// invoices

const uploadOrderInvoice = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('uploadInvoice response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}

export {
  getOrders,
  uploadOrderInvoice,
  addOrder,
  editOrder,
  getOrderById,
  getRelatedInfoByOrderNumber,
  getOrderLogsById,
  confirmOrder,
  cancelOrder,
}
