import {Formik, Form} from 'formik'
// import {trackPageOverviewSchema} from '../../core/yup/track-page'
import {useEffect, useState} from 'react'
import {TrackCodeField} from '../../modules/general/TrackCodeField'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'

import {ICreateNewTrack, Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {ICreateNewOrder, IOrder} from '../../../../../pages/orders/core/_models/_orders-models'
import {CardFooter} from '../../../form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'

interface Props {
  item: Track | IOrder
  setCurrItem: (obj: Track) => void
}

export function OverviewTab({item, setCurrItem}: Props) {
  const intl = useIntl()
  const {number} = item
  const queryClient = useQueryClient()

  const initialValues = {
    number,
  }
  const [trackCode, setTrackCode] = useState(number)
  const [trackCodeStatus, setTrackCodeStatus] = useState({success: false, msg: '', id: ''})
  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTrackCode(number)
  }, [number])

  const handleSubmit = async (values: Partial<ICreateNewTrack | ICreateNewOrder>) => {
    setLoading(true)
    // const response = await editTrack({
    // ...track,
    // receiver: track?.receiver?.id ? +track?.receiver?.id : null,
    // track_code,
    // type_delive: deliveryType,
    // description,
    // return_to_shop: returnToStore === true ? 1 : 0,
    // invoice_file:
    //   track.invoice_file.map((item) => {
    //     return {name: item.name, tmp_name: item.file.filename}
    //   }) || undefined,
    // })

    // if (response) {
    //   setCurrItem(response)
    //   queryClient.invalidateQueries([QUERIES.TRACK_BY_ID, QUERIES.ORDER_BY_ID])
    //   setLoading(false)
    //   setSuccess(true)

    //   setTimeout(() => {
    //     setSuccess(false)
    //   }, 3000)
    // }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue}) => (
          <Form noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                  <FormattedMessage id='TRACKS.ADD_MODAL.FORM.TRACK_CODE_TITLE' />
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <TrackCodeField
                        withTitle={false}
                        title={''}
                        setFieldValue={setFieldValue}
                        value={values.number || ''}
                        initialValue={number}
                        trackCodeStatus={
                          trackCodeStatus.msg === 'TRACKS.TRACK_CODE.ERROR.ALREADY_ADDED'
                            ? {success: true, msg: '', id: ''}
                            : trackCodeStatus
                        }
                        setTrackCodeStatus={setTrackCodeStatus}
                        trackCode={trackCode}
                        setTrackCode={setTrackCode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
