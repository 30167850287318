import {useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {IWarehouse, warehouseLocales} from '../../core/_models'

interface Props {
  warehouse: IWarehouse
}

const WarehouseHeaderLangSwitcher = ({warehouse}: Props) => {
  const {locale} = useParams()
  const [activeTab, setActiveTab] = useState(locale)
  const navigate = useNavigate()

  const location = useLocation()
  const locArray = location.pathname.split(`/${locale}`)[0].split('/')
  const currTab = locArray[locArray.length - 1]

  return (
    <ul className='nav min-w-100px'>
      {Object.values(warehouseLocales).map((locale) => (
        <li className='nav-item' key={locale}>
          <button
            type='button'
            onClick={() => {
              navigate(`/warehouses/edit/${currTab}/${locale}/${warehouse.id}`)

              setActiveTab(locale)
            }}
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
              activeTab === locale ? 'active' : ''
            } fw-bold px-4`}
          >
            {locale}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default WarehouseHeaderLangSwitcher
