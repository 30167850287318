/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../../../websites/core/_models'

import {ICollection} from '../../../../../_models'

type Props = {
  collection: ICollection
}

const CollectionWebsitesCell = ({collection}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === collection.website.id)

  return (
    <div className='d-flex justify-content-start flex-column'>
      <span className='fw-semobold d-block fs-6 me-1'> {website?.name || ' - '}</span>

      <a
        className='fw-normal d-block fs-7'
        href={`${process.env.REACT_APP_HTTP}${website?.host}`}
        target='_blank'
        rel='noreferrer noopener'
      >
        {website?.host}
      </a>
    </div>
  )
}

export {CollectionWebsitesCell}
