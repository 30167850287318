import {Dispatch, SetStateAction} from 'react'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {IWebsite} from '../../../websites/core/_models'
import {IEditArticle} from '../../core/_models'
import ArticleHeaderLangSwitcher from './ArticleHeaderLangSwitcher'
import {ArticleHeaderName} from './ArticleHeaderName'
import {ArticleHeaderNav} from './ArticleHeaderNav'

interface Props {
  article: IEditArticle
}

const ArticleHeader = ({article}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const {locale} = useParams()

  const website = websitesData?.find((item) => item.id === article.website.id)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={toAbsoluteUrl(
                    article?.imageUrl
                      ? `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` +
                          article?.imageUrl
                      : '/media/icons/duotune/art/art008.svg'
                  )}
                  alt='Template'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ArticleHeaderName article={article} />
              <a
                href={`${process.env.REACT_APP_HTTP}${website?.host}${
                  !!locale && locale !== 'uk' ? `/${locale}` : ''
                }/blog/${article.slug}`}
                target='_blank'
                rel='noreferrer noopener'
              >
                <KTSVG
                  path='/media/icons/duotune/communication/com001.svg'
                  className='svg-icon-3 link-primary me-3'
                />
                <span className='text-dark text-hover-primary'>{website?.host}</span>
              </a>
            </div>
          </div>
          <ArticleHeaderLangSwitcher article={article} website={website} />
        </div>

        <ArticleHeaderNav article={article} />
      </div>
    </div>
  )
}

export {ArticleHeader}
