import {FC, useState, createContext, useContext, useMemo, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../_metronic/helpers'
import {IUserSelect} from '../../users/core/_models'
import {getUsers} from '../../users/core/_requests'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [isArchive, setIsArchive] = useState<boolean>(initialListView.isArchive || false)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()

  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [usersLoading, setUsersLoading] = useState(false)
  const [usersResults, setUsersResults] = useState<IUserSelect[]>([])
  const filterUsers = async (query: string) => {
    setUsersLoading(true)
    await getUsers(`search=${query}`)
      .then((res) => {
        const transformed = res?.data?.map((item) => {
          return {
            ...item,
            value: item.id || item.externalId || '',
            label:
              (item.externalId ? item.externalId + ' ' : '') +
              (item.lastName ? item.lastName + ' ' : '') +
              (item.firstName ? item.firstName : ''),
          }
        })
        setUsersResults(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  const {pathname} = useLocation()
  const currPage = pathname.split('/')[1]

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isArchive,
        setIsArchive,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        usersLoading,
        usersResults,
        setUsersLoading,
        setUsersResults,
        filterUsers,
        currPage,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
