import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {AcceptedQueryResponse, IAcceptedItem, IAcceptTrackOrOrder} from './_models/_models'

const API_URL = process.env.REACT_APP_API_PATH
const ACCEPTED_URL = `/orders/location-accept`
const BASE_URL = `${API_URL}${ACCEPTED_URL}`

const getTracks = (query: string): Promise<AcceptedQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<AcceptedQueryResponse>) => {
      return d.data
    })
}

const acceptItem = (item: Partial<IAcceptTrackOrOrder>): Promise<IAcceptedItem | undefined> => {
  return axios
    .post(`${BASE_URL}`, item)
    .then((response: AxiosResponse<Response<IAcceptedItem>>) => response.data)
    .then((response: Response<IAcceptedItem>) => response.data)
}

export {getTracks, acceptItem}
