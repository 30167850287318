import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {WarehouseHeader} from './header/WarehouseHeader'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import WarehouseAddress from './tabs/address/WarehouseAddress'
import {QUERIES} from '../../../../_metronic/helpers'
import {getWarehouseById} from '../core/_requests'
import {IWarehouse} from '../core/_models'
import {directionsApi} from '../../directions/core/_requests'
import WarehouseOverview from './tabs/overview/WarehouseOverview'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const WarehousePage = ({id}: Props) => {
  const intl = useIntl()

  const {data: warehouseById} = useQuery(`${QUERIES.WAREHOUSE_BY_ID}-${id}`, () =>
    getWarehouseById(id)
  )
  const {data: countryAbbr} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    directionsApi.fetchDirectionCountriesList
  )
  const queryClient = useQueryClient()

  const invalidateWarehouse = () => {
    queryClient.invalidateQueries(`${QUERIES.WAREHOUSE_BY_ID}-${id}`)
  }

  const [currWarehouse, setCurrentWarehouse] = useState<IWarehouse | undefined>(undefined)

  useEffect(() => {
    if (warehouseById) {
      setCurrentWarehouse(warehouseById)
    }
  }, [warehouseById])

  return (
    <>
      {currWarehouse && warehouseById ? (
        <Routes>
          <Route
            element={
              <>
                <WarehouseHeader warehouse={currWarehouse} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <WarehouseOverview
                    warehouse={currWarehouse}
                    invalidateWarehouse={invalidateWarehouse}
                  />
                </>
              }
            />
            <Route
              path={`address/:locale/:id`}
              element={
                <>
                  <WarehouseAddress
                    warehouse={currWarehouse}
                    setCurrWarehouse={setCurrentWarehouse}
                    invalidateWarehouse={invalidateWarehouse}
                  />
                </>
              }
            />
            <Route
              path={`sec-address/:locale/:id`}
              element={
                <>
                  <WarehouseAddress
                    secondary={true}
                    warehouse={currWarehouse}
                    setCurrWarehouse={setCurrentWarehouse}
                    invalidateWarehouse={invalidateWarehouse}
                  />
                </>
              }
            />
            <Route index element={<Navigate to={`address/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default WarehousePage
