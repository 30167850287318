import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {SetStateAction, Dispatch} from 'react'
import {TranslationsQueryResponse} from './_models'
import {ITranslationObject} from '../../../core/_models'

const API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

const getVisibleTranslations = (query: string): Promise<TranslationsQueryResponse> => {
  return axios
    .get(`${API_URL}/website_translation_keys?${query}`)
    .then((d: AxiosResponse<TranslationsQueryResponse>) => {
      return d.data
    })
}

const getTranslationKey = (id: ID) => {
  return axios
    .get(`${API_URL}/website_translation_keys/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((err) => console.log(err))
}

const getTranslationByWebsiteId = (id: ID, setState?: Dispatch<SetStateAction<number>>) => {
  return axios
    .get(`${API_URL}/website_translation_keys?website=${id}`)
    .then((response) => {
      if (setState) {
        setState(response.data.totalItems)
      }
      return response.data
    })
    .catch((err) => console.log(err))
}

const fetchTranslationsButton = (id: ID) => {
  return axios.post(`${API_URL}/websites/${id}/translations_update`, {}).then((data) => {
    return data.data
  })
}

const updateKey = (key: ITranslationObject, id: ID) => {
  return axios.put(`${API_URL}/website_translation_keys/${id}`, key).then((response) => {
    return response.data
  })
}

export {
  getTranslationByWebsiteId,
  getVisibleTranslations,
  fetchTranslationsButton,
  getTranslationKey,
  updateKey,
}
