import {ErrorMessage, useFormikContext} from 'formik'
import React from 'react'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {TCourierDeliveryMethod} from '../core/_models'

type TProps = {
  delMethods: TCourierDeliveryMethod[]
  flexRow?: boolean
}

const DeliveryMethods = ({delMethods, flexRow = false}: TProps) => {
  const {setFieldValue, values} = useFormikContext<{deliveryMethods: string[]}>()


  return (
    <>
      <div className={`d-flex ${flexRow ? 'flex-row flex-gutter' : 'flex-column flex-col-gutter'}`}>
        {delMethods.map((i) => (
          <InputTemplate
            key={i.id?.toString() || i.type}
            fieldWidth={flexRow ? 'w-25' : ''}
            inputName='deliveryMethods'
            required={false}
            type='checkbox'
            value={i.id?.toString() || i.type}
            title={i.name}
            labelClass='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4 pb-3'
            containerMarginBottom={false}
            errorMessage={false}
            checkboxProps={{
              multipleCheckbox: true,
              checked: i.id
                ? values.deliveryMethods.includes(i.id.toString())
                : values.deliveryMethods.includes(i.type),
              disabled: false,
              setFieldValue: setFieldValue,
              value: i.id?.toString() || i.type,
              inputName: 'deliveryMethods',
              type: 'checkbox',
              handleChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(
                  'deliveryMethods',
                  values.deliveryMethods.includes(e.target.value.toString())
                    ? [
                        ...values.deliveryMethods.filter(
                          (i) => i.toString() !== e.target.value.toString()
                        ),
                      ]
                    : [...values.deliveryMethods, e.target.value.toString()]
                )
              },
            }}
          />
        ))}
      </div>
      <div className='text-danger'>
        <ErrorMessage name='deliveryMethods' />
      </div>
    </>
  )
}

export default DeliveryMethods
