import {directionsApi} from '../../pages/directions/core/_requests'
import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {websitesApi} from '../../pages/websites/core/_requests'
import {deliveryTypesApi} from '../../../_metronic/helpers/custom/api/deliveryTypesApi'
import {useMutation, useQueryClient} from 'react-query'
import {ID, QUERIES} from '../../../_metronic/helpers'

interface Props {
  allowDelete?: boolean
  id: ID
  refetch?: () => void
}

function StatusBarSettings({allowDelete, id, refetch}: Props) {
  const [option, setOption] = useState('1')
  let location = useLocation()
  const queryClient = useQueryClient()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [item, setItem] = useState('')

  // STATUS
  const patchDirectionStatus = useMutation<any, Error>(
    async () => {
      return await directionsApi.patchStatus(id, option === '1' ? true : false)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES.DIRECTIONS_WITH_IDS_LIST)
        if (refetch) {
          refetch()
        }
      },
    }
  )

  const patchWebsiteStatus = useMutation<any, Error>(
    async () => {
      return await websitesApi.patchStatus(id, option === '1' ? true : false)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES.WEBSITES_LIST)
      },
    }
  )

  const changeDeliveryTypeStatus = useMutation<any, Error>(
    async () => {
      return await deliveryTypesApi.changeStatus(id, option === '1' ? true : false)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERIES.DELIVERY_TYPES_LIST)
      },
    }
  )

  // DELETE

  const deleteWebsiteMutation = useMutation(websitesApi.deleteWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.WEBSITES_LIST)
    },
  })

  const deleteDeliveryTypeMutation = useMutation(deliveryTypesApi.deleteDeliveryType, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.DELIVERY_TYPES_LIST)
    },
  })

  const handleDelete = () => {
    if (location.pathname === '/websites') {
      deleteWebsiteMutation.mutate(id)
    } else if (location.pathname === '/delivery-types/list') {
      deleteDeliveryTypeMutation.mutate(id)
    }

    setDeleteConfirm(false)
  }

  const handleSave = () => {
    if (location.pathname.includes('/directions')) {
      switch (option) {
        case '1':
          patchDirectionStatus.mutate()

          break

        case '2':
          patchDirectionStatus.mutate()

          break

        case '3':
          setItem('направление')
          setDeleteConfirm(true)
          break

        default:
          console.log('error')
      }
    } else if (location.pathname === '/websites') {
      switch (option) {
        case '1':
          patchWebsiteStatus.mutate()

          break

        case '2':
          patchWebsiteStatus.mutate()

          break

        case '3':
          deleteWebsiteMutation.mutate(id)
          setItem('сайт')
          setDeleteConfirm(true)
          break

        default:
          console.log('error')
      }
    } else if (location.pathname === '/delivery-types/list') {
      switch (option) {
        case '1':
          changeDeliveryTypeStatus.mutate()

          break

        case '2':
          changeDeliveryTypeStatus.mutate()

          break

        case '3':
          setItem('тип доставки')
          setDeleteConfirm(true)
          break

        default:
          console.log('error')
      }
    } else {
      console.log(location.pathname)
    }
  }
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px StatusBarSettings'
        data-kt-menu='true'
        id={`status-bar-${id}`}
      >
        <div className='px-7 py-5'>
          <div className={allowDelete ? 'mb-10' : ''}>
            <label className='form-label fw-bold'>Статус:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={'1'}
                onChange={(e) => setOption(e.target.value)}
              >
                <option value='1'>Активувати</option>
                <option value='2'>Заблокувати</option>
                {allowDelete && !location.pathname.includes('/directions') && (
                  <option value='3'>Видалити</option>
                )}{' '}
              </select>
            </div>
          </div>
          {!allowDelete && !location.pathname.includes('/directions') && (
            <div className='border border-gray-300 border-dashed rounded  py-3 px-4 mx-3 mb-3'>
              <div className='fw-bold text-gray-400'>Видалення недоступне</div>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              Скасувати
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-menu-dismiss='true'
              onClick={handleSave}
            >
              Зберегти
            </button>
          </div>
        </div>
      </div>
      {/* {deleteConfirm && (
        <Modal
          className='modal fade'
          show={deleteConfirm}
          dialogClassName='modal-dialog-centered mw-300px'
          id='kt_modal_create_app'
          aria-hidden='true'
        >
          {' '}
          <div className='modal-dialog modal-dialog-centered mw-300px'>
            <div className='modal-content  DeleteConfirmation__modal'>
              <div className='swal2-icon swal2-warning swal2-icon-show'>
                {' '}
                <div className='swal2-icon-content'>!</div>
              </div>

              <p className='fs-5 fw-bold StatusBarSettings__confirm'>{`Вы уверены, что хотите удалить ${item}?`}</p>
              <div className='d-flex StatusBarSettings__controllers'>
                {' '}
                <button
                  type='reset'
                  className='btn btn-sm btn-white btn-active-dark-primary me-2'
                  data-kt-menu-dismiss='true'
                  onClick={(e) => setDeleteConfirm(false)}
                >
                  Скасувати
                </button>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                  onClick={handleDelete}
                >
                  Так
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </>
  )
}

export {StatusBarSettings}
