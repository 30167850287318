/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {IPollResult} from '../../../../../../../core/_models'

type Props = {
  result: IPollResult
}

const DateCell = ({result}: Props) => {
  return (
    <div className='p-1 fs-7 S fw-semobold text-dark d-flex flex-column'>
      <span>
        <FormattedDate year='numeric' month='numeric' day='numeric' value={result.createdAt} />
      </span>
      <span>
        {' '}
        <FormattedTime
          value={new Date(result.createdAt)}
          hour='numeric'
          minute='numeric'
          second='numeric'
        />
      </span>
    </div>
  )
}

export {DateCell}
