import {ICategory} from '../../../../../_models'

type Props = {
  category: ICategory
}
const CategoryContentCell = ({category}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {category.itemsCount}
      </p>
    </div>
  )
}

export {CategoryContentCell}
