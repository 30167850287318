import {FC} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {StatusBar} from '../../../../../../modules/custom/StatusBar'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IPoll} from '../../../../core/_models'

type Props = {
  poll: IPoll
}

const PollStatusCell: FC<Props> = ({poll}) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()
  // const enableDisable = async (boolean: boolean) => {
  //   await updateReceiver({enabled: boolean}, receiver.id)

  //   refetch()
  // }
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <span
        className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
          poll.enabled
        )}`}
        // onClick={() => enableDisable(!poll.enabled)}
      >
        {poll.enabled ? (
          <FormattedMessage id='GENERAL.STATUSES.ACTIVE' />
        ) : (
          <FormattedMessage id='GENERAL.STATUSES.BLOCKED' />
        )}
      </span>
    </div>
  )
}

export {PollStatusCell}
