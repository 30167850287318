import {ID, KTSVG} from '../../../../_metronic/helpers'
import {Field} from 'formik'
import {ISetFieldValue} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  setFieldValue: ISetFieldValue
  directionId: ID
  chosenDirections: ID[]
  transformedDirection: string
}

function WebsiteDirection({
  setFieldValue,
  chosenDirections,
  directionId,
  transformedDirection,
}: Props) {
  return (
    <label className='d-flex flex-stack cursor-pointer mb-5'>
      <span className='d-flex align-items-center me-2'>
        <span className='symbol symbol-50px me-6'>
          <span className='symbol-label bg-light-warning'>
            <KTSVG path='/media/icons/duotune/ecommerce/ecm005.svg' className='svg-icon-1' />
          </span>
        </span>

        <span className='d-flex '>
          <span className='fw-bolder fs-6'>{transformedDirection}</span>
        </span>
      </span>

      <span className='form-check form-check-custom form-check-solid'>
        <Field
          className='form-check-input'
          type='checkbox'
          name='directions'
          value={directionId}
          onChange={(e: any) => {
            const val = parseInt(e.target.value)
            setFieldValue(
              'directions',
              !chosenDirections.includes(directionId)
                ? [...chosenDirections, val]
                : chosenDirections.filter((i) => i !== val)
            )
          }}
          checked={chosenDirections.includes(directionId)}
        />
      </span>
    </label>
  )
}

export {WebsiteDirection}
