const EditingWarning = () => {
  return (
    <div className='alert alert-danger d-flex align-items-center p-5 col-12'>
      <i className='ki-duotone ki-shield-tick fs-2hx text-success me-4'>
        <span className='path1'></span>
        <span className='path2'></span>
      </i>

      <div className='d-flex flex-column'>
        <h4 className='mb-1 text-dark'>Увага!</h4>
        <p>
          Фігурні дужки та решта символів подібних до {`<b> </b>`} у перекладах відповідають за
          формат та автоматичну підстановку чисел. В полях, де присутні подібні символи, треба
          редагувати лише слова та числа. В прикладах нижче вони виділені жирним:
        </p>
        <ul>
          <li>
            <span>
              <b>Підтвердити</b> {`{sum}`}
            </span>
          </li>
          <li>
            {`<b>`}
            <b>$5</b>
            {`</b>`}
            <b> - вартість послуги</b>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default EditingWarning
