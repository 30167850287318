export const getDateForBackend = (date: Date, start?: boolean) => {

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}${
    start === undefined
      ? ''
      : start
      ? encodeURIComponent('T00:00:00+00:00')
      : encodeURIComponent('T23:59:59+00:00')
  }`
}

export const startDateForSelect = (n: number) => {
  const currentDate = new Date()

  const targetDate = new Date(currentDate)
  targetDate.setDate(currentDate.getDate() - n)

  return targetDate
}
