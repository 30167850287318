import {FC} from 'react'
import {Location} from '../../../../core/_models'

type Props = {
  location: Location
}

const LocationCityCell: FC<Props> = ({location}) => {
  return (
    <div className='d-flex '>
      <p className='text-dark fw-bold d-block fs-6'>{location.name}</p>
    </div>
  )
}

export {LocationCityCell}
