import React, {useEffect} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {handleFileUpload} from '../../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useState} from 'react'
import {receiverOverviewSchema} from '../../../receivers-list/core/yup/receiver-page'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {FileInput} from '../../../elements/FileInput'
import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CheckboxSwitcher} from '../../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {IReceiverToSend, NewReceiverFullForm, Receiver} from '../../../receivers-list/core/_models'
import {uploadFile, updateReceiver} from '../../../receivers-list/core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {
  CARDFOOTER_NOTIFICATIONS,
  FULF_RECEIVER_VERIFICATION_STATUS,
  KTSVG,
} from '../../../../../../../_metronic/helpers'
import {ReceiverType} from '../../../elements/ReceiverType'
import {ClientSelect} from '../../../elements/ClientSelect'
import {getUsers} from '../../../../../users/core/_requests'
import {IUserSelect} from '../../../../../users/core/_models'
import {Verification} from './Verification'
import {PatronymicCheckbox} from '../../../elements/PatronymicCheckbox'
import PartialPageLoader from '../../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  receiver: Receiver
  setCurrReceiver: (obj: Receiver) => void
}
const {VERIFIED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

export function ReceiverOverview({receiver, setCurrReceiver}: Props) {
  const intl = useIntl()

  const {
    id,
    user,
    enabled,
    firstName,
    lastName,
    patronymic,
    noPatronymic,

    documentUrl,
    international,
    final,
    withinCountry,
    common,
    verifStatus,
    documentNumber,
    main,
  } = receiver

  const getVerifStatus = () => {
    return {
      value: verifStatus,
      label:
        verifStatus === VERIFIED
          ? intl.formatMessage({id: 'GENERAL.STATUSES.VERIFIED'})
          : verifStatus === WAITING
          ? intl.formatMessage({id: 'GENERAL.STATUSES.VERIF_WAITING'})
          : intl.formatMessage({id: 'GENERAL.STATUSES.CHECKED'}),
    }
  }

  const initialValues = {
    active: enabled === true ? 1 : 0,
    firstName: firstName || '',
    lastName: lastName || '',
    patronymic: patronymic || '',
    noPatronymic: noPatronymic || false,
    document: documentUrl,
    receiverType: [
      international === true ? 1 : 0,
      final === true ? 2 : 0,
      withinCountry === true ? 3 : 0,
    ].filter((item) => +item !== 0),
    commonReceiver: common,
    user: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      value: user?.id,
      label:
        (user?.lastName ? user?.lastName + ' ' : '') + (user?.firstName ? user?.firstName : ''),
    },
    verifStatus: getVerifStatus(),
    documentNumber: documentNumber || '',
    main: main,
  }
  const [documentObj, setDocumentObj] = useState({
    document: receiver.document,
    documentUrl: receiver.documentUrl,
  })

  const [docIsLoading, setDocIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [usersLoading, setUsersLoading] = useState(false)
  const [usersResults, setUsersResults] = useState<IUserSelect[]>([])
  const filterUsers = async (query: string) => {
    setUsersLoading(true)
    await getUsers(`search=${query}`)
      .then((res) => {
        const transformed = res?.data?.map((item) => {
          return {
            ...item,
            value: item.externalId || item.id || '',
            label:
              (item.lastName ? item.lastName + ' ' : '') + (item.firstName ? item.firstName : ''),
          }
        })
        setUsersResults(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  useEffect(() => {
    filterUsers('')
  }, [])

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setDocIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'document',
      'document',
      uploadFile,
      false,
      'documentUrl'
    )

    setDocumentObj(linkResponse)

    // const response = await updateReceiver(
    //   {
    //     document: linkResponse.document,
    //     documentUrl: linkResponse.documentUrl,
    //   },
    //   id
    // )

    // if (response) {
    //   setCurrReceiver(response)
    //   setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //   setTimeout(() => {
    //     setFooterNotification(null)
    //   }, 3000)
    // } else {
    //   // set error
    // }

    setDocIsLoading(false)
    setLoading(false)
  }

  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    // setLoading(true)

    setFieldValue('document', '')

    setDocumentObj({
      document: null,
      documentUrl: null,
    })

    // const response = await updateReceiver(
    //   {
    //     document: null,
    //     documentUrl: null,
    //   },
    //   id
    // )

    // if (response) {
    //   setCurrReceiver(response)
    //   setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //   setTimeout(() => {
    //     setFooterNotification(null)
    //   }, 3000)
    // }
    // setLoading(false)
  }

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<NewReceiverFullForm>, actions: FormikValues) => {
    const {
      receiverType,
      noPatronymic,
      firstName,
      lastName,
      patronymic,
      commonReceiver,
      user,
      documentNumber,
      main,
    } = values
    setLoading(true)
    const enabledChanged = values.active !== initialValues.active
    const verifiedChanged =
      values.verifStatus?.value?.toString().toLowerCase() !==
      initialValues.verifStatus.toString().toLowerCase()

    const payload: Partial<IReceiverToSend> = {
      firstName,
      lastName,
      patronymic,
      international: receiverType?.includes(1) ? true : false,
      final: receiverType?.includes(2) ? true : false,
      withinCountry: receiverType?.includes(3) ? true : false,
      noPatronymic: noPatronymic || false,
      common: commonReceiver,
      user: commonReceiver === true ? null : {id: user?.id},
      documentNumber,
      main,
      document: documentObj.document,
      documentUrl: documentObj.documentUrl,
    }

    if (enabledChanged) {
      payload.enabled = values.active === 1 ? true : false
    }
    if (verifiedChanged) {
      payload.verifStatus = values.verifStatus?.value?.toString()
    }

    try {
      const response = await updateReceiver(payload, id)

      if (response) {
        setCurrReceiver(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        if (error?.response?.data?.message?.includes('RECEIVER.VALIDATION.DOCUMENT_NUMBER_TAKEN')) {
          setFooterNotification(
            CARDFOOTER_NOTIFICATIONS('RECEIVERS.REC_PAGE.GENERAL.DUPL_DOC_NUMBER_ERR').ERROR
          )
        } else {
          setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)
        }

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      {receiver ? (
        <Formik
          validationSchema={receiverOverviewSchema(intl)}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values)} */}
              {/*  {JSON.stringify(errors)} */}
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Статус</label>
                  <div className='col-lg-8'>
                    <div className='row flex-nowrap'>
                      <div className='col-lg-6 fv-row me-4'>
                        <CheckboxSwitcher
                          inputName='active'
                          firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED'})}
                          secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE'})}
                          // @ts-ignore
                          value={+values.active}
                          setFieldValue={setFieldValue}
                          bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                        />
                      </div>{' '}
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='d-flex'>
                      {' '}
                      <InputTemplate
                        inputName='main'
                        type='checkbox'
                        checkboxProps={{
                          checked: values.main || false,
                          disabled: false,
                          setFieldValue: setFieldValue,
                          value: 1,
                          inputName: 'main',
                          type: 'checkbox',
                        }}
                        title={intl.formatMessage({id: 'RECEIVERS.REC_PAGE.MAIN_LABEL'})}
                        containerMarginBottom={false}
                        promptText={'RECEIVERS.REC_PAGE.MAIN_PROMPT'}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label
                    className='col-lg-4 col-form-label required fw-bold fs-6'
                    htmlFor='verifStatus'
                  >
                    Верифікація
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <Verification values={values} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.REC_PAGE.FULL_NAME_LABEL' />
                  </label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='firstName'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'RECEIVERS.REC_PAGE.NAME_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='lastName'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'RECEIVERS.REC_PAGE.LAST_NAME_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>{' '}
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='patronymic'
                          required={false}
                          type='text'
                          placeholder={intl.formatMessage({
                            id: 'RECEIVERS.REC_PAGE.PATRONYMIC_PLACEHOLDER',
                          })}
                          addSymbol={
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-3'
                            />
                          }
                          symbolMarginTop={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      {' '}
                      <PatronymicCheckbox
                        setFieldValue={setFieldValue}
                        title={intl.formatMessage({
                          id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                        })}
                        value={values.noPatronymic || false}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Клієнт</label>{' '}
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        {common === false ? (
                          <ClientSelect
                            setUsersLoading={setUsersLoading}
                            setUsersResults={setUsersResults}
                            options={usersResults}
                            isLoading={usersLoading}
                            currentValue={values.user}
                            disabled={values.commonReceiver}
                            title={false}
                            onInputChange={filterUsers}
                          />
                        ) : (
                          <div className='py-4'>
                            <span
                              className={`py-1 px-2 fs-6 fw-semobold StatusBar__status bg-primary`}
                            >
                              Загальний
                              {/* <FormattedMessage id='RECEIVERS.STATUSES.INTERN_REC' /> */}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>{' '}
                </div>
                {/* <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'></label>{' '}
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='fv-row'>
                          <InputTemplate
                            inputName='commonReceiver'
                            type='checkbox'
                            checkboxProps={{
                              checked: values.commonReceiver || false,
                              disabled: false,
                              setFieldValue: setFieldValue,
                              value: 1,
                              inputName: 'commonReceiver',
                              type: 'checkbox',
                            }}
                            title={'Застосовувати для будь-якого клієнта'}
                            containerMarginBottom={false}
                            handleChange={() => {
                              if (values.commonReceiver === true) {
                                setFieldValue('user', {value: '', label: ''})
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                <div className='row mb-6'>
                  <label className='d-flex col-lg-4 col-form-label fw-bold fs-6 position-relative required'>
                    <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                  </label>
                  <div className='col-lg-8'>
                    <ReceiverType values={{receiverType: values.receiverType || []}} />
                  </div>
                </div>{' '}
                <div className='row mb-6'>
                  <label
                    className={`d-flex col-lg-4 col-form-label fw-bold fs-6 position-relative ${
                      values?.verifStatus?.value === VERIFIED ? 'required' : ''
                    }`}
                  >
                    <FormattedMessage id='RECEIVERS.REC_PAGE.GENERAL.DOC_NUMBER_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='documentNumber'
                          required={false}
                          type='text'
                          containerMarginBottom={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <FileInput
                    title={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                    })}
                    inputName='document'
                    inputId={`${id}-receiver-file-input`}
                    uploadedFile={{
                      base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                      link: values.document || documentObj.documentUrl || '',
                    }}
                    setFieldValue={setFieldValue}
                    uploadFunction={saveImage}
                    deleteFunction={deleteImage}
                    placeholder={intl.formatMessage({
                      id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                    })}
                    notification={{
                      bool: true,
                      text: intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                      }),
                      borderTop: false,
                    }}
                    loading={docIsLoading}
                    required={
                      values?.receiverType?.includes(1) || values?.receiverType?.includes(2)
                    }
                  />
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </div>
  )
}
