import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'
import {useListView as locationsUseListView} from '../../locations/core/ListViewProvider'
import {CreateRegionForm} from './CreateRegionForm'

export const CreateRegionFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = locationsUseListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  // const {
  //   isLoading,
  //   data: track,
  //   error,
  // } = useQuery(
  //   'trackEdit',
  //   () => {
  //     return getTrackById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  //  if (isLoading) {
  //    return (
  //      <div>
  //        <PartialPageLoader />
  //      </div>
  //    )
  // }

  if (itemIdForUpdate === null) {
    return <CreateRegionForm />
  }

  return null
}
