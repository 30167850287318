import {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import {IPaymentProvider} from '../../../../core/_models'
import {paymentProvidersApi} from '../../../../core/_requests'

type Props = {
  paymentProvider: IPaymentProvider
}

const PaymentProviderActionsCell: FC<Props> = ({paymentProvider}) => {
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {id} = paymentProvider
  // const deleteMutation = useMutation<any, Error>(
  //   async () => {
  //     return await paymentProvidersApi.deletePaymentProvider(id)
  //   },
  //   {
  //     onSuccess: () => {},
  //     onSettled: () => {
  //       setDeleteModalOpen(false)
  //       refetch()
  //     },
  //     onError: (error) => {
  //       console.log(error)
  //     },
  //   }
  // )
  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      <Link
        to={`/finances/payment-providers/edit/overview/${id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>
      {/* {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'WAREHOUSE.PAGE.LIST.ACTIONS.TRASH_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )} */}
      {/* {allowDelete && (
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{intl.formatMessage({id: 'GENERAL.TOOLTIPS.TRASH'})}</Tooltip>}
        >
          <a
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => setDeleteModalOpen(true)}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </a>
        </OverlayTrigger>
      )} */}
    </div>
  )
}

export {PaymentProviderActionsCell}
