/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {ID, KTSVG} from '../../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {IShipment} from '../../../../core/_models/_models'
import {editShipment} from '../../../../core/_requests'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'

type Props = {
  shipment: IShipment
}

const AddTrackCell: FC<Props> = ({shipment}: Props) => {
  const intl = useIntl()

  const navigate = useNavigate()
  const {state} = useLocation() as {state: {showChoseButton: boolean; trackId: ID} | undefined}

  const addTrackToShipment = async () => {
    try {
      await editShipment(
        {trackingNumbers: [...shipment.trackingNumbers, {id: state?.trackId}]},
        shipment.id
      )
      navigate('/tracks/list')
    } catch (error) {
      console.log('edit add track to shipment err', error)
    }
  }

  return (
    <div className='d-flex flex-gutter flex-shrink-0'>
      <button className='btn btn-sm text-dark btn-light' onClick={addTrackToShipment}>
        Обрати
      </button>
    </div>
  )
}

export {AddTrackCell}
