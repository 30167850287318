import {Formik, Form} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {IPoll, IPollForm} from '../../../core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {pollsApi} from '../../../core/_requests'
import QuestionsAnswers from '../../../modules/QuestionsAnswers'
import {transformApiQuestionsForForm} from '../../../core/funcs/transformQuestions'
import {useParams} from 'react-router-dom'
import {IWebsite} from '../../../../websites/core/_models'
import {integrateEditedTranslations} from '../../../core/funcs/integrateEditedQuestionsAndAnswersToEdit'

interface Props {
  poll: IPoll
  setCurrPoll: (obj: IPoll) => void
  website: IWebsite | undefined
}

export function PollQuestionsAnswers({poll, setCurrPoll, website}: Props) {
  const {locale = 'uk'} = useParams()
  const initialValues: Partial<IPollForm> = {
    website: {...website, value: website?.id, label: website?.name || ''},
    questions: transformApiQuestionsForForm(poll.questions, locale),
  }

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IPollForm>) => {
    setLoading(true)

    const {website, questions} = values
    if (questions && website) {
      const pollData = {
        questions: integrateEditedTranslations(poll.questions, questions, locale, website),
      }


      const response = await pollsApi.editPoll(pollData, poll.id)

      if (response) {
        queryClient.invalidateQueries([QUERIES.POLL_BY_ID])
        setLoading(false)
        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      }
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, errors, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values.questions && values.questions[0])} */}
            <div className='card-body border-top p-9'>
              <QuestionsAnswers values={values} editingFormat={true} />
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
