import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {Direction} from '../../../../core/_models'

type Props = {
  direction: Direction
}

const DirectionTypeCell: FC<Props> = ({direction}) => {
  return (
    <div>
      {direction.senderTypes.includes('1') && (
        <p className='fw-semobold text-black d-block fs-6 mb-1'>
          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.FORW' />{' '}
        </p>
      )}
      {direction.senderTypes.includes('2') && (
        <p className='fw-semobold text-black d-block fs-6 mb-0'>
          <FormattedMessage id='DIRECTIONS.PAGE.LIST.TYPE.PERSONAL' />{' '}
        </p>
      )}
    </div>
  )
}

export {DirectionTypeCell}
