import {Formik, Form} from 'formik'
import {useState} from 'react'
// import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
// import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
// import {objectToArray} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'

import {IPoll, IPollForm, IPollResult, PollResultsQueryResponse} from '../../../core/_models'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {pollsApi} from '../../../core/_requests'
import {PollResultsListWrapper} from './list/PollResultsList'

interface Props {
  poll: IPoll
  setCurrPoll: (obj: IPoll) => void
}

export function PollResults({poll, setCurrPoll}: Props) {
  const initialValues: Partial<IPollForm> = {}

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IPollForm>) => {
    setLoading(true)

    const {name} = values

    const pollData = {
      name,
    }
    const response = await pollsApi.editPoll(pollData, poll.id)

    if (response) {
      queryClient.invalidateQueries([QUERIES.POLL_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <PollResultsListWrapper />
    </div>
  )
}
