import {ErrorMessage, Field, FieldArray} from 'formik'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {profileNumber} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useEffect, useRef} from 'react'

interface Props {
  synonyms: string[]
  setFieldValue: ISetFieldValue
  title?: boolean
}
export function CategorySynonyms({synonyms, title = false}: Props) {
  return (
    <div className='row mb-6'>
      {title && <label className='col-lg-4 col-form-label fw-bold fs-6'>Синонимы:</label>}
      <div className='col-lg-8'>
        <div className='d-flex flex-column mb-10'>
          {' '}
          <div className='w-100'>
            <FieldArray
              name='synonyms'
              render={(arrayHelpers) => (
                <div className='d-flex flex-column flex-col-gutter'>
                  {synonyms.map((item, index) => (
                    <div key={index}>
                      <div className='d-flex'>
                        <Field
                          className={`form-control form-control-lg form-control-solid`}
                          name={`synonyms.${index}`}
                          value={item}
                          autoFocus
                        />
                        <button
                          className='btn'
                          type='button'
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen040.svg'
                            className='svg-icon-1x svg-icon-primary d-block my-2 '
                          />
                        </button>
                      </div>{' '}
                      <div className='text-danger'>
                        <ErrorMessage name={`synonyms.${index}`} />
                      </div>
                    </div>
                  ))}
                  <div className=''>
                    <button
                      className='btn btn-sm btn-secondary d-flex align-items-center'
                      type='button'
                      onClick={() => {
                        arrayHelpers.push('')
                      }}
                    >
                      <span className='me-2'>Добавить</span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen041.svg'
                        className='svg-icon-1x svg-icon-primary d-block my-2'
                      />
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
