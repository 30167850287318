import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../_metronic/helpers'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {IDeliveryTypeForm} from '../core/_models'
import MethodRadio from '../modules/vol-weight-calculation/MethodRadio'

const VolWeightCalculation = ({
  values,
  withTitle = true,
}: {
  values: Partial<IDeliveryTypeForm>
  withTitle?: boolean
}) => {
  const queryClient = useQueryClient()

  const volumetricWeightPriceMethods: Record<string, string> | undefined = queryClient.getQueryData(
    [QUERIES.VOL_WEIGHT_PRICE_METHODS]
  )
  return (
    <>
      {volumetricWeightPriceMethods && (
        <div className='fv-row d-flex'>
          {withTitle && (
            <h3 className='fs-5 fw-bold mb-4 me-10 w-25'>Методика розрахунку об'ємної ваги</h3>
          )}
          <div className='d-flex flex-column'>
            {Object.entries(volumetricWeightPriceMethods).map(([k, v]) => (
              <div className='d-flex align-items-center' key={k}>
                <MethodRadio k={k} v={v} values={values} />{' '}
                {k === 'combined' && values.volumetricWeightPriceMethod === 'combined' && (
                  <InputTemplate
                    placeholder='Коефіціент'
                    required={false}
                    inputName='volumetricWeightCombinedRate'
                    type='number'
                    symbolMarginTop={false}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default VolWeightCalculation
