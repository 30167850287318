/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Employee} from '../../../core/_models'

type Props = {
  employee: Employee
  iconAlt: string
  icon?: string
}

const EmployeeBalance: FC<Props> = ({employee, iconAlt, icon}) => {
  return (
    <div className='d-flex align-items-center'>
      <OverlayTrigger placement='right' overlay={<Tooltip>{iconAlt}</Tooltip>}>
        <span>$0</span>
      </OverlayTrigger>
    </div>
  )
}

export {EmployeeBalance}
