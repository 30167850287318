import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ChatsQueryResponse, IChat} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const CHATS_URL = `/message_subjects`
const BASE_URL = `${API_URL}${CHATS_URL}`

const getChats = (query: string): Promise<ChatsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ChatsQueryResponse>) => {
      return d.data
    })
}

const getChatOutcomes = () => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/outcomes`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d) => {
      return d.data
    })
}

export {getChats, getChatOutcomes}
