import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES, selectDebounce} from '../../../../../_metronic/helpers'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {InputTemplate} from '../InputTemplate'
import {getCountries} from '../../../../pages/countries/countries-list/core/_requests'
import {ICountryConfigForm} from '../../../../pages/warehouses/core/_models'

const CountriesSelect = ({queryFilter = '', withTitle = true}) => {
  const intl = useIntl()
  const {values, setFieldValue} = useFormikContext<{countryConfig: ICountryConfigForm}>()
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const [resultsLoading, setResultsLoading] = useState(false)
  const [results, setResults] = useState<IBasicSelect[]>([])

  const filterCountries = useCallback(
    selectDebounce((callback?: any) => {
      setResultsLoading(true)

      getCountries(queryFilter)
        .then((res) => {
          const reformed = res?.data?.map((item) => {
            return {
              ...item,
              label: countries[item.country],
              value: item.id,
            }
          })
          if (reformed) {
            setResults(reformed)
          }
          if (callback) {
            callback(reformed)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getCountries]
  )

  useEffect(() => {
    filterCountries()
  }, [filterCountries])
  return (
    <InputTemplate
      inputName='countryConfig'
      required={true}
      containerMarginBottom={false}
      type='select'
      // value={values.countryConfig || {value: '', label: ''}}
      title={withTitle ? intl.formatMessage({id: 'COMPONENTS.CountriesSelect.LABEL'}) : ''}
      titleFontSize='fs-5 text-dark'
      reactSelectprops={{
        currentValue: values.countryConfig || {value: '', label: ''},
        options: results,
        selectName: 'countryConfig',
        isLoading: resultsLoading,
        onInputChange: filterCountries,
        setFieldValue: setFieldValue,
        setObject: true,
        placeholder: intl.formatMessage({
          id: 'WAREHOUSES.ADD_MODAL.FORM.COUNTRIES_PLACEHOLDER',
        }),
        noOptionsMessage: intl.formatMessage({
          id: 'WAREHOUSES.ADD_MODAL.FORM.NO_COUNTRIES_MESSAGE',
        }),
      }}
    />
  )
}

export default CountriesSelect
