/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {Portal} from '../../../../../../_metronic/partials'
import {FormattedMessage, useIntl} from 'react-intl'
import {ITrackProd, Track} from '../../../../../pages/tracks/core/_models/_tracks-models'
import {IOrder} from '../../../../../pages/orders/core/_models/_orders-models'

type Props = {
  products: ITrackProd[]
  product: ITrackProd
  item: Track | IOrder
  setCurrTrack: (obj: Track | IOrder) => void
}

export function ProductCard({products, product, item, setCurrTrack}: Props) {
  const intl = useIntl()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {category, quantity, priceForAll, priceForOne} = product

  // const deleteMutation = useMutation<any, Error>(
  //   async () => {
  //     const filteredProducts = track.products.filter((prod) => prod.id !== item.id)
  //     return await editTrack({
  //       ...track,
  //       receiver: track?.receiver?.id ? +track?.receiver?.id : null,
  //       prods: filteredProducts,
  //       invoice_file:
  //         track.invoice_file.map((item) => {
  //           return {name: item.name, tmp_name: item.file.filename}
  //         }) || undefined,
  //       mitnitsya:
  //         +track?.type_delive === 0 &&
  //         track.prods
  //           .filter((prod) => prod.id !== item.id)
  //           .some((prod) => +prod.commercial === 1) === true
  //           ? 1
  //           : 0,
  //     })
  //   },
  //   {
  //     onSuccess: (data) => {
  //       setCurrTrack(data)
  //     },
  //     onSettled: () => {
  //       setDeleteModalOpen(false)
  //     },
  //     onError: (error) => {
  //       console.log(error)
  //     },
  //   }
  // )

  return (
    <>
      {/* {editModalOpen && (
        <Portal
          children={
            <EditProductCard
              setIsOpen={setEditModalOpen}
              isOpen={editModalOpen}
              dataForEditing={product}
              item={item}
              setCurrTrack={setCurrTrack}
            />
          }
          className='modal-root'
        />
      )} */}

      {/* {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'TRACK_PAGE.CONTENT_TAB.DELETE_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={track.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )} */}
      <div className='col-sm-6 col-xl-4'>
        <div className='card h-100 border border-2 border-gray-300 border-hover'>
          <div className='card-body p-9'>
            <div className='d-flex justify-content-between align-items-center mb-4'>
              <div className='d-flex align-items-center'>
                <img
                  alt=''
                  width='55'
                  height='55'
                  className='rounded-circle w-55px me-3'
                  src={
                    `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${category?.logoUrl}` ||
                    '/media/icons/duotune/ecommerce/ecm002.svg'
                  }
                />
                <div className='fs-3 fw-bolder text-dark'>{product.title}</div>
              </div>

              {/* <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-gray-900 btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    {' '}
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3 svg-icon-gray-900'
                    />
                  </button>
                </div> */}
            </div>

            <div className='d-flex flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>{quantity}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_QTY' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${priceForOne}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_COST' />
                </span>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                <span className='fs-6 text-gray-800 fw-bolder me-2'>${priceForAll}</span>
                <span className='fw-bold text-gray-400'>
                  <FormattedMessage id='TRACK_PAGE.CONTENT_TAB.CARD_TOTAL' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
