import {Link, useLocation, useParams} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {IWebsite} from '../../core/_models'

interface Props {
  website: IWebsite
}

export function WebsiteHeaderNav({website}: Props) {
  const {pathname} = useLocation()
  const {locale} = useParams()

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {tabs.map(({value, label}) => (
          <li key={value} className='nav-item' onClick={scrollToTabsStart}>
            <Link
              className={
                `nav-link text-active-primary me-6 text-center ` +
                (pathname === `/websites/edit/${value}/${locale}/${website.id}` && 'active')
              }
              to={`/websites/edit/${value}/${locale}/${website.id}`}
            >
              {label}
              {/* <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' /> */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const tabs = [
  // {
  //   path: `overview`,
  //   intl: 'Основне',
  // },
  {
    value: `tos`,
    label: 'Угода користувача',
  },
]
