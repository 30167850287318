import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {CollectionHeader} from './header/CollectionHeader'
import {CollectionNoResults} from './CollectionNoResults'
import {ICollection} from '../../_models'
import {getCollectionById} from '../core/_requests'
import CollectionOverview from './tabs/overview/CollectionOverview'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  id: string | number
}

const CollectionPage = ({id}: Props) => {
  const {data: collectionById} = useQuery(`website-collection-${id}`, () => getCollectionById(id))

  const [currCollection, setCurrCollection] = useState<ICollection | undefined>(undefined)

  const queryClient = useQueryClient()

  const invalidateCollection = () => {
    queryClient.invalidateQueries(`website-collection-${id}`)
  }

  useEffect(() => {
    if (collectionById) {
      setCurrCollection(collectionById)
    }
  }, [collectionById])

  return (
    <>
      {currCollection && collectionById ? (
        <Routes>
          <Route
            element={
              <>
                <CollectionHeader collection={currCollection} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:locale/:id`}
              element={
                <>
                  <CollectionOverview
                    collection={currCollection}
                    invalidateCollection={invalidateCollection}
                    type={2}
                  />
                </>
              }
            />

            <Route path={`:id`} element={<CollectionNoResults />} />

            <Route index element={<Navigate to={`edit/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default CollectionPage
