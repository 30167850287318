import ListSearch from '../../../../../../modules/custom/lists/ListSearch'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'

const TranslationsListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      <ListSearch updateState={updateState} />

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TranslationsListHeader}
