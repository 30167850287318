/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useMemo, useState} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
  stringifyRequestQuery,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {OrdersTable} from './table/OrdersTable'
import {OrdersListHeader} from './components/header/OrdersListHeader'
import {useLocation, useParams} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'
import OnePageModal from '../../../modules/custom/track-or-order/_modals/OnePageModal'
import {useQueryClient} from 'react-query'
import {DirectionsQueryResponse} from '../../directions/core/_models'

export const OrdersList = () => {
  const listView = useListView()
  const {itemIdForUpdate, setItemIdForUpdate, isArchive, currPage} = useListView()

  const buyoutsPage = currPage === 'buyouts'

  const location = useLocation()
  const {state: locState}: {pathname: string; state: any} = location
  const {updateState, state} = useQueryRequest()
  const queryResponse = useQueryResponse()

  const openAddOrderModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.ORDERS_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    if (buyoutsPage) {
      state.filter.type = 'buyout'
    }
    console.log('st', state)

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.ORDERS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])

  // useEffect(() => {
  //   const retrievedData = getSavedPagination()
  //   const savedFilter = getSavedFilter(QUERIES.ORDERS_LIST)

  //   let state = {
  //     ...initialQueryState,
  //     filter: {...savedFilter},
  //     itemsPerPage: retrievedData,
  //   }
  //   // if (buyoutsPage) {
  //   //   state.filter.type = 'buyout'
  //   // }
  // }, [buyoutsPage])

  useEffect(() => {
    if (locState?.create === true) {
      setItemIdForUpdate(null)
    } else {
      setItemIdForUpdate(undefined)
    }
  }, [locState])

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const queryClient = useQueryClient()
  const directions: DirectionsQueryResponse | undefined = queryClient.getQueryData([
    QUERIES.DIRECTIONS_WITH_IDS_LIST,
  ])

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>{buyoutsPage ? 'Викупи' : 'Замовлення'}</h3>
        <div className='d-flex flex-gutter'>
          {isArchive === false && (
            <>
              <button type='button' className='btn btn-sm btn-primary' onClick={openAddOrderModal}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <FormattedMessage
                  id={buyoutsPage ? 'BUYOUTS.LIST.ADD_BUTTON' : 'ORDERS.PAGE.LIST.ADD_BUTTON'}
                />
              </button>
            </>
          )}
        </div>
      </div>
      <KTCard>
        {directions?.data && <OrdersListHeader />}
        <OrdersTable />

        {itemIdForUpdate !== undefined && (
          <OnePageModal listView={listView} queryResponse={queryResponse} />
        )}
      </KTCard>
    </>
  )
}

const OrdersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OrdersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OrdersListWrapper}
