import React, {useEffect} from 'react'
import {Formik, Form, FormikValues} from 'formik'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useState} from 'react'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CheckboxSwitcher} from '../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'
import {ILocationToSend, NewLocationFullForm, Location} from '../../../core/_models'
import {FormattedMessage, useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'

import {locationOverviewSchema} from '../../../core/yup'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  location: Location
  setCurrLocation: (obj: Location) => void
}

export function LocationOverview({location, setCurrLocation}: Props) {
  const intl = useIntl()

  const initialValues = {}

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<NewLocationFullForm>, actions: FormikValues) => {
    const {} = values
    setLoading(true)

    // try {
    //   const response = await updateLocation(payload, id)

    //   if (response) {
    //     setCurrLocation(response)
    //     setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //     setTimeout(() => {
    //       setFooterNotification(null)
    //     }, 3000)
    //   }
    // } catch (error: any) {
    //   console.log(error)
    //   if (error?.response?.data?.message) {
    //     setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

    //     setTimeout(() => {
    //       setFooterNotification(null)
    //     }, 3000)
    //   }
    // } finally {
    //   setLoading(false)
    // }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      {location ? (
        <div className='card-body py-9 px-20 cardBody'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>
                    Служба
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_DATE_COLUMN' /> */}
                  </th>

                  <th className='min-w-100px mw-50'>
                    Назва
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.CHANGE_COLUMN' /> */}
                  </th>
                  <th className='min-w-50px'>
                    ID
                    {/* <FormattedMessage id='RECEIVERS.REC_PAGE.LOGS.AUTHOR_COLUMN' /> */}
                  </th>
                </tr>
              </thead>
              <tr>
                <td>
                  <div className='d-flex flex-column'>
                    <span className='text-dark fw-bold fs-6'>Vector</span>
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column'>
                    <span className='fw-bold text-black d-block fs-6 me-1'>
                      {location.name} ({location.region.name + ' ' + location.region.type})
                    </span>
                  </div>
                </td>{' '}
                <td>
                  <span
                    className='text-dark d-block fs-6 mw-500px'
                    style={{overflowWrap: 'break-word'}}
                  >
                    {location.id}
                  </span>
                </td>
              </tr>
              <tbody></tbody>
            </table>
          </div>
        </div>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
