/* eslint-disable jsx-a11y/anchor-is-valid */
import {Formik, Form, FormikValues} from 'formik'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {TFooterNotification} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IParcel} from '../../../core/_models/_models'

interface Props {
  parcel: IParcel
  setCurrParcel: (obj: IParcel) => void
  invalidateParcel: () => void
}

export function ParcelOverviewTab({parcel, setCurrParcel}: Props) {
  const initialValues = {}
  const intl = useIntl()
  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)

  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleSubmit = async (values: Partial<IParcel>, actions: FormikValues) => {
    const {} = values
    setLoading(true)
    // const response = await updateParcel(
    //   {
    //     phoneNumber: '+38' + phoneNumber,
    //     email,
    //     id: parcel.id,
    //   },
    //   parcel.id
    // )

    // if (response) {
    //   setCurrParcel(response)
    //   setLoading(false)
    //   setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //   setTimeout(() => {
    //     setFooterNotification(null)
    //   }, 3000)
    // } else {
    //   // set error
    //   setLoading(false)
    // }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={() => {}}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              <div className='card-body  p-9'></div>
              <CardFooter
                loading={loading}
                disabled={disableSubmit}
                success={false}
                // notification={footerNotification}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
