import * as Yup from 'yup'

const createDeliveryTypeSchema = Yup.object({
  title: Yup.string().required('Укажите название типа доставки').label('Название типа доставки'),
  avatar: Yup.mixed().label('Аватар для типа доставки'),
  direction: Yup.string().required('Выберите направление').label('Направление сайта'),

  categories: Yup.array().label('Категория товаров'),

  deliveryTime: Yup.number()
    .min(1, 'Нельзя выбрать ноль или отрицательное значение')
    .typeError('Допустимы только цифры')
    .required('Укажите количество дней')
    .label('Срок доставки'),
  type: Yup.number()
    .typeError('Укажите тип доставки')
    .required('Укажите тип доставки')
    .label('Тип доставки'),

  price: Yup.number()
    .typeError('Допустимы только цифры')
    .when('type', {
      is: 1,
      then: Yup.number()
        .min(1, 'Нельзя выбрать ноль или отрицательное значение')
        .required('Укажите стоимость')
        .label('Цена за килограмм'),
    }),
  max_weight: Yup.number()
    .typeError('Допустимы только цифры')
    .when('type', {
      is: 1,
      then: Yup.number()
        .min(1, 'Нельзя выбрать ноль или отрицательное значение')
        .required('Укажите максимальный вес')
        .label('Максимально допустимый вес'),
    }),
  minimum_value: Yup.number().when('type', {
    is: 1,
    then: Yup.number().required().label('Минимально допустимая величина'),
  }),
  min_price: Yup.number()
    .typeError('Допустимы только цифры')
    .when('minimum_value', {
      is: 0,
      then: Yup.number()
        .min(0, 'Нельзя выбрать отрицательное значение')
        .label('Минимальная стоимость'),
    }),
  min_weight: Yup.number()
    .typeError('Допустимы только цифры')
    .when('minimum_value', {
      is: 1,
      then: Yup.number().min(0, 'Нельзя выбрать отрицательное значение').label('Минимальный вес'),
    }),
  deliveryTwoItems: Yup.array()
    .of(
      Yup.object().shape({
        weight: Yup.number(),
        specificWeightPrice: Yup.number().when('weight', {
          is: true,
          then: Yup.number()
            .min(1, 'Нельзя выбрать ноль или отрицательное значение')
            .typeError('Допустимы только цифры')
            .required('Укажите стоимость за указанный вес'),
        }),
      })
    )

    .when('type', {
      is: 2,
      then: Yup.array()
        .min(1, 'Добавьте, как минимум, одну позицию')
        .required('Добавьте, как минимум, одну позицию'),
    }),

  priceRangeType: Yup.number().required().label('Зона действия'),
  volumetricWeightType: Yup.number().required().label('Учитывать объёмный вес'),
  volumetricWeightToWeightRatio: Yup.number().when('volumetricWeightType', {
    is: (value: number) => value === 1 || value === 2,
    then: Yup.number().min(0.1, 'Вкажіть коефіцієнт').required('Вкажіть коефіцієнт'),
  }),
  volumetricWeightDivisor: Yup.number().when('volumetricWeightType', {
    is: (value: number) => value === 1 || value === 2,
    then: Yup.number().min(0.1, 'Вкажіть коефіцієнт').required('Вкажіть коефіцієнт'),
  }),
  volumetricWeightPriceMethod: Yup.string(),
  volumetricWeightCombinedRate: Yup.number(),
  volumetricWeightMinValue: Yup.number()
    .min(1, 'Нельзя выбрать ноль или отрицательное значение')
    .label('Превышение размера одной из сторон'),
  palletWidth: Yup.number()

    .min(0, 'Нельзя выбрать отрицательное значение')
    .label('Ширина паллеты'),
  palletLength: Yup.number()

    .min(0, 'Нельзя выбрать отрицательное значение')
    .label('Длина паллеты'),
  palletOversizeFee: Yup.number()

    .min(0, 'Нельзя выбрать отрицательное значение')
    .label('Размер надбавки за превышение размера паллеты'),
})

const deliveryTypeSettingsSchema = Yup.object({
  volumetricWeightType: Yup.number().required().label('Учитывать объёмный вес'),
  volumetricWeightToWeightRatio: Yup.number().when('volumetricWeightType', {
    is: (value: number) => value === 1 || value === 2,
    then: Yup.number().min(0.1, 'Вкажіть коефіцієнт').required('Вкажіть коефіцієнт'),
  }),
  volumetricWeightDivisor: Yup.number().when('volumetricWeightType', {
    is: (value: number) => value === 1 || value === 2,
    then: Yup.number().min(0.1, 'Вкажіть коефіцієнт').required('Вкажіть коефіцієнт'),
  }),
  volumetricWeightPriceMethod: Yup.string(),
  volumetricWeightCombinedRate: Yup.number(),
  volumetricWeightMinValue: Yup.number()
    .min(1, 'Нельзя выбрать ноль или отрицательное значение')
    .label('Превышение размера одной из сторон'),
})

export {createDeliveryTypeSchema, deliveryTypeSettingsSchema}
