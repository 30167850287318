/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
} from '../../../../_metronic/helpers'
import {CouriersTable} from './table/CouriersTable'
import {CouriersListHeader} from './components/header/CouriersListHeader'
import {useEffect} from 'react'
import CreateCourierModal from '../_modals/CreateCourierModal'
import {FormattedMessage} from 'react-intl'

const CouriersList = () => {
  const {filterUsers, setItemIdForUpdate, itemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.COURIERS_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  const openAddModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>
          <FormattedMessage id='COURIERS.PAGE.HEADER' />
        </h3>
        <button type='button' className='btn btn-sm btn-primary' onClick={openAddModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          <FormattedMessage id='COURIERS.LIST.CREATE' />
        </button>
      </div>
      <KTCard>
        <CouriersListHeader />
        <CouriersTable />
        {itemIdForUpdate !== undefined && <CreateCourierModal />}
      </KTCard>
    </>
  )
}

const CouriersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CouriersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CouriersListWrapper}
