import {ICreateNewDirection, Direction, DirectionsQueryResponse} from './_models'
import axios, {AxiosResponse} from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ID} from '../../../../_metronic/helpers'

axios.defaults.baseURL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API = process.env.REACT_APP_API_PATH

const ENDPOINT_BASE = `/directions`

const addDirection = async (data: ICreateNewDirection) => {
  const {data: response} = await axios.post(`${API}${ENDPOINT_BASE}`, data)

  return response
}

const fetchDirections = async (res: any) => {
  const {data} = await axios.get<Direction[]>(`${API}${ENDPOINT_BASE}`, {params: res.queryKey[1]})
  return data
}

// const fetchDirectionsWithIds = async (query: string) => {
//   const {data} = await axios.get<DirectionsQueryResponse>(
//     `${API}${ENDPOINT_BASE}?parcelIdentByOrderNumber=1&enabled=true`
//   )
//   return data
// }

const fetchDirectionsWithIds = (
  query: string,
  enabled = true
): Promise<DirectionsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(
      `${API}${ENDPOINT_BASE}?${query}parcelIdentByOrderNumber=1${enabled ? '&enabled=true' : ''}`,
      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((d: AxiosResponse<DirectionsQueryResponse>) => {
      return d.data
    })
}

const getDirectionById = (id: ID): Promise<Direction | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${API}${ENDPOINT_BASE}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Direction>) => d.data)
}

const fetchDirectionCountriesList = async () => {
  const {data} = await axios.get(`${API}/public${ENDPOINT_BASE}/countries`)
  return data
}

const deleteDirection = async (id: ID) => {
  const response = await axios.delete(`${API}${ENDPOINT_BASE}/${id}`)
  return response
}

const patchStatus = async (id: ID, newStatus: boolean) => {
  const response = await axios.put<any>(`${API}${ENDPOINT_BASE}/${id}`, {enabled: newStatus})
  return response
}

const editDirection = async (id: ID, data: any) => {
  const {data: response} = await axios.put<any>(`${API}${ENDPOINT_BASE}/${id}`, data)
  return response
}

export const directionsApi = {
  addDirection,
  fetchDirections,
  fetchDirectionsWithIds,
  getDirectionById,
  fetchDirectionCountriesList,
  deleteDirection,
  patchStatus,
  editDirection,
}
