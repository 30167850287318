import {Column} from 'react-table'
import {TranslationCustomHeader} from './TranslationCustomHeader'
import {TranslationActionsCell} from './cells/TranslationActionsCell'
import {TranslationKeyCell} from './cells/TranslationKeyCell'
import {TranslationWebsitesCell} from './cells/TranslationWebsitesCell'
import {IChatTranslation} from '../../../core/_models'
import {TranslationsCell} from './cells/TranslationsCell'

const translationsColumns: ReadonlyArray<Column<IChatTranslation>> = [
  {
    Header: (props) => (
      <TranslationCustomHeader
        tableProps={props}
        title={'BLOG_COLUMNS_TITLE'}
        className='min-w-125px'
      />
    ),
    id: 'title',
    Cell: ({...props}) => <TranslationKeyCell translation={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TranslationCustomHeader tableProps={props} title='Переклад' className='min-w-150px' />
    ),
    id: 'translations',
    Cell: ({...props}) => <TranslationsCell translation={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TranslationCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-end'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TranslationActionsCell translation={props.data[props.row.index]} />,
  },
]

export {translationsColumns}
