import {FormattedMessage} from 'react-intl'
import {toAbsoluteUrl} from '../../helpers'

const PartialPageLoader = ({fullHeight = false}) => {
  return (
    <div
      id='splash-screen'
      className={`splash-screen position-relative ${
        fullHeight ? 'h-100' : 'h-300px'
      } bg-white d-flex flex-column align-items-center rounded border border-gray-300 PartialPageLoader`}
    >
      <img
        src={toAbsoluteUrl('/media/custom/svg/logo-dark.svg')}
        alt='Crauzer logo'
        height='200'
        width='200'
        className='m-0 mb-10'
      />{' '}
      <span className='fw-bolder fs-5'>
        {' '}
        <FormattedMessage id='GENERAL.BUTTONS.WAIT' />
      </span>
    </div>
  )
}

export default PartialPageLoader
