import {FC, useState, createContext, useContext} from 'react'
import {ID, WithChildren} from '../../../../../../_metronic/helpers'
import {IUnpackedItem} from './_models'
import {initialContext, TPrintGoodsItem, UnpackShipmentContextProps} from './_contextHelpers'

const UnpackShipmentContext = createContext<UnpackShipmentContextProps>(initialContext)

const ContextProvider: FC<WithChildren> = ({children}) => {
  const [itemForUpdate, setItemIdForUpdate] = useState<string | null>(initialContext.itemForUpdate)
  const [unpackedItems, setUnpackedItems] = useState<IUnpackedItem[]>(initialContext.unpackedItems)
  const [currClient, setCurrClient] = useState(initialContext.currClient)
  const [errorMessage, setErrorMessage] = useState('')
  const printMode = initialContext.printMode
  const [initiatePrint, setInitiatePrint] = useState(initialContext.initiatePrint)
  const [printQueue, setPrintQueue] = useState<TPrintGoodsItem[]>(initialContext.printQueue)
  const [customUser, setCustomUser] = useState(initialContext.customUser)

  return (
    <UnpackShipmentContext.Provider
      value={{
        itemForUpdate,
        setItemIdForUpdate,
        unpackedItems,
        setUnpackedItems,
        initiatePrint,
        setInitiatePrint,
        printMode,
        printQueue,
        setPrintQueue,
        currClient,
        setCurrClient,
        errorMessage,
        setErrorMessage,
        customUser,
        setCustomUser,
      }}
    >
      {children}
    </UnpackShipmentContext.Provider>
  )
}

const useUnpackShipmentContext = () => useContext(UnpackShipmentContext)

export {ContextProvider, useUnpackShipmentContext}
