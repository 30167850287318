import {useEffect} from 'react'
import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import StorePage from './store-page/StorePage'
import {StoresListWrapper} from './stores-list/StoresList'
import {PageViewProvider} from './core/PageViewProvider'

const StoresPage = () => {
  const {config} = useLayout()
  const location = useLocation()
  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <StoresListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <PageViewProvider>
              <StorePage
                id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
              />
            </PageViewProvider>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/stores/list`} />} />
    </Routes>
  )
}

export default StoresPage
