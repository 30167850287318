import {Link} from 'react-router-dom'
import {Track} from '../../../../core/_models/_tracks-models'

type Props = {
  track: Track
}

const TrackUserCell = ({track}: Props) => {
  return (
    <div>
      <p className='text-dark fw-bold text-hover-primary d-block fs-6 p-0 m-0'>
        {track?.user?.id ? (
          <Link
            to={`/users/edit/overview/${track?.user?.id}`}
            className='text-dark fw-bold text-hover-primary fs-6 mb-1 d-flex flex-column'
          >
            <span>{track?.user?.externalId}</span>
            <span className='text-muted fs-6'>
              {[track?.user?.lastName, track.user?.firstName, track.user?.patronymic].join(' ')}
            </span>
          </Link>
        ) : (
          '-'
        )}
      </p>
    </div>
  )
}

export {TrackUserCell}
