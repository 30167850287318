import {Formik, Form, FormikValues} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../modules/custom/form-elements/InputTemplate'
import {useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IUserGroupEdit} from '../user-groups-list/core/_models'
import {editUserGroup} from '../user-groups-list/core/_requests'
import PartialPageLoader from '../../../../_metronic/layout/core/PartialPageLoader'

interface Props {
  group: IUserGroupEdit
  setCurrGroup: (obj: IUserGroupEdit) => void
}

export function UserGroupTab({group, setCurrGroup}: Props) {
  const intl = useIntl()
  const {locale} = useParams()
  const initialValues: Record<string, string> =
    group?.translations && locale ? {...group.translations[locale]} : {key: 'value'}

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Record<string, string>, actions: FormikValues) => {
    setLoading(true)

    try {
      const translations =
        group.translations && locale
          ? {
              translations: {
                ...group.translations,
                [locale]: {...values},
              },
            }
          : group

      const response = await editUserGroup(group.id, translations)

      if (response) {
        setCurrGroup(response)
        setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      {group?.translations && locale && group?.translations[locale] ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body border-top p-9'>
                {/* @ts-ignore */}
                {Object.entries(group?.translations[locale]).map(([fieldKey, fieldValue]) => (
                  <div className='row align-items-center' key={fieldKey}>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <p>{fieldKey}</p>
                      {/* <p className='fs-8 text-muted'>{fieldValue}</p> */}
                    </label>

                    <div className='col-lg-8'>
                      <InputTemplate inputName={fieldKey} required={false} type='textarea' />
                    </div>
                  </div>
                ))}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
