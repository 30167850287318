import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {ErrorMessage} from 'formik'
import {useCallback} from 'react'
import {customStyles} from '../../form-elements/ReactSelectTemplate'
import {ICategorySelectOption} from '../../../../pages/lists/categories/core/_models'

interface Props {
  setFieldValue: ISetFieldValue
  value: Partial<ICategorySelectOption>
  options: ICategorySelectOption[]
  inputName: string
  isLoading: boolean
  noOptionsMessage?: string
  disabled?: boolean
  onInputChange: (query: string, callback?: any) => void
}

export function SearchCategorySelect({
  setFieldValue,
  value,
  inputName,
  isLoading,
  noOptionsMessage,
  disabled = false,
  options,
  onInputChange,
}: Props) {
  const intl = useIntl()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>{noOptionsMessage?noOptionsMessage:intl.formatMessage({id: 'COMPONENTS.SearchSelect.NO_OPTIONS'})}</span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = useCallback(
    (inputValue: string, callback: any) => {
      if (!inputValue) {
        callback(options)
        return
      }

      onInputChange(inputValue, callback)
    },
    [onInputChange, options]
  )

  const handleOnChange = (selectedValue: Partial<ICategorySelectOption> | null) => {
    if (selectedValue !== null) {
      setFieldValue(inputName, selectedValue)
    } else {
      setFieldValue(inputName, {value: '', label: ''})
    }
  }
  return (
    <>
      <div className={`fv-row d-flex w-100 mb-5 flex-column`}>
        <label
          className={`d-flex position-relative  fs-5 flex-column fw-bold mb-2 w-100`}
          htmlFor={inputName}
        >
          <div className='mb-2'>
            {' '}
            <span className={`fs-6 required`}>
              <FormattedMessage id='COMPONENTS.SearchCategorySelect.CATEGORY' />
            </span>
          </div>
          <AsyncSelect
            formatOptionLabel={({value, label, logoUrl}) => (
              <div className='tagify__dropdown__item d-flex align-items-center' tabIndex={0}>
                <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
                  <img
                    alt=''
                    className='rounded-circle w-50px me-2 fit-content'
                    src={
                      `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${logoUrl}` ||
                      '/media/icons/duotune/ecommerce/ecm002.svg'
                    }
                  />
                </div>

                <div className='d-flex flex-column text-gray-600'>
                  <strong>{label}</strong>
                  {/* {descr && <span className='fs-6 fw-normal'>{descr}</span>} */}
                </div>
              </div>
            )}
            styles={customStyles}
            loadOptions={loadOptions}
            defaultOptions={options}
            form={inputName}
            isClearable={value.value === '' ? false : true}
            isDisabled={disabled}
            components={{NoOptionsMessage}}
            loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
            placeholder={intl.formatMessage({
              id: 'COMPONENTS.SearchSelect.PLACEHOLDER',
            })}
            isLoading={isLoading}
            value={value}
            onInputChange={(e) => {
              onInputChange(e)
            }}
            onChange={handleOnChange}
          />
        </label>

        <div className='text-danger'>
          <ErrorMessage name={`${inputName}.value`} />
        </div>
      </div>
    </>
  )
}
