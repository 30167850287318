import {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {BuyoutHeaderShipment} from './BuyoutHeaderShipment'
import currenciesLocal from '../../../../../data/general/currencies.json'
import {TCurrencyData} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {IOrder, IOrderBuyoutProps} from '../../../orders/core/_models/_orders-models'
import {HeaderInfo} from './HeaderInfo'
import BuyoutHeaderNav from './BuyoutHeaderNav'
import {confirmOrder} from '../../../orders/core/_requests'

interface Props {
  setCurrBuyout: (obj: IOrder) => void
}

const BuyoutHeader = ({buyout, setCurrBuyout}: Props & IOrderBuyoutProps) => {
  const intl = useIntl()
  const location = useLocation()
  const [show, setShow] = useState(false)
  const {products} = buyout
  const {id} = useParams()

  const confirmBuyout = async () => {
    await confirmOrder(buyout.id)
  }
  const currSymbols: TCurrencyData = currenciesLocal

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-start align-items-center flex-column flex-md-row flex-lg-row   align-items-md-start justify-content-md-between overflow-hidden overflow-md-auto'>
          <div className='d-flex flex-row w-100'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-75px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm005.svg')}
                  alt='Buyout'
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <HeaderInfo order={buyout} />
            </div>
            {buyout.allowConfirm && buyout.paid && (
              <div>
                <button type='button' className='btn btn-sm btn-primary' onClick={confirmBuyout}>
                  {`Підтвердити викуп ${
                    buyout.direction.buyoutCurrency
                      ? currSymbols[buyout.direction.buyoutCurrency].symbol
                      : ''
                  }${buyout.priceToPay}`}
                </button>
              </div>
            )}
          </div>

          <BuyoutHeaderShipment buyout={buyout} />
        </div>

        <BuyoutHeaderNav order={buyout} />
      </div>
    </div>
  )
}

export {BuyoutHeader}
