/* eslint-disable jsx-a11y/anchor-is-valid */
import {Formik, Form, FormikValues} from 'formik'

import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {Location} from '../../../core/_models'
import {locationContactsSchema} from '../../../core/yup'
import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'
import {TFooterNotification} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  location: Location
  setCurrLocation: (obj: Location) => void
}

export function LocationArea({location, setCurrLocation}: Props) {
  const initialValues = {}
  const intl = useIntl()
  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)

  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const handleSubmit = async (values: Partial<Location>, actions: FormikValues) => {

    const {} = values
    setLoading(true)
    // const response = await updateLocation(
    //   {
    //     phoneNumber: '+38' + phoneNumber,
    //     email,
    //     id: location.id,
    //   },
    //   location.id
    // )

    // if (response) {
    //   setCurrLocation(response)
    //   setLoading(false)
    //   setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)
    //   setTimeout(() => {
    //     setFooterNotification(null)
    //   }, 3000)
    // } else {
    //   // set error
    //   setLoading(false)
    // }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={locationContactsSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {/* <div className='card-body  p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS.PHONE_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate
                          inputName='phoneNumber'
                          type='tel'
                          required={true}
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber}
                          custom={
                            <LocationContact
                              inputName='phoneNumber'
                              setFieldValue={setFieldValue}
                              value={values.phoneNumber || ''}
                              setDisableSubmit={setDisableSubmit}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS.EMAIL_LABEL' />
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <InputTemplate inputName='email' type='mail' />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <CardFooter
                loading={loading}
                disabled={disableSubmit}
                success={false}
                // notification={footerNotification}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
