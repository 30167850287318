import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {AcceptTracksListWrapper} from './accept-list/AcceptTracksList'
import {LayoutSetup, useLayout} from '../../../_metronic/layout/core'
import {useEffect} from 'react'
import {useQueries} from 'react-query'
import {getWarehouses} from '../warehouses/core/_requests'
import {QUERIES} from '../../../_metronic/helpers'
import {directionsApi} from '../directions/core/_requests'

const AcceptTracksPage = () => {
  const {config} = useLayout()

  useQueries([
    {
      queryKey: [QUERIES.WAREHOUSES_LIST],
      queryFn: () => getWarehouses('', true),
    },
    {queryKey: [QUERIES.COUNTRIES_LIST], queryFn: directionsApi.fetchDirectionCountriesList},
  ])

  useEffect(() => {
    LayoutSetup.updatePartialConfig({toolbar: {...config.toolbar, layout: 'none'}})
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <AcceptTracksListWrapper />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/accept-tracks/list`}
            state={{
              create: true,
            }}
          />
        }
      />
    </Routes>
  )
}

export default AcceptTracksPage
