/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedTime} from 'react-intl'
import {FlagsDirectionBar} from '../../../../../../../modules/custom/FlagsDirectionBar'
import FlagsDirectionItem from '../../../../../../../modules/custom/FlagsDirectionItem'
import {IPayment} from '../../../../core/_models'

type Props = {
  payment: IPayment
}

const PaymentDateCell = ({payment}: Props) => {
  return (
    <div className='d-flex'>
      {payment?.direction.countryFrom && payment?.direction.countryTo && (
        <ul className={`FlagsDirectionBar px-0 mb-0 me-2`}>
          <FlagsDirectionItem
            from={payment?.direction.countryFrom}
            to={payment?.direction.countryTo}
            minified={true}
          />
        </ul>
      )}
      <div className='p-1 fs-7 S fw-semobold d-flex flex-column'>
        <span className='text-dark'>
          <FormattedDate year='numeric' month='numeric' day='numeric' value={payment.createdAt} />
        </span>
        <span>
          {' '}
          <FormattedTime
            value={new Date(payment.createdAt)}
            hour='numeric'
            minute='numeric'
            second='numeric'
          />
        </span>
      </div>
    </div>
  )
}

export {PaymentDateCell}
