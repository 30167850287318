import {FC} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {IShipment} from '../../../../core/_models/_models'

type Props = {
  shipment: IShipment
}

const ShipmentWarehouseCell: FC<Props> = ({shipment}) => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold d-block fs-6 me-1'>{shipment.location.name}, </p>

      <p className='fw-semobold d-block fs-6 me-1'>
        {countries[shipment.location.countryConfig.country]}
      </p>
    </div>
  )
}

export {ShipmentWarehouseCell}
