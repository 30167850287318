import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useQueries, useQuery} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import PartialPageLoader from '../../../../../_metronic/layout/core/PartialPageLoader'
import {paymentProvidersApi} from '../core/_requests'
import {IPaymentProvider} from '../core/_models'
import {ProviderOverview} from './tabs/overview/ProviderOverview'
import {ProviderHeader} from './header/ProviderHeader'
import {generalApi} from '../../../../../_metronic/helpers/custom/api/generalApi'

interface Props {
  id: string | number
}

const ProviderPage = ({id}: Props) => {
  const {data: providerById} = useQuery(QUERIES.DIRECTION_BY_ID, () =>
    paymentProvidersApi.getProviderById(id)
  )

  const [currProvider, setCurrentProvider] = useState<IPaymentProvider | undefined>(undefined)

  useEffect(() => {
    if (providerById) {
      setCurrentProvider(providerById)
    }
  }, [providerById])

  return (
    <>
      {currProvider && providerById ? (
        <Routes>
          <Route
            element={
              <>
                <ProviderHeader provider={currProvider} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <ProviderOverview provider={currProvider} setCurrProvider={setCurrentProvider} />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      ) : (
        <PartialPageLoader fullHeight={true} />
      )}
    </>
  )
}

export default ProviderPage
