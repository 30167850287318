import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {establishOwnerFromDomain} from '../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {ITranslationObject} from '../../../websites/core/_models'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {ClientTranslationsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH

const COMPANY_ENDPOINT = '/company'
const KEYS_ENDPOINT = '_translation_keys'
const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API_URL}${COMPANY_ENDPOINT}`

const getTranslations = (query: string): Promise<ClientTranslationsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}${KEYS_ENDPOINT}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ClientTranslationsQueryResponse>) => {
      return d.data
    })
}

const getTranslationById = (id: ID) => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}${KEYS_ENDPOINT}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => console.log(err))
}

const fetchTranslationsButton = () => {
  return axios
    .post(`${BASE_URL}/translations_update`, {headers: {'Content-Type': 'application/json'}})
    .then((data) => {
      return data.data
    })
}

const editTranslation = (
  key: {keyName: string; translations: {locale: string; value: string}[]},
  id: ID
) => {
  return axios.put(`${BASE_URL}${KEYS_ENDPOINT}/${id}`, key).then((response) => {
    return response.data
  })
}

export {getTranslations, fetchTranslationsButton, getTranslationById, editTranslation}
