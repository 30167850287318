import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {IShipment} from '../../../../core/_models/_models'

type Props = {
  shipment: IShipment
}

const ShipmentActionsCell: FC<Props> = ({shipment}) => {
  return (
    <div className='d-flex justify-content-center align-items-center flex-shrink-0'>
      <Link
        to={`/shipments/edit/overview/${shipment.id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>
    </div>
  )
}

export {ShipmentActionsCell}
