import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}

const StatusFilter = ({savedFilter, setSavedFilter}: TProps) => {
  return (
    <div className='mb-5'>
      <label className='form-label fw-bold'>Статус: </label>

      <div className='d-flex flex-wrap flex-gutter'>
        <label className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
          <input
            className='form-check-input'
            name='status'
            type='radio'
            value={undefined}
            checked={savedFilter?.status === undefined}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, status: undefined}
              })
            }}
          />
          <span className='form-check-label'>
            <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL' />
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
          <input
            className='form-check-input'
            name='status'
            type='radio'
            value={1}
            checked={savedFilter?.status === 1}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, status: 1}
              })
            }}
          />
          <span className='form-check-label'>
            Очікує на підтвердження
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' /> */}
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
          <input
            className='form-check-input'
            name='status'
            type='radio'
            value={2}
            checked={savedFilter?.status === 2}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, status: 2}
              })
            }}
          />
          <span className='form-check-label'>
            Оплачено
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' /> */}
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
          <input
            className='form-check-input'
            name='status'
            type='radio'
            value={4}
            checked={savedFilter?.status === 4}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, status: 4}
              })
            }}
          />
          <span className='form-check-label'>
            Зарезервовано
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' /> */}
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            name='status'
            type='radio'
            value={3}
            checked={savedFilter?.status === 3}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, status: 3}
              })
            }}
          />
          <span className='form-check-label'>
            Відхилено
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' /> */}
          </span>
        </label>
      </div>
    </div>
  )
}

export default StatusFilter
