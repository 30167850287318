import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
interface Props {
  props: any
}

export function SelectInputTemplate({props}: Props) {
  const {
    options,
    selectName,
    disabled,
    title,
    required,
    destructuredProperties,
    transformationFunction,
    argumentsForTransformation,
    backgroundClass,
  } = props
  return (
    <Field
      as='select'
      name={selectName}
      className={`form-select form-select-solid ${disabled ? 'disabled' : ''} ${
        backgroundClass ? backgroundClass : ''
      }`}
      disabled={disabled}
    >
      <option></option>

      {options?.map((option: any, index: number) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </Field>
  )
}
