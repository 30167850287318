/* eslint-disable jsx-a11y/anchor-is-valid */
import {ICategoryForm, ICategory} from '../../core/_models'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {FC} from 'react'

type Props = {
  category: ICategory
}

const CategoryStatusCell: FC<Props> = ({category}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span
          className={`btn p-1 me-2 mb-2 fw-semobold fs-9 StatusBar__status ${statusColor(
            category.enabled
          )}`}
        >
          {category.enabled === true ? 'Активно' : 'Заблокировано'}
        </span>
      </div>
    </div>
  )
}

export {CategoryStatusCell}
