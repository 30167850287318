import {FC} from 'react'

import {ITranslationObject} from '../../../../../core/_models'

type Props = {
  translation: ITranslationObject
}

const TranslationsCell: FC<Props> = ({translation}) => {
  return (
    <div className='d-flex TranslationsCell'>
      <div className='d-flex flex-column fs-7'>
        {translation.translations.map(({locale, value}, index) => (
          <p key={index}>
            {locale}: {value}
          </p>
        ))}
      </div>
    </div>
  )
}

export {TranslationsCell}
