/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverOwnerCell: FC<Props> = ({receiver}) => {
  return (
    <div className='d-flex align-items-center'>
      {receiver.user ? (
        <Link to={`/users/edit/overview/${receiver.user.id}`}>
          {' '}
          <div className='d-flex flex-column'>
            <span className='fw-semobold text-black d-block fs-6'>
              {receiver.user.externalId || receiver.user.id || '-'}
            </span>
            <span className='fw-normal d-block fs-7'>
              {receiver.user.firstName} {receiver.user.lastName}
            </span>
          </div>
        </Link>
      ) : (
        'Загальний'
      )}
    </div>
  )
}

export {ReceiverOwnerCell}
