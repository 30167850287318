/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUser, login} from '../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../core/Auth'
import {setYupLocale} from '../../../../_metronic/helpers/YupIntl'
import {emailRegex, passwordRegex, phoneRegex} from '../../../../_metronic/helpers/custom/regexs'
import {getAuth, removeAuth} from '../core/AuthHelpers'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const location = useLocation()

  let {token: paramsToken} = useParams()

  const try_token_auth = async () => {
    const {data: user} = await getUser()
    setCurrentUser(user)
  }

  useEffect(() => {
    if (paramsToken) {
      saveAuth({token: paramsToken, email: currentUser?.email || 'test'})
      try_token_auth()
    }
  }, [paramsToken])

  // @ts-ignore
  initialValues.email = location.state?.email || initialValues.email
  const intl = useIntl()
  setYupLocale(intl)
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .min(5)
      .max(50)
      .required()
      .test('username', intl.formatMessage({id: 'VALIDATION.USERNAME'}), function (value) {
        value = value ? value : ''
        const phoneValue = value.replace(/\D/g, '')
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(phoneValue)
        return !(!isValidEmail && !isValidPhone)
      }),
    password: Yup.string()
      .min(6)
      .max(20)
      .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
      .required(),
  })
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, currentUser} = useAuth()

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStatus(false)
      setLoading(true)
      let username = values.email
      const phoneValue = username.replace(/\D/g, '')
      let isValidPhone = phoneRegex.test(phoneValue)
      if (isValidPhone) username = phoneValue
      let authData = undefined
      try {
        if (username === getAuth()?.token) {
          removeAuth()
        }
        authData = await login(username, values.password, intl.locale)
      } catch (error) {
        console.log(error)
      } finally {
        if (authData) {
          saveAuth({token: authData.data.token, email: username})
          try_token_auth()
        } else {
          setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}))
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          <FormattedMessage id='AUTH.LOGIN.FIRST_VISIT' />{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            <FormattedMessage id='AUTH.REGISTER.CREATE' />
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          <FormattedMessage id='AUTH.INPUT.EMAIL' />
        </label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex flex-stack mb-2 mt-n5'>
          {/* begin::Label */}
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
            <FormattedMessage id='AUTH.INPUT.PASSWORD' />
          </label>
          {/* end::Label */}
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        {/* <div className='mt-1 d-flex justify-content-end'>
          <Link
            to='/auth/forgot-password'
            state={{email: formik.values.email}}
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />?
          </Link>
        </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='fv-row text-center w-100'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='AUTH.GENERAL.CONTINUE' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='AUTH.GENERAL.WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
