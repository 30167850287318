import {
  IBasicSelect,
  ICheckboxProps,
  IReactSelectProps,
  ISelectProps,
  ISetFieldValue,
} from '../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ErrorMessage} from 'formik'
import {RadioCheckTemplate} from './RadioCheckTemplate'
import {NumberTextInput} from './NumberTextInput'
import {TextareaTemplate} from './TextareaTemplate'
import {PhoneNumberInput} from './PhoneNumberInput'
import {ReactNode} from 'react'
import {ModalNotification} from '../modals/ModalNotification'
import {FormattedMessage} from 'react-intl'
import {SelectTemplateWrapper} from './SelectTemplateWrapper'
import {ReactSelectTemplate} from './ReactSelectTemplate'

const radioCheckClass = 'fs-6 row-reverse justify-content-end'
const basicClass = 'fs-5 flex-column'

interface Props {
  inputName: string
  type: string
  required?: boolean
  title?: string
  placeholder?: string
  promptText?: string
  value?: string | number | IBasicSelect
  marginClass?: string
  backgroundClass?: string
  selectProps?: ISelectProps
  reactSelectprops?: IReactSelectProps
  promptSign?: string
  addSymbol?: string | ReactNode
  containerMarginBottom?: boolean
  titleFontSize?: string
  fieldWidth?: string
  symbolMarginTop?: boolean
  autoFocus?: boolean
  inputFontSize?: string
  disabled?: boolean
  setFieldValue?: ISetFieldValue
  numbersArray?: boolean
  phoneArrayOnChange?: (index: number, name: string, value: string) => void
  arrayIndex?: number
  checkboxProps?: ICheckboxProps
  handleChange?: any
  defaultValue?: string | number | IBasicSelect
  objectErrorKey?: string
  custom?: ReactNode
  numberWithControls?: boolean
}

function FloatingInputTemplate({
  title,
  promptText,
  inputName,
  placeholder,
  required = false,
  type,
  marginClass,
  backgroundClass,
  value,
  selectProps,
  reactSelectprops,
  promptSign,
  addSymbol,
  containerMarginBottom,
  titleFontSize,
  inputFontSize,
  fieldWidth,
  symbolMarginTop,
  autoFocus,
  disabled,
  setFieldValue,
  numbersArray = true,
  phoneArrayOnChange,
  arrayIndex,
  checkboxProps,
  handleChange,
  defaultValue,
  objectErrorKey,
  custom,
  numberWithControls,
}: Props) {
  return (
    <div
      className={`form-floating ${fieldWidth ? fieldWidth : 'w-100'}  ${
        marginClass ? marginClass : ''
      } ${containerMarginBottom === false ? '' : 'mb-5'}`}
    >
      {/*  INPUT TYPE DIVISION */}

      {custom ? (
        custom
      ) : (
        <>
          {' '}
          {/* select */}
          {type === 'select' && selectProps && (
            <SelectTemplateWrapper selectProps={{...selectProps, backgroundClass}} />
          )}
          {type === 'select' && reactSelectprops && (
            <ReactSelectTemplate props={{...reactSelectprops, backgroundClass, disabled}} />
          )}
          {/* number || text */}
          {(type === 'number' || type === 'text' || type === 'mail') && (
            <NumberTextInput
              inputName={inputName}
              placeholder={placeholder}
              type={type}
              disabled={disabled}
              backgroundClass={backgroundClass}
              autoFocus={autoFocus}
              inputFontSize={inputFontSize}
              handleChange={handleChange}
            />
          )}
          {/* tel */}
          {type === 'tel' &&
            setFieldValue &&
            (typeof value === 'string' || typeof value === 'number' || value === null) && (
              <PhoneNumberInput
                inputName={inputName}
                setFieldValue={setFieldValue}
                value={value}
                numbersArray={numbersArray}
                handleChange={phoneArrayOnChange}
                index={arrayIndex}
              />
            )}
          {/* radio || checkbox */}
          {(type === 'radio' || type === 'checkbox') && checkboxProps && (
            <RadioCheckTemplate props={checkboxProps} />
          )}
          {/* textarea */}
          {type === 'textarea' && (
            <TextareaTemplate
              inputName={inputName}
              placeholder={placeholder}
              backgroundClass={backgroundClass}
              inputFontSize={inputFontSize}
              disabled={disabled}
            />
          )}
        </>
      )}
      {addSymbol && (
        <span
          className={`fs-6 fw-normal position-absolute top-25 mt-1 translate-middle-y end-0 me-5`}
        >
          {addSymbol}
        </span>
      )}

      <label
        // className={`d-flex position-relative ${
        //   type === 'radio' || type === 'checkbox' ? radioCheckClass : basicClass
        // }  ${addSymbol ? 'me-2' : ''}
        //  fw-bold mb-2`}
        htmlFor={inputName}
      >
        <div className='mb-2'>
          {' '}
          <span
            className={` ${titleFontSize ? titleFontSize : 'fs-6'} ${required ? 'required' : ''} ${
              type === 'radio' ? 'fw-normal' : ''
            }`}
          >
            {title}
          </span>
          {promptText && (
            <ModalNotification
              svg={promptSign ? promptSign : '/media/icons/duotune/general/gen046.svg'}
              addClass='ModalNotification__position'
              children={
                <div className='fs-7 fw-normal'>
                  <FormattedMessage id={promptText} />
                </div>
              }
            />
          )}
        </div>
      </label>

      <div className='text-danger'>
        <ErrorMessage name={objectErrorKey ? objectErrorKey : inputName} />
      </div>
    </div>
  )
}

export {FloatingInputTemplate}
