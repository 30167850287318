import {useQueryClient} from 'react-query'
import {QUERIES, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../websites/core/_models'
import {ITelegramTranslation} from '../../core/_models'
import TranslationHeaderLangSwitcher from './TranslationHeaderLangSwitcher'
import {TranslationHeaderName} from './TranslationHeaderName'
import {TranslationHeaderNav} from './TranslationHeaderNav'

interface Props {
  translation: ITelegramTranslation
}

const TranslationHeader = ({translation}: Props) => {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === translation.website?.id)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed fit-content position-relative'>
                <img
                  src={toAbsoluteUrl('/media/custom/svg/telegram-app.svg')}
                  alt='Template'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <TranslationHeaderName translation={translation} />
            </div>
          </div>
          <TranslationHeaderLangSwitcher translation={translation} website={website} />
        </div>

        <TranslationHeaderNav translation={translation} />
      </div>
    </div>
  )
}

export {TranslationHeader}
