import {ICountry} from '../../countries-list/core/_models'
interface Props {
  country: ICountry
}

export function CountryHeaderInfo({country}: Props) {
  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex flex-column fw-bold fs-6 mb-4 pe-2'>
        <div className='btn btn-sm btn-light-primary fw-bold px-4'>
          {country.send === true ? 'Відправник' : 'Отримувач'}
        </div>
      </div>
      <div className='card-toolbar mb-2'>
        {/* <div className='d-flex'>
          {' '}
          {location.international === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center me-5 mb-2 fs-8'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER' />
          </span>
        </div>
        <div className='d-flex'>
          {location.final === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center fs-8  me-5 mb-2'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL' />
          </span>
        </div>
        <div className='d-flex'>
          {location.withinCountry === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center fs-8  me-5 mb-2'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.LOCAL' />
          </span>
        </div> */}
      </div>
    </div>
  )
}
