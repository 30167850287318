import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {PaymentActionsCell} from './cells/PaymentActionsCell'
import {PaymentNameCell} from './cells/PaymentNameCell'
import {PaymentStatusCell} from './cells/PaymentStatusCell'
import {IPayment} from '../../../core/_models'
import {PaymentUserCell} from './cells/PaymentUserCell'
import {PaymentDateCell} from './cells/PaymentDateCell'
import {PaymentSumCell} from './cells/PaymentSumCell'

const paymentsColumns: ReadonlyArray<Column<IPayment>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'SHIPMENTS_COLUMNS_DATE'} className='min-w-50px' />
    ),
    id: 'date',
    Cell: ({...props}) => <PaymentDateCell payment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_NAME'}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <PaymentNameCell payment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'PAYMENTS_COLUMNS_TYPE'} className='min-w-125px' />
    ),
    id: 'sum',
    Cell: ({...props}) => <PaymentSumCell payment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'TRACKS_COLUMNS_USER'} className='min-w-75px' />
    ),
    id: 'user',
    Cell: ({...props}) => <PaymentUserCell payment={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'DELIVERY_TYPES_COLUMNS_STATUS'}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <PaymentStatusCell payment={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <CustomHeader
  //       tableProps={props}
  //       title={'COLUMNS_ACTIONS'}
  //       className='min-w-120px text-center'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <PaymentActionsCell payment={props.data[props.row.index]} />,
  // },
]

export {paymentsColumns}
