import {Formik, Form} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {IDeliveryType, IDeliveryTypeForm} from '../../../core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {deliveryTypesApi} from '../../../core/_requests'
import Name from '../../../elements/Name'
import Image from '../../../elements/Image'
import Direction from '../../../elements/Direction'
import DeliveryTime from '../../../elements/DeliveryTime'
import PriceRangeType from '../../../elements/PriceRangeType'
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap'
import {Link, Navigate, useNavigate} from 'react-router-dom'

interface Props {
  deliveryType: IDeliveryType
  setCurrDeliveryType: (obj: IDeliveryType) => void
}

export function DeliveryTypeOverview({deliveryType, setCurrDeliveryType}: Props) {
  const [showTooltip, setShowTooltip] = useState(false)

  const navigate = useNavigate()
  const initialValues: Partial<IDeliveryTypeForm> = {
    title: deliveryType.title,
    avatar: deliveryType.logo,
    direction: deliveryType.direction?.id,
    priceRangeType: deliveryType.rate.priceRangeType,
    deliveryTime: deliveryType.deliveryTime,
  }

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IDeliveryTypeForm>) => {
    setLoading(true)

    const {avatar, direction, title, deliveryTime, priceRangeType} = values

    const deliveryTypeData = {
      logo: avatar?.logo || '',
      id: deliveryType.id,
      direction: {id: direction},
      title,
      deliveryTime: deliveryTime,
      rate: {
        ...deliveryType.rate,
        priceRangeType: priceRangeType ? +priceRangeType : null,
      },
    }
    const response = await deliveryTypesApi.editDeliveryType(deliveryTypeData)

    if (response) {
      queryClient.invalidateQueries([QUERIES.DELIVERY_TYPE_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        // validationSchema={trackPageOverviewSchema(intl)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, errors, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(errors)} */}
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Назва</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <Name withTitle={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Аватар </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <Image deliveryType={deliveryType} withTitle={false} />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Напрямок</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <Direction withTitle={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>Зона дії</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <PriceRangeType withTitle={false} />
                    </div>
                    <div className='col-lg-4'>
                      <OverlayTrigger
                        placement='top'
                        show={showTooltip}
                        overlay={
                          <Tooltip
                            style={{position: 'fixed'}}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                          >
                            <span>Щоб скорегувати суму безмитного ввезення, перейдіть за </span>
                            <Link
                              to={`/directions/edit/params/${values.direction}`}
                              className='btn btn-link text-hover-primary fs-7 p-0'
                            >
                              посиланням
                            </Link>
                          </Tooltip>
                        }
                      >
                        {/* <Link to={`/directions/edit/params/${values.direction}`} className='m-0'> */}
                        <div
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                        >
                          {' '}
                          <KTSVG
                            path='/media/icons/duotune/general/gen046.svg'
                            className='svg-icon-3'
                          />
                        </div>
                        {/* </Link> */}
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  Термін доставки (к-сть днів)
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <DeliveryTime withTitle={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
