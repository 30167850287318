/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
} from '../../../../_metronic/helpers'
import {ShipmentsTable} from './table/ShipmentsTable'
import {ShipmentsListHeader} from './components/header/ShipmentsListHeader'
import {useEffect} from 'react'
import CreateShipmentModal from '../_modals/CreateShipmentModal'

const ShipmentsList = () => {
  const {filterUsers, setItemIdForUpdate, itemIdForUpdate} = useListView()

  const {updateState} = useQueryRequest()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.LOCATIONS_LIST)

    if (filterUsers) filterUsers('')
    const state = {
      ...initialQueryState,
      itemsPerPage: retrievedData,
    }

    if (savedFilter) {
      state.filter = savedFilter
    }

    updateState(state)
  }, [])

  const openAddTrackModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      <div
        style={{zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>Відправки</h3>
        <div className='d-flex flex-gutter'>
          <button type='button' className='btn btn-sm btn-primary' onClick={openAddTrackModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Додати
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.ADD_BUTTON' /> */}
          </button>
        </div>
      </div>
      <KTCard>
        <ShipmentsListHeader />
        <ShipmentsTable />

        {itemIdForUpdate !== undefined && <CreateShipmentModal />}
      </KTCard>
    </>
  )
}

const ShipmentsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ShipmentsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ShipmentsListWrapper}
