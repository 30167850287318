import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {DirectionActionsCell} from './cells/DirectionActionsCell'
import {DirectionNameCell} from './cells/DirectionNameCell'
import {DirectionTypeCell} from './cells/DirectionTypeCell'
import {Direction} from '../../../core/_models'

const directionsColumns: ReadonlyArray<Column<Direction>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'DIRECTIONS_COLUMNS_NAME'} className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <DirectionNameCell direction={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title={'DIRECTIONS_COLUMNS_TYPE'} className='min-w-125px' />
    ),
    id: 'type',
    Cell: ({...props}) => <DirectionTypeCell direction={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <DirectionActionsCell direction={props.data[props.row.index]} />,
  },
]

export {directionsColumns}
