import * as Yup from 'yup'
import {
  basicNameSchema,
  object_shape_basic_select,
  patronymicOptionalSchema,
  phoneNumberSchema,
} from '../../../../../_metronic/helpers/custom/formik-schemas/partials/generalPartials'
import {emailSchema} from '../../../../pages/fulfillment/receivers/receivers-list/core/yup/partials'

const createUserValidationSchema = (intl: any) =>
  Yup.object().shape({
    group: object_shape_basic_select.required(),
    website: object_shape_basic_select.required(),
    phoneNumber: phoneNumberSchema(intl),
    email: emailSchema.required('Вкажіть пошту'),
    password: Yup.string().min(6, 'Введіть щонайменше 6 символів').required('Вкажіть пароль'),
    lastName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_LAST_NAME),
    firstName: basicNameSchema(intl, intl.messages.YUP_VALIDATION_GENERAL_ENTER_NAME),
    patronymic: patronymicOptionalSchema(intl),
  })

export {createUserValidationSchema}
