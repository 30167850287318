import {Formik, Form} from 'formik'
import {useState} from 'react'
import {useQueryClient} from 'react-query'
import {IDeliveryType, IDeliveryTypeForm} from '../../../core/_models'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {deliveryTypesApi} from '../../../core/_requests'
import {minValueInit} from '../../../../../../_metronic/helpers/custom/funcs/delivery-types'
import {objectToArray2} from '../../../../../../_metronic/helpers/custom/funcs/objectToArray'
import VolWeight from '../../../elements/VolWeight'
import PriceRangeType from '../../../elements/PriceRangeType'
import VolWeightCalculation from '../../../elements/VolWeightCalculation'
import {deliveryTypeSettingsSchema} from '../../../core/yup'
import OversizeAddFee from '../../../elements/OversizeAddFee'

interface Props {
  deliveryType: IDeliveryType
  setCurrDeliveryType: (obj: IDeliveryType) => void
}

export function DeliveryTypeSettings({deliveryType, setCurrDeliveryType}: Props) {
  const minValueInitValue = minValueInit(
    deliveryType.rate.data.min_weight,
    deliveryType.rate.data.min_price
  )

  const deliveryTwoItemsInits = deliveryType.rate.data.price
    ? [{weight: 1, specificWeightPrice: 0}]
    : objectToArray2(deliveryType.rate.data)
  const [deliveryTwoFields, setDeliveryTwoFields] = useState(deliveryTwoItemsInits)
  const initialValues: Partial<IDeliveryTypeForm> = {
    volumetricWeightType: deliveryType.volumetricWeightType,
    volumetricWeightToWeightRatio: deliveryType.volumetricWeightToWeightRatio || '',
    volumetricWeightMinValue: deliveryType.volumetricWeightMinValue || '',
    volumetricWeightPriceMethod: deliveryType.volumetricWeightPriceMethod || 'standard',
    volumetricWeightDivisor: deliveryType.volumetricWeightDivisor
      ? +deliveryType.volumetricWeightDivisor
      : '',
    volumetricWeightCombinedRate:
      deliveryType.volumetricWeightCombinedRate &&
      deliveryType.volumetricWeightPriceMethod === 'combined'
        ? +deliveryType.volumetricWeightCombinedRate
        : '',
    palletLength: deliveryType.palletLength || '',
    palletOversizeFee: deliveryType.palletOversizeFee || '',
    palletWidth: deliveryType.palletWidth || '',
  }

  const queryClient = useQueryClient()

  const countriesList: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  const getCountries: Record<string, string> = countriesList

  const [success, setSuccess] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: Partial<IDeliveryTypeForm>) => {
    setLoading(true)

    const {
      volumetricWeightType,
      volumetricWeightMinValue,
      volumetricWeightPriceMethod,
      volumetricWeightCombinedRate,
      volumetricWeightToWeightRatio,
      volumetricWeightDivisor,
      palletWidth,
      palletLength,
      palletOversizeFee,
    } = values

    const deliveryTypeData = {
      id: deliveryType.id,
      volumetricWeightType: volumetricWeightType ? +volumetricWeightType : null,
      volumetricWeightMinValue: volumetricWeightMinValue ? +volumetricWeightMinValue : 0,
      volumetricWeightPriceMethod,
      volumetricWeightDivisor:
        volumetricWeightType !== undefined &&
        volumetricWeightType !== undefined &&
        volumetricWeightDivisor !== undefined &&
        (+volumetricWeightType === 1 || +volumetricWeightType === 2)
          ? +volumetricWeightDivisor
          : 0,
      volumetricWeightCombinedRate:
        volumetricWeightCombinedRate && volumetricWeightPriceMethod === 'combined'
          ? +volumetricWeightCombinedRate
          : 0,
      volumetricWeightToWeightRatio: volumetricWeightToWeightRatio
        ? +volumetricWeightToWeightRatio
        : 0,
      palletWidth: palletWidth ? +palletWidth : 0,
      palletLength: palletLength ? +palletLength : 0,
      palletOversizeFee: palletOversizeFee ? +palletOversizeFee : 0,
    }
    const response = await deliveryTypesApi.editDeliveryType(deliveryTypeData)

    if (response) {
      queryClient.invalidateQueries([QUERIES.DELIVERY_TYPE_BY_ID])
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        validationSchema={deliveryTypeSettingsSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, errors, setFieldValue}) => (
          <Form noValidate className='form'>
            {/* {JSON.stringify(values.volumetricWeightPriceMethod)} */}
            {/*  {JSON.stringify(values.volumetricWeightType)}
            {JSON.stringify(values.volumetricWeightToWeightRatio)}
            {JSON.stringify(errors)} */}

            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  Об'ємна вага
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <VolWeight values={values} withTitle={false} />
                    </div>
                  </div>
                </div>
              </div>{' '}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  Методика розрахунку об'ємної ваги
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-8 fv-row'>
                      <VolWeightCalculation values={values} withTitle={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6 required'>
                  Надбавка за перевищення розміру палети:
                </label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <OversizeAddFee values={values} withTitle={false} />
                  </div>
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={success} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
