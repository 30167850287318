import {websitesApi} from '../../core/_requests'
import {useParams} from 'react-router-dom'
import {useQueries} from 'react-query'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {TranslationListWrapper} from './components/TranslationList'
import {QUERIES} from '../../../../../_metronic/helpers'

function TranslationBoard() {
  const {id} = useParams()

  const websiteId = id ? +id : 0
  const [{data: websitesData}] = useQueries([
    {queryKey: [QUERIES.WEBSITES_LIST], queryFn: websitesApi.fetchWebsites},
  ])

  return (
    <div>
      <PageTitle>{`Переводы ${
        websitesData?.find((item) => item.id === websiteId)?.host
      }`}</PageTitle>
      <TranslationListWrapper />
    </div>
  )
}

export default TranslationBoard
