import {useEffect} from 'react'
import {string} from 'yup'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {
  initialQueryState,
  QueryRequestContextProps,
  QueryResponseContextProps,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../_metronic/helpers'
import {Employee} from '../../../../pages/employees/employees-list/core/_models'
import {User} from '../../../../pages/users/core/_models'

type TProps = {
  useQueryRequest: QueryRequestContextProps
  useQueryResponse: QueryResponseContextProps<Employee | User>
  queriesKey: string
}

const ListFilter = ({useQueryRequest, useQueryResponse, queriesKey}: TProps) => {
  const {updateState, state}: any = useQueryRequest
  const {isLoading} = useQueryResponse

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(queriesKey), '')
  }

  const filterData = (filter: Record<string, boolean>) => {
    updateState({
      ...initialQueryState,
      filter,
    })
    const data = JSON.stringify(filter)
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(queriesKey), data)
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm ${
          !state.filter?.enabled ? 'btn-primary' : ' btn-light-primary'
        } fw-bold me-3`}
        onClick={() => {
          resetData()
        }}
      >
        Всі
      </button>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm fw-bold me-3 ${
          state.filter?.enabled === true ? 'btn-primary' : ' btn-light-primary'
        }`}
        onClick={() => {
          filterData({...state.filter, enabled: true})
        }}
      >
        Активні
      </button>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm ${
          state.filter?.enabled === false ? 'btn-primary' : ' btn-light-primary'
        } fw-bold me-3 `}
        onClick={() => {
          filterData({...state.filter, enabled: false})
        }}
      >
        Заблоковані
      </button>
    </>
  )
}

export {ListFilter}
