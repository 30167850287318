import {useListView} from '../../../core/ListViewProvider'
import {PaymentProvidersListToolbar} from './PaymentProvidersListToolbar'
import ListSearch from '../../../../../../modules/custom/lists/ListSearch'
import {useQueryRequest} from '../../../core/QueryRequestProvider'

const PaymentProvidersListHeader = () => {
  const {selected} = useListView()
  const {updateState} = useQueryRequest()
  return (
    <div className='card-header border-0 pt-6'>
      {/* <ListSearch updateState={updateState} /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <PaymentProvidersListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PaymentProvidersListHeader}
