import {Modal} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {ID} from '../../../../_metronic/helpers'

interface Props {
  setModalOpen: (bool: boolean) => void
  id?: ID
  handleAction: (id?: ID) => void
  isOpen: boolean
  actionName: string
  customAction?: string
  customOptions?: {appr: string; decline: string}
}
export function ActionConfirmation({
  setModalOpen,
  id,
  handleAction,
  isOpen,
  actionName,
  customAction,
  customOptions,
}: Props) {
  const intl = useIntl()
  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-300px'
      id='kt_modal_create_app'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered mw-300px'>
        <div className='modal-content  DeleteConfirmation__modal'>
          <div className='swal2-icon swal2-warning swal2-icon-show'>
            {' '}
            <div className='swal2-icon-content'>!</div>
          </div>

          <p className='fs-5 fw-bold DeleteConfirmation__confirm'>{`${intl.formatMessage({
            id: customAction || 'COMPONENTS.ActionConfirmation.QUESTION_BASE',
          })} ${actionName}?`}</p>
          <div className='d-flex DeleteConfirmation__controllers'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-dark-primary me-2 min-w-100px'
              data-kt-menu-dismiss='true'
              onClick={() => setModalOpen(false)}
            >
              <FormattedMessage
                id={customOptions?.decline || 'COMPONENTS.ActionConfirmation.CANCEL_BUTTON'}
              />
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                id ? handleAction(id) : handleAction()
              }}
            >
              <FormattedMessage
                id={customOptions?.appr || 'COMPONENTS.ActionConfirmation.CONF_BUTTON'}
              />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
