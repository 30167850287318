/* eslint-disable jsx-a11y/anchor-is-valid */
import {IStatus} from '../../core/_models'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {FC} from 'react'

type Props = {
  status: IStatus
}

const ActiveCell: FC<Props> = ({status}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span
          className={`btn p-1 me-2 mb-2 fw-semobold fs-9 StatusBar__status ${statusColor(
            status.showClient
          )}`}
        >
          {status.showClient === true ? 'Активно' : 'Заблокировано'}
        </span>
      </div>
    </div>
  )
}

export {ActiveCell}
