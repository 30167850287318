import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}
const TypeFilter = ({savedFilter, setSavedFilter}: TProps) => {
  return (
    <div className='mb-10'>
      <label className='form-label fw-bold'>Тип:</label>

      <div className='d-flex'>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='type'
            type='radio'
            value={undefined}
            checked={savedFilter?.type === undefined}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, type: undefined}
              })
            }}
          />
          <span className='form-check-label'>
            <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL' />
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='type'
            type='radio'
            value={'commerce'}
            checked={savedFilter?.type === 'commerce'}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, type: 'commerce'}
              })
            }}
          />
          <span className='form-check-label'>
            Комерційні
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' /> */}
          </span>
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            name='type'
            type='radio'
            value={'buyout'}
            checked={savedFilter?.type === 'buyout'}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, type: 'buyout'}
              })
            }}
          />
          <span className='form-check-label'>
            Викупи
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' /> */}
          </span>
        </label>
      </div>
    </div>
  )
}

export default TypeFilter
