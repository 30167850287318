import {FC, useState, createContext, useContext, MutableRefObject} from 'react'
import {
  initialPageView,
  PageViewContextProps,
  WithChildren,
} from '../../../../../../_metronic/helpers'

const PageViewContext = createContext<PageViewContextProps>(initialPageView)

const PageViewProvider: FC<WithChildren> = ({children}) => {
  const [anyChanges, setAnyChanges] = useState(initialPageView.anyChanges)
  const [formsToSave, setFormsToSave] = useState<Array<MutableRefObject<HTMLFormElement>>>(
    initialPageView.formsToSave
  )
  return (
    <PageViewContext.Provider value={{anyChanges, setAnyChanges, formsToSave, setFormsToSave}}>
      {children}
    </PageViewContext.Provider>
  )
}

const usePageView = () => useContext(PageViewContext)

export {PageViewProvider, usePageView}
