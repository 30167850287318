import {BuyoutLogsItem} from './BuyoutLogsItem'
import {FormattedMessage, useIntl} from 'react-intl'
import {IOrder, IOrderHistory} from '../../../../orders/core/_models/_orders-models'

interface Props {
  logs: IOrderHistory[]
}

export function BuyoutLogs({logs}: Props) {
  const intl = useIntl()

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='mw-100px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.DATE_COLUMN' />
                </th>

                <th className='min-w-200px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.STATUS_COLUMN' />
                </th>
                <th className='mw-100px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.AUTHOR_COLUMN' />
                </th>
              </tr>
            </thead>

            <tbody>
              {logs?.map((item, index) => (
                <BuyoutLogsItem key={index} log={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
