import {useQueryClient} from 'react-query'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import flags from '../../../../../data/general/flags.json'
import {IWebsitePage} from '../../website-pages-list/core/_models'
import {IWebsite} from '../../../websites/core/_models'

interface Props {
  page: IWebsitePage
  website: IWebsite
}

export function PageHeaderName({page, website}: Props) {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center mb-2 mb-md-none'>
              <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
                {page.name}
              </p>
            </div>
          </div>{' '}
          <a
            href={`${process.env.REACT_APP_HTTP}${website?.host}/${page.slug}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com001.svg'
              className='svg-icon-3 link-primary me-3'
            />
            <span className='text-dark text-hover-primary'>{`${website?.host}/${page.slug}`}</span>
          </a>
        </div>
      </div>
    </div>
  )
}
