import {useRef} from 'react'
import {TPrintGoodsItem} from '../../core/_contextHelpers'
import {GoodsSticker} from './GoodsSticker'
import {ActionButton} from './ActionButton'

interface Props {
  printQueue: TPrintGoodsItem[]
}

export const PostponedPrintQueue = ({printQueue}: Props) => {
  const componentToPrint = useRef(null)

  return (
    <div className='print-hidden'>
      <div ref={componentToPrint}>
        {printQueue.map((item, index) => (
          <GoodsSticker
            client={item.clientId}
            goodsNumber={item.goodsNumber}
            componentToPrint={null}
            key={index}
          />
        ))}
      </div>
      <ActionButton componentToPrint={componentToPrint} id={'print-queue'} />
    </div>
  )
}
