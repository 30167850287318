import {Form, Formik, FormikValues} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../../_metronic/helpers'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {ICategory, ICategoryForm} from '../../../core/_models'
import {updateCategory, addLogo} from '../../../core/_requests'
import {handleFileUpload} from '../../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {ISetFieldValue} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {FileInput} from '../../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {CheckboxSwitcher} from '../../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcher'

interface Props {
  category: ICategory
  invalidateCategory: () => void
}
const CategoryOverviewTab = ({category, invalidateCategory}: Props) => {
  const intl = useIntl()
  const {locale = 'uk'} = useParams()

  const initialValues: Partial<ICategoryForm> = {
    name: category?.translations[locale]?.name,
    active: category.enabled === true ? 1 : 0,
    customsCode: category.customsCode,
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const [imageObj, setImageObj] = useState<{logo: string; logoUrl: string} | null>({
    logo: category.logo || '',
    logoUrl: category.logoUrl || '',
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    try {
      setLoading(true)

      const linkResponse = await handleFileUpload(
        e,
        setFieldValue,
        'logo',
        'logo',
        addLogo,
        false,
        'imageUrl'
      )

      setImageObj(linkResponse)

      invalidateCategory()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const deleteImage = async (setFieldValue: ISetFieldValue) => {
    setFieldValue('image', '')

    setImageObj(null)
  }

  const handleSubmit = async (values: Partial<ICategoryForm>, actions: FormikValues) => {
    setLoading(true)

    try {
      const {active, synonyms, name, customsCode} = values
      const dataToSend: Partial<ICategory> = {
        id: category.id,
        customsCode,
        name,
        translations: {
          ...category.translations,
          [locale]: {
            name: name || '',
          },
        },
        enabled: active === 1 ? true : false,
        synonyms: synonyms,
        logo: imageObj?.logo || '',
      }

      await updateCategory(dataToSend)

      invalidateCategory()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
      actions.resetForm()
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div>
                <FileInput
                  title={intl.formatMessage({
                    id: 'GENERAL.UPLOAD_IMAGE_LABEL',
                  })}
                  inputName='image'
                  inputId={`${category.id}-category-file-input`}
                  uploadedFile={{
                    base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                    link: imageObj?.logoUrl || '',
                  }}
                  setFieldValue={setFieldValue}
                  uploadFunction={saveImage}
                  deleteFunction={deleteImage}
                  loading={loading}
                  proportionsTip='Додайте зображення формату 1:1'
                />
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='name' required={true} type='text' />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Митний код</label>

                <div className='col-lg-8'>
                  <InputTemplate
                    inputName='customsCode'
                    type='text'
                    titleFontSize='fs-5 text-dark'
                  />
                </div>
              </div>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Активація</label>

                <div className='col-lg-8'>
                  <CheckboxSwitcher
                    inputName='active'
                    firstOption='Заблокировать'
                    secondOption='Активировать'
                    // @ts-ignore
                    value={+values.active}
                    setFieldValue={setFieldValue}
                    bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
                  />
                </div>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CategoryOverviewTab
