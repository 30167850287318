import {Column} from 'react-table'
import {TemplateCustomHeader} from './TemplateCustomHeader'
import {EmailTemplate} from '../../core/_models'
import {TemplateActionsCell} from './cells/TemplateActionsCell'
import {TemplateNameCell} from './cells/TemplateNameCell'
import {TemplateIdCell} from './cells/TemplateIdCell'

const templatesColumns: ReadonlyArray<Column<EmailTemplate>> = [
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title={'TEMPLATES_COLUMNS_REGION'}
        className='min-w-50px'
      />
    ),
    id: 'id',
    Cell: ({...props}) => <TemplateIdCell template={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title={'TEMPLATES_COLUMNS_NAME'}
        className='min-w-125px text-center'
      />
    ),
    id: 'name',
    Cell: ({...props}) => <TemplateNameCell template={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <TemplateCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-120px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <TemplateActionsCell template={props.data[props.row.index]} />,
  },
]

export {templatesColumns}
